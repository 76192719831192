import React from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Restore } from '@material-ui/icons';
import MailOutlineOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#fff',
    color: ' #423d3d',
    fontSize: '13px',
    padding: '5px',
    whiteSpace: 'nowrap',
    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif;',
    border: '1px solid lightgrey',
  },
});

type UserActionsRendererProps = {
  userStatus?: string;
  userId?: number;
};

const UserActionsRenderer = ({ userStatus, userId }: UserActionsRendererProps) => {
  const classes = useStyles(); // Declare the 'classes' variable

  const mailIcon =
    userStatus === 'Not Activated' ? (
      <Tooltip title="Send Activation Mail" classes={{ tooltip: classes.tooltip }}>
        <MailOutlineOutlinedIcon fontSize="small" htmlColor="#0074CA" />
      </Tooltip>
    ) : null;
  return (
    <span style={{ position: 'relative', top: '10px' }}>
      <div className="iconContainer">{mailIcon}</div>
      <div className="iconContainer">
        <Tooltip title="View History" classes={{ tooltip: classes.tooltip }}>
          <Restore fontSize="small" htmlColor="#0074CA" />
        </Tooltip>
      </div>
      <div className="iconContainer">
        <Tooltip title="Edit" classes={{ tooltip: classes.tooltip }}>
          <CreateOutlinedIcon fontSize="small" htmlColor="#0074CA" />
        </Tooltip>
      </div>
    </span>
  );
};

export default UserActionsRenderer;
