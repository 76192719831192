import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { Firm } from 'api/profile/types';

const Remittance = () => {
  const history = useHistory();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { setUserFirm, profile } = profileCtx;
  const firms = profile?.firms;
  const firmId = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
  const matchedFirmData = firms?.find((firm: Firm) => firm.firmId === firmId);

  if (matchedFirmData) {
    window.localStorage.setItem('userFirm', JSON.stringify(matchedFirmData));
    setUserFirm && setUserFirm(matchedFirmData);
    history.push('/reports');
  }

  return null;
};

export default Remittance;
