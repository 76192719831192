import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { AgentNetTextInput } from 'ui-kit/inputs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Popper, Grid, IconButton, Typography, Paper, Card, Checkbox } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { ArrowDropDown, ArrowDropUp, Close } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import CloseIcon from 'ui-kit/icons/Close';

interface OptionType {
  id?: string | number;
  name: string;
  default?: boolean;
}
interface AutocompleteChecklistProps {
  options: OptionType[];
  label: string;
  onChange: (value: any) => void;
  onDelete?: (index: any) => void;
  value?: OptionType[];
  dialogText?: string;
  dialogTitle?: string;
  qaAttrPrefix?: string;
  qaBtnprefix?: string;
}

const popperWidth = 400;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '99%',
      padding: '0 0 24px',
    },
    tableHeader: {
      backgroundColor: '#F2F5F6',
      borderBottom: '1px solid #D9E0E5',
      fontWeight: 600,
      fontSize: '1.4rem',
    },
    tableHeaderCell: {
      padding: '1.2rem',
      paddingLeft: '0.8rem',
    },
    tableList: {},
    tableRow: {
      borderBottom: `1px solid #E0E0E0`,
    },
    tableCell: {
      padding: `1.2rem`,
      display: 'flex',
      //justifyContent: 'space-between',
      alignItems: 'center',
    },
    deleteRow: {
      padding: 0,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    my1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    checkbox: {
      borderRadius: '4px',
      width: '10px',
      marginRight: theme.spacing(1),
      transition: '.2s all',
    },
    deleteCell: {
      justifyContent: 'space-between',
    },
    ctaBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
      width: '98.8%',
    },
    popper: {
      backgroundColor: '#FFF',
      border: '1px solid #c4c4c4',
      borderRadius: '6px',
      zIndex: 2000,
    },
    popperWrap: {
      width: popperWidth,
      backgroundColor: '#fff',
    },

    popperContent: {
      width: 400,
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    },
    popperHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #c4c4c4',
      padding: '0 8px 0 24px',
    },
    popperClose: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    popperOption: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: '8px 16px',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
      width: '100% !important',
    },
    popperPaper: {
      margin: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    textInputWrap: {
      borderBottom: '1px solid #c4c4c4',
      padding: theme.spacing(2),
    },
    textInput: {
      '&>div': {
        paddingRight: '8px !important',
      },
    },
    autocompletePopper: {
      height: 0,
    },
    autocompleteOptionsPaper: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      width: popperWidth,
    },
  }),
);

const CustomPopper = (props: any) => {
  const classes = useStyles();
  //force the list to only open downwards
  return (
    <Popper
      className={classes.autocompletePopper}
      {...props}
      modifiers={{
        filp: { enabled: false },
        preventOverflow: {
          enabled: 'auto',
          boundariesElement: 'scrollParent',
        },
      }}
      popperOptions={{ placement: 'bottom-start' }}
      placement="bottom-start"
      disablePortal
    />
  );
};
const CustomPaper = ({ children }: any) => {
  const classes = useStyles();
  return <Card className={classes.autocompleteOptionsPaper}>{children}</Card>;
};
const AutocompleteChecklist = ({
  options,
  label,
  onChange,
  onDelete,
  value,
  dialogText,
  dialogTitle,
  qaAttrPrefix,
  qaBtnprefix,
}: AutocompleteChecklistProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>(value || []);
  const [dialog, setDialog] = React.useState<DialogInterface>({ show: false, id: undefined });

  const togglePopper = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  interface DialogInterface {
    show: boolean;
    id: any;
  }

  const modalConfirmation = (e: OptionType[]) => {
    setDialog({ show: true, id: e });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'autocomplete-checklist-popper' : undefined;

  const handleDelete = (e: any) => {
    const newOptions = selectedOptions.filter((option) => option.name !== e.name);
    setSelectedOptions(newOptions);
    onDelete && onDelete(e);
    onChange && onChange(newOptions);
    setDialog({ show: false, id: undefined });
  };

  const handleChange = (newValue: OptionType[]) => {
    setSelectedOptions(newValue);
    onChange && onChange(newValue);
  };

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  const [menuOpen, setMenuOpen] = useState(true);
  return (
    <>
      {dialog.id && (
        <AgentNetConfirmationDialog
          onConfirm={() => handleDelete(dialog.id)}
          onDismissAction={() => setDialog({ show: false, id: undefined })}
          open={dialog.show}
          dialogTitle={dialogTitle}
          dialogBtnContent="Yes, Continue"
          dialogText={dialogText}
          qaAttrPrefix={qaAttrPrefix}
        />
      )}
      <div className={classes.ctaBtn}>
        <AgentNetButton
          variant="contained"
          size="small"
          data-qa={qaBtnprefix}
          onClick={togglePopper}
          aria-describedby={id}
        >
          Add {label}
        </AgentNetButton>
      </div>
      <div>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={classes.popper}
          // modifiers={[
          //   {
          //     name: 'flip',
          //     enabled: true,
          //   },
          // ]}
        >
          <Paper elevation={8} className={classes.popperContent}>
            <div className={classes.popperHeader}>
              <Typography variant="h2" color="secondary">
                Preferred Downloads
              </Typography>
              <IconButton
                className={classes.popperClose}
                aria-label="Close Preferred Downloads Popper"
                onClick={() => setAnchorEl(null)}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </div>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={options}
              classes={{
                option: classes.popperOption,
                popperDisablePortal: classes.popperDisablePortal,
                paper: classes.popperPaper,
              }}
              disableCloseOnSelect
              disableClearable
              disablePortal
              getOptionDisabled={(option: OptionType) =>
                selectedOptions.some((selectedOption) => selectedOption.id === option.id)
              }
              getOptionLabel={(option: OptionType) => option.name}
              PaperComponent={CustomPaper}
              PopperComponent={CustomPopper}
              onChange={(event, newValue: OptionType[]) => handleChange(newValue)}
              renderOption={(option, state) => {
                return (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selectedOptions.filter((o) => o.id === option.id).length ? true : false}
                      className={classes.checkbox}
                      color="primary"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      data-qa={qaBtnprefix + 'checkbox'}
                    />
                    <Typography variant="body1">{option.name}</Typography>
                  </>
                );
              }}
              renderInput={(params) => (
                <div className={classes.textInputWrap}>
                  <AgentNetTextInput
                    {...params}
                    label={'Search ' + label}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textInput}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: null,
                      endAdornment: (
                        <IconButton
                          className={classes.deleteRow}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          onClick={() => setMenuOpen(!menuOpen)}
                          data-qa={qaBtnprefix + 'DropDown'}
                        >
                          {menuOpen ? (
                            <ArrowDropUp fontSize="large" color="disabled" />
                          ) : (
                            <ArrowDropDown fontSize="large" color="disabled" />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              )}
              open={menuOpen}
              value={selectedOptions}
            />
          </Paper>
        </Popper>
      </div>
      <div className={classes.table}>
        <Grid container className={classes.tableHeader}>
          <Grid item sm={12} className={classes.tableHeaderCell}>
            {label}
          </Grid>
        </Grid>
        <Grid container className={classes.tableList}>
          {selectedOptions.map((e: any, i) => (
            <Grid container key={i} className={clsx(classes.tableRow)}>
              <Grid item sm={11} className={clsx(classes.tableCell)}>
                <Typography variant="body2">{e.name}</Typography>
              </Grid>
              <Grid item sm={1} className={clsx(classes.tableCell, classes.deleteCell)}>
                {e.id != 0 && (
                  <IconButton
                    className={classes.deleteRow}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    aria-label="delete document"
                    onClick={() => modalConfirmation(e)}
                    data-qa={qaBtnprefix + 'Close' + i}
                  >
                    <Close color="disabled" fontSize="large" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default AutocompleteChecklist;
