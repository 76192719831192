import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'required';

export const requiredFieldMessage = (fieldName: string): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (fieldName?: string, maxLength?: number): string =>
  `${fieldName} Max length is ${maxLength}`;

export const searchFirmSchema = (applyNumericCheck?: boolean): any => {
  return yup.object().shape({
    searchText: yup.string().when([], () => {
      return applyNumericCheck
        ? yup.string().test('numeric-characters-check', 'maximum of 10 numeric characters allowed', (value) => {
            const numericCount = (value?.match(/\d/g) || []).length;
            return numericCount <= 10;
          })
        : yup.string().test('length-check', 'You must enter between 3 and 100 characters', (value) => {
            return (value && value.length >= 3 && value.length <= 100) || false;
          });
    }),
    notifyEmail: yup
      .string()
      .email('Invalid Email format, @ and . characters are required')
      .max(255)
      .required('E-mail is required'),
  });
};
