import React, { useEffect, useMemo, useState } from 'react';
import { AgentNetTextInput, AgentTextInputProps } from '../TextField';
import { Autocomplete, FilterOptionsState } from '@material-ui/lab';
import { ArrowDropDown } from '@material-ui/icons';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { SearchType, Place, PlaceAddress } from './Models';
import appConfig from '../../../app.config.json';
import { extractPlaceAddress } from './Utilities';
import states from '../StateSelectField/states.json';
import { stateType } from '../StateSelectField/StateSelectField';
import '../AutocompleteSearch/AutocompleteSearch.scss';

export type GoogleAutocompleteSearchProps = AgentTextInputProps & {
  label?: string;
  value?: string;
  onChange?: (value: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  onPlaceSelect?: (place: PlaceAddress) => void;
  shouldDisableClearable?: boolean;
  options?: Array<SearchType> | null | undefined;
  additionalOptions?: Array<SearchType> | null | undefined;
  primaryLabel?: string;
  secondaryLabel?: string;
  officeState?: string;
  disabled?: boolean;
  disableGrouping?: boolean;
  disableNoMatchesLabel?: boolean;
  stateConstraint?: string;
  orderOptions?: (options: Array<SearchType>) => Array<SearchType>;
};

export function GoogleAutocompleteSearch({
  label = 'Address Autocomplete Search',
  value,
  onBlur = () => undefined,
  onChange = () => undefined,
  id = '',
  shouldDisableClearable = false,
  options,
  additionalOptions,
  primaryLabel = 'Group 1',
  secondaryLabel = 'Group 2',
  errs,
  officeState,
  name,
  showValidation,
  orderOptions,
  onPlaceSelect,
  stateConstraint,
  disabled,
  ...props
}: GoogleAutocompleteSearchProps): JSX.Element {
  /* Handles  Validation Errors */
  const [errors, setErrors] = useState(false);
  const [displayErrors, setDisplayError] = useState(showValidation);

  /* Use Domestic Address for Autocomplete component  */
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: appConfig.google_maps_api_key,
  });

  useEffect(() => {
    const hasError = errs?.find((err) => err.field === name);
    hasError ? setErrors(true) : setErrors(false);
  }, [errs]);

  useEffect(() => {
    setDisplayError(showValidation);
  }, [showValidation]);

  const filterOptions = (options: SearchType[], searchValue: FilterOptionsState<SearchType>) => {
    return options;
  };

  /* Place predictions comes in from Google - transform values to be read by autocomplete as options  - taking predictions and turning a key/value pair for name/value  */
  const predictionOptions =
    placePredictions?.map((item) => {
      return { name: item.description, value: item.place_id };
    }) ?? [];

  /* Google responds w/ place  */
  const placeSelected = (place: Place) => {
    const placeAddress = extractPlaceAddress(place, stateConstraint); // call utility function to extract google place address
    onPlaceSelect && onPlaceSelect({ ...placeAddress }); //passed down from domestic address - to convert normal address
  };
  const statesByAbbr: { [key: string]: stateType } = useMemo(
    () => states.reduce((prev, current) => ({ ...prev, [current.abbreviation]: current }), {}),
    [],
  );
  const generateStateOptions = () => {
    if ((value?.length ?? 0) < 3) {
      return [];
    }
    return orderOptions ? orderOptions(predictionOptions) : predictionOptions;
  };
  const val = value as string;

  return (
    <Autocomplete
      freeSolo
      disableClearable
      autoHighlight
      filterOptions={filterOptions}
      onChange={(_, val) => {
        const placeId = typeof val === 'string' ? val : val.value;
        if (placeId) {
          //sends google the placeid
          placesService?.getDetails(
            {
              placeId: placeId,
            },
            placeSelected, //callback
          );
        }
      }}
      inputValue={value}
      onBlur={onBlur}
      options={generateStateOptions()}
      getOptionLabel={(option: SearchType) => option.name || ''}
      getOptionSelected={(selected, option) => selected.name === option?.name}
      renderInput={(param) => {
        return (
          <AgentNetTextInput
            variant="outlined"
            label={label}
            errs={errs}
            showValidation={showValidation}
            value={value}
            name={name}
            onChange={(e) => {
              if (e.target.value?.length >= 3)
                getPlacePredictions(
                  stateConstraint
                    ? {
                        input: e.target.value,
                        bounds: {
                          east: statesByAbbr[stateConstraint].east ?? 0,
                          south: statesByAbbr[stateConstraint].south ?? 0,
                          west: statesByAbbr[stateConstraint].west ?? 0,
                          north: statesByAbbr[stateConstraint].north ?? 0,
                        },
                        componentRestrictions: { country: ['us', 'pr', 'vi'] },
                      }
                    : {
                        input: e.target.value,
                        componentRestrictions: { country: ['us', 'pr', 'vi'] },
                      },
                );
              onChange(e);
            }}
            {...param}
            {...props}
            disabled={disabled}
            inputProps={{
              ...param.inputProps,
              'aria-label': 'g-field-input-select',
              autoComplete: 'new-password',
              form: { Autocomplete: 'off' },
              title: props.title ? props.title : val ? val : label,
            }}
            InputLabelProps={{ shrink: true }}
            error={errors && displayErrors}
          />
        );
      }}
      id={id}
      data-testid={id}
      popupIcon={<ArrowDropDown fontSize="large" />}
      className="autocomplete-search"
      disabled={disabled}
    />
  );
}

export default GoogleAutocompleteSearch;
