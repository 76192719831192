import { handleError, handleBusinessError } from 'utilities/utilities';
import { getAgencyApiHost } from 'api/host-by-env';
import { GetServiceOrderInvoiceRequest, InvoiceDocumentRequest } from 'features/ServiceOrderInvoices/type';

export async function getInvoiceDocument(
  invoiceDocumentRequest: InvoiceDocumentRequest,
  token: string,
): Promise<{ result: any; errorCode?: string | number }> {
  const url = `${getAgencyApiHost()}remittance-service/v1/service-order/invoice-document?orderNumber=${
    invoiceDocumentRequest.orderNumber
  }&invoiceDate=${invoiceDocumentRequest.invoiceDate}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleBusinessError(json);
  return json.Result;
}

export async function getInvoices(
  token: string,
  request: GetServiceOrderInvoiceRequest,
): Promise<{ result: any; errorCode?: string | number }> {
  let url = `${getAgencyApiHost()}remittance-service/v1/service-order/invoices?firmId=${request.firmId}`;
  if (request.isViewInvoice) {
    url = url + `&isViewInvoice=${request.isViewInvoice}&fromDate=${request.fromDate}&toDate=${request.toDate}`;
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();

  handleError(json);

  // Assuming json.result is an array of invoice objects
  const mappedResult = json.Result?.map((invoice: any) => ({
    isPremium: invoice.IsPremium,
    invoiceNumber: invoice.InvoiceNumber,
    invoicedAmount: invoice.InvoicedAmount,
    invoiceBalance: invoice.InvoiceBalance,
    invoiceDate: invoice.InvoiceDate,
    invoiceStatus: invoice.InvoiceStatus,
    orderNumber: invoice.OrderNumber,
    propertyAddress: invoice.PropertyAddress,
    officeAddress: invoice.OfficeAddress,
    fileNumber: invoice.FileNumber,
  }));

  return mappedResult;
}

export async function exportReports(files: any, token: string, tabName: string): Promise<any> {
  const url = `${getAgencyApiHost()}remittance-service/v1/service-order/invoices-export`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(files),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}
