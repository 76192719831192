import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { AgentNetCheckBox } from 'ui-kit/inputs';
import AgentNetButton from '../button/AgentNetButton';
import { DocumentPDF, DocumentXLS, Document } from 'ui-kit/icons/DocIcons';

interface DocumentItem {
  id: string;
  name: string;
  description: string;
  lastModifiedDate: string;
  uri: string;
  type: string;
  canDownloadAsZipPdf: boolean;
  isChecked?: boolean;
  canDownLoad?: boolean;
}

interface ServiceOrderDocumentsTable {
  documents?: Array<any>;
  disabled?: boolean;
  onRowCheck?: (value: any, documentURI: string) => void;
  onDelete?: (documentURI: string) => void;
  onViewDocument?: any;
  onDownloadPdf?: (documentURI: string[]) => void;
  onDownloadZip?: (documentURI: string[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentsTable: {
      width: '100%',
      padding: '0 0 24px',
    },
    documentsTableHeader: {
      backgroundColor: '#F2F5F6',
      borderBottom: '1px solid #D9E0E5',
      fontWeight: 600,
      fontSize: '1.4rem',
    },
    tableHeaderCell: {
      padding: '1.2rem',
    },
    documentsTableList: {},
    tableRow: {
      borderBottom: `1px solid #E0E0E0`,
    },
    tableCell: {
      padding: `0.8rem`,
      display: 'flex',
      alignItems: 'center',
    },
    deleteRow: {
      padding: 0,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    checkbox: {
      marginRight: theme.spacing(1),
    },
    incorporatedCell: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    incorporatedCellDescription: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    my1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    actions: {
      '& .MuiAgentNetButton': {
        marginRight: theme.spacing(2),
      },
    },
    deleteCell: {
      justifyContent: 'space-between',
    },
    rowChecked: {
      backgroundColor: '#FDF8CF',
    },
  }),
);

const ServiceOrderDocumentsTable = ({
  documents,
  disabled,
  onRowCheck,
  onDelete,
  onViewDocument,
  onDownloadPdf,
  onDownloadZip,
}: ServiceOrderDocumentsTable) => {
  const classes = useStyles();

  const [items, setItems] = useState<DocumentItem[]>([]);
  const [selectAllDocuments, setSelectAllDocuments] = useState(false);

  useEffect(() => {
    console.log('changed documents within component', documents);
    if (documents && documents.length > 0) {
      const newDocs = documents?.map((d) => {
        return {
          ...d,
          isChecked: false,
        };
      });
      setItems(newDocs);
    }
  }, [documents]);

  function generateIcon(fileType?: any) {
    if (fileType === 'pdf') {
      return <DocumentPDF />;
    }
    if (fileType === 'XLS') {
      return <DocumentXLS />;
    }
    if (fileType === 'DOCX') {
      return <Document />;
    }
    return <Document />;
  }

  const handleRowCheck = (checked: boolean, uri: any) => {
    if (uri === 'selectAll') {
      setSelectAllDocuments(checked);
      onRowCheck && onRowCheck(checked, uri);
      const newObj = items.map((doc) => {
        if (doc.canDownloadAsZipPdf) {
          return { ...doc, isChecked: checked };
        }
        return doc;
      });
      setItems(newObj);
    } else {
      onRowCheck && onRowCheck(checked, uri);
      const newObj = items.map((doc) => {
        if (doc.uri == uri) {
          return { ...doc, isChecked: !doc.isChecked };
        }
        return doc;
      });
      setItems(newObj);
    }
  };

  const handleDownload = (payload: string) => {
    const selected: any = [];
    items.forEach((i) => {
      if (i.isChecked) {
        selected.push(i.uri);
      }
    });
    if (payload === 'pdf') {
      console.log(`download pdfs of the following ${selected}`);
      onDownloadPdf && onDownloadPdf(selected);
    }
    if (payload === 'zip') {
      console.log(`download zip of the following ${selected}`);
      onDownloadZip && onDownloadZip(selected);
    }
  };

  return (
    <>
      {items && items.length > 0 && (
        <>
          <div className={clsx(classes.documentsTable, 'documentsTableWrapper')}>
            <Grid container className={classes.documentsTableHeader}>
              <Grid item sm={4} className={classes.tableHeaderCell}>
                <AgentNetCheckBox
                  value={selectAllDocuments}
                  checkHandler={(el) => handleRowCheck(el, 'selectAll')}
                  className={classes.checkbox}
                  disabled={disabled}
                />
                Document Name
              </Grid>
              <Grid item sm={3} className={classes.tableHeaderCell}>
                Description
              </Grid>
              <Grid item sm={4} className={classes.tableHeaderCell}>
                Date
              </Grid>
              <Grid item sm={1} className={classes.tableHeaderCell}>
                File
              </Grid>
            </Grid>
            <Grid container className={classes.documentsTableList}>
              {items.map((e: any, i) => (
                <Grid
                  container
                  key={i}
                  className={clsx(classes.tableRow, { [classes.rowChecked]: e.isChecked === true })}
                >
                  <Grid item sm={4} className={clsx(classes.tableCell)}>
                    <AgentNetCheckBox
                      value={e.isChecked}
                      checkHandler={(el) => handleRowCheck(el, e.uri)}
                      className={classes.checkbox}
                      disabled={!e.canDownloadAsZipPdf}
                    />
                    <Typography variant="body2">{e.name}</Typography>
                  </Grid>
                  <Grid item sm={3} className={clsx(classes.tableCell)}>
                    <Typography variant="body2">{e.description}</Typography>
                  </Grid>
                  <Grid item sm={4} className={clsx(classes.tableCell)}>
                    <Typography variant="body2">{e.lastModifiedDate}</Typography>
                  </Grid>
                  <Grid item sm={1} className={clsx(classes.tableCell)}>
                    <AgentNetButton onClick={(e1) => onViewDocument(e)} data-qa="ServiceOrderDocumentView">
                      {generateIcon(e.mimeType)}
                    </AgentNetButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className={classes.actions}>
            <AgentNetButton
              variant="outlined"
              size="small"
              onClick={() => handleDownload('pdf')}
              disabled={false}
              data-qa="ServiceOrderDocumentDownloadAsPDF"
            >
              Download as PDF
            </AgentNetButton>
            <AgentNetButton
              variant="contained"
              size="small"
              onClick={() => handleDownload('zip')}
              disabled={false}
              data-qa="ServiceOrderDocumentDownloadasZIP"
            >
              Download as ZIP
            </AgentNetButton>
          </div>
        </>
      )}
    </>
  );
};

export default ServiceOrderDocumentsTable;
