import React from 'react';

export const PinOutlined = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="none">
      <path
        fill="#fff"
        d="M16.408 16.435a2.675 2.675 0 01-2.667-2.667c0-1.466 1.2-2.666 2.667-2.666 1.466 0 2.666 1.2 2.666 2.666 0 1.467-1.2 2.667-2.666 2.667zm8-2.4c0-4.84-3.534-8.267-8-8.267-4.467 0-8 3.427-8 8.267 0 3.12 2.6 7.253 8 12.187 5.4-4.934 8-9.067 8-12.187zm-8-10.933c5.6 0 10.666 4.293 10.666 10.933 0 4.427-3.56 9.667-10.666 15.733C9.3 23.702 5.74 18.462 5.74 14.035c0-6.64 5.067-10.933 10.667-10.933z"
      ></path>
    </svg>
  );
};

export const HelpOutlined = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="none">
      <path
        fill="#7FA9D1"
        d="M15.097 24.433h2.666v-2.667h-2.666v2.667zM16.43 3.1C9.07 3.1 3.097 9.073 3.097 16.433S9.07 29.766 16.43 29.766s13.333-5.973 13.333-13.333S23.79 3.1 16.43 3.1zm0 24c-5.88 0-10.667-4.787-10.667-10.667S10.55 5.766 16.43 5.766s10.667 4.787 10.667 10.667S22.31 27.1 16.43 27.1zm0-18.667a5.332 5.332 0 00-5.333 5.333h2.666c0-1.466 1.2-2.666 2.667-2.666 1.467 0 2.667 1.2 2.667 2.666 0 2.667-4 2.334-4 6.667h2.666c0-3 4-3.333 4-6.667a5.332 5.332 0 00-5.333-5.333z"
      ></path>
    </svg>
  );
};

export const IndividualOutlined = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="none">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.742 9.768a5.333 5.333 0 11-10.667 0 5.333 5.333 0 0110.667 0zm-5.333 9.333a9.333 9.333 0 00-9.334 9.334h18.667a9.333 9.333 0 00-9.334-9.334z"
      ></path>
    </svg>
  );
};

export const CoupleOutlined = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="none">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.408 6.24a5.333 5.333 0 110 7.056m4 15.138h-16V27.1a8 8 0 1116 0v1.334zm0 0h8V27.1a8 8 0 00-12-6.93m1.333-10.403a5.333 5.333 0 11-10.666 0 5.333 5.333 0 0110.666 0z"
      ></path>
    </svg>
  );
};

export const BuildingOutlined = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="none">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M25.763 28.434V7.1a2.667 2.667 0 00-2.667-2.666H9.763A2.667 2.667 0 007.096 7.1v21.334m18.667 0h2.667m-2.667 0h-6.667m-12 0H4.43m2.666 0h6.667M12.43 9.767h1.333M12.43 15.1h1.333m5.333-5.333h1.334M19.096 15.1h1.334m-6.667 13.334v-6.667c0-.736.597-1.333 1.333-1.333h2.667c.736 0 1.333.596 1.333 1.333v6.667m-5.333 0h5.333"
      ></path>
    </svg>
  );
};

export const DocumentOutlined = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="none">
      <path
        fill="#fff"
        d="M11.096 21.737h10.667v2.667H11.096v-2.667zm0-5.333h10.667v2.666H11.096v-2.666zm8-13.334H8.429a2.675 2.675 0 00-2.666 2.667V27.07a2.663 2.663 0 002.653 2.667h16.013c1.467 0 2.667-1.2 2.667-2.667v-16l-8-8zm5.333 24h-16V5.737h9.334v6.667h6.666V27.07z"
      ></path>
    </svg>
  );
};

export const NavServices = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M20 3h-8c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 8h-8V7h8v4zM8 8H6v7c0 1.1.9 2 2 2h9v-2H8V8zm-6 4h2v7h9v2H4c-1.1 0-2-.9-2-2v-7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const NavDashboard = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="#fff"
        d="M3 3v8h8V3H3zm6 6H5V5h4v4zm-6 4v8h8v-8H3zm6 6H5v-4h4v4zm4-16v8h8V3h-8zm6 6h-4V5h4v4zm-6 4v8h8v-8h-8zm6 6h-4v-4h4v4z"
      ></path>
    </svg>
  );
};
export const NavAccounting = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="#fff"
        d="M19.071 2H4.93C3.9 2 3 2.875 3 3.875v16.25c0 1 .9 1.875 1.929 1.875H19.07c1.03 0 1.93-.875 1.93-1.875V3.875c0-1-.9-1.875-1.929-1.875zM8.143 19c0 .25-.257.5-.514.5H6.086c-.257 0-.515-.25-.515-.5v-1.5c0-.25.258-.5.515-.5h1.543c.257 0 .514.25.514.5V19zm0-5c0 .25-.257.5-.514.5H6.086c-.257 0-.515-.25-.515-.5v-1.5c0-.25.258-.5.515-.5h1.543c.257 0 .514.25.514.5V14zm5.143 5c0 .25-.257.5-.515.5H11.23c-.258 0-.515-.25-.515-.5v-1.5c0-.25.257-.5.515-.5h1.542c.258 0 .515.25.515.5V19zm0-5c0 .25-.257.5-.515.5H11.23c-.258 0-.515-.25-.515-.5v-1.5c0-.25.257-.5.515-.5h1.542c.258 0 .515.25.515.5V14zm5.143 5c0 .25-.258.5-.515.5h-1.543c-.257 0-.514-.25-.514-.5v-6.5c0-.25.257-.5.514-.5h1.543c.257 0 .515.25.515.5V19zm0-10c0 .25-.258.5-.515.5H6.086c-.257 0-.515-.25-.515-.5V5c0-.25.258-.5.515-.5h11.828c.257 0 .515.25.515.5v4z"
      ></path>
    </svg>
  );
};
export const NavResourceCenter = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="#fff"
        d="M22.47 6.7c-.47-.24-.96-.44-1.47-.61v12.03c-1.14-.41-2.31-.62-3.5-.62-1.9 0-3.78.54-5.5 1.58V6.98C10.38 6.05 8.51 5.5 6.5 5.5c-1.79 0-3.48.44-4.97 1.2-.33.16-.53.51-.53.88v12.08c0 .58.47.99 1 .99.16 0 .32-.04.48-.12 1.21-.63 2.57-1.03 4.02-1.03 2.07 0 3.98.82 5.5 2 1.52-1.18 3.43-2 5.5-2 1.45 0 2.81.4 4.02 1.04.16.08.32.12.48.12.52 0 1-.41 1-.99V7.58c0-.37-.2-.72-.53-.88zM10 18.12c-1.14-.41-2.31-.62-3.5-.62s-2.36.21-3.5.62V8.21c1.11-.47 2.28-.71 3.5-.71 1.2 0 2.39.25 3.5.72v9.9zM19 2l-5 5v9.5l5-4.5V2z"
      ></path>
    </svg>
  );
};

export const SupportAgentOutlined = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="#fff"
        d="M18 11.03A6.04 6.04 0 0012.05 6c-3.03 0-6.29 2.51-6.03 6.45a8.075 8.075 0 004.86-5.89c1.31 2.63 4 4.44 7.12 4.47zM15 14a1 1 0 100-2 1 1 0 000 2z"
      ></path>
      <path fill="#fff" d="M9 14a1 1 0 100-2 1 1 0 000 2z"></path>
      <path
        fill="#fff"
        d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z"
      ></path>
    </svg>
  );
};

export const CollapseArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path fill="#fff" d="M11 18l1.41-1.41L7.83 12l4.58-4.59L11 6l-6 6 6 6z"></path>
      <path fill="#fff" d="M17.59 18L19 16.59 14.42 12 19 7.41 17.59 6l-6 6 6 6z"></path>
    </svg>
  );
};

export const ExpandArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path fill="#fff" d="M13 6l-1.41 1.41L16.17 12l-4.58 4.59L13 18l6-6-6-6z"></path>
      <path fill="#fff" d="M6.41 6L5 7.41 9.58 12 5 16.59 6.41 18l6-6-6-6z"></path>
    </svg>
  );
};

export default PinOutlined;
