import React, { useEffect, useReducer, useState } from 'react';
import { useAuth } from '@agentnet/auth';
import {
  Order,
  Field,
  ProductRequest,
  ProductResponse,
  OrderDocument,
  Contact,
  DownloadDocumentResponse,
  DownloadDocumentRequest,
} from './type';
import useAsync from 'hooks/useAsync';
import { ServiceOrderRequest } from './type';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import { DividerType } from 'ui-kit/components/dividers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccordionContent from 'ui-kit/components/accordion/AccordionContent';
import ServiceOrderForm from './ServiceOrderForm';
import { ServiceOrderStatus, ServiceOrdersActions, noActiveServiceOrders, openFile } from './constants';
import { Container, Typography } from '@material-ui/core';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { fileInfoType } from 'api/file/interfaces/get-file';
import ServiceOrderActions from './ServiceOrderActions';
import { openDocument } from 'utilities/utilities';
import { newWindow, WindowFeatures, DocumentType, zipDocType } from '../constants';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';

import {
  getProducts,
  postServiceOrder,
  getOrderDocument,
  putServiceOrder,
  postServiceOrderDocumentDownload,
} from 'api/serviceorder-api';
import EditOrderForm from './EditOrderForm';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { AgentNetTextInput } from 'ui-kit/inputs';
import { Notification } from 'ui-kit/components/notification/Notification';

interface ServiceOrderProps {
  serviceOrderList?: Order[] | null;
  fileData?: fileInfoType | null;
  decryptedFileId?: number | null;
  isAddNewServiceOrderClicked?: boolean;
  OnCancel?: () => void;
  handleAddNewServiceOrder?: () => void;
  refreshServiceOrderData?: () => void;
}
const ServiceOrderFC: React.FC<ServiceOrderProps> = ({
  serviceOrderList,
  fileData,
  decryptedFileId,
  isAddNewServiceOrderClicked,
  OnCancel,
  handleAddNewServiceOrder,
  refreshServiceOrderData,
}: ServiceOrderProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
      serviceOrdersWrap: {
        padding: `0 2.4rem 4.8rem`,
        marginBottom: theme.spacing(5),
      },
      warningMsg: { marginBottom: theme.spacing(3) },
      mb2: {
        marginBottom: theme.spacing(2),
      },
    }),
  );

  const { getAccessToken } = useAuth();
  const randomId = () => (Math.random() + 1).toString(36).substring(7);
  const classes = useStyles();

  const [draftMode, setDraftMode] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [initialOrders, setInitialOrders] = useState<Order[]>([]);
  const [activeOrdersCount, setActiveOrdersCount] = useState<number>(0);

  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [updateAttempted, setUpdateAttempted] = useState<boolean>(false);

  const { addGlobalMsg } = useGlobalMessages();
  const [errMsgs, setErrMsgs] = useState([]);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [triggerSaveCall, setTriggerSaveCall] = useState(false);
  const [triggerUpdateCall, setTriggerUpdateCall] = useState(false);
  const [isCreateServiceOrderButtonDisabled, setCreateServiceOrderButton] = useState<boolean>(false);
  const [editingOrderNumber, setEditingOrderNumber] = useState<any>('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [noteDialog, setNoteDialog] = React.useState<any>({ show: false, id: undefined, note: '' });
  const [selectedViewDocument, setSelectedViewDocument] = useState<any>('');
  const [selectedDownloadDocument, setSelectedDownloadDocument] = useState<string[]>([]);
  const [downloadAsPdf, setDownloadAsPdf] = useState<boolean>(false);

  const [update, setUpdate] = useState(false);
  const { addSnackbarMessage } = useSnackBars();

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case ServiceOrdersActions.edit:
        return { ...state, isEditing: true, selectedOrderNumber: action.payload };
      case ServiceOrdersActions.view:
        return { ...state, isEditing: false, selectedProduct: '', closeAllActive: !state.closeAllActive };
      case ServiceOrdersActions.expandCollapseActive:
        return { ...state, isExpandActive: action.payload, closeAllActive: !state.closeAllActive };
      case ServiceOrdersActions.expandCollapseInActive:
        return { ...state, isExpandInActive: action.payload, closeAllInActive: !state.closeAllInActive };
      case ServiceOrdersActions.onInactiveToggleChange:
        return { ...state, isInactiveToggle: !state.isInactiveToggle };
      case 'OnExpandChangeActive':
        return { ...state, closeAllActive: false };
      case 'OnExpandChangeInActive':
        return { ...state, closeAllInActive: false };
      case 'OnChangingServiceOrderId':
        return { ...state, selectedProduct: action.payload };
      default:
        throw new Error();
    }
  };

  const [orderActionsState, dispatch] = useReducer(reducer, {
    isEditing: false,
    selectedProduct: '',
    selectedOrderNumber: '',
    isExpandActive: false,
    isExpandInActive: false,
    isInactiveToggle: false,
    closeAllActive: false,
    closeAllInActive: false,
  });

  const fetchProductList = async (): Promise<ProductResponse[]> => {
    const token = await getAccessToken();
    const payload: ProductRequest = {
      fileId: fileData?.fileId,
    };
    return getProducts(payload, token);
  };

  const {
    execute: executeGetProductList,
    value: getProductListValue,
    status: getProductListStatus,
    errors: getProductListError,
  } = useAsync<ProductResponse[]>(fetchProductList, false);

  const saveServiceOrdersData = async (): Promise<Order[]> => {
    const token = await getAccessToken();
    return postServiceOrder(
      {
        fileId: decryptedFileId ?? 0,
        accountNumber: fileData?.accountId ?? 0,
        orders: orders,
      },
      token,
    );
  };

  const updateServiceOrdersData = async (): Promise<boolean> => {
    const token = await getAccessToken();
    return putServiceOrder(
      {
        fileId: decryptedFileId ?? 0,
        accountNumber: fileData?.accountId ?? 0,
        order: orders?.find((x) => x.orderNumber === editingOrderNumber) ?? ({} as Order),
      },
      token,
    );
  };

  const {
    execute: executeSaveServiceOrdersData,
    value: saveServiceOrdersDataValue,
    status: saveServiceOrdersDataStatus,
    errors: saveServiceOrdersDataError,
  } = useAsync<Order[]>(saveServiceOrdersData, false);

  const {
    execute: executeUpdateServiceOrdersData,
    value: updateServiceOrdersDataValue,
    status: updateServiceOrdersDataStatus,
    errors: updateServiceOrdersDataError,
  } = useAsync<boolean>(updateServiceOrdersData, false);

  const fetchOrderDocument = async (): Promise<OrderDocument> => {
    const token = await getAccessToken();
    const serviceOrderReferenceId = selectedViewDocument.uri.slice(0, selectedViewDocument.uri.indexOf('-'));
    const payload: ServiceOrderRequest = {
      fileId: decryptedFileId ?? 0,
      serviceOrderReferenceId: serviceOrderReferenceId,
    };
    return getOrderDocument(payload, selectedViewDocument.uri, token);
  };

  const fetchOrderDownloadDocuments = async (): Promise<DownloadDocumentResponse> => {
    const token = await getAccessToken();
    const serviceOrderReferenceId = selectedDownloadDocument[0]?.slice(0, selectedDownloadDocument[0]?.indexOf('-'));
    const downloadFormat = downloadAsPdf ? 'pdf' : 'zip';
    const downloadPayload: DownloadDocumentRequest = {
      fileId: decryptedFileId ?? 0,
      serviceOrderReferenceId: serviceOrderReferenceId ?? '0',
      downloadFormat: downloadFormat,
      URI: selectedDownloadDocument,
    };
    return postServiceOrderDocumentDownload(downloadPayload, token);
  };

  const {
    execute: executeGetOrderDownloadDocumentData,
    value: getServiceOrderDownloadDocumentValue,
    status: getServiceOrderDownloadDocumentStatus,
    errors: getServiceOrderDownloadDocumentError,
  } = useAsync<any>(fetchOrderDownloadDocuments, false);

  const {
    execute: executeGetOrderDocumentData,
    value: getServiceOrderDocumentValue,
    status: getServiceOrderDocumentStatus,
    errors: getServiceOrderDocumentError,
  } = useAsync<any>(fetchOrderDocument, false);

  useEffect(() => {
    if (getServiceOrderDocumentValue?.Content.length > 0) {
      const orderDocumentBytes = getServiceOrderDocumentValue?.Content;
      let docType = '';

      switch (getServiceOrderDocumentValue.MimeType.toUpperCase()) {
        case 'XML':
          docType = 'application/xml';
          break;
        case 'PDF':
          docType = DocumentType;
          break;
        case 'JPG':
        case 'JPEG':
        case 'PNG':
          docType = 'image/jpeg';
          break;
        case 'GIF':
          docType = 'image/gif';
          break;
        case 'BMP':
          docType = 'image/bmp';
          break;
        case 'TIFF':
        case 'TIF':
          docType = 'image/tiff';
          break;
        case 'DOC':
          docType = 'application/msword';
          break;
        case 'RTF':
          docType = 'application/msword';
          break;
        case 'TXT':
          docType = 'text/plain';
          break;
        case 'XLS':
          docType = 'application/vnd.ms-excel';
          break;
        case 'XPS':
          docType = 'application/vnd.ms-xpsdocument';
          break;
        case 'WPS':
          docType = 'application/vnd.ms-works';
          break;
        case 'DOCX':
          docType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'XLSX':
          docType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        case 'ODT':
          docType = 'application/vnd.oasis.opendocument.formula';
          break;
        case 'WPD':
          docType = 'application/wordperfect';
          break;
        case 'ESP':
          docType = 'application/octet-stream';
          break;
        case 'HTM':
          docType = 'text/html';
          break;
        case 'MSG':
          docType = 'application/vnd.ms-outlook';
          break;
        case 'JSON':
          docType = 'application/json';
          break;
        default:
          docType = DocumentType;
          break;
      }
      if (getServiceOrderDocumentValue.Description == 'Title Reports / RamQuest XML') {
        docType = 'application/json';
      }
      openDocument(orderDocumentBytes, docType, newWindow, WindowFeatures);
    }
  }, [getServiceOrderDocumentValue]);

  useEffect(() => {
    if (getServiceOrderDownloadDocumentValue?.Content.length > 0) {
      const orderDocumentBytes = getServiceOrderDownloadDocumentValue?.Content;
      let docType = '';
      switch (getServiceOrderDownloadDocumentValue.FileType.toUpperCase()) {
        case 'PDF':
          docType = DocumentType;
          break;
        case 'ZIP':
          docType = zipDocType;
          break;
        default:
          docType = DocumentType;
          break;
      }
      openDocument(orderDocumentBytes, docType, newWindow, WindowFeatures);
    }
  }, [getServiceOrderDownloadDocumentValue]);

  useEffect(() => {
    setTriggerSaveCall(false);
  }, [saveServiceOrdersDataValue, saveServiceOrdersDataError]);

  useEffect(() => {
    setTriggerUpdateCall(false);
  }, [updateServiceOrdersDataValue, updateServiceOrdersDataError]);

  useEffect(() => {
    if (selectedViewDocument !== '') {
      setSelectedViewDocument('');
      executeGetOrderDocumentData().then();
    }
  }, [selectedViewDocument]);

  useEffect(() => {
    if (selectedDownloadDocument?.length > 0) {
      setSelectedDownloadDocument([]);
      executeGetOrderDownloadDocumentData().then();
    }
  }, [selectedDownloadDocument]);

  useEffect(() => {
    if (saveServiceOrdersDataValue || updateServiceOrdersDataValue) {
      refreshServiceOrderData && refreshServiceOrderData();
    }
  }, [saveServiceOrdersDataValue, updateServiceOrdersDataValue]);

  useEffect(() => {
    if (serviceOrderList) {
      const getactiveOrdersCount = serviceOrderList?.filter(
        (item) =>
          item.orderStatus === ServiceOrderStatus.orderplaced ||
          item.orderStatus === ServiceOrderStatus.orderdelivered ||
          item.orderStatus === ServiceOrderStatus.updateDelivered ||
          item.orderStatus === ServiceOrderStatus.updateRequested,
      ).length;
      if (getactiveOrdersCount === 0) {
        dispatch({ type: ServiceOrdersActions.onInactiveToggleChange });
      }
      setInitialOrders(serviceOrderList);
      setOrders(serviceOrderList);
      setActiveOrdersCount(getactiveOrdersCount ?? 0);
    }
  }, [serviceOrderList]);

  useEffect(() => {
    setDisplayError(
      getProductListStatus === 'error' ||
        saveServiceOrdersDataStatus === 'error' ||
        updateServiceOrdersDataStatus === 'error' ||
        getServiceOrderDocumentStatus === 'error' ||
        getServiceOrderDownloadDocumentStatus === 'error',
    );
  }, [
    getProductListStatus,
    saveServiceOrdersDataStatus,
    updateServiceOrdersDataStatus,
    getServiceOrderDocumentStatus,
    getServiceOrderDownloadDocumentStatus,
  ]);

  useEffect(() => {
    const errorMessages = getProductListError?.length
      ? getProductListError
      : saveServiceOrdersDataError?.length
      ? saveServiceOrdersDataError
      : updateServiceOrdersDataError?.length
      ? updateServiceOrdersDataError
      : getServiceOrderDocumentError?.length
      ? getServiceOrderDocumentError
      : getServiceOrderDownloadDocumentError?.length
      ? getServiceOrderDownloadDocumentError
      : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [displayError, errMsgs]);

  const emptyServiceOrder: Order = {
    serviceOrderReferenceId: 0,
    orderNumber: '',
    saleAmount: 0,
    productName: '',
    productField: [],
    orderStatus: '',
    owningOffice: '',
    orderNotificationEmail: '',
    isDisclaimerAccepted: false,
    disclaimerInformation: '',
    fileNotes: [],
    note: '',
    userUploadedDocuments: [],
    documents: [],
    updates: [],
    serviceOrderContact: {} as Contact,
    randomId: randomId(),
    expanded: true,
    status: 'Draft',
    createdDate: '',
    updateType: '',
  };

  useEffect(() => {
    if (!getProductListValue) executeGetProductList().then();
  }, []);

  useEffect(() => {
    if (isAddNewServiceOrderClicked) {
      addServiceOrder();
      setDraftMode(true);
    }
  }, [isAddNewServiceOrderClicked]);

  const addServiceOrder = () => {
    setActiveOrdersCount((prevCount) => prevCount + 1);
    setOrders([{ ...emptyServiceOrder, randomId: randomId() }]);
  };

  const updateOrder = (
    id: any,
    value: any,
    fields: string,
    isFieldValue: boolean,
    isDocumentDelete?: boolean,
    selectedProduct?: ProductResponse,
  ) => {
    console.log(id, value, fields);
    let resetFields = false;
    if (fields === 'productName') {
      resetFields = true;
    }
    const newObj = orders.map((obj) => {
      if (obj.randomId === id) {
        if (resetFields) {
          obj.note = '';
        }

        if (fields === 'userUploadedDocuments' && !isDocumentDelete) {
          return {
            ...obj,
            productField: obj.productField?.map((field) => ({ ...field })),
            note: obj.note,
            userUploadedDocuments: obj.userUploadedDocuments.concat(value).filter((e) => e.uri == '' || !e.uri),
          };
        } else if (fields === 'userUploadedDocuments' && isDocumentDelete) {
          return {
            ...obj,
            productField: obj.productField?.map((field) => ({ ...field })),
            note: obj.note,
            userUploadedDocuments: value,
          };
        }

        return isFieldValue
          ? {
              ...obj,
              productField: obj.productField?.map((field) => ({
                ...field,
                value: field.name === fields ? value : field.value,
              })),
              note: obj.note,
              userUploadedDocuments: obj.userUploadedDocuments.filter((e) => e.uri == '' || !e.uri),
            }
          : {
              ...obj,
              productField: obj.productField?.map((field) => ({ ...field })),
              note: obj.note,
              [fields]: value,
              userUploadedDocuments: obj.userUploadedDocuments.filter((e) => e.uri == '' || !e.uri),
            };
      }
      return obj;
    });

    if (fields === 'productName') {
      newObj[0].orderNotificationEmail = selectedProduct?.notificationEmailAddress ?? '';
    }
    setOrders(newObj);
  };

  const addOrderFields = (randomId: string, fields: Field[]) => {
    const updatedOrders = orders.map((obj) => {
      if (obj.randomId === randomId) {
        return {
          ...obj,
          productField: [...fields],
        };
      }
      return obj;
    });
    setOrders(updatedOrders);
  };

  const saveOrders = () => {
    console.log('save triggered');
    setSubmitAttempted(true);
    console.log(orders);
  };

  const cancelUpdate = () => {
    console.log('cancel triggered on Update Service Order Page');
    updateOrder(noteDialog.id, 'Cancel', 'updateType', false);
    setNoteDialog({ show: false, id: undefined, note: '' });
    setTriggerUpdateCall(true);
  };

  const cancelOrder = () => {
    console.log('cancel botton triggered on Update Service Order Page');
    refreshServiceOrderData && refreshServiceOrderData();
    console.log(orders);
  };

  const handleAccordionAction = (o: Order) => {
    if (o.orderStatus && o.orderStatus === ServiceOrderStatus.orderplaced) {
      //Cancel Order
      setNoteDialog({ ...noteDialog, show: true, id: o.randomId });
    }

    if (o.status && o.status === ServiceOrderStatus.orderdelivered) {
      //Cancel Order
      //setUpdateDialog({ ...noteDialog, show: true, id: o.id });
    }

    if (o.status && o.status !== 'Update Requested') {
      //do nothing
    }
  };
  function handleViewDocument(document: any) {
    //order;
    //console.log(document);
    setSelectedViewDocument(document);
  }

  function handleDownloadAsZipPdf(documentsSelected: string[], isPdf: boolean) {
    console.log(documentsSelected);
    if (documentsSelected && documentsSelected.length > 0) {
      setSelectedDownloadDocument(documentsSelected);
      setDownloadAsPdf(isPdf);
    } else {
      addSnackbarMessage({
        message: `Must select one document to Download`,
        type: 'error',
        onClose: () => {
          setSelectedDownloadDocument([]);
        },
      });
    }
  }
  useEffect(() => {
    if (triggerSaveCall) {
      executeSaveServiceOrdersData().then();
    }
  }, [triggerSaveCall]);

  useEffect(() => {
    if (triggerUpdateCall) {
      executeUpdateServiceOrdersData().then();
    }
  }, [triggerUpdateCall]);

  const isCreateOrderDisabled = (): boolean => {
    return isCreateServiceOrderButtonDisabled
      ? isCreateServiceOrderButtonDisabled
      : !(orders.filter((element) => element.productName !== '').length > 0 && actionStatus !== 'pending'); //TODO
  };

  function getAccordionProps(order: Order, index: number) {
    const i = index + 1;
    return {
      closeAll:
        (order.orderStatus !== ServiceOrderStatus.closed &&
          order.orderStatus !== ServiceOrderStatus.cancelled &&
          order.orderStatus !== ServiceOrderStatus.openedInError &&
          orderActionsState.closeAllActive) ||
        ((order.orderStatus === ServiceOrderStatus.closed ||
          order.orderStatus === ServiceOrderStatus.cancelled ||
          order.orderStatus === ServiceOrderStatus.openedInError) &&
          orderActionsState.closeAllInActive),
      expanded:
        (order.orderStatus !== ServiceOrderStatus.closed &&
          order.orderStatus !== ServiceOrderStatus.cancelled &&
          order.orderStatus !== ServiceOrderStatus.openedInError &&
          orderActionsState.isExpandActive) ||
        ((order.orderStatus === ServiceOrderStatus.closed ||
          order.orderStatus === ServiceOrderStatus.cancelled ||
          order.orderStatus === ServiceOrderStatus.openedInError) &&
          orderActionsState.isExpandInActive),
      isEditing: order.orderNumber === orderActionsState.selectedOrderNumber && orderActionsState.isEditing,
      isEditDisabled: orderActionsState.isEditing,
      id: order.orderNumber,
      title: `${order.productName}`,
      type: 'orders',
      status: order.orderStatus,
      subtitle:
        order.updates?.length > 0 && order.updates[order.updates.length - 1].updateType
          ? [
              `Order No: ${order.orderNumber}-${order.updates[order.updates.length - 1].updateType}`,
              `Created: ${order.createdDate}`,
            ]
          : [`Order No: ${order.orderNumber}`, `Created: ${order.createdDate}`],
      icon: 'document',
      className: 'serviceorder-accordion',
      //pdfQAAttribute: `OrderSummary${i}ViewPDF`,
      summaryDocumentURI: '',
      editQAAttribute: `ServiceOrder${i}Edit`,
      pdfQAAttribute: `ServiceOrder${i}ViewPDF`,
      deleteQAAttribute:
        order.orderStatus !== ServiceOrderStatus.orderdelivered &&
        order.orderStatus !== ServiceOrderStatus.updateDelivered &&
        order.orderStatus !== ServiceOrderStatus.updateRequested
          ? `ServiceOrder${i}Cancel`
          : `ServiceOrder${i}RequestUpdate`,
      onExpandChange: (expand: boolean, id: string | number) => {
        if (expand) {
          order.orderStatus === ServiceOrderStatus.closed
            ? dispatch({ type: 'OnExpandChangeActive' })
            : dispatch({ type: 'OnExpandChangeInActive' });
        }
      },
      ...(order.orderStatus !== ServiceOrderStatus.orderdelivered &&
        order.orderStatus !== ServiceOrderStatus.cancelled &&
        order.orderStatus !== ServiceOrderStatus.closed &&
        order.orderStatus !== ServiceOrderStatus.updateDelivered &&
        order.orderStatus !== ServiceOrderStatus.updateRequested &&
        order.orderStatus !== ServiceOrderStatus.openedInError &&
        fileData?.fileStatus === openFile && {
          onEdit: (id: number | string) => {
            console.log('edit');
            setDrawerOpen(true);
            setEditingOrderNumber(id);
          },
        }),
      ...(order.orderStatus !== ServiceOrderStatus.cancelled &&
        order.orderStatus !== ServiceOrderStatus.closed &&
        order.orderStatus !== ServiceOrderStatus.openedInError && {
          onPDF: (id: number | string) => {
            const summaryDoc = order.userUploadedDocuments?.filter(
              (doc) => doc.description == 'Service Order Summary PDF',
            )[0];
            setSelectedViewDocument(summaryDoc);
          },
        }),
      ...(order.orderStatus !== ServiceOrderStatus.cancelled &&
        order.orderStatus !== ServiceOrderStatus.closed &&
        order.orderStatus !== ServiceOrderStatus.openedInError &&
        fileData?.fileStatus === openFile && {
          onCustom: (id: number | string) => {
            handleAccordionAction(order);
            setUpdateAttempted(false);
            setEditingOrderNumber(id);
            if (
              order.orderStatus === ServiceOrderStatus.orderdelivered ||
              order.orderStatus === ServiceOrderStatus.updateRequested ||
              order.orderStatus === ServiceOrderStatus.updateDelivered
            ) {
              setUpdate(true);
            }
          },
        }),

      onCustomLabel:
        order.orderStatus !== ServiceOrderStatus.orderdelivered &&
        order.orderStatus !== ServiceOrderStatus.updateDelivered &&
        order.orderStatus !== ServiceOrderStatus.updateRequested
          ? 'Cancel Order'
          : 'Request Update',
    };
  }

  const renderOrderDetails = (orders: Order[] | undefined) => {
    return orders?.map((order, index) => (
      <>
        <AccordionContent key={order.productName + [index]} {...getAccordionProps(order, index)}>
          <ServiceOrderForm
            index={index + 1}
            order={order}
            productList={getProductListValue ?? []}
            contentDisabled={!(order.orderNumber === orderActionsState.selectedProduct && orderActionsState.isEditing)}
            updateOrder={updateOrder}
            addOrderFields={addOrderFields}
            submitAttempted={submitAttempted}
            setSubmitAttempted={setSubmitAttempted}
            updateAttempted={updateAttempted}
            setUpdateAttempted={setUpdateAttempted}
            setTriggerSaveCall={setTriggerSaveCall}
            setOnViewDocument={handleViewDocument}
            setOnDownloadPdfZip={handleDownloadAsZipPdf}
          />
        </AccordionContent>
      </>
    ));
  };

  const activeOrders = orders?.filter(
    (item) =>
      item.orderStatus === ServiceOrderStatus.orderplaced ||
      item.orderStatus === ServiceOrderStatus.orderdelivered ||
      item.orderStatus === ServiceOrderStatus.updateRequested ||
      item.orderStatus === ServiceOrderStatus.updateDelivered,
  );

  const inactiveOrders = orders?.filter(
    (item) =>
      item.orderStatus === ServiceOrderStatus.cancelled ||
      item.orderStatus === ServiceOrderStatus.closed ||
      item.orderStatus === ServiceOrderStatus.openedInError,
  );

  const actionStatus =
    getProductListStatus === 'pending' ||
    saveServiceOrdersDataStatus === 'pending' ||
    updateServiceOrdersDataStatus === 'pending' ||
    getServiceOrderDocumentStatus === 'pending'
      ? 'pending'
      : 'idle';

  return (
    <>
      <LoadingSpinner status={actionStatus} variant="linear" />
      {!isAddNewServiceOrderClicked && initialOrders ? (
        <div>
          <div className="paper-layout">
            <Container>
              <AgentNetDivider
                variant={DividerType.header}
                title={draftMode ? 'Create Service Order' : 'Service Orders'}
                typoVariant="h1"
                primaryButtonName="Add New Service Order"
                onClickPrimary={handleAddNewServiceOrder}
                primaryButtonDisable={fileData?.fileStatus != openFile}
                buttonName={activeOrdersCount === 0 ? '' : 'Close All'}
                onClick={() => {
                  dispatch({ type: ServiceOrdersActions.expandCollapseActive, payload: false });
                }}
                buttonName2={activeOrdersCount === 0 ? '' : 'Expand All'}
                onClick2={() => {
                  dispatch({ type: ServiceOrdersActions.expandCollapseActive, payload: true });
                }}
                disablePaddingX
                id={`ServiceOrder${fileData?.propertyState}`}
              />
              {activeOrdersCount === 0 && (
                <Notification inline severity="warning" className={classes.warningMsg}>
                  {noActiveServiceOrders}
                </Notification>
              )}
              {renderOrderDetails(activeOrders)}
              {inactiveOrders?.length ? (
                <AgentNetDivider
                  variant={DividerType.header}
                  title="Inactive Service Orders"
                  {...(orderActionsState.isInactiveToggle && {
                    buttonName: 'Close All',
                    buttonName2: 'Expand All',
                  })}
                  onClick={() => {
                    dispatch({ type: ServiceOrdersActions.expandCollapseInActive, payload: false });
                  }}
                  onClick2={() => {
                    dispatch({ type: ServiceOrdersActions.expandCollapseInActive, payload: true });
                  }}
                  disablePaddingX
                  onSwitch={() => {
                    console.log('onSwitch');
                    dispatch({ type: ServiceOrdersActions.onInactiveToggleChange });
                  }}
                  switchValue={orderActionsState.isInactiveToggle}
                />
              ) : null}
              {orderActionsState.isInactiveToggle ? renderOrderDetails(inactiveOrders) : null}
            </Container>
          </div>
        </div>
      ) : (
        ''
      )}
      {isAddNewServiceOrderClicked && orders.length ? (
        <AgentNetDivider
          variant={DividerType.header}
          title={draftMode ? 'Create Service Order' : 'Order Details'}
          typoVariant="h1"
        />
      ) : (
        ''
      )}
      <div className={classes.serviceOrdersWrap}>
        {drawerOpen ? (
          <>
            <EditOrderForm
              order={orders.find((x) => x.orderNumber === editingOrderNumber) ?? ({} as Order)}
              updateOrder={updateOrder}
              setTriggerUpdateCall={setTriggerUpdateCall}
              updateAttempted={updateAttempted}
              setUpdateAttempted={setUpdateAttempted}
              handleDrawerOpen={drawerOpen}
              onDismissAction={() => setDrawerOpen(false)}
              onSecondaryAction={() => setDrawerOpen(false)}
              isEdit={drawerOpen ?? false}
            />
          </>
        ) : (
          ''
        )}

        {update ? (
          <>
            <EditOrderForm
              order={orders.find((x) => x.orderNumber === editingOrderNumber) ?? ({} as Order)}
              updateOrder={updateOrder}
              setTriggerUpdateCall={setTriggerUpdateCall}
              updateAttempted={updateAttempted}
              setUpdateAttempted={setUpdateAttempted}
              handleDrawerOpen={update}
              onDismissAction={() => setUpdate(false)}
              onSecondaryAction={() => setUpdate(false)}
              isEdit={drawerOpen ?? false}
            />
          </>
        ) : (
          ''
        )}

        {orders.length ? (
          draftMode ? (
            <>
              {orders
                .filter((j) => j.status && j.status === 'Draft')
                .map((p, i) => {
                  return (
                    <ServiceOrderForm
                      index={i + 1}
                      key={p.randomId}
                      order={p}
                      productList={getProductListValue ?? []}
                      contentDisabled={!draftMode}
                      updateOrder={updateOrder}
                      addOrderFields={addOrderFields}
                      submitAttempted={submitAttempted}
                      setSubmitAttempted={setSubmitAttempted}
                      updateAttempted={updateAttempted}
                      setUpdateAttempted={setUpdateAttempted}
                      setTriggerSaveCall={setTriggerSaveCall}
                    />
                  );
                })}
              <ServiceOrderActions
                hasServiceOrders={false}
                saveServiceOrder={() => saveOrders()}
                cancelServiceOrder={cancelOrder}
                isSaveDisabled={actionStatus === 'pending' || isCreateOrderDisabled()}
              />
            </>
          ) : (
            <div>
              <AgentNetConfirmationDialog
                onConfirm={cancelUpdate}
                onDismissAction={() => setNoteDialog({ show: false, id: undefined })}
                open={noteDialog.show}
                size="md"
                disablePrimaryBtn={noteDialog?.note?.trim().length > 0 ? false : true}
                dialogTitle={'Cancel Order'}
                qaAttrPrefix="ServiceOrderCancel"
                dialogBtnContent="Confirm Cancellation"
                dialogTextHTML={
                  <>
                    <Typography variant="body2" className={classes.mb2}>
                      Please leave your reason for cancelling this service order.
                    </Typography>
                    <AgentNetTextInput
                      variant="outlined"
                      label="Note"
                      multiline
                      fullWidth
                      value={noteDialog.note}
                      required
                      onChange={(e: any) => {
                        setNoteDialog({ ...noteDialog, note: e.target.value });
                        updateOrder(noteDialog.id, e.target.value, 'note', false);
                      }}
                      Data-QA={'ServiceOrderCancelNotes'}
                      rows={4}
                    />
                  </>
                }
              />
            </div>
          )
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default ServiceOrderFC;
