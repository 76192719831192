import React, { useState, useContext, useMemo, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ReportsNav from './ReportsNav';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import ReportsList from 'features/Remittance/ReportsList';
import './RemittanceContent.scss';
import { Pay_Manual_Only, Pay_Rights, Remit_Rights, View_Pay } from 'utilities/constants/activity-rights';

const ReportContent = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: '6.4rem',
      },
      list: {
        fontSize: '16px',
        padding: '0 16px',
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 6.4rem)',
        marginTop: '52px',
      },
      contentClass: {
        margin: '0 auto auto',
      },
    }),
  );
  const classes = useStyles();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile } = profileCtx;
  const payRights = useMemo(() => {
    return Boolean(
      profile?.activityRights?.find(
        (rights: any) =>
          rights.ActivityRightId === Pay_Rights ||
          rights.ActivityRightId === Pay_Manual_Only ||
          rights.ActivityRightId === View_Pay,
      ),
    );
  }, [profile?.activityRights]);

  const remitRights = useMemo(() => {
    return Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === Remit_Rights));
  }, [profile?.activityRights]);

  const [selectedTab, setSelectedTab] = useState(1);
  const [reviewCount, setReviewCount] = useState(0);
  const tabName = selectedTab === 0 ? 'Review' : selectedTab === 1 ? 'Report' : 'Pay';
  const handleTabChange = (tab: number) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (!remitRights && payRights) {
      setSelectedTab(2);
    }
  }, [payRights, remitRights]);

  return (
    <div className={classes.root}>
      <ReportsNav
        handleTabChange={handleTabChange}
        tabSelection={selectedTab}
        reviewCount={reviewCount}
        payRights={payRights}
        remitRights={remitRights}
      />
      <div className={classes.contentWrap}>
        <ReportsList
          tabSelection={selectedTab}
          tabName={tabName}
          contentClassName={classes.contentClass}
          setReviewCount={setReviewCount}
          handleTabChange={handleTabChange}
        />
      </div>
    </div>
  );
};

export default ReportContent;
