import React, { useState, useContext, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InvoiceNav from './InvoiceNav';
import './InvoiceContent.scss';
import InvoicesList from 'features/ServiceOrderInvoices/InvoicesList';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
//import { ProfileContext } from 'hooks/ProfileContext';

const InvoiceContent = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: '6.4rem',
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 6.4rem)',
        marginTop: '52px',
      },
      contentClass: {
        margin: '0 auto auto',
      },
    }),
  );
  const classes = useStyles();

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile } = profileCtx;
  const payRights = useMemo(() => {
    return (
      //payRights
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 61)) ||
      //view pay rights
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 84)) ||
      //pay manual rights
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 83))
    );
  }, [profile?.activityRights]);
  const defaultTab = payRights ? 0 : 1;
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const handleTabChange = (tab: number) => {
    setSelectedTab(tab);
  };

  const tabName = selectedTab === 0 ? 'Pay' : 'View';

  return (
    <div className={classes.root}>
      <InvoiceNav handleTabChange={handleTabChange} tabSelection={selectedTab} payRights={payRights} />
      <div className={classes.contentWrap}>
        <InvoicesList
          tabSelection={selectedTab}
          tabName={tabName}
          contentClassName={classes.contentClass}
          handleTabChange={handleTabChange}
        />
      </div>
    </div>
  );
};

export default InvoiceContent;
