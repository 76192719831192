import { getAgencyApiHost, getApiHost } from 'api/host-by-env';
import {
  InCompleteFiles,
  ServiceOrderLogReport,
  CPLActivityLogReport,
  PolicyLogReport,
} from 'core/layout-core/core-content/Reports/types';
import { handleError } from 'utilities/utilities';

export async function incompleteFiles(incompleteFilesReport: InCompleteFiles, token: string): Promise<string> {
  const url = `${getApiHost()}report/report/incomplete-files`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(incompleteFilesReport),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function serviceOrderLog(serviceOrderLogReport: ServiceOrderLogReport, token: string): Promise<string> {
  const url = `${getApiHost()}report/report/service-order-log`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(serviceOrderLogReport),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getHistory(token: string): Promise<any> {
  const url = `${getApiHost()}report/report/history`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function CplActivityLog(cplActivityLogReport: CPLActivityLogReport, token: string): Promise<string> {
  const url = `${getApiHost()}report/report/cpl-Activity-Log`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(cplActivityLogReport),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function PolicyLog(policyLogReport: PolicyLogReport, token: string): Promise<string> {
  const url = `${getApiHost()}report/report/policy-log`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(policyLogReport),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getDocument(reportQueueId: number, token: string): Promise<any> {
  const url = `${getAgencyApiHost()}report-service/v1/document/?ReportQueueId=${reportQueueId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}
