import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
function CloseIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <path
        fill={props.htmlColor ? props.htmlColor : `#5B6A75`}
        d="M19.917 2.491L17.919.494 10 8.413 2.081.493.083 2.492l7.92 7.92-7.92 7.918 1.998 1.998 7.92-7.92 7.918 7.92 1.998-1.998-7.92-7.919 7.92-7.919z"
      ></path>
    </SvgIcon>
  );
}
export default CloseIcon;
