/* eslint-disable react/jsx-key */
import React from 'react';
import { List, ListProps, createStyles, makeStyles } from '@material-ui/core';

export type L3MenuProps = ListProps;

const useStyles = makeStyles(() =>
  createStyles({
    affixMenu: {
      position: 'relative',
    },
  }),
);
const L3NavMenu = ({ children, ...props }: L3MenuProps): JSX.Element => {
  const classes = useStyles();
  return (
    <List {...props} disablePadding={false} component="nav" role="nav" className={classes.affixMenu}>
      {children}
    </List>
  );
};

export default L3NavMenu;
