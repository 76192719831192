import React, { ReactElement, useMemo } from 'react';
import { Collapse, createStyles, List, ListItem, makeStyles, Theme, Typography } from '@agentnet/components';
import { TopicCollection } from '../../../types';
import { Link, useLocation } from 'react-router-dom';
import { ExpandMore, KeyboardArrowRight } from '@material-ui/icons';
import { HELP_ROUTE } from 'features/help/constants';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navHeader: {
      marginBottom: theme.spacing(2),
    },
    navItems: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    w100: {
      width: '100%',
    },
    navItemHeight: {
      lineHeight: '1.25rem',
      borderColor: '#D5DCE2',
    },
    subItem: {
      color: '#666',
    },
    articles: {
      marginLeft: theme.spacing(1),
    },
    title: {
      marginBottom: '-5px',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
    icon: {
      fontSize: '24px',
      color: '#757575',
    },
    selectedIcon: {
      fontSize: '24px',
      color: '#0074CA',
    },
    menuItem: {
      paddingLeft: '0',
    },
    menuItemName: {
      fontWeight: 500,
    },
    selectedItem: {
      color: '#0074CA',
      fontWeight: 600,
    },
  }),
);

function LeftHandNav({ topics }: { topics?: TopicCollection | null }): ReactElement | null {
  const classes = useStyles();
  const location = useLocation();

  const pathParts = useMemo(() => location.pathname.split('/'), [location.pathname]);
  const isSelected = (slug: string, length: number) => {
    if (pathParts.length > length) {
      return false;
    }

    return pathParts.includes(slug);
  };

  const openArray = topics?.topicCollection.items.map((t, i) => {
    return t.slug === pathParts[2];
  });

  const [open, setOpen] = React.useState(openArray ?? []);

  const handleClick = (index: number) => {
    setOpen(open.map((o, i) => (i === index ? !o : o)));
  };

  return (
    <div data-testid="kb-left-hand-nav" className="kb-l3">
      <Typography variant="h3" className={classes.navHeader} data-testid="kb-left-hand-nav-header">
        Browse by Topic
      </Typography>

      <div className={classes.navItems} data-testid="kb-left-hand-nav-items">
        {topics?.topicCollection?.items?.map((topic, index) => {
          return (
            <div className={classes.w100} key={`${topic.topicName}-l3-topic-${index}`}>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <div className={classes.flex} onClick={() => handleClick(index)}>
                  {open[index] ? (
                    <ExpandMore className={isSelected(topic.slug, 3) ? classes.selectedIcon : classes.icon} />
                  ) : (
                    <KeyboardArrowRight className={isSelected(topic.slug, 3) ? classes.selectedIcon : classes.icon} />
                  )}

                  <Link to={`${HELP_ROUTE}/${topic.slug}`}>
                    <ListItem
                      button
                      className={clsx(isSelected(topic.slug, 3) ? classes.selectedItem : '', classes.menuItem)}
                    >
                      <Typography
                        className={clsx(isSelected(topic.slug, 3) ? classes.selectedItem : '', classes.menuItemName)}
                      >
                        {topic.topicName}
                      </Typography>
                    </ListItem>
                  </Link>
                </div>
                <Collapse in={open[index]} timeout="auto" unmountOnExit>
                  <List component="div" className={classes.articles}>
                    {topic.linkedFrom?.kbArticleCollection.items.map((article) => (
                      <Link to={`${HELP_ROUTE}/${topic.slug}/${article.slug}`} key={`${article.slug}-l3-article`}>
                        <ListItem className={classes.title}>
                          <Typography className={isSelected(article.slug, 4) ? classes.selectedItem : classes.subItem}>
                            {article.articleTitle}
                          </Typography>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              </List>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { LeftHandNav };
