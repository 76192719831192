import React from 'react';
import 'ag-grid-enterprise';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './ui-kit/theme/theme';
import AppCore from './core/AppCore';
import './App.css';
import './common-assets/styles/styles.scss';
import { AuthProvider } from '@agentnet/auth';
import { ProfileProvider } from './hooks/ProfileContext';
import { SnackbarMessageProvider } from './ui-kit/components/notification/SnackbarProvider';
import { GlobalMsgProvider } from './ui-kit/components/notification/GlobalMsgProvider';
import { FileDataProvider } from './hooks/FileDataContext';
import { ViewStateProvider } from 'hooks/ViewStateContext';

const App: React.FC = () => {
  return (
    <Router
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt so we can display custom modals that respond to navigation events */
      }}
    >
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <SnackbarMessageProvider>
            <ProfileProvider>
              <FileDataProvider>
                <GlobalMsgProvider>
                  <ViewStateProvider>
                    <AppCore />
                  </ViewStateProvider>
                </GlobalMsgProvider>
              </FileDataProvider>
            </ProfileProvider>
          </SnackbarMessageProvider>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
