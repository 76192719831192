import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, InputAdornment, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { CurrencyField } from 'ui-kit/inputs';
import OverflowTip from 'ui-kit/components/utility/OverflowTip';
import { calculateTotals, dollarize } from 'utilities/utilities';
interface ReportedFeesTableRowProps {
  rateFeeLineItemId: string;
  rateFeeDescription: string;
  grossReported: number;
  netReported: number;
  isGrossOverridden?: boolean;
  isNetOverridden?: boolean;
  isDisabled?: boolean;
  additionalFees?: ReportedFeesTableRowProps[];
}

interface ReportedFeesTableProps {
  products?: Array<any>;
  onChangeProduct?: (
    value: any,
    rateFeeLineItemId: string,
    field: string,
    childId?: string,
    nestedChildId?: string,
  ) => void;
  onClear: (rateFeeLineItemId: string, field: string, childId?: string, nestedChildId?: string) => void;
  qaPrefix: string;
  showValidation?: boolean;
  displayNet?: boolean;
  disableFields?: boolean;
}

const randomID = () => (Math.random() + 1).toString(36).substring(7);

const productsTest = [
  {
    rateFeeLineItemId: randomID(),
    rateFeeDescription: 'ALTA Loan Policy',
    grossReported: 0,
    netReported: 0,
    grossCalculated: 0,
    netCalculated: 0,
    isDisabled: false,
    isGrossOverridden: false,
    isNetOverridden: false,
    additionalFees: [
      {
        rateFeeLineItemId: randomID(),
        rateFeeDescription: 'State Policy Fee',
        grossReported: 3,
        netReported: 2.4,
        grossCalculated: 3,
        netCalculated: 2.4,
        isDisabled: true,
        isGrossOverridden: false,
        isNetOverridden: false,
      },
      {
        rateFeeLineItemId: randomID(),
        rateFeeDescription: '[ALTA 6.2-06] Variable Mortgage Rate - Negative Amorization',
        grossReported: 55,
        netReported: 44,
        grossCalculated: 55,
        netCalculated: 44,
        isDisabled: false,
        isGrossOverridden: false,
        isNetOverridden: false,
        additionalFees: [
          {
            rateFeeLineItemId: randomID(),
            rateFeeDescription: 'Municipality Fee',
            grossReported: 3,
            netReported: 2.4,
            grossCalculated: 3,
            netCalculated: 2.4,
            isDisabled: true,
            isGrossOverridden: false,
            isNetOverridden: false,
          },
        ],
      },
      {
        rateFeeLineItemId: randomID(),
        rateFeeDescription: '[ALTA 3.3-06] Zoning - Completed Improvement - Non-conf...',
        grossReported: 25,
        netReported: 32,
        grossCalculated: 25,
        netCalculated: 32,
        isDisabled: false,
        isGrossOverridden: false,
        isNetOverridden: false,
      },
    ],
  },
  {
    rateFeeLineItemId: randomID(),
    rateFeeDescription: 'StandardCPL',
    grossReported: 20,
    netReported: 16,
    grossCalculated: 20,
    netCalculated: 16,
    isDisabled: false,
    isGrossOverridden: false,
    isNetOverridden: false,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reportedFeesTable: {
      width: '100%',
      margin: '8px 35px 16px 8px',
    },
    reportedFeesTableHeader: {
      backgroundColor: '#F2F5F6',
      borderBottom: '1px solid #D9E0E5',
      fontWeight: 600,
      fontSize: '1.4rem',
    },
    reportedFeesTableHeaderCell: {
      padding: '1.2rem 0.8rem',
    },
    reportedFeesTableList: {},
    tableRow: {
      borderBottom: `1px solid #E0E0E0`,
    },
    tableCell: {
      padding: `0.8rem`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    tableCellRight: {
      textAlign: 'right',
    },
    indented: {
      paddingLeft: theme.spacing(3),
    },
    indented2: {
      paddingLeft: theme.spacing(5),
    },
    totalsRow: {
      borderTop: '1px solid #616161',
      borderBottom: '1px solid #E0E0E0',
    },
    totalsCell: {
      padding: theme.spacing(1),
    },
    weight500: {
      fontWeight: 500,
    },
    my1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    isTouched: {
      backgroundColor: '#FDF8CF',
    },
    isError: {
      backgroundColor: '#FDECEA !important',
    },
  }),
);

const ReportedFeesTable = ({
  onChangeProduct,
  products = productsTest,
  onClear,
  qaPrefix,
  showValidation,
  displayNet = true,
  disableFields = true,
}: ReportedFeesTableProps) => {
  const classes = useStyles();
  const [grandTotal, setGrandTotal] = useState({
    gross: 0,
    net: 0,
  });
  useEffect(() => {
    setGrandTotal(calculateTotals(products));
  }, [products]);

  return (
    <>
      {products && products.length > 0 && (
        <div className={classes.reportedFeesTable}>
          <Grid container className={classes.reportedFeesTableHeader}>
            <Grid item sm={displayNet ? 6 : 9} className={classes.reportedFeesTableHeaderCell}>
              Product
            </Grid>
            <Grid item sm={3} className={classes.reportedFeesTableHeaderCell}>
              Gross Reported
            </Grid>
            {displayNet && (
              <Grid item sm={3} className={classes.reportedFeesTableHeaderCell}>
                Net Reported
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.reportedFeesTableList}>
            {products.map((e: any, i) => (
              <>
                <Grid container key={e.rateFeeLineItemId} className={classes.tableRow}>
                  <Grid item sm={displayNet ? 6 : 9} className={clsx(classes.tableCell)}>
                    <OverflowTip>
                      <Typography variant="body2">{e.rateFeeDescription}</Typography>
                    </OverflowTip>
                  </Grid>
                  <Grid item sm={3} className={clsx(classes.tableCell)}>
                    <CurrencyField
                      variant="outlined"
                      value={e.grossReported}
                      fullWidth
                      compact
                      max={999999999}
                      allowNegative={false}
                      data-qa={`${qaPrefix}Gross${i + 1}`}
                      onChange={(el) => {
                        onChangeProduct && onChangeProduct(el.target.value, e.rateFeeLineItemId, 'grossReported');
                      }}
                      className={clsx({ [classes.isTouched]: e.isGrossOverridden === true })}
                      disabled={disableFields || e.isDisabled}
                      InputProps={
                        e.isGrossOverridden && {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                                onClick={() => onClear(e.rateFeeLineItemId, 'grossReported')}
                                edge="end"
                              >
                                <Clear fontSize="large" data-qa={`${qaPrefix}DeleteGross${i + 1}`} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      }
                    />
                  </Grid>
                  {displayNet && (
                    <Grid item sm={3} className={clsx(classes.tableCell)}>
                      <CurrencyField
                        variant="outlined"
                        value={e.netReported}
                        error={e.isError && showValidation}
                        fullWidth
                        compact
                        max={999999999}
                        data-qa={`${qaPrefix}Net${i + 1}`}
                        allowNegative={false}
                        onChange={(el) => {
                          onChangeProduct && onChangeProduct(el.target.value, e.rateFeeLineItemId, 'netReported');
                        }}
                        className={clsx({
                          [classes.isTouched]: e.isNetOverridden === true,
                          [classes.isError]: e.isError && showValidation,
                        })}
                        disabled={disableFields || e.isDisabled}
                        InputProps={
                          e.isNetOverridden && {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disableFocusRipple
                                  disableRipple
                                  disableTouchRipple
                                  onClick={() => onClear(e.rateFeeLineItemId, 'netReported')}
                                  edge="end"
                                >
                                  <Clear fontSize="large" data-qa={`${qaPrefix}DeleteNet${i + 1}`} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                {e.additionalFees &&
                  e.additionalFees.map((c: any, j: any) => (
                    <>
                      <Grid container key={c.rateFeeLineItemId} className={classes.tableRow}>
                        <Grid item sm={displayNet ? 6 : 9} className={clsx(classes.tableCell, classes.indented)}>
                          <OverflowTip>
                            <Typography variant="body2">{c.rateFeeDescription}</Typography>
                          </OverflowTip>
                        </Grid>
                        <Grid item sm={3} className={clsx(classes.tableCell)}>
                          <CurrencyField
                            variant="outlined"
                            value={c.grossReported}
                            fullWidth
                            compact
                            data-qa={`${qaPrefix}Gross${i + 1}${j + 1}`}
                            max={999999999}
                            allowNegative={false}
                            onChange={(el) => {
                              onChangeProduct &&
                                onChangeProduct(
                                  el.target.value,
                                  e.rateFeeLineItemId,
                                  'grossReported',
                                  c.rateFeeLineItemId,
                                );
                            }}
                            className={clsx({ [classes.isTouched]: c.isGrossOverridden === true })}
                            disabled={disableFields || c.isDisabled}
                            InputProps={
                              c.isGrossOverridden && {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      disableFocusRipple
                                      disableRipple
                                      disableTouchRipple
                                      onClick={() => onClear(e.rateFeeLineItemId, 'grossReported', c.rateFeeLineItemId)}
                                      edge="end"
                                    >
                                      <Clear fontSize="large" data-qa={`${qaPrefix}DeleteGross${i + 1}${j + 1}`} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }
                            }
                          />
                        </Grid>
                        {displayNet && (
                          <Grid item sm={3} className={clsx(classes.tableCell)}>
                            <CurrencyField
                              variant="outlined"
                              value={c.netReported}
                              fullWidth
                              compact
                              max={999999999}
                              error={c.isError && showValidation}
                              data-qa={`${qaPrefix}Net${i + 1}${j + 1}`}
                              allowNegative={false}
                              onChange={(el) => {
                                onChangeProduct &&
                                  onChangeProduct(
                                    el.target.value,
                                    e.rateFeeLineItemId,
                                    'netReported',
                                    c.rateFeeLineItemId,
                                  );
                              }}
                              className={clsx({
                                [classes.isTouched]: c.isNetOverridden === true,
                                [classes.isError]: c.isError && showValidation,
                              })}
                              disabled={disableFields || c.isDisabled}
                              InputProps={
                                c.isNetOverridden && {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disableFocusRipple
                                        disableRipple
                                        disableTouchRipple
                                        onClick={() => onClear(e.rateFeeLineItemId, 'netReported', c.rateFeeLineItemId)}
                                        edge="end"
                                      >
                                        <Clear fontSize="large" data-qa={`${qaPrefix}DeleteNet${i + 1}${j + 1}`} />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                      {c.additionalFees &&
                        c.additionalFees.map((z: any, k: any) => (
                          <Grid container key={z.rateFeeLineItemId} className={classes.tableRow}>
                            <Grid item sm={displayNet ? 6 : 9} className={clsx(classes.tableCell, classes.indented2)}>
                              <OverflowTip>
                                <Typography variant="body2">{z.rateFeeDescription}</Typography>
                              </OverflowTip>
                            </Grid>
                            <Grid item sm={3} className={clsx(classes.tableCell)}>
                              <CurrencyField
                                variant="outlined"
                                value={z.grossReported}
                                fullWidth
                                compact
                                data-qa={`${qaPrefix}Gross${i + 1}${j + 1}${k + 1}`}
                                max={999999999}
                                allowNegative={false}
                                onChange={(el) => {
                                  onChangeProduct &&
                                    onChangeProduct(
                                      el.target.value,
                                      e.rateFeeLineItemId,
                                      'grossReported',
                                      c.rateFeeLineItemId,
                                      z.rateFeeLineItemId,
                                    );
                                }}
                                className={clsx({ [classes.isTouched]: z.isGrossOverridden === true })}
                                disabled={disableFields || z.isDisabled}
                                InputProps={
                                  z.isGrossOverridden && {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          disableFocusRipple
                                          disableRipple
                                          disableTouchRipple
                                          onClick={() =>
                                            onClear(
                                              e.rateFeeLineItemId,
                                              'grossReported',
                                              c.rateFeeLineItemId,
                                              z.rateFeeLineItemId,
                                            )
                                          }
                                          edge="end"
                                        >
                                          <Clear
                                            fontSize="large"
                                            data-qa={`${qaPrefix}DeleteGross${i + 1}${j + 1}${k + 1}`}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }
                                }
                              />
                            </Grid>
                            {displayNet && (
                              <Grid item sm={3} className={clsx(classes.tableCell)}>
                                <CurrencyField
                                  variant="outlined"
                                  value={z.netReported}
                                  fullWidth
                                  compact
                                  error={z.isError && showValidation}
                                  data-qa={`${qaPrefix}Net${i + 1}${j + 1}${k + 1}`}
                                  max={999999999}
                                  allowNegative={false}
                                  onChange={(el) => {
                                    onChangeProduct &&
                                      onChangeProduct(
                                        el.target.value,
                                        e.rateFeeLineItemId,
                                        'netReported',
                                        c.rateFeeLineItemId,
                                        z.rateFeeLineItemId,
                                      );
                                  }}
                                  className={clsx({
                                    [classes.isTouched]: z.isNetOverridden === true,
                                    [classes.isError]: z.isError && showValidation,
                                  })}
                                  disabled={disableFields || z.isDisabled}
                                  InputProps={
                                    z.isNetOverridden && {
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            onClick={() =>
                                              onClear(
                                                e.rateFeeLineItemId,
                                                'netReported',
                                                c.rateFeeLineItemId,
                                                z.rateFeeLineItemId,
                                              )
                                            }
                                            edge="end"
                                          >
                                            <Clear
                                              fontSize="large"
                                              data-qa={`${qaPrefix}DeleteNet${i + 1}${j + 1}${k + 1}`}
                                            />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        ))}
                    </>
                  ))}
              </>
            ))}
            <Grid container className={classes.totalsRow}>
              <Grid item sm={displayNet ? 6 : 9} className={classes.totalsCell}>
                <Typography variant="body2" className={classes.weight500}>
                  Grand Total:
                </Typography>
              </Grid>
              <Grid item sm={3} className={clsx(classes.totalsCell, classes.tableCellRight)}>
                <Typography variant="body2" data-qa={`${qaPrefix}GrossTotal`} className={classes.weight500}>
                  {dollarize(grandTotal.gross)}
                </Typography>
              </Grid>
              {displayNet && (
                <Grid item sm={3} className={clsx(classes.totalsCell, classes.tableCellRight)}>
                  <Typography variant="body2" data-qa={`${qaPrefix}NetTotal`} className={classes.weight500}>
                    {dollarize(grandTotal.net)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ReportedFeesTable;
