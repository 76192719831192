import React from 'react';
import moment from 'moment';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { formatDate } from './reportsListConfig';
type PolicyDateRendererProps = {
  value?: string;
};

const PolicyDateRenderer = ({ value }: PolicyDateRendererProps) => {
  const policyDt = moment(value);
  const currDt = moment(new Date());
  const dayDiff = moment.duration(currDt.diff(policyDt)).asDays();

  return (
    <>
      {dayDiff > 90 ? (
        <div className="error-status d-flex-center">
          {formatDate(value ?? '')}
          <span className="policy-date-icon">
            <ErrorOutlineIcon />
          </span>
        </div>
      ) : (
        <div>{formatDate(value ?? '')}</div>
      )}
    </>
  );
};

export default PolicyDateRenderer;
