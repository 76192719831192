import React, { useCallback, useMemo, useRef, useState, useEffect, useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import { Notification } from 'ui-kit/components/notification/Notification';
import { AgGridReact } from 'ag-grid-react';
import clsx from 'clsx';
import { ColDef } from 'ag-grid-community';
import NoResultFoundIcon from 'ui-kit/icons/NoResultFound';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../files/files-summary/files-list/FilesList.scss';
import '../ServiceOrderInvoices/Invoice.scss';
import { useAuth } from '@agentnet/auth';
import useAsync from 'hooks/useAsync';
import { getPrefferedOffices, setPrefferedOffices } from 'api/admin/preferred-offices-api';
import { SavePreferredOffices } from 'api/admin/interfaces';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      background: theme.palette.common.white,
    },
    contentWrap: {
      padding: `0 0 32rem`,
      //maxWidth: 1200,
    },
    mb: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      width: '926px',
    },
    preferredOfficeDesc: {
      marginLeft: theme.spacing(3),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    userName: {
      marginBottom: theme.spacing(0.5),
    },
    heading: {},
    captionDivider: {
      width: theme.spacing(2),
      display: 'inline-block',
    },
    fieldSection: {},
    fieldSectionLeft: {
      padding: theme.spacing(3),
    },
    fieldSectionRight: {
      padding: theme.spacing(3),
      marginTop: 0,
    },
    adornmentIcon: {
      padding: 0,
      '&:hover': {
        background: 'transparent',
      },
    },
    infoIcon: {
      alignItems: 'flex-start',
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
    ctaBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    alertContainer: {
      marginBottom: '63px',
    },
    alertMsg: {
      width: '-webkit-fill-available',
      position: 'absolute',
      bottom: '63px',
      left: 0,
      padding: '0 35px',
    },
    tableStyles: {
      marginTop: '0px !important',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      height: 'calc(100vh - 330px) !important',
      //width: '95% !important',
    },
  }),
);
const selectAllOption = 'All';
// const CustomOverlayNoRows = () => {
//   return (
//     <div>
//       <NoResults
//         showButton={false}
//         type="results"
//         titleText="No Results Found"
//         pText="Try adjusting your filters to find what you’re looking for."
//       />
//     </div>
//   );
// };

const CustomOverlayNoRows = () => {
  return (
    <div className="no-rows">
      <NoResultFoundIcon className="no-files" />
      <h1>No Results Found</h1>
      <p className="adjust-your-search">Try adjusting your search or filter to find what you’re looking for</p>
    </div>
  );
};

const prefferedOfficeContent = () => {
  const classes = useStyles();
  const gridRef = useRef<any>(null);
  const { addSnackbarMessage } = useSnackBars();
  const [gridApi, setGridApi] = useState<any>(null);
  const [columnApi, setColumnApi] = useState<any>(null);
  const { getAccessToken } = useAuth();
  const [rowData, setRowData] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [underwriters, setUnderWriters] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { setpreferredOffice } = profileCtx;
  const preferredOfficesData = async () => {
    const token = await getAccessToken();
    const prefferedOfficeresponse = await getPrefferedOffices(token);
    setRowData(prefferedOfficeresponse);
    const state = Array.from(new Set(prefferedOfficeresponse.map((item: { StateCode: any }) => item.StateCode))).sort();
    setStates(state);
    const underwriter = Array.from(
      new Set(prefferedOfficeresponse.map((item: { UnderwriterName: any }) => item.UnderwriterName)),
    ).sort();
    setUnderWriters(underwriter);
    console.log('prefferedOfficeresponse', prefferedOfficeresponse);
  };

  useEffect(() => {
    if (gridApi) {
      rowData.forEach((row: { IsSelected: number }, index: any) => {
        if (row.IsSelected > 0) {
          gridApi.forEachNode((node: any) => {
            if (node.rowIndex === index) {
              node.setSelected(true);
            }
          });
        }
      });
    }
  }, [gridApi, rowData]);
  const { execute: executePreferredOfficeData, status: getPreferredOfficeData } = useAsync(preferredOfficesData, false);
  const actionStatus = getPreferredOfficeData === 'pending' ? 'pending' : 'idle';
  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const onSelectionChanged = useCallback(() => {
    const currentselectedRows = gridRef.current?.api.getSelectedRows();
    setSelectedRows(currentselectedRows);
  }, []);

  const savePreferredOffices = async () => {
    if (selectedRows) {
      const jurisdictionIds: number[] = selectedRows.map((o: any) => o.FirmLocationJurisdictionID);

      if (jurisdictionIds !== undefined) {
        const saveOffices: SavePreferredOffices = {
          FirmLocationJurisdictionIds: jurisdictionIds.join(','),
        };
        const token = await getAccessToken();
        const saveResponse = await setPrefferedOffices(token, saveOffices);
        const prefferedOfficeresponse = await getPrefferedOffices(token);
        if (setpreferredOffice) {
          setpreferredOffice(prefferedOfficeresponse);
        }
        if (saveResponse.Status) {
          addSnackbarMessage({
            message: 'Saving Successful',
            type: 'success',
          });
        } else {
          addSnackbarMessage({
            message: 'Save Failed',
            type: 'error',
          });
        }
      }
      window.location.reload();
    }
  };

  const defaultColDef = useMemo<ColDef>(() => {
    // call get the list API here;
    executePreferredOfficeData();
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, []);

  const prefferedOfficeColumnsConfig: any = [
    {
      field: '',
      maxWidth: 40,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      // pinned: 'left',
    },
    {
      headerName: 'Firm',
      field: 'FirmName',
      tooltipField: 'FirmName',
      // sort: 'desc',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Office',

      field: 'BusinessName',
      tooltipField: 'BusinessName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'State',
      maxWidth: 100,
      // tooltipField: 'state',
      filter: 'agTextColumnFilter',

      field: 'StateCode',
      tooltipField: 'StateCode',
      // filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Underwriter',
      field: 'UnderwriterName',
      tooltipField: 'UnderwriterName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Account',
      maxWidth: 140,
      field: 'FirmLocationJurisdictionID',
      tooltipField: 'FirmLocationJurisdictionID',
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'ALTA ID',
      maxWidth: 140,
      // tooltipField: 'altaid',
      filter: 'agTextColumnFilter',
      field: 'ALTAID',
      tooltipField: 'ALTAID',
    },
  ];

  return (
    <>
      <div className={classes.root}>
        <LoadingSpinner status={actionStatus} variant="linear" />
        {rowData.length !== 0 && (
          <AgentNetDivider
            typoVariant="h1"
            title={`Preferred Offices`}
            subtitle="Select offices to show in dropdown when creating new files."
            subtitleMuted
          />
        )}

        {rowData.length !== 0 && (
          <div className={classes.mb2}>
            <Grid item sm={12}>
              <Notification inline severity="info" className={classes.infoIcon}>
                If multiple offices work on the same file, it is recommended to select all offices to avoid file
                duplication.
              </Notification>
            </Grid>
          </div>
        )}
        {rowData.length !== 0 && (
          <div className={clsx('ag-theme-alpine table-grid', classes.tableStyles)}>
            <AgGridReact
              columnDefs={prefferedOfficeColumnsConfig}
              ref={gridRef}
              rowData={rowData}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              suppressRowClickSelection={true}
              rowSelection={'multiple'}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        )}

        {rowData.length !== 0 && (
          <BottomAppBar
            primaryActionLabel="Save"
            onPrimaryClick={() => savePreferredOffices()}
            cancelLabel="Clear All"
            onCancel={() => window.location.reload()}
          />
        )}
      </div>
    </>
  );
};

export default prefferedOfficeContent;
