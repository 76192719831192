import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Paper, Tabs, Tab, Tooltip } from '@material-ui/core';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useRatesAndFee from 'features/Remittance/useRatesAndFee';
import ReportsAndPayDrawer from 'core/layout-core/core-content/ReportsAndPayDrawer';
import CalculatorPricing from 'ui-kit/icons/CalculatorPricing';
import './FileContent.scss';
import { useViewState } from 'hooks/ViewStateContext';

const TabNavigation = ({ expanded }: { expanded?: boolean }): JSX.Element => {
  const { menuCollapsed } = useViewState();
  const [drawerWidth, setDrawerWidth] = React.useState(menuCollapsed ? 104 : 284);
  React.useEffect(() => {
    setDrawerWidth(menuCollapsed ? 104 : 284);
  }, [menuCollapsed]);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      tabWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: '.3s all',
        marginTop: '4.6rem',
        padding: `0 ${theme.spacing(2)}px`,
        borderBottom: `1px solid #e5e5e5`,
        position: 'fixed',
        zIndex: 5,
        width: '100%',
        '& .MuiTab-root': {
          margin: '0 8px',
        },
      },
      tabWrapInner: {
        display: 'flex',
        justifyContent: 'space-between',
        width: `calc(100% - ${drawerWidth}px)`, //100% of page minus navbar width and padding
      },
      tabWrapExpanded: {},
    }),
  );
  const history = useHistory();
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData, tabValue, setTabValue, setFirmAnchorEl, setExecuteFileApiCall } = fileDataCtx;
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile, userFirm } = profileCtx;
  const [hideCplMenu, setHideCplMenu] = useState<boolean>(false);
  const [hideDocumentsMenu, setHideDocumentsMenu] = useState<boolean>(false);
  const [isApprovedAttorneyAccount, setIsApprovedAttorneyAccount] = useState<boolean>(false);
  const classes = useStyles();
  const isRatesAndFees = profile?.activityRights?.some((right) => right.ActivityRightId === 25);

  const { drawerData, setDrawerData, openDrawer, setOpenDrawer, handleDrawerOpen, rateAndFeeExecute } = useRatesAndFee(
    userFirm?.firmId ?? '',
    1,
  );

  useEffect(() => {
    if (profile?.roleId === 2 || profile?.roleId === 13) setHideCplMenu(true);
  }, [profile]);

  useEffect(() => {
    if (
      profile?.roleId === 2 &&
      profile?.roleTypeId === 101 &&
      profile?.activityRights?.some((right) => right.ActivityRightId === 125)
    )
      setHideDocumentsMenu(true);
  }, [profile]);

  useEffect(() => {
    const accountNumber = parseInt(window.sessionStorage.getItem('accountNumber') || '0', 10);
    if (fileData && userFirm?.offices)
      for (const office of userFirm.offices)
        for (const account of office.accounts)
          if (accountNumber === account.accountId) {
            setIsApprovedAttorneyAccount(account.clientType === 'Approved Attorney');
            break;
          }
  }, [fileData]);

  const isDisabled = useMemo(() => !fileData?.fileId, [fileData?.fileId]);

  const handleRatesandFees = () => {
    const hasDataList = [
      fileData?.serviceStatus?.hasRemittableCpl,
      fileData?.serviceStatus?.hasActiveJacket,
      fileData?.serviceStatus?.hasStandAloneEndorsement,
      fileData?.serviceStatus?.hasRemittedJacket,
      fileData?.serviceStatus?.hasActiveCpl,
      fileData?.serviceStatus?.hasRemittedCpl,
    ];

    if (!hasDataList.includes(true)) {
      handleDrawerOpen({ data: { fileNumber: fileData?.fileNumber } }, 'report', true);
    } else {
      handleDrawerOpen(
        {
          data: {
            fileId: fileData?.fileId,
            fileNumber: fileData?.fileNumber,
            firmId: fileData?.firmId,
            underwriter: fileData?.underwriterCode,
            isReopened: fileData?.serviceStatus?.isReopenedForRemit,
          },
        },
        fileData?.serviceStatus?.isPricingValid ? 'report' : 'review',
      );
    }
  };

  const switchToSummaryPage = (selectedRows: any, showSummary: boolean, localFirmID: string | null) => {
    history.push({
      pathname: '/reports',
      state: { localFirmID, selectedRows },
    });
  };

  const isCalculateIconValid = useMemo(() => {
    return !(
      !fileData?.serviceStatus?.isPricingValid ||
      (fileData?.serviceStatus?.isPricingValid && !fileData?.serviceStatus?.isRemitValid)
    );
  }, [fileData?.serviceStatus?.isPricingValid, fileData?.serviceStatus?.isRemitValid]);

  const onReport = () => setExecuteFileApiCall?.(true);

  return (
    <>
      <Paper
        square
        elevation={0}
        className={clsx(classes.tabWrap, {
          [classes.tabWrapExpanded]: expanded,
        })}
      >
        <div className={classes.tabWrapInner}>
          <Tabs
            value={parseInt(tabValue || '0')}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, v) => {
              setTabValue && setTabValue(v);
              setFirmAnchorEl && setFirmAnchorEl(null);
            }}
          >
            <Tab
              label="File Info"
              onClick={() => history.push(`/files/file-info/${fileData?.fileId}`)}
              Data-QA="FileNavFileInfo"
              disabled={isDisabled}
            />
            <Tab
              label="Back Title"
              onClick={() => history.push(`/files/${fileData?.fileId}/back-title`)}
              Data-QA="FileNavBackTitle"
              disabled={isDisabled}
            />
            {!hideCplMenu ? (
              <Tab
                label="CPL"
                disabled={isDisabled}
                onClick={() => history.push(`/files/${fileData?.fileId}/cpl`)}
                Data-QA="FileNavCPL"
              />
            ) : null}
            <Tab
              label="Service Orders"
              onClick={() => history.push('/files/file-service-orders')}
              Data-QA="FileNavServiceOrder"
              disabled={isDisabled}
            />
            <Tab
              label="Underwriting"
              onClick={() => history.push(`/files/${fileData?.fileId}/underwriting`)}
              Data-QA="FileNavUnderwriting"
              disabled={isDisabled}
            />
            {!isApprovedAttorneyAccount ? (
              <Tab
                label="Jackets"
                onClick={() => history.push(`/files/${fileData?.fileId}/jackets`)}
                Data-QA="FileNavJackets"
                disabled={isDisabled}
              />
            ) : null}
            <Tab label="Notes" disabled Data-QA="FileNavNotes" />
            {!hideDocumentsMenu ? (
              <Tab
                label="Documents"
                Data-QA="FileNavDocuments"
                onClick={() => history.push(`/files/${fileData?.fileId}/documents`)}
                disabled={isDisabled}
              />
            ) : null}
          </Tabs>
          {isRatesAndFees ? (
            <Tooltip title="Open Rates and Fees">
              <div style={{ cursor: 'pointer' }} data-qa="FileNavCalcRateIcon" onClick={handleRatesandFees}>
                <CalculatorPricing isValid={isCalculateIconValid || false} />
              </div>
            </Tooltip>
          ) : null}
        </div>
      </Paper>
      <ReportsAndPayDrawer
        isFromFile
        openDrawer={openDrawer}
        disableFields={fileData?.fileStatus !== 'Open'}
        onReport={onReport}
        setOpenDrawer={setOpenDrawer}
        drawerData={drawerData}
        fileId={fileData?.fileId}
        setFileTab={setTabValue}
        setDrawerData={setDrawerData}
        rateAndFeeExecute={rateAndFeeExecute}
        switchToSummaryPage={switchToSummaryPage}
      />
    </>
  );
};

export default TabNavigation;
