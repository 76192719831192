import * as yup from 'yup';
export const REQUIRED_FIELD_MESSAGE_TEXT = 'Required';
export const requiredFieldMessage = (fieldName: any): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
declare module 'yup' {
  interface StringSchema {
    validateEmail(type: string): StringSchema;
  }
}

declare module 'yup' {
  interface StringSchema {
    validateOfficeEmail(): StringSchema;
  }
}

yup.addMethod<yup.StringSchema>(yup.string, 'validateEmail', function (type: string) {
  return this.test({
    test(value) {
      const myArray = value?.toString().split(';');
      const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      let isValid = true;
      myArray?.every(function (element, index) {
        const userinput = element;
        if (userinput && userinput.trim() && !pattern.test(userinput)) {
          isValid = false;
        } else return true;
      });

      if (!isValid && type === 'Firm') {
        return this.createError({
          message: 'Please enter a valid Email address',
          path: 'email',
        });
      }
      if (!isValid && type === 'Office') {
        return this.createError({
          message: 'Please enter a valid Email address',
          path: 'emailOffice',
        });
      } else {
        return true;
      }
    },
  });
});

export const AutoReportingSchema = yup.object().shape({
  email: yup.string().trim().required(requiredFieldMessage('Email Notification')).validateEmail('Firm'),
  frequencyFirm: yup.string().required(requiredFieldMessage('Frequency')).nullable(),
  reportDay: yup.string().required(requiredFieldMessage('Report Day')).optional(),
  startDate: yup
    .date()
    .required(requiredFieldMessage('Start Date'))
    .min(new Date(), 'Start Date must be a future date')
    .nullable()
    .test('is-valid-date', 'Invalid Start Date', function (value) {
      if (!value) {
        return true; // Allow blank values
      }
      return yup.date().min(new Date('0500-01-01')).max(new Date('2100-01-01')).isValidSync(value);
    }),
  daysAfterPolicyDate: yup.string().required(requiredFieldMessage('Days after Policy Date')).nullable(),
});

export const AutoReportingSchemaOffice = yup.object().shape({
  emailOffice: yup.string().trim().required(requiredFieldMessage('Email Notification')).validateEmail('Office'),
  frequencyOffice: yup.string().required(requiredFieldMessage('Frequency')).nullable(),
  reportDayOffice: yup.string().required(requiredFieldMessage('Report Day')).optional(),
  startDateOffice: yup
    .date()
    .required(requiredFieldMessage('Start Date'))
    .min(new Date(), 'Start Date must be a future date')
    .nullable()
    .test('is-valid-date', 'Invalid Start Date', function (value) {
      if (!value) {
        return true; // Allow blank values
      }
      return yup.date().min(new Date('0500-01-01')).max(new Date('2100-01-01')).isValidSync(value);
    }),
  daysAfterPolicyDateOffice: yup.string().required(requiredFieldMessage('Days after Policy Date')).nullable(),
});
