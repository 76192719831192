import { GridColDef, GridValueFormatterParams, GridRenderCellParams, GridCellValue } from '@mui/x-data-grid-pro';
import moment, { MomentInput } from 'moment';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PDFIcon from '../../../../ui-kit/icons/PDF';
import FileStatus from '../../../../ui-kit/icons/FileStatus';
import { dateRangeFilters } from '../../../constants';
import formatISO from 'date-fns/formatISO';
import subYears from 'date-fns/subYears';
import formatDate from 'date-fns/format';
import addSeconds from 'date-fns/addSeconds';
import addMinutes from 'date-fns/addMinutes';
import addHours from 'date-fns/addHours';
import './FilesList.scss';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { Firm } from 'api/profile/types';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';

export const FileNumber = ({
  id,
  fileNumber,
  data,
  firmId,
}: {
  id: any;
  fileNumber: GridCellValue;
  data: any;
  firmId?: string;
}): JSX.Element => {
  const history = useHistory();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, setUserFirm, profile } = profileCtx;
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { setTabValue } = fileDataCtx;

  function handleClick() {
    window.sessionStorage.setItem('fileId', id);
    window.sessionStorage.setItem('accountNumber', data.accountNumber);
    if (firmId && firmId !== userFirm?.firmId) {
      if (!profile?.firms) return undefined;
      const fId: Firm | undefined = profile.firms.find((firm: Firm) => firm.firmId === firmId);

      if (fId) {
        window.localStorage.setItem('userFirm', JSON.stringify(fId));
        setUserFirm && setUserFirm(fId);
      }
    }
    setTabValue && setTabValue('0');
    history.push(`/files/file-info/${id}`);
  }

  return (
    <div className="fileNumber" onClick={handleClick} data-qa={fileNumber}>
      {fileNumber}
    </div>
  );
};

export const FormatDate = ({ value }: { value: string }): string => {
  if (!value) return '';
  const dateParsed = Date.parse(value);
  const valueFormatted = formatDate(dateParsed, 'MM/dd/yyyy');
  return valueFormatted;
};

export const getDateRange = (
  type: string,
  filterModelDateFrom: string,
  filterModelDateTo: string,
  years = 10,
): { dateFrom: Date; dateTo: Date; dateFromFormatISO: string; dateToFormatISO: string } => {
  const getStartOfDay = (date: Date): Date => new Date(formatDate(date, 'MM/dd/yyyy'));
  const getEndOfDay = (date: Date): Date => addSeconds(addMinutes(addHours(date, 23), 59), 59);

  const parseDateFrom = new Date(filterModelDateFrom);
  const parseDateTo = type !== dateRangeFilters.equal ? new Date(filterModelDateTo) : new Date(filterModelDateFrom);
  const parseDateToday = getStartOfDay(new Date());
  const dateFrom = type !== dateRangeFilters.lessThan ? parseDateFrom : subYears(parseDateFrom, years);
  const dateTo =
    type === dateRangeFilters.lessThan
      ? getEndOfDay(parseDateFrom)
      : type === dateRangeFilters.greaterThan
      ? getEndOfDay(parseDateToday)
      : getEndOfDay(parseDateTo);

  return { dateFrom, dateTo, dateFromFormatISO: formatISO(dateFrom), dateToFormatISO: formatISO(dateTo) };
};

export const StatusRenderer = (params: any): React.ReactElement => {
  const { value } = params;
  const lowerCasedValue = value?.toLowerCase();
  let statusDotClassName, statusClassName;
  if (lowerCasedValue === 'open' || lowerCasedValue === 'reopened') {
    statusDotClassName = 'green-dot';
    statusClassName = 'open-status';
  } else if (lowerCasedValue?.includes('in process')) {
    statusDotClassName = 'pending-dot';
    statusClassName = 'pending-status';
  } else if (lowerCasedValue === 'cancelled') {
    statusDotClassName = 'grey-dot';
    statusClassName = 'cancelled-status';
  } else if (lowerCasedValue === 'closed') {
    statusDotClassName = 'blue-dot';
    statusClassName = 'closed';
  } else if (lowerCasedValue === 'processed') {
    statusDotClassName = 'green-dot';
    statusClassName = 'open-status';
  } else if (lowerCasedValue === 'processing') {
    statusDotClassName = 'pending-dot';
    statusClassName = 'pending-status';
  } else if (lowerCasedValue === 'pending') {
    statusDotClassName = 'pending-dot';
    statusClassName = 'pending-status';
  } else {
    statusDotClassName = 'dot';
    statusClassName = 'error-status';
  }

  return FileStatus({
    value: params?.value,
    statusDotClassName: statusDotClassName,
    statusClassName: statusClassName,
  });
};

export const defaultConfig = {
  flex: 1,
  headerClassName: 'column-headers',
};

export const columnsConfig: GridColDef[] = [
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params: GridRenderCellParams): React.ReactElement => {
      const { value } = params;
      let statusDotClassName, statusClassName;
      if (value === 'Open') {
        statusDotClassName = 'green-dot';
        statusClassName = 'open-status';
      } else if (value === 'Cancelled') {
        statusDotClassName = 'grey-dot';
        statusClassName = 'cancelled-status';
      } else if (value === 'Closed') {
        statusDotClassName = 'blue-dot';
        statusClassName = 'closed';
      } else {
        statusDotClassName = 'dot';
        statusClassName = 'error-status';
      }

      return FileStatus({
        value: params?.value,
        statusDotClassName: statusDotClassName,
        statusClassName: statusClassName,
      });
    },
    ...defaultConfig,
  },
  {
    field: 'fileNumber',
    headerName: 'File Number',
    ...defaultConfig,
    renderCell: (params: GridRenderCellParams): React.ReactElement => (
      <FileNumber id={params.id} fileNumber={params.value} data={params} />
    ),
  },
  {
    field: 'address',
    headerName: 'Property Address',
    ...defaultConfig,
  },
  {
    field: 'buyer',
    headerName: 'Buyer',
    ...defaultConfig,
  },
  {
    field: 'office',
    headerName: 'Office',
    ...defaultConfig,
  },
  {
    field: 'transactionType',
    headerName: 'Transaction Type',
    ...defaultConfig,
  },
  {
    field: 'openDate',
    headerName: 'Open Date',
    valueFormatter: (params: GridValueFormatterParams): string => {
      const valueFormatted = moment(params as MomentInput).format('MM/DD/YYYY');
      return `${valueFormatted}`;
    },
    ...defaultConfig,
  },
  {
    field: 'pdf',
    headerName: 'Summary',
    ...defaultConfig,
    sortable: false,
    renderCell: (): React.ReactElement => PDFIcon({ fontSize: 'small' }), //params will be used for future story of generating pdf's
  },
];
