import React, { useEffect, useContext, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useAuth } from '@agentnet/auth';
import { RouteType } from './Router';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import GlobalHeader from '../header/header';
import NavBar from '../navbar/NavBar';
import { useViewState } from 'hooks/ViewStateContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import Footer from 'core/footer/footer';
import ProductFruitsFullStory from 'core/layout-core/core-content/ProductFruitsFullStory';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import IdlePrompter from 'core/IdlePrompter';
import HelpLayout from 'features/help/components/Main/HelpLayout/HelpLayout';
import { ContentfulErrorProvider } from 'features/help/hooks/ContentfulErrorContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: `1 0 auto`,
      backgroundColor: theme.palette.common.white,
      width: '100%',
    },
    loader: {
      width: '100%',
      marginTop: '6.4rem',
      marginLeft: '16px',
    },
  }),
);

// A wrapper for <Route> that handles "sub"-routes by passing them as
// `routes` prop to the component it renders.

const RouteWithSubRoutes = (route: RouteType): JSX.Element => {
  const auth = useAuth();
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    if (!route.isPublic && !auth.isAuthenticated()) {
      auth.login().then();
    }
  }, []);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};

  const { userFirm, setNavigated, profileStatus } = profileCtx;

  const [appSwitcherActive, setAppSwitcherActive] = useState(false);
  const { viewState, setViewState } = useViewState();

  useEffect(() => {
    if (
      !(
        route.path.includes('/reports/') ||
        route.path.includes('/invoices/') ||
        route.path.includes('/statements/') ||
        route.path.includes('/help')
      )
    ) {
      if (!(window.localStorage.getItem('isNavigated') ?? false) && auth.isAuthenticated() && userFirm?.firmId) {
        setNavigated && setNavigated(true);
        history.push('/files');
      }
    }
  }, [auth.isAuthenticated(), userFirm?.firmId]);

  useEffect(() => {
    if (!route.path.includes('/files')) {
      window.sessionStorage.removeItem('properties');
    }
  }, [route.path]);

  useEffect(() => {
    if (route.path.includes('/files')) {
      setViewState('portal');
      localStorage.removeItem('activeTab');
    } else if (route.path.includes('/admin')) {
      setViewState('admin');
    }
  }, []);

  const onViewSwitchAdmin = () => {
    setViewState('admin/user');
    history.push('/admin/user/profile');
  };

  const onViewSwitchPortal = () => {
    setViewState('portal');
    history.push('/files');
  };

  return (
    <>
      {(auth.isAuthenticated() || route.isPublic) && (
        <>
          <ProductFruitsFullStory />
          <IdlePrompter />
          <Route
            path={route.path}
            render={(props) => {
              // pass the sub-routes down to keep nesting4
              if (route.layout === 'AuthLayout') {
                return (
                  <>
                    <GlobalHeader
                      onAppSwitcherClick={(e) => {
                        setAppSwitcherActive(!appSwitcherActive);
                      }}
                      onViewSwitch={onViewSwitchAdmin}
                      onPortalSwitch={onViewSwitchPortal}
                      currentView={viewState}
                    />
                    <div className={classes.root}>
                      <NavBar
                        appSwitcher={appSwitcherActive}
                        currentView={viewState}
                        onViewSwitch={onViewSwitchAdmin}
                        onPortalSwitch={onViewSwitchPortal}
                      />
                      {!userFirm?.firmId && profileStatus !== 'success' ? (
                        <div className={classes.loader}>
                          <LoadingSpinner variant="circle" status={profileStatus} />
                        </div>
                      ) : (
                        <route.component key={userFirm?.firmId} {...props} routes={route.routes} />
                      )}
                    </div>
                  </>
                );
              } else if (route.layout === 'PublicLayout') {
                return (
                  <>
                    <GlobalHeader currentView={'public'} />
                    <div className={classes.root}>
                      <route.component {...props} routes={route.routes} />
                    </div>
                    <Footer />
                  </>
                );
              } else if (route.layout === 'HelpLayout') {
                return (
                  <ContentfulErrorProvider>
                    <HelpLayout>
                      <div className={classes.root}>
                        <route.component {...props} routes={route.routes} />
                      </div>
                    </HelpLayout>
                  </ContentfulErrorProvider>
                );
              } else {
                return (
                  <div className={classes.root}>
                    <route.component {...props} routes={route.routes} />
                  </div>
                );
              }
            }}
          />
        </>
      )}
    </>
  );
};
export default RouteWithSubRoutes;
