import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import AgentNetDropdownSelector from 'ui-kit/inputs/AgentNetDropdownSelector';
import { AgentNetTextInput } from 'ui-kit/inputs';
import { Edit } from '@material-ui/icons';
import SearchOrderEmailsTable from 'ui-kit/components/tables/AdminUserSearchOrderEmailsTable';
import AutocompleteChecklist from 'ui-kit/inputs/AutocompleteChecklist/AutocompleteChecklist';
import UserAccessKeyComponent, { AccessKeyOptionType, UserAccessKeyType } from './UserAccessKey';
import {
  AddUserAcessKeys,
  getProfileDetails,
  getUserAcessKeys,
  setUserProfileDetails,
  updateBopsPreferences,
} from 'api/admin/user-profile-api';
import { useAuth } from '@agentnet/auth';
import { BopsPreferences, userKeyPref, userProfile } from 'api/profile/types';
import useAsync from 'hooks/useAsync';
import { UserOffices, sortedBopsoptions, sortedUserAccessKeyOptions } from './interfaces';
import { SaveUserProfileDetails } from 'api/admin/interfaces';
import PhoneField from 'ui-kit/inputs/PhoneField/PhoneField';
import _ from 'lodash';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { UserRoles, UserRoleTypes } from './constants';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';

const randomID = () => (Math.random() + 1).toString(36).substring(7);
const randomKey = () => {
  const length = 24;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
const getCurrentDate = () => {
  const d = new Date();
  return moment(d).format('MM/DD/YYYY');
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      background: theme.palette.common.white,
    },
    contentWrap: {
      padding: `0 0 32rem`,
      maxWidth: 1200,
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    userBannerWrap: {
      padding: theme.spacing(3),
      borderBottom: `1px solid #c4c4c4`,
    },
    userBannerContainer: {
      maxWidth: 1135,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    userBannerDetails: { display: 'flex', alignItems: 'center' },
    userDetails: {
      marginLeft: theme.spacing(2),
    },
    userName: {
      marginBottom: theme.spacing(0.5),
    },
    avatar: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '46px',
      height: '46px',
      borderRadius: '46px',
      border: `1px solid #013A6F`,
      textAlign: 'center',
      color: theme.palette.secondary.main,
      '& span': {
        marginTop: '4px',
      },
    },
    captionDivider: {
      width: theme.spacing(2),
      display: 'inline-block',
    },
    fieldSection: {},
    fieldSectionLeft: {
      padding: theme.spacing(3),
    },
    fieldSectionRight: {
      padding: theme.spacing(3),
      marginTop: 0,
    },
    adornmentIcon: {
      padding: 0,
      '&:hover': {
        background: 'transparent',
      },
    },
    ctaBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    alertContainer: {
      marginBottom: '63px',
    },
    alertMsg: {
      width: '-webkit-fill-available',
      position: 'absolute',
      bottom: '63px',
      left: 0,
      padding: '0 35px',
    },
  }),
);

interface AdminProfileFormProps {
  id?: number | string | undefined;
  onChange?: (value: any) => void;
  name?: string;
  email?: string;
  phone?: string;
  officeId?: number | string;
  officeAddress?: string;
  officePhone?: string;
  officeFirmLicense?: string;
  ALTAID?: string;
  searchOrderNotificationEmails?: string[];
  titleCommitmentPreferences?: any[];
  userAccessKeys?: any[];
}

interface AdminProfileBannerProps {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  userId?: number | string;
  payConnectionId?: string;
  roleId?: any;
  onChangeClick: () => void;
}

const AdminUserProfileBanner = ({
  firstName,
  middleName,
  lastName,
  userId,
  payConnectionId,
  roleId,
  onChangeClick,
}: AdminProfileBannerProps) => {
  const classes = useStyles();
  function nameTag(str?: any) {
    const fname = firstName ? firstName[0].toUpperCase() : '';
    const lname = lastName ? lastName[0].toUpperCase() : '';
    return `${fname}${lname}`;
  }
  return (
    <div className={classes.userBannerWrap}>
      <div className={classes.userBannerContainer}>
        <div className={classes.userBannerDetails}>
          <div className={classes.avatar}>
            <Typography variant="h2" color="secondary">
              {firstName && lastName ? nameTag() : 'JT'}{' '}
            </Typography>
          </div>
          <div className={classes.userDetails}>
            <Typography variant="h1" className={classes.userName}>
              {((firstName && firstName.trim()) ?? '') +
                (firstName && (middleName || lastName) ? ' ' : '') +
                ((middleName && middleName.trim()) ?? '') +
                (middleName && lastName ? ' ' : '') +
                (lastName && lastName.trim()) ?? ''}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {userId && `User ID: ${userId} `}
              <span className={classes.captionDivider}></span>
              {/* {payConnectionId && `Pay Connection ID: ${payConnectionId} `} */}
            </Typography>
          </div>
        </div>
        {roleId === UserRoleTypes.Agent ? (
          <div className="userBannerPw">
            <AgentNetButton
              variant="contained"
              size="medium"
              data-qa="UserProfileChangePassword"
              onClick={onChangeClick}
            >
              Change Password
            </AgentNetButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const AdminUserProfileContent = () => {
  const { getAccessToken } = useAuth();
  const idaaSAuth = useAuth();
  const classes = useStyles();
  const [showActions, setShowActions] = React.useState(false);

  const [ServiceOrderEmailAddress, setServiceOrderEmailAddress] = useState<string[]>([]);
  const [resData, setData] = useState<userProfile>();
  const [userAccessKeyData, setuserAccessKeyData] = useState<UserAccessKeyType[]>();
  const [defaultOfficeLocation, setdefaultOfficeLocation] = useState('');
  const officeDetails: Partial<UserOffices> = {};
  const [currentOfficeDetail, setCurrentOfficeDetail] = useState(officeDetails);
  const [updatedOfficeDetail, setUpdatedOffice] = useState(officeDetails);
  const [bopsPreferences, setBopsPreferences] = useState<BopsPreferences[]>([]);
  const [updatedServiceOrderEmailNotification, setupdatedServiceOrderEmailNotification] = useState<emailType[]>([]);
  const { addSnackbarMessage } = useSnackBars();
  const [isSaveDisabled, setisSaveDisabled] = useState<boolean>(false);
  const [contactPhoneNumber, setContactPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  interface emailType {
    ServiceOrderEmailNotificationId: number;
    ServiceOrderEmailAddress: string;
  }
  const [initialBopsPreferences, setinitialBopsPreferences] = useState<BopsPreferences[]>([]);

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};

  const getProfileData = async () => {
    const token = await getAccessToken();
    // if (
    //   !(
    //     idaaSAuth.account?.idTokenClaims &&
    //     typeof idaaSAuth.account?.idTokenClaims === 'object' &&
    //     Object.prototype.hasOwnProperty.call(idaaSAuth.account?.idTokenClaims, 'onpremADusername')
    //   )
    // ) {
    //   const request: SaveStaffProfileDetails = {
    //     PhoneNumber: '',
    //   };
    //   await updateUserProfileContact(token, request);
    //}
    const responsedata = await getProfileDetails(token);
    const userAccessresponse = await getUserAcessKeys(token);
    setuserAccessKeyData(userAccessresponse);
    console.log('ress', userAccessKeyData);
    responsedata?.FirmAccess[0]?.Offices?.map((o: any) => {
      if (o.OfficeId === responsedata.DefaultFirmLocationId) {
        o.Name = `${o.Name} (Default Office)`;
      }
      if (o.Status === 2) {
        o.Name = `${o.Name} (Inactive)`;
      }
    });
    setData(responsedata);
  };

  const { execute: executegetProfileData, status: getProfileDataStatus } = useAsync(getProfileData, false);

  const actionStatus = getProfileDataStatus === 'pending' ? 'pending' : 'idle';

  useEffect(() => {
    executegetProfileData().then();
  }, []);

  useEffect(() => {
    if (resData != undefined && resData?.PhoneNumber != undefined) {
      setContactPhoneNumber(resData?.PhoneNumber);
    }
  }, [resData]);

  const saveUserProfileDetails = async (
    contactPhoneNumber: string,
    profileOfficeRequest: Partial<UserOffices>,
    ServiceOrderEmailRequest: emailType[],
    bopsUpdatedRequest: BopsPreferences[],
  ) => {
    const request: SaveUserProfileDetails = {
      StaffContactRequest: undefined,
      ProfileDefaultOfficeRequest: undefined,
      SOEmailRequest: undefined,
      BOPSPreferenceRequest: undefined,
    };

    if (contactPhoneNumber != undefined && contactPhoneNumber != '') {
      request.StaffContactRequest = {
        PhoneNumber: contactPhoneNumber,
      };
    }

    if (ServiceOrderEmailRequest[0]?.ServiceOrderEmailNotificationId != undefined) {
      request.SOEmailRequest = {
        searchOrderEmailDetails: ServiceOrderEmailRequest,
      };
    }

    const token = await getAccessToken();
    if (profileOfficeRequest.OfficeId != undefined && !_.isEqual(profileOfficeRequest, currentOfficeDetail)) {
      request.ProfileDefaultOfficeRequest = {
        DefaultFirmLocationId: profileOfficeRequest.OfficeId,
        PhoneNumber: profileOfficeRequest.PhoneNumber,
        FirmLicense: profileOfficeRequest.FirmLicense,
      };
    }
    if (bopsUpdatedRequest.length > 0 && !_.isEqual(bopsPreferences, initialBopsPreferences)) {
      const mappedBopsid = bopsUpdatedRequest.slice(1).map((o) => {
        return o.id;
      });
      request.BOPSPreferenceRequest = {
        BopsPreferenceId: mappedBopsid,
      };
    }
    const result = await setUserProfileDetails(token, request);
    const data: Record<string, any> = {};
    let msg;
    let count = 0;
    const keyvaluePairs = result.split('  ');
    keyvaluePairs.forEach((pair: { split: (arg0: string) => [any, any] }) => {
      const [key, value] = pair.split(':');
      data[key] = value;
      if (value != ' 200' && value != ' undefined') {
        count = count + 1;
      }
    });

    if (count != 0) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const value = data[key];
          if (key == 'OfficeResponse') {
            msg = 'Office Information';
          }
          if (key == 'EmailResponse') {
            msg = 'Service Order Email Notification';
          }
          if (key == 'BopsResponse') {
            msg = 'Title Commitment Download Preferences';
          }
          if (key == 'StaffContactResponse') {
            msg = 'Contact Information';
          }
          if (value != ' 200' && value != ' undefined') {
            addSnackbarMessage({
              message: `${msg} Save Failed`,
              type: 'error',
            });
          } else if (value == ' 200') {
            addSnackbarMessage({
              message: `${msg} Saving  Successful`,
              type: 'success',
            });
          }
        }
      }
    } else {
      addSnackbarMessage({
        message: `Saving  Successful`,
        type: 'success',
      });
    }
  };

  const updatePreferences = async (BopsUpdaterequest: BopsPreferences[]) => {
    const token = await getAccessToken();

    const mappedBopsid = BopsUpdaterequest.slice(1).map((BopsUpdaterequest) => BopsUpdaterequest.id);
    const bopsRequest = {
      bopsPreferenceId: mappedBopsid,
    };
    const result = await updateBopsPreferences(token, bopsRequest);
    console.log('result bops', result);
  };

  useEffect(() => {
    if (resData && resData?.DefaultFirmLocationId) {
      setdefaultOfficeLocation(resData?.DefaultFirmLocationId);
    }
  }, [resData]);

  useEffect(() => {
    if (resData?.FirmAccess[0]?.Offices !== null) {
      resData?.FirmAccess[0]?.Offices.map((o: UserOffices) => {
        if (resData?.DefaultFirmLocationId == o.OfficeId) {
          setCurrentOfficeDetail(o);
          setUpdatedOffice(o);
        }
      });
    }
  }, [resData]);

  const setChangedOffice = (field: string, value: any) => {
    if (resData?.FirmAccess[0]?.Offices !== null) {
      resData?.FirmAccess[0]?.Offices.map((o: UserOffices) => {
        // if (updatedOfficeDetail.OfficeId !== undefined) {
        //   setUpdatedOffice(o);
        // }
        if (o.OfficeId == value) {
          setdefaultOfficeLocation(o.OfficeId);
          setUpdatedOffice(o);
        }
      });
    }
  };

  const setUpdatedEmail = (value: emailType[]) => {
    //remove empty emailaddress before saving Service Order Notification
    const filteredArray = value.filter((obj) => obj.ServiceOrderEmailAddress.trim() !== '');
    setupdatedServiceOrderEmailNotification(filteredArray);
  };

  const handleBopsDelete = (e: any) => {
    if (initialBopsPreferences.some((i) => i.id === e.id)) {
      const newOptions = bopsPreferences.filter((option) => option.id !== e.id);
      updatePreferences(newOptions);
    }
  };

  const setField = (field: string, value: any) => {
    if (updatedOfficeDetail?.OfficeId != undefined) {
      setUpdatedOffice({
        ...updatedOfficeDetail,
        [field]: value,
      });
    }
  };

  useEffect(() => {
    if (resData?.ServiceOrderEmailNotification) {
      const searchOrderNotificationEmails: string[] = [];
      resData?.ServiceOrderEmailNotification?.forEach((item) => {
        const option = item.ServiceOrderEmailAddress;
        searchOrderNotificationEmails.push(option);
      });
      setServiceOrderEmailAddress(searchOrderNotificationEmails);
    }
  }, []);

  console.log(resData);

  useEffect(() => {
    if (resData?.BopsPreferences) {
      const bopsPreferencesUnsorted = resData.BopsPreferences.map((x, i) => {
        return { id: x.BopsPreferenceId, name: x.BopsPreferenceDescription };
      });
      const bopsPreferencesSorted = bopsPreferencesUnsorted.slice(1).sort((a, b) => a.name.localeCompare(b.name));
      const bopsPreferences = [bopsPreferencesUnsorted[0], ...bopsPreferencesSorted];
      setBopsPreferences(bopsPreferences);
      setinitialBopsPreferences(bopsPreferences);
    }
  }, [resData]);

  const [selectedUserAccessKey, setselectedUserAccessKey] = useState<userKeyPref[]>([]);
  useEffect(() => {
    if (userAccessKeyData) {
      const selectedUserAccessKeyData = userAccessKeyData?.filter((x) => x.Status != 2);
      const selectedUserAccessKey: any = selectedUserAccessKeyData?.map((x, i) => {
        return {
          vendorName: x.ThirdPartyVendor.VendorName,
          ThirdPartyVendorId: x.ThirdPartyVendor.ThirdPartyVendorId,
        };
      });
      setselectedUserAccessKey(selectedUserAccessKey);
    }
  }, [resData]);
  // userAccessKeyData

  interface DialogInterface {
    show: boolean;
    id: any;
    action: string | undefined;
  }

  // const [dialog, setDialog] = React.useState<DialogInterface>({ show: false, id: undefined });

  const handleChangePasswordClick = () => {
    idaaSAuth.changePassword();
  };

  const onSelection = async (e: any) => {
    let reqBody;
    await e.map(async (k: AccessKeyOptionType) => {
      reqBody = {
        thirdPartyVendorId: k.ThirdPartyVendorId,
      };
    });
    const token = await getAccessToken();
    await AddUserAcessKeys(token, reqBody);
  };

  const handleBlur = () => {
    if (contactPhoneNumber.trim() === '') {
      setPhoneNumberError('Phone Number is required');
    }
  };

  const IsSOPreferenceVisible = () => {
    if (
      resData?.RoleTypeCdId === UserRoleTypes.Agent ||
      resData?.RoleId === UserRoles.OnBehalfOf ||
      resData?.RoleId === UserRoles.SystemAdministrator ||
      (resData?.RoleId === UserRoles.User &&
        profileCtx.profile?.activityRights?.find((o) => o.ActivityRightId === 71) &&
        profileCtx.profile?.activityRights?.find((o) => o.ActivityRightId === 73))
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className={classes.root}>
        <LoadingSpinner status={actionStatus} variant="linear" />

        {/*User Profile Banner */}
        {resData && (
          <AdminUserProfileBanner
            onChangeClick={handleChangePasswordClick}
            firstName={resData?.FirstName}
            middleName={resData?.MiddleName && resData?.MiddleName}
            lastName={resData?.LastName}
            userId={resData?.LoginUserId}
            roleId={resData?.RoleTypeCdId}
          />
        )}

        <div className={classes.contentWrap}>
          {/*Contact Information */}
          {resData && (
            <Grid container className={classes.fieldSection}>
              <Grid item sm={4} className={classes.fieldSectionLeft}>
                <Typography variant="h2">Contact Information</Typography>
              </Grid>
              <Grid container spacing={2} sm={8} className={classes.fieldSectionRight}>
                <Grid item sm={12}>
                  <AgentNetTextInput
                    fullWidth
                    variant="outlined"
                    label={'Name'}
                    name="userProfileName"
                    data-qa="UserProfileName"
                    value={
                      ((resData?.FirstName && resData?.FirstName.trim()) ?? '') +
                      (resData?.FirstName && (resData?.MiddleName || resData?.LastName) ? ' ' : '') +
                      ((resData?.MiddleName && resData?.MiddleName.trim()) ?? '') +
                      (resData?.MiddleName && resData?.LastName ? ' ' : '') +
                      ((resData?.LastName && resData?.LastName.trim()) ?? '')
                    }
                    disabled
                    endAdornment={
                      resData?.RoleTypeCdId === UserRoleTypes.Agent ? (
                        <IconButton
                          className={classes.adornmentIcon}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          data-qa="UserProfileNameEdit"
                          onClick={() => idaaSAuth.editProfile()}
                        >
                          <Edit fontSize="large" htmlColor="#666" />
                        </IconButton>
                      ) : undefined
                    }
                  />
                </Grid>
                <Grid item sm={6}>
                  <AgentNetTextInput
                    fullWidth
                    variant="outlined"
                    label={'Email'}
                    name="userProfileEmail"
                    data-qa="UserProfileEmail"
                    value={resData?.EmailAddress}
                    disabled
                    endAdornment={
                      resData?.RoleTypeCdId === UserRoleTypes.Agent ? (
                        <IconButton
                          className={classes.adornmentIcon}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          data-qa="UserProfileEmailEdit"
                          onClick={() => idaaSAuth.editProfile()}
                        >
                          <Edit fontSize="large" htmlColor="#666" />
                        </IconButton>
                      ) : undefined
                    }
                  />
                </Grid>
                {resData?.RoleTypeCdId === UserRoleTypes.Agent ? (
                  <Grid item sm={6}>
                    <AgentNetTextInput
                      fullWidth
                      variant="outlined"
                      label={'Phone'}
                      name="userProfilePhone"
                      value={resData?.PhoneNumber}
                      disabled
                      data-qa="UserProfilePhoneEdit"
                      endAdornment={
                        <IconButton
                          className={classes.adornmentIcon}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          onClick={() => idaaSAuth.editPhone()}
                        >
                          <Edit fontSize="large" htmlColor="#666" />
                        </IconButton>
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item sm={6}>
                    <AgentNetTextInput
                      fullWidth
                      variant="outlined"
                      label={'Phone'}
                      name="userProfilePhone"
                      value={contactPhoneNumber}
                      data-qa="UserProfilePhoneEdit"
                      onChange={(e: any) => setContactPhoneNumber(e.target.value)}
                      onBlur={handleBlur}
                    />
                    {contactPhoneNumber === '' ? <div style={{ color: 'red' }}>{phoneNumberError}</div> : null}
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {/*Office Information */}
          <>
            {resData?.RoleTypeCdId === UserRoleTypes.Agent ? (
              <Grid container className={classes.fieldSection}>
                <Grid item sm={4} className={classes.fieldSectionLeft}>
                  <Typography variant="h2">Office Information</Typography>
                </Grid>
                <Grid container spacing={2} sm={8} className={classes.fieldSectionRight}>
                  <Grid item sm={12}>
                    <AgentNetDropdownSelector
                      name="userProfileOffice"
                      data-qa="UserProfileOffice"
                      label="Office"
                      dropdowntype="outlined"
                      options={resData?.FirmAccess[0]?.Offices.map((o: any) => {
                        return { name: o.Name, value: o.OfficeId };
                      })}
                      value={defaultOfficeLocation}
                      menuOption={(value: any, name?: any) => {
                        setChangedOffice('OfficeId', value);
                      }} //value={profileState?.officeId !== 0 ? offices?.find((office) => office.officeId === profileState?.officeId) : offices && offices[0]}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <AgentNetTextInput
                      fullWidth
                      variant="outlined"
                      label={'Address'}
                      name="userProfileOfficeAddress"
                      data-qa="UserProfileOfficeAddress"
                      value={`${updatedOfficeDetail.Address?.Address1} ${updatedOfficeDetail.Address?.Address2} ${updatedOfficeDetail.Address?.City} ${updatedOfficeDetail.Address?.State} ${updatedOfficeDetail.Address?.County} ${updatedOfficeDetail.Address?.PostalCode}`}
                      disabled
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <PhoneField
                      fullWidth
                      variant="outlined"
                      label={'Phone'}
                      name="userProfileOfficePhone"
                      data-qa="UserProfileOfficePhone"
                      value={updatedOfficeDetail.PhoneNumber}
                      onChange={(e: any) => setField('PhoneNumber', e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <AgentNetTextInput
                      fullWidth
                      variant="outlined"
                      label={'FirmLicense'}
                      name="userProfileOfficeFirmLicense"
                      data-qa="UserProfileOfficeFirmLicense"
                      value={updatedOfficeDetail.FirmLicense}
                      onChange={(e: any) => setField('FirmLicense', e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <AgentNetTextInput
                      fullWidth
                      variant="outlined"
                      label={'ALTAID'}
                      name="userProfileOfficeALTAID"
                      data-qa="UserProfileOfficeALTAID"
                      value={updatedOfficeDetail.AltaId}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </>

          {/*Search Order Email Notifications Component */}
          {/*Pass the email list down to this component */}
          <>
            {resData?.RoleTypeCdId === UserRoleTypes.Agent ? (
              <Grid container className={classes.fieldSection}>
                <Grid item sm={4} className={classes.fieldSectionLeft}>
                  <Typography variant="h2">Service Order Email Notifications</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Service Order Email Notifications will be sent to this/these email address(es)
                  </Typography>
                </Grid>
                <Grid container spacing={2} sm={8} className={classes.fieldSectionRight}>
                  <SearchOrderEmailsTable
                    emails={resData?.ServiceOrderEmailNotification}
                    onDelete={(e) => console.log('deleted', e)}
                    onChange={(value: any) => {
                      setUpdatedEmail(value);
                    }}
                    isDuplicate={(value: any) => {
                      if (value == true) {
                        setisSaveDisabled(true);
                      } else {
                        setisSaveDisabled(false);
                      }
                    }}
                    dialogText="You are about to permanently delete this User Email Notification. Are you sure you want to proceed?"
                    dialogTitle="Delete Email Notification"
                    onValidate={(value: any) => {
                      if (value == false) {
                        setisSaveDisabled(true);
                      } else {
                        setisSaveDisabled(false);
                      }
                    }}
                    qaAttrPrefix="ConfirmationSoEmailNotification"
                    qaBtnprefix="UserSoEmail"
                  />
                </Grid>
              </Grid>
            ) : null}
          </>

          {/*Title Commitment Download Preferences Component */}
          {/*Pass the list of options down to this component */}
          <>
            {IsSOPreferenceVisible() && (
              <Grid container className={classes.fieldSection}>
                <Grid item sm={4} className={classes.fieldSectionLeft}>
                  <Typography variant="h2">
                    Title Commitment <br /> Download Preferences
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Select the preferred Download
                    <br />
                    option based on your title software
                  </Typography>
                </Grid>
                <Grid container spacing={2} sm={8} className={classes.fieldSectionRight}>
                  <AutocompleteChecklist
                    options={sortedBopsoptions}
                    label="Preferred Downloads"
                    value={bopsPreferences}
                    onDelete={(e) => {
                      handleBopsDelete(e);
                    }}
                    onChange={function (value: any): void {
                      const sortedValue: BopsPreferences[] = [
                        value[0],
                        ...value
                          .slice(1)
                          .sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name)),
                      ];
                      setBopsPreferences(sortedValue);
                    }}
                    dialogText="You are about to permanently delete this Preference. Are you sure you want to proceed?"
                    dialogTitle="Delete Preferred Download"
                    qaAttrPrefix="ConfirmationBopsPreferences"
                    qaBtnprefix="PreferredDownloads"
                  />
                </Grid>
              </Grid>
            )}
          </>

          {/*User Access Keys Component */}
          {/*Pass the list of options down to this component */}

          {resData?.RoleTypeCdId === UserRoleTypes.Agent ? (
            <Grid container className={classes.fieldSection}>
              <Grid item sm={4} className={classes.fieldSectionLeft}>
                <Typography variant="h2">User Access Keys</Typography>
                <Typography variant="body2" color="textSecondary">
                  Create a User Access Key to use in place of your username and password when accessing AgentNet from a
                  vendor application.
                </Typography>
              </Grid>
              <Grid container spacing={2} sm={8} className={classes.fieldSectionRight}>
                <UserAccessKeyComponent
                  onChange={(e) => onSelection(e)}
                  accessKeyOptions={sortedUserAccessKeyOptions}
                  value={selectedUserAccessKey}
                />
              </Grid>
            </Grid>
          ) : null}
        </div>
        {resData && (
          <BottomAppBar
            primaryActionLabel="Save"
            cancelDataQa={'UserProfileCancel'}
            cancelLabel="Clear All"
            primaryActionDataQa={'UserProfileSave'}
            onPrimaryClick={() =>
              saveUserProfileDetails(
                contactPhoneNumber,
                updatedOfficeDetail,
                updatedServiceOrderEmailNotification,
                bopsPreferences,
              )
            }
            disablePrimary={isSaveDisabled}
            onCancel={() => window.location.reload()}
          />
        )}
      </div>
    </>
  );
};

export default AdminUserProfileContent;
