import React from 'react';
import { NavLink } from 'react-router-dom';
import L3Nav from 'ui-kit/components/L3Nav';
import L3NavMenu from 'ui-kit/components/L3Nav/L3NavMenu';
import L3NavItem from 'ui-kit/components/L3Nav/L3NavItem';

export type NavLinkType = {
  label: string;
  route: string | null;
  disabled?: boolean;
};

export const LeftNav = (): JSX.Element => {
  const DEFAULT_ROUTE = '/services/sdn-search';
  const links: NavLinkType[] = [
    { label: 'Agency Verification Letters', route: '/services/agency-verification-letters' },
    { label: 'Property Reports', route: '/disabled' },
    { label: 'Back Title Search', route: '/services/back-title-search' },
    { label: 'Underwriting Library', route: '/disabled' },
    { label: 'SDN Search', route: '/services/sdn-search' },
    { label: 'Policy Upload', route: '/services/policy-upload' },
  ];

  const L3NavItems = links.map((link: NavLinkType, index) => {
    const { route, label } = link;

    return (
      <L3NavItem key={index} to={route} component={NavLink} exact>
        {label}
      </L3NavItem>
    );
  });

  return (
    <div>
      <L3Nav>
        <L3NavMenu>{L3NavItems}</L3NavMenu>
      </L3Nav>
    </div>
  );
};
