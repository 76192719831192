import createTypography from '@material-ui/core/styles/createTypography';
import palette from '../palette/palette';
import '../../../common-assets/fonts/inter/Inter-Regular.ttf';
import '../../../common-assets/fonts/inter/Inter-Medium.ttf';

//Note: html font base size is 62.5%
const typography = createTypography(palette, {
  fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '2.0rem',
    lineHeight: '2.4rem',
    color: palette.text.primary,
  },
  h2: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '1.8rem',
    lineHeight: '2.2rem',
    color: palette.text.primary,
  },
  h3: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '1.6rem',
    lineHeight: '1.9rem',
    color: palette.text.primary,
  },
  h4: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: palette.text.primary,
  },
  h5: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: palette.text.primary,
  },
  h6: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    color: palette.text.primary,
  },
  caption: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '1.2rem',
    lineHeight: '140%',
    color: palette.text.primary,
  },
  body1: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: palette.text.primary,
  },
  body2: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '1.4rem',
    lineHeight: '2rem',
    color: palette.text.primary,
  },
  overline: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    color: palette.secondary.main,
    textTransform: 'none',
  },
});

export default typography;
