import { TopicCollection } from '../types';
import { gql } from '@apollo/client';
import performQuery from './contentfulClient';
import { HELP_COLLECTION_SLUG } from '../constants';

const TOPICS_ARTICLES_COLLECTION = gql`
  query TopicSubtopicCollection($preview: Boolean) {
    topicCollection(where: { collection: { slug: "${HELP_COLLECTION_SLUG}" } }, limit: 16, order: sortOrder_ASC, preview: $preview) {
      total
      items {
        sys {
          id
          spaceId
        }
        topicName
        slug
        previewDescription
        linkedFrom {
          kbArticleCollection (order: sortOrder_ASC) {
            items {
              sys {
                id
                spaceId
              }
              slug
              articleTitle
            }
          }
        }
      }
    }
  }
`;

export const useTopicsWithArticlesQuery = async () => {
  const response = await performQuery(TOPICS_ARTICLES_COLLECTION, {});

  if (response.hasError) {
    return {
      data: null,
      error: true,
    };
  }

  return {
    data: response.res?.data as TopicCollection | null,
    error: false,
  };
};
