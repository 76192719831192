/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { AgentNetTextInput, AgentTextInputProps } from '../TextField';
import { Autocomplete, AutocompleteRenderGroupParams, FilterOptionsState } from '@material-ui/lab';
import { ListSubheader } from '@material-ui/core';
import { Search, ArrowDropDown } from '@material-ui/icons';
import './AutocompleteSearch.scss';
import { MyLenderDropdown } from 'api/file/constants';

export type searchType = {
  id?: number | string | undefined | null;
  value: any;
  name: any;
  type?: ('primary' | 'secondary' | 'none') | string | undefined | null;
};

export type AutocompleteSearchProps = AgentTextInputProps & {
  label?: string;
  value?: string | number;
  onChange?: (value: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  shouldDisableClearable?: boolean;
  options?: Array<searchType> | null | undefined;
  additionalOptions?: Array<searchType> | null | undefined;
  hideSearchIcon?: boolean;
  disableIcon?: boolean;
  primaryLabel?: string;
  secondaryLabel?: string;
  noMatchesLabel?: string;
  hideNoMatches?: boolean;
  disabled?: boolean;
  disableGrouping?: boolean;
  disableNoMatchesLabel?: boolean;
};

export function AutocompleteSearch({
  label = 'AutocompleteSearch',
  value: valueProp = '',
  onBlur = () => undefined,
  onChange = () => undefined,
  id = '',
  shouldDisableClearable = false,
  options,
  additionalOptions,
  disableIcon,
  hideSearchIcon,
  primaryLabel = 'Group 1',
  secondaryLabel = 'Group 2',
  noMatchesLabel = MyLenderDropdown.noMatchingLender,
  disableGrouping = false,
  hideNoMatches = false,
  errs,
  name,
  showValidation,
  disabled,
  ...props
}: AutocompleteSearchProps): JSX.Element {
  const noMatches = { name: noMatchesLabel, type: 'none', value: noMatchesLabel, lenderId: noMatchesLabel };
  const optionsById = (val: any) => {
    return (
      options?.find((ele) => ele.value === val) ?? additionalOptions?.find((ele) => ele.value === val) ?? noMatches
    );
  };

  const [value, setValue] = useState<any | null>(null);

  /* Display Validation Errors */
  const [errors, setErrors] = useState(false);
  const [displayErrors, setDisplayError] = useState(showValidation);
  /* Will need this code for future purposes, will keep this out */
  // const displayErrorOnCallback = (callback: () => void) => {
  //   callback();
  // };

  useEffect(() => {
    const hasError = errs?.find((err) => err.field === name);
    hasError ? setErrors(true) : setErrors(false);
  }, [errs]);

  useEffect(() => {
    setDisplayError(showValidation);
  }, [showValidation]);

  useEffect(() => {
    if (value !== valueProp && valueProp !== '') {
      const val = optionsById(valueProp);
      setValue(val);
    }
  }, [valueProp, options]);

  const filterOptions = (options: searchType[], searchValue: FilterOptionsState<searchType>) => {
    const input = searchValue.inputValue.toLowerCase();

    if (input == '') {
      return options;
    }

    const filteredOptions = options.filter((option: searchType) => {
      const result = option.name.toLowerCase();
      return result.includes(input) && result[0] == input[0];
    });

    return filteredOptions.sort((optionA, optionB) => {
      return optionA.name.localeCompare(optionB.name);
    });
  };

  const renderGroup = (params: AutocompleteRenderGroupParams) => {
    if (!disableGrouping) {
      if (!additionalOptions) {
        return [params.children];
      }
      if (params.group !== primaryLabel) {
        //remove header on first group
        return [
          <ListSubheader key={params.key} component="div" disableSticky>
            {params.group}
          </ListSubheader>,
          params.children,
        ];
      }
    }
    return [params.children];
  };

  const getOptions = () => {
    let arr = [];

    if (options !== null && options !== undefined) {
      arr = options.map((e) => {
        e.type = 'primary';
        return e;
      });

      if (!additionalOptions) {
        !hideNoMatches && arr.push(noMatches);
        return arr;
      }

      let addOp = additionalOptions?.map((e) => {
        e.type = 'secondary';
        return e;
      });

      if (!hideNoMatches) {
        addOp = [noMatches, ...addOp];
      }

      return arr.concat(addOp);
    } else if (!options && additionalOptions) {
      let addOp = additionalOptions?.map((e) => {
        e.type = 'secondary';
        return e;
      });
      if (!hideNoMatches) {
        addOp = [noMatches, ...addOp];
      }
      return addOp;
    }
    return [noMatches];
  };

  const [optionState, setOptionState] = useState(getOptions());

  useEffect(() => {
    if (options !== null && options !== undefined) {
      setOptionState(getOptions());
    }
  }, [options]);

  return (
    <Autocomplete
      autoComplete
      disableClearable={shouldDisableClearable}
      clearOnEscape
      autoSelect
      autoHighlight
      filterOptions={filterOptions}
      onBlur={onBlur}
      options={optionState}
      getOptionLabel={(option: searchType) => option.name}
      // we should show only id or something unique for the selection
      getOptionSelected={(selected, option) => selected.value === option?.value}
      value={value}
      onChange={(_, value) => {
        onChange(value?.value || '');
        setValue(value);
      }}
      renderInput={(param) => {
        return (
          <AgentNetTextInput
            variant="outlined"
            label={label}
            errs={errs}
            showValidation={showValidation}
            onChange={(value) => {
              onChange(value);
            }}
            {...param}
            {...props}
            inputProps={{
              ...param.inputProps,
              'aria-label': 'autocomplete-field-input-select',
              autoComplete: 'new-password',
              form: { Autocomplete: 'off' },
            }}
            InputLabelProps={{ shrink: true }}
            error={errors && displayErrors}
            title={value && value.name ? value.name.toString() : label}
          />
        );
      }}
      id={id}
      data-testid={id}
      popupIcon={!hideSearchIcon ? <Search fontSize="large" /> : <ArrowDropDown fontSize="large" />}
      className="autocomplete-search"
      forcePopupIcon={disableIcon || disabled ? false : true}
      renderGroup={renderGroup}
      disabled={disabled}
      groupBy={
        additionalOptions
          ? (option: any) => {
              let label;
              option.type === 'primary'
                ? (label = primaryLabel)
                : option.type === 'none'
                ? (label = ``)
                : (label = secondaryLabel);
              return label;
            }
          : undefined
      }
    />
  );
}
export default AutocompleteSearch;
