import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import clsx from 'clsx';
import './usersList.scss';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FloatingFilterComponent from 'features/files/files-summary/files-list/FloatingFilterComponent';
import useAsync from 'hooks/useAsync';
import { useAuth } from '@agentnet/auth';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import { getUsersList } from 'api/manageUsers/manage-users-api';
import UserActionsRenderer from './userActionsRenderer';
import LoginUserNameRenderer from './loginUserIdRenderer';
const ManageUsers: React.FC = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: '6.4rem',
      },
      paddingX: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: 'auto',
      },
      tableDefault: {
        height: `calc(100vh - 180px) !important`,
        fontFamily: theme.typography.fontFamily,
      },
      contentWrap: {
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: 'calc(100% - 6.4rem)',
      },
      contentClass: {
        margin: '0 auto auto',
      },
      mr1: {
        marginRight: theme.spacing(1),
      },
      mr2: {
        marginRight: theme.spacing(2),
      },
    }),
  );
  const classes = useStyles();
  const gridRef = useRef<any>(null);
  const [gridApi, setGridApi] = useState<any>(null);
  const [columnApi, setColumnApi] = useState<any>(null);

  const [rowData, setRowData] = useState<any>([]);
  const { getAccessToken } = useAuth();

  const getUsersListData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getUsersList(token);
  };

  const {
    execute: executeGetUsersList,
    status: usersListStatus,
    value: usersListResults,
    errors: usersListErrors,
  } = useAsync<any>(getUsersListData, false);

  useEffect(() => {
    setRowData(usersListResults);
  }, [usersListResults]);

  const defaultColDef = useMemo<ColDef>(() => {
    executeGetUsersList().then();
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, []);

  const usersColumnsConfig: any = [
    {
      headerName: 'User Id',
      field: 'LoginUserId',
      tooltipField: 'LoginUserId',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: any) => {
        return <LoginUserNameRenderer loginUserId={params.data.LoginUserId} />;
      },
    },
    {
      headerName: 'Full Name',
      field: 'FullName',
      tooltipField: 'FullName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Office',
      field: 'FirmLocation',
      tooltipField: 'FirmLocation',
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'Email',
      field: 'EmailAddress',
      tooltipField: 'EmailAddress',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Role',
      field: 'Role',
      tooltipField: 'Role',
      filterParams: {
        valueFormatter: (params: any) => {
          const roleMappings: { [key: string]: string } = {
            agent: 'Agent',
            teUser: 'Title & Escrow User',
            eUser: 'Escrow Only User',
            cplUser: 'CPL Only User',
            // Add more mappings as needed
          };
          return roleMappings[params.value] || params.value;
        },
      },
    },
    {
      headerName: 'HQ User',
      field: 'HQUser',
      tooltipField: 'HQUser',
      filterParams: {
        valueFormatter: (params: any) => {
          const hqMappings: { [key: string]: string } = {
            yes: 'Yes',
            no: 'No',
          };
          return hqMappings[params.value] || params.value;
        },
      },
    },
    {
      headerName: 'Status',
      field: 'UserStatus',
      tooltipField: 'UserStatus',
      filterParams: {
        valueFormatter: (params: any) => {
          const statusMappings: { [key: string]: string } = {
            notActivated: 'Not Activated',
            activated: 'Active',
            inActive: 'Inactive',
            disabled: 'Disabled',
          };
          return statusMappings[params.value] || params.value;
        },
      },
    },
    {
      headerName: '',
      field: '',
      cellRenderer: (params: any) => {
        return <UserActionsRenderer userStatus={params.data.UserStatus} userId={params.data.UserId} />;
      },
      maxWidth: 90,
      cellStyle: { display: 'flex', textAlign: 'right', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
      filter: false,
    },
  ];

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const getDisplayedRowCount = () => {
    if (gridApi) {
      return gridApi.getDisplayedRowCount();
    }
    return 0;
  };

  const CustomOverlayLoading = () => {
    return (
      <div className="files-container">
        <div className="no-rows">
          <LoadingSpinner status="pending" className="files-container--spinner-root" />
        </div>
      </div>
    );
  };

  if (usersListStatus === 'pending') {
    return <> {CustomOverlayLoading()}</>;
  }
  return (
    <>
      <div className={classes.root}>
        <div className={classes.contentWrap}>
          <div>
            <AgentNetDivider typoVariant="h1" title={`Manage Users`} />
          </div>
          <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
            <AgGridReact
              onGridReady={onGridReady}
              ref={gridRef}
              rowData={rowData}
              columnDefs={usersColumnsConfig}
              defaultColDef={defaultColDef}
              suppressRowClickSelection={true}
              components={{
                customDropdownFilter: FloatingFilterComponent,
              }}
              noRowsOverlayComponent={'customOverlayNoRows'}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </>
  );
};
export default ManageUsers;
