import { CPLActions } from 'features/cpl/cpl-list/constants';
import { CreateCPLType, cplList, cplFormDataType, savedCPL, SecondPartyOptions } from 'features/cpl/types';
import { handleError } from 'utilities/utilities';
import { fileInfoType } from './file/interfaces/get-file';
import { getApiHost } from './host-by-env';

export async function createCPLInfo(fileData: fileInfoType | null | undefined, token: string): Promise<CreateCPLType> {
  const url = `${getApiHost()}cpl/loadPage`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(fileData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getCPLList(fileId: string | undefined, token: string): Promise<cplList> {
  const url = `${getApiHost()}cpl/list/${fileId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function editCPLInfo(
  cplId: string,
  fileData: fileInfoType | null | undefined,
  token: string,
): Promise<CreateCPLType> {
  const url = `${getApiHost()}cpl/loadPage/${cplId}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(fileData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function saveCPLInfo(
  cplData: cplFormDataType,
  token: string,
  method: 'POST' | 'PUT' = 'POST',
): Promise<savedCPL> {
  const url = `${getApiHost()}cpl`;
  const response = await fetch(url, {
    method,
    body: JSON.stringify(cplData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function deleteCPL(fileId: string, cplId: string, token: string): Promise<cplList> {
  const url = `${getApiHost()}cpl/${fileId}/${cplId}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json;
}

export async function voidCPL(
  fileId: string,
  cplId: string,
  token: string,
  accountId: number | undefined,
): Promise<cplList> {
  const url = `${getApiHost()}cpl/status`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify({
      fileId,
      cplId,
      accountId,
      status: CPLActions.void,
    }),
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
  });
  const json = await response.json();
  handleError(json);
  return json;
}

export async function unvoidCPL(
  fileId: string,
  cplId: string,
  token: string,
  accountId: number | undefined,
): Promise<cplList> {
  const url = `${getApiHost()}cpl/status`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify({
      fileId,
      cplId,
      accountId,
      status: CPLActions.unvoid,
    }),
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
  });
  const json = await response.json();
  handleError(json);
  return json;
}

export async function getCPLDetail(
  fileId: string | undefined,
  accountId: number | undefined,
  token: string,
  cplId: string | number,
): Promise<unknown> {
  const url = `${getApiHost()}cpl/document/${fileId}/${cplId}/${accountId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getFullListAttorenys(
  fileData: fileInfoType | null | undefined,
  token: string,
): Promise<CreateCPLType> {
  const url = `${getApiHost()}cpl/fullAttorneyList/${fileData?.firmId}/${fileData?.propertyState}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getFullListSecondParties(
  fileData: fileInfoType | null | undefined,
  token: string,
  roleType: string,
): Promise<SecondPartyOptions[]> {
  const url = `${getApiHost()}cpl/fullSecondPartyList/${roleType}/${fileData?.propertyState}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}
