import React, { useEffect, useRef, useContext } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Typography, MenuItem } from '@material-ui/core';
import {
  AgentAdvantage,
  AgentPrintPro,
  ComprehensiveCalc,
  Discownts,
  EagleAcademy,
  RecordsCenter,
  SoftwareIntegrationHub,
  AgentNetResourceCenter,
  AgentNetTitleEscrow,
} from './AppSwitcherIcons';
import { makeStyles } from '@material-ui/core/styles';
import { getAgentNetKnowledgeUrl } from 'api/host-by-env';
import { getAnteUrl } from 'api/host-by-env';
import { ProfileContext, ProfileContextInterface } from '../../hooks/ProfileContext';

type NavLinkType = {
  path: string;
  linkText: string;
  linkDescription: string;
  linkClassName?: string;
  icon?: any;
};

interface AppSwitcherProps {
  drawerWidth: number;
  visible: boolean;
  isHqUser: boolean;
  onClose: () => void;
}

const AppSwitcherLinks = ({ drawerWidth, visible, isHqUser, onClose }: AppSwitcherProps) => {
  const agentNetKnowledgeUrl = isHqUser ? getAgentNetKnowledgeUrl() + '?type=b2e' : getAgentNetKnowledgeUrl();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const anteUrl = `${getAnteUrl()}TitleEscrow/FileService/FileService.html?v1=${userFirm?.firmId}`;
  const agentNetKnowledgeEducationTrainingUrl = isHqUser
    ? agentNetKnowledgeUrl + '&callbackUrl=/?landing-page=education-training'
    : agentNetKnowledgeUrl + '?callbackUrl=/?landing-page=education-training';
  const appSwitcherLinks: NavLinkType[] = [
    {
      path: 'https://www.firstam.com/title/agency/advantage/',
      linkText: 'AgentAdvantage™ Marketplace',
      linkDescription: 'Products & Services to Grow Your Business',
      icon: <AgentAdvantage />,
    },
    {
      path: agentNetKnowledgeUrl,
      linkText: 'AgentNet Knowledge',
      linkDescription: 'Forms, Bulletins, Education and UW Guidance',
      icon: <AgentNetResourceCenter />,
    },
    {
      path: anteUrl,
      linkText: 'AgentNet Title & Escrow',
      linkDescription: 'First American Underwriter Web Portal',
      icon: <AgentNetTitleEscrow />,
    },
    {
      path: 'https://agency.myfirstam.com/LaunchPage.aspx?AgentXpress',
      linkText: 'discownts.com®',
      linkDescription: 'Special Offers From Our Favorite Suppliers',
      icon: <Discownts />,
    },
    {
      path: agentNetKnowledgeEducationTrainingUrl,
      linkText: 'Education & Training',
      linkDescription: 'Online Courses & Continuing Education (CE/CLE)',
      icon: <EagleAcademy />,
    },
    {
      path: 'https://agency.facc.firstam.com/',
      linkText: 'First American Comprehensive Calculator',
      linkDescription: 'Title Rates & Recording Fee Estimates',
      icon: <ComprehensiveCalc />,
    },
    {
      path: 'https://agency.myfirstam.com/AgentPrintPro/Authentication',
      linkText: 'Marketing & Print Services',
      linkDescription: 'High-Quality Content to Build Your Brand',
      icon: <AgentPrintPro />,
    },
    {
      path: 'https://records.firstam.com/',
      linkText: 'Records Center',
      linkDescription: 'Search Agency Imaging',
      icon: <RecordsCenter />,
    },
    {
      path: 'https://agency.myfirstam.com/ApplicationTabGUI/IntegrationTraining.html',
      linkText: 'Software Integration Hub',
      linkDescription: 'Master AgentNet® Integrations with Ease',
      icon: <SoftwareIntegrationHub />,
    },
  ];
  const useStyles = makeStyles((theme) => ({
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      width: 'calc(100% - 24px)',
      margin: '0 auto',
      transition: '.3s all ease-in',
    },
    svgContainer: {
      marginRight: theme.spacing(2),
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    drawerFloat: {
      [theme.breakpoints.up('xs')]: {
        flexShrink: 0,
        position: 'absolute',
        height: 'calc(100vh - 64px)',
        marginTop: '64px',
        left: drawerWidth,
        zIndex: 100,
        backgroundColor: 'white',
        width: 400,
        opacity: 0,
        transform: 'translateX(-100%)',
        transition: 'opacity .3s ease-out, transform .3s ease-out, box-shadow .3s ease-out',
        padding: '1.2rem 0',
      },
    },
    drawerFloatVisible: {
      opacity: 1,
      transform: 'translateX(0)',
      boxShadow: `0px 10px 9px 0px rgba(0, 0, 0, 0.80)`,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const handleLinkClick = (path: string) => {
    if (path !== '/') {
      window.open(path, '_blank');
    }
  };

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && visible) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible, onClose]);

  return (
    <nav ref={menuRef} className={clsx(classes.drawerFloat, { [classes.drawerFloatVisible]: visible })}>
      {appSwitcherLinks.map((link, i) => (
        <MenuItem className={classes.menuItem} onClick={() => handleLinkClick(link.path)} key={i} divider>
          <div className={classes.svgContainer}>{link.icon}</div>
          <div className={classes.textContainer}>
            <Typography variant="body2">{link.linkText}</Typography>
            <Typography variant="caption" color="textSecondary">
              {link.linkDescription}
            </Typography>
          </div>
        </MenuItem>
      ))}
    </nav>
  );
};

export default AppSwitcherLinks;
