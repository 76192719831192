import React, { useEffect, useState } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { AgentNetTextInput } from 'ui-kit/inputs';
import AgentNetButton from '../button/AgentNetButton';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { fileReopenSchema } from 'utilities/validation/schemas/file-reopen-schema';

interface AgentNetConfirmFileReopenModalProps {
  onClose: () => void;
  onConfirm: (value: any) => void;
  isActionInProgress: boolean;
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  footer: {
    margin: '6.4rem 0 0 0',
    display: 'flex',
    width: 'calc(100% - 64px)',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10.5px 32px',
    borderTop: '1px solid #C4C4C4',
  },
  footerText: {
    textAlign: 'right',
  },
  footerTos: {
    fontSize: '1.4rem',
    '& span': {
      padding: '0 10px',
      color: theme.palette.text.secondary,
      transform: `translateY(1px)`,
      display: 'inline-block',
    },
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  dialogWrap: {
    minWidth: 600,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  list: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  listItem: {
    fontSize: '14px',
    fontWeight: 400,
    padding: '0',
    paddingTop: theme.spacing(1),
    display: 'list-item',
    listStyleType: 'disc',
  },
  dialogClose: {
    padding: 0,
  },
  dialogContent: {
    padding: '2.4rem',
    paddingTop: theme.spacing(0),
  },
  logoWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& svg': {
      width: '231px',
      height: 'auto',
    },
  },
  menuIconBtn: {
    marginLeft: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover, &:visited': {
      color: theme.palette.primary.dark,
    },
  },
}));

const AgentNetConfirmFileReopenModal = ({
  onClose,
  onConfirm,
  isActionInProgress,
  open,
}: AgentNetConfirmFileReopenModalProps) => {
  const [fileReopenObj, setFileReopenObj] = useState<any>({
    fileNumber: '',
    reason: '',
  });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const classes = useStyles();
  const handleFileReopen = async () => {
    setIsSubmitted(true);
    if (!validationErrors || validationErrors.length === 0) {
      await onConfirm(fileReopenObj);
    }
  };

  useEffect(() => {
    if (!open) {
      handleCloseDialog();
    }
  }, [open]);

  useEffect(() => {
    doValidate(fileReopenObj, fileReopenSchema()).then((errs: FieldValidationError[]) => {
      setValidationErrors(errs);
    });
  }, [fileReopenObj]);

  const handleCloseDialog = () => {
    setIsSubmitted(false);
    setFileReopenObj({ fileNumber: '', reason: '' });
    onClose();
  };
  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="contact-us"
      open={open}
      maxWidth={'xs'}
      PaperProps={{ className: classes.dialogWrap }}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <Typography variant="h3" color="secondary">
            Confirm Reopen
          </Typography>
          <IconButton className={classes.dialogClose} onClick={handleCloseDialog}>
            <Close fontSize="large" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <div>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              Remitted products will be returned to Open status for editing.
            </ListItem>
            <ListItem className={classes.listItem}>
              Remitted fees will be held in accounting pending resubmission or cancellation.
            </ListItem>
            <ListItem className={classes.listItem}>
              Resubmitted files will not be eligible for payment until an invoice is generated, to ensure credits are
              properly applied.
            </ListItem>
          </List>
        </div>
        <AgentNetTextInput
          multiline
          fullWidth
          helperText="Please indicate your preferences for managing previous payments, i.e., refund, reapply payment to file on re-remit, apply payment to other file, etc."
          variant="outlined"
          rows={4}
          label=""
          name="reason"
          id="reason"
          required
          className="message-box"
          Data-QA={'ReopenReason'}
          value={fileReopenObj.reason}
          showValidation={isSubmitted}
          onChange={(event: any) => setFileReopenObj({ ...fileReopenObj, reason: event.target.value })}
          errs={validationErrors}
        />
      </DialogContent>
      <DialogActions className="modal-btn-container ghost-white flex-sb p-16-24">
        <AgentNetButton
          variant="outlined"
          onClick={handleCloseDialog}
          data-testid="cancel-action"
          aria-label="cancel-delete"
          data-qa={'FileReopenCancel'}
        >
          Cancel
        </AgentNetButton>
        <AgentNetButton
          color="primary"
          variant="contained"
          autoFocus
          aria-label="confirm-delete"
          data-qa={'FileReopenConfirm'}
          onClick={handleFileReopen}
          loading={isActionInProgress}
          disabled={isActionInProgress}
        >
          Yes, Reopen File
        </AgentNetButton>
      </DialogActions>
    </Dialog>
  );
};

export default AgentNetConfirmFileReopenModal;
