import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import './RemittanceContent.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabWrap: {
      padding: `0 ${theme.spacing(2)}px`,
      borderBottom: `1px solid #e5e5e5`,
      position: 'fixed',
      zIndex: 5,
      width: '100%',
      '& .MuiTab-root': {
        margin: '0 8px',
      },
    },
    oval: {
      width: '30px',
      height: '17px',
      background: theme.palette.primary.main,
      borderRadius: '40px',
      color: '#fff',
      fontSize: '12px',
      margin: '3px 0 0 3px',
    },
    tabs: {
      minHeight: 'auto',
      paddingTop: 0,
      '& .MuiTab-wrapper': {
        display: 'flex',
        flexDirection: 'row-reverse',
      },
    },
  }),
);

const ReportsNav = ({ handleTabChange, tabSelection, reviewCount, payRights, remitRights }: any): JSX.Element => {
  const classes = useStyles();

  const CountNotification = () =>
    reviewCount < 1 ? null : <div className={classes.oval}>{reviewCount <= 99 ? reviewCount : '99+'}</div>;

  return (
    <Paper square elevation={0} className={classes.tabWrap}>
      <Tabs
        value={tabSelection}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, v) => {
          handleTabChange && handleTabChange(v);
        }}
      >
        {/* Needs to put tab seperataly else they are not working  */}
        {remitRights && (
          <Tab label="Review" icon={<CountNotification />} value={0} Data-QA="ReviewTab" className={classes.tabs} />
        )}
        {remitRights && <Tab label="Report" Data-QA="ReportTab" value={1} />}
        {payRights && <Tab label="Pay" Data-QA="PayTab" value={2} />}
      </Tabs>
    </Paper>
  );
};

export default ReportsNav;
