export const underwritingText = {
  CREATE_NEW_UNDERWRITING: `Would you like to create one now?`,
  NO_UNDERWRITING_SUPPORT: `No Underwritings Found.  File Status does not support Underwriting Request creation`,
  MAX_LIABILITY_TOOLTIP_MSG: `This number represents the Company's largest title policy liability
exposure in this transaction, not the combined Amount of Insurance for all the policies to be issued. For example,
if the proposed transaction involves a $10M owners policy and $7M loan policy, the Maximum Liability Amount is $10M`,
  ASSIGN_TO_TOOLTIP_MSG: `Default group inbox is encourged but you may select a specific underwriter 
or other group below`,
  // DUPLICATE_POLICY_TYPE_WARNING_MSG: `is already selected for the Underwriting Request.`,
  MISC_ENDO_OPTION: `MISC. END`,
  DELETE_ENDO_CONFIRM_MSG: `You are about to delete this endorsement. Are you sure you want to proceed?`,
  DELETE_POLICY_CONFIRM_MSG: `You are about to delete this policy. Are you sure you want to proceed?`,
  SUBMIT_UWR_CONFIRM_MSG: `You are about to submit this underwriting request to First American Underwriters. Are you sure you want to proceed?`,
  UWR_CANCEL_REQUEST_MSG: `Please leave your reason for cancelling this request`,
  UWR_REOPEN_REQUEST_MSG: `Optional: Leave a note below before reopening request`,
};
