import { Files } from '../layout-core/core-content/FileContent/FileContent';
import ServiceContent from '../layout-core/core-content/ServiceContent';
import SdnContent from '../../features/services/sdnAdhoc/SdnContent';
import BackTitleContent from '../../features/services/backtitleAdhoc/BackTitleContent';
import FilesList from '../../features/files/files-summary/files-list/FilesList';
import FileInfoContainer from '../layout-core/core-content/FileContent/FileInfoContainer';
import CPLSummary from '../../features/cpl/CPLContainer';
import JacketsContainer from '../../features/jackets/JacketsContainer';
import Underwriting from '../../features/underwriting/Underwriting';
import ServiceOrdersContainer from '../../features/serviceorder/ServiceOrdersContainer';
import Verification from '../../utilities/verification/verification';
import Dashboard from '../layout-core/core-content/Dashboard';
import Remittance from '../layout-core/core-content/Remittance/Remittance';
import Reports from '../layout-core/core-content/Remittance/ReportContent';
import Invoices from '../layout-core/core-content/ServiceOrderInvoices/InvoiceContent';
import { FileSummaryMap } from 'features/files/files-summary/file-summary-google-map/FileSummaryMap';
import OnBehalfOFSearch from 'core/layout-core/core-content/OnBehalfOFSearch';
import Billing from 'core/layout-core/core-content/Payment/Billing';
import PolicyUpload from 'features/services/policyupload/PolicyUpload';
import AdminUserProfileContainer from 'features/admin/AdminUserProfileContainer';
import AdminUserProfileContent from 'features/admin/AdminUserProfileContent';
import PrefferedOfficeContent from 'features/admin/preferredOfficeContent';
import ContactUs from 'utilities/contact-us/contact-us';
import AvlList from 'features/services/agencyVerificationLetters/AvlList';
//import PropertyProfile from 'features/services/propertyProfile/PropertyProfile';
import IdaasContent from 'features/admin/IdaasContent';
import statements from 'features/statements/Statements';
import MyReports from '../layout-core/core-content/Reports/MyReportsContent';
import documents from 'features/documents/documentsContainer';
import AccountRegistration from '../../utilities/account-registration/account-registration';
import HelpHomePage from 'features/help/HelpHomePage';
import { ArticlePage } from 'features/help/ArticlePage';
import { TopicPage } from 'features/help/TopicPage';
import { SearchResultPage } from 'features/help/SearchResultPage';
import Signature from 'features/admin/e-signatures/Signature';
import autoReporting from 'features/admin/autoReporting/autoReporting';
import ManageUsers from 'features/admin/manageUsers/manageUsers';

export type RouteType = {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  isPublic?: boolean;
  layout?: string;
  // component: JSX.Element;
  routes?: Array<RouteType>;
  //onClick?: () => void;
  isAdminView?: boolean;
};

export const routes: Array<RouteType> = [
  {
    path: '/verification',
    component: Verification,
    isPublic: true,
  },
  {
    path: '/contact-us',
    component: ContactUs,
    layout: 'PublicLayout',
    isPublic: true,
  },
  {
    path: '/account-registration',
    component: AccountRegistration,
    layout: 'PublicLayout',
    isPublic: true,
  },
  {
    path: '/remittance',
    component: Remittance,
    layout: 'AuthLayout',
  },
  {
    path: '/searchFirms',
    component: OnBehalfOFSearch,
    layout: 'AuthLayout',
  },
  {
    path: '/reports/:firmId?',
    component: Reports,
    layout: 'AuthLayout',
  },
  {
    path: '/invoices/:firmId?',
    component: Invoices,
    layout: 'AuthLayout',
  },
  {
    path: '/services',
    component: ServiceContent,
    routes: [
      {
        path: '/services/sdn-search',
        component: SdnContent,
      },
      {
        path: '/services/back-title-search',
        component: BackTitleContent,
      },
      {
        path: '/services/agency-verification-letters',
        component: AvlList,
      },
      {
        path: '/services/policy-upload',
        component: PolicyUpload,
      },
    ],
    layout: 'AuthLayout',
  },
  {
    path: '/files',
    component: Files,
    layout: 'AuthLayout',
    routes: [
      {
        path: '/files/file-info/:fileId',
        component: FileInfoContainer,
      },
      {
        path: '/files/map/:fileId',
        component: FileSummaryMap,
      },
      {
        path: '/files/file-service-orders',
        component: ServiceOrdersContainer,
      },
      {
        path: '/files/:fileId/cpl',
        component: CPLSummary,
      },
      {
        path: '/files/:fileId/jackets',
        component: JacketsContainer,
      },
      {
        path: '/files/:fileId/underwriting',
        component: Underwriting,
      },
      {
        path: '/files/:fileId/back-title',
        component: BackTitleContent,
      },
      {
        path: '/files/:fileId/Documents',
        component: documents,
      },
      {
        path: '/files',
        component: FilesList,
      },
    ],
  },
  {
    path: '/statements/:firmId?',
    component: statements,
    layout: 'AuthLayout',
  },
  {
    path: '/admin/billing',
    component: Billing,
    layout: 'AuthLayout',
  },
  {
    path: '/admin/user/',
    component: AdminUserProfileContainer,
    layout: 'AuthLayout',
    routes: [
      {
        path: '/admin/user/profile/update',
        component: IdaasContent,
        isAdminView: false,
      },
      {
        path: '/admin/user/profile',
        component: AdminUserProfileContent,
        isAdminView: false,
      },
      {
        path: '/admin/user/preferred-offices',
        component: PrefferedOfficeContent,
        isAdminView: false,
      },
    ],
  },
  {
    path: '/admin/auto-reporting',
    component: autoReporting,
    layout: 'AuthLayout',
  },
  {
    path: '/admin/e-signatures',
    component: Signature,
    layout: 'AuthLayout',
  },
  {
    path: '/admin/users/manage',
    component: ManageUsers,
    layout: 'AuthLayout',
  },
  {
    path: '/statements',
    component: statements,
    layout: 'AuthLayout',
  },

  // Reports Section
  {
    path: '/reporting',
    component: MyReports,
    layout: 'AuthLayout',
  },
  {
    path: '/help',
    component: HelpHomePage,
    layout: 'HelpLayout',
    routes: [
      {
        path: '/help/search',
        component: SearchResultPage,
      },
      {
        path: '/help/:topic/:article',
        component: ArticlePage,
      },
      {
        path: '/help/:topic',
        component: TopicPage,
      },
    ],
  },
  {
    path: '/:err?',
    component: Dashboard,
    layout: 'AuthLayout',
  },
];
