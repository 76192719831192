import { LenderClauseTypeValue } from 'api/file/constants';

export const mortgageClause = [
  { name: 'Standard', value: 'Standard' },
  { name: 'Successor Ownership', value: 'Successor Ownership' },
  { name: 'FHA', value: 'FHA' },
  { name: 'VA', value: 'VA' },
  { name: 'MERS', value: 'MERS' },
  { name: 'Other', value: 'Other' },
];

export const MortgageClauseTextOptions: Record<string, { name: string; value: string }> = {
  [LenderClauseTypeValue.standard]: {
    value: 'its successors and/or assigns, as their interests may appear',
    name: 'Standard',
  },
  [LenderClauseTypeValue.successor_ownership]: {
    value:
      'and each successor and/or assign that is a successor in ownership of the Indebtedness, except as provided in Section 12(c) of the Conditions of the Loan Policy to be issued',
    name: 'SuccessorOwnership',
  },
  [LenderClauseTypeValue.fha]: {
    value:
      'its successors and/or assigns, as their interests may appear, and THE SECRETARY OF HOUSING AND URBAN DEVELOPMENT, WASHINGTON, D.C., defined in the paragraph entitled "Definitions of Terms" contained in this policy',
    name: 'FHA',
  },
  [LenderClauseTypeValue.va]: {
    value:
      'its successors and/or assigns, as their interests may appear, and the Secretary of Veterans Affairs, their successors and assigns as defined in the paragraph entitled "Definitions of Terms" contained in this policy',
    name: 'VA',
  },
  [LenderClauseTypeValue.mers]: {
    value:
      'appearing of record as MERS, as nominee for {{ COMPANY NAME }}, its successors and/or assigns, as their interests may appear',
    name: 'MERS',
  },
  [LenderClauseTypeValue.other]: {
    value: '',
    name: 'Other',
  },
};
