import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Container, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '../icons/Close';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: '1.8rem 2.4rem',
      borderBottom: `1px solid #c4c4c4`,
      width: 'inherit',
      justifyContent: 'space-between',
      position: 'fixed',
      zIndex: 1201,
      backgroundColor: 'white',
    },
    drawerCloseIcon: {
      padding: '4px 0 0 0',
      '&:hover': {
        backgroundColor: 'white',
      },
      '&:disabled': {
        opacity: 0.6,
      },
    },
  }),
);

export interface FormHeaderProps {
  text: string;
  handleClose?: () => void;
  disableCrossIconClick?: boolean;
  crossQAPrefix?: string;
}

export const FormHeader = ({
  text,
  handleClose,
  disableCrossIconClick = false,
  crossQAPrefix = '',
}: FormHeaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Container className={`${classes.root} form-header-container`}>
      <Typography className="form-header" variant={'h1'} color={'secondary'}>
        {text}
      </Typography>
      <IconButton
        onClick={handleClose}
        data-qa={crossQAPrefix + 'Close'}
        className={classes.drawerCloseIcon}
        disabled={disableCrossIconClick}
        disableFocusRipple
        disableRipple
      >
        <CloseIcon fontSize="large" />
      </IconButton>
    </Container>
  );
};
