export const getUsersList = async (token: string): Promise<any> => {
  //   const url = ''; //`${getApiHost()}/users`;
  //   const response = await fetch(url, {
  //     method: 'GET',
  //     headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  //   });
  //   const json = await response.json();
  await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds

  // Mocked response
  const json: any = {
    result: [
      {
        UserId: 190432,
        FullName: 'Marsha Demo',
        FirmLocation: 'DEMO - ABC Settlement Services/CO/Denver',
        LoginUserId: '',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
      {
        UserId: 190434,
        FullName: 'Leah Christoffersen',
        FirmLocation: 'DEMO - ABC Settlement Services/FL/Atlantic Beach',
        LoginUserId: '',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
      {
        UserId: 205401,
        FullName: 'Renaye Jolman',
        FirmLocation: 'DEMO - ABC Settlement Services /TX/Dallas',
        LoginUserId: '',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
      {
        UserId: 202110,
        FullName: 'Test PCN',
        FirmLocation: 'DEMO - ABC Settlement Services /TX/Dallas',
        LoginUserId: '',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
      {
        UserId: 219912,
        FullName: 'Sky Harris',
        FirmLocation: 'DEMO - ABC Settlement Services /TX/Dallas',
        LoginUserId: '',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Title & Escrow User',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
      {
        UserId: 220886,
        FullName: 'MLC Test',
        FirmLocation: 'DEMO - ABC Settlement Services/FL/Atlantic Beach',
        LoginUserId: '',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Title & Escrow User',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
      {
        UserId: 229307,
        FullName: 'Test User Test User',
        FirmLocation: 'Closeline, LLC/WI/Brookfield1',
        LoginUserId: '',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Title & Escrow User',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
      {
        UserId: 229315,
        FullName: 'Mars 1',
        FirmLocation: 'Closeline, LLC/WI/Brookfield1',
        LoginUserId: '',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Title & Escrow User',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
      {
        UserId: 229317,
        FullName: 'RemitOnly ANMUser',
        FirmLocation: 'DEMO - ABC Settlement Services/FL/Atlantic Beach',
        LoginUserId: '',
        EmailAddress: 'vidrao@yahoo.com',
        UserType: 'Agent',
        Role: 'Title & Escrow User',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
      {
        UserId: 229319,
        FullName: 'Remit Only',
        FirmLocation: 'DEMO - ABC Settlement Services/FL/Atlantic Beach',
        LoginUserId: '',
        EmailAddress: 'vidrao@yahoo.com',
        UserType: 'Agent',
        Role: 'Title Only User',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
      {
        UserId: 229359,
        FullName: 'Gokul P',
        FirmLocation: 'DEMO - ABC Settlement Services /TX/Dallas',
        LoginUserId: '',
        EmailAddress: 'testingagentnet@gmail.com',
        UserType: 'Agent',
        Role: 'Title & Escrow User',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
      {
        UserId: 10915,
        FullName: 'Jessica Charmatz',
        FirmLocation: 'DEMO - ABC Settlement Services/PA/Pittsburgh',
        LoginUserId: 'abc09',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Disabled',
        HQUser: 'No',
      },
      {
        UserId: 10032,
        FullName: 'Northeast ABC SETTLEMENT SERVICES',
        FirmLocation: 'DEMO - ABC Settlement Services/MA/Quincy',
        LoginUserId: 'abc123__2042351966667',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Disabled',
        HQUser: 'No',
      },
      {
        UserId: 14580,
        FullName: 'Demo User',
        FirmLocation: 'Demo - ABC Settlement Services/AZ/Phoenix',
        LoginUserId: 'abc2__2042351966667',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Disabled',
        HQUser: 'No',
      },
      {
        UserId: 14500,
        FullName: 'Joanne Zech',
        FirmLocation: 'DEMO - ABC Settlement Services/WI/Appleton',
        LoginUserId: 'abc62__2042351966667',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Disabled',
        HQUser: 'No',
      },
      {
        UserId: 8062,
        FullName: 'Christine Heffner',
        FirmLocation: 'DEMO - ABC Settlement Services/PA/Pittsburgh',
        LoginUserId: 'abc987__2042351966667',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Escrow Only User',
        UserStatus: 'Disabled',
        HQUser: 'No',
      },
      {
        UserId: 100860,
        FullName: 'Rama Kamuni',
        FirmLocation: 'DEMO - ABC Settlement Services/OH/Cleveland',
        LoginUserId: 'abcdefghijklmnopqrst__2042351966667',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Disabled',
        HQUser: 'No',
      },
      {
        UserId: 15289,
        FullName: 'Jody Bauer',
        FirmLocation: 'DEMO - ABC Settlement Services/PA/Pittsburgh',
        LoginUserId: 'abcmn__2042351966667',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Disabled',
        HQUser: 'No',
      },
      {
        UserId: 15575,
        FullName: 'Anita Johnson',
        FirmLocation: 'DEMO - ABC Settlement Services/PA/Pittsburgh',
        LoginUserId: 'abcss__2042351966667',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Disabled',
        HQUser: 'No',
      },
      {
        UserId: 178478,
        FullName: 'Gee Kaye',
        FirmLocation: 'DEMO - ABC Settlement Services/FL/Atlantic Beach',
        LoginUserId: 'accttest',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Agent',
        UserStatus: 'Inactive',
        HQUser: 'No',
      },
      {
        UserId: 176715,
        FullName: 'fn mn ln',
        FirmLocation: 'DEMO - ABC Settlement Services/SC/Charleston',
        LoginUserId: 'Af01_11/20',
        EmailAddress: 'AgencyNotification@firstam.com',
        UserType: 'Agent',
        Role: 'Title & Escrow User',
        UserStatus: 'Not Activated',
        HQUser: 'No',
      },
    ],
  };
  const usersList: any = json.result;
  return usersList;
};
