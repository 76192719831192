export const createNewJacket = 'Would you like to create one now?';
export const noJacketSupport = 'No Jackets Found.  File Status does not support Jacket creation';
export const noActiveJackets = 'No active Jackets Found';
export const policyFieldsWithNameInLabel = [
  'Buyer',
  'Borrower',
  'Seller',
  'Fee',
  'Subdivision',
  'Insured',
  'Grantee',
  'Exception',
  'Premium',
];
export const StatCodePdfWindowName = 'STATCODE PDF';
export const WindowFeatures = 'popup';
export const policiesWithDefaultPolicyAmount = ['5006936'];
export const openJacketsLimit =
  '5 active Jackets limit reached. Please void or delete a Jacket if you would like to add a new one.';

export enum JacketsActions {
  edit = 'Edit',
  delete = 'Delete',
  void = 'Void',
  view = 'View',
  unvoid = 'Unvoid',
  expandCollapseActive = 'ExpandCollapseActive',
  expandCollapseInActive = 'ExpandCollapseInActive',
  onInactiveToggleChange = 'OnInactiveToggleChange',
}
export enum JacketStatus {
  open = 'Open',
  pendingVoid = 'Pending Void',
  void = 'Void',
  pendingRemit = 'Pending Remit',
  remitted = 'Remitted',
  reported = 'Reported',
}
