import React from 'react';
import {
  FolderOpen,
  BarChart,
  Apps,
  Person,
  ContactMailOutlined,
  CreditCard,
  MonetizationOnOutlined,
  DescriptionOutlined,
  AccountTreeOutlined,
  LoopOutlined,
  PeopleAltOutlined,
  ReceiptOutlined,
  StorefrontOutlined,
} from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core';
import {
  //NavDashboard,
  NavServices,
  NavAccounting,
  SupportAgentOutlined,
} from 'ui-kit/icons/AgentNetIcon';
export type NavLinkType = {
  linkText: string;
  path: string;
  linkClassName?: string;
  icon?: SvgIconProps;
  sublinks?: NavLinkType[];
  expanded?: boolean;
  dataQA?: string;
  category?: string;
  isDisabled?: boolean;
  isHidden?: boolean;
};
import { isEnableAllFeatures } from 'api/host-by-env';

const linkClassName = 'nav-link';

export const links: NavLinkType[] = [
  // this will be reenabled when we have the actual dashboard
  // {
  //   linkText: 'Dashboard',
  //   path: '/',
  //   linkClassName,
  //   icon: <NavDashboard />,
  //   dataQA: 'GlobalNavDashboard',
  // },
  {
    linkText: 'Files',
    path: '/files',
    linkClassName,
    icon: <FolderOpen htmlColor="#FFFFFF" fontSize="large" />,
    dataQA: 'GlobalNavFiles',
  },
  {
    linkText: 'Accounting',
    path: '/',
    linkClassName,
    icon: <NavAccounting />,
    //isHidden: !isEnableAllFeatures(),
    sublinks: [
      {
        linkText: 'Report & Pay',
        path: '/reports',
        linkClassName,
        dataQA: 'GlobalNavReports',
        //isHidden: !isEnableAllFeatures(),
      },
      {
        linkText: 'Service Order Invoices',
        path: '/invoices',
        linkClassName,
        dataQA: 'GlobalNavInvoices',
      },
      {
        linkText: 'Billing',
        path: '/billing',
        linkClassName,
        dataQA: 'GlobalNavBilling',
        isHidden: true,
      },
      {
        linkText: 'Statements',
        path: '/statements',
        linkClassName,
        dataQA: 'GlobalNavStatements',
      },
    ],
    expanded: false,
    dataQA: 'GlobalNavAccounting',
  },
  {
    linkText: 'Services',
    path: '/services/sdn-search',
    linkClassName,
    icon: <NavServices />,
    sublinks: [
      {
        linkText: 'Agency Verification Letters',
        path: '/services/agency-verification-letters',
        linkClassName,
        dataQA: 'GlobalNavAgencyVerificationLetters',
      },
      {
        linkText: 'Back Title Search',
        path: '/services/back-title-search',
        linkClassName,
        dataQA: 'GlobalNavBackTitle',
      },
      { linkText: 'Property Reports', path: '#', linkClassName, dataQA: 'GlobalNavPropertyProfiles' },
      {
        linkText: 'Policy Upload',
        path: '/services/policy-upload',
        linkClassName,
        dataQA: 'GlobalNavPolicyUpload',
      },
      { linkText: 'SDN Search', path: '/services/sdn-search', linkClassName, dataQA: 'GlobalNavSDN' },
    ],
    expanded: false,
    dataQA: 'GlobalNavServices',
  },
  {
    linkText: 'Reports',
    path: '/reporting',
    linkClassName,
    icon: <BarChart htmlColor="#FFFFFF" fontSize="large" />,
    dataQA: 'GlobalNavReports',
    //isHidden: !isEnableAllFeatures(),
  },
  {
    linkText: 'Apps',
    path: '',
    linkClassName,
    icon: <Apps htmlColor="#FFFFFF" fontSize="large" />,
    dataQA: 'GlobalNavReports',
  },
];

export const adminLinks: NavLinkType[] = [
  {
    category: 'User',
    linkText: 'User Profile & Preferences',
    path: '/admin',
    linkClassName,
    icon: <Person htmlColor="#FFFFFF" fontSize="large" />,
    sublinks: [
      {
        linkText: 'Profile',
        path: '/admin/profile',
        linkClassName,
        dataQA: 'AdminNavUserProfile',
      },
      {
        linkText: 'Resource Center Preferences',
        path: '/admin/profile',
        linkClassName,
        dataQA: 'AdminNavResourceCenter',
        isHidden: true,
      },
      {
        linkText: 'Policy Preferences',
        path: '/admin/policy',
        linkClassName,
        dataQA: 'AdminNavPolicyPreferences',
        isHidden: true,
      },
    ],
    expanded: false,
    dataQA: 'AdminNavUser',
  },
  {
    category: 'Firm',
    linkText: 'Address Book',
    path: '',
    linkClassName,
    icon: <ContactMailOutlined htmlColor="#FFFFFF" fontSize="large" />,
    expanded: false,
    dataQA: 'AdminNavAddress',
    isHidden: true,
  },
  {
    category: 'Firm',
    linkText: 'Auto-Reporting',
    path: '/admin/auto-reporting',
    linkClassName,
    icon: <MonetizationOnOutlined htmlColor="#FFFFFF" fontSize="large" />,
    expanded: false,
    dataQA: 'AdminNavAutoReporting',
    isHidden: !isEnableAllFeatures(),
  },
  {
    category: 'Firm',
    linkText: 'Billing',
    path: '/admin/billing',
    linkClassName,
    icon: <CreditCard htmlColor="#FFFFFF" fontSize="large" />,
    expanded: false,
    dataQA: 'AdminNavBilling',
  },
  {
    category: 'Firm',
    linkText: 'e-Signatures',
    path: '/admin/e-signatures',
    linkClassName,
    icon: <DescriptionOutlined htmlColor="#FFFFFF" fontSize="large" />,
    expanded: false,
    dataQA: 'AdminNavSignatures',
    isHidden: !isEnableAllFeatures(),
  },
  {
    category: 'Firm',
    linkText: 'Mappings',
    path: '',
    linkClassName,
    icon: <AccountTreeOutlined htmlColor="#FFFFFF" fontSize="large" />,
    expanded: false,
    dataQA: 'AdminNavMappings',
    isHidden: true,
    sublinks: [
      {
        linkText: 'Firm Mappings',
        path: '/admin/mapping/firms',
        linkClassName,
        dataQA: 'AdminNavMappingsFirm',
      },
      {
        linkText: 'Rate Type Mappings',
        path: '/admin/mapping/rate-type',
        linkClassName,
        dataQA: 'AdminNavMappingsRateType',
      },
      {
        linkText: 'Service Order Mappings',
        path: '/admin/mapping/service-order',
        linkClassName,
        dataQA: 'AdminNavMappingsServiceOrder',
      },
    ],
  },
  {
    category: 'Firm',
    linkText: 'Preset Overrides',
    path: '',
    linkClassName,
    icon: <LoopOutlined htmlColor="#FFFFFF" fontSize="large" />,
    expanded: false,
    dataQA: 'AdminNavPresetOverrides',
    isHidden: true,
  },
  {
    category: 'Firm',
    linkText: 'Users',
    path: '',
    linkClassName,
    icon: <PeopleAltOutlined htmlColor="#FFFFFF" fontSize="large" />,
    expanded: false,
    dataQA: 'AdminNavUsers',
    isHidden: !isEnableAllFeatures(),
    sublinks: [
      {
        linkText: 'Manage Users',
        path: '/admin/users/manage',
        linkClassName,
        dataQA: 'AdminNavManageUser',
        isHidden: !isEnableAllFeatures(),
      },
      {
        linkText: 'User Registration',
        path: '/admin/users/registration',
        linkClassName,
        dataQA: 'AdminNavRegistrationUser',
        isHidden: true,
      },
    ],
  },
  {
    category: 'Staff',
    linkText: 'ASC Support',
    path: '',
    linkClassName,
    icon: <SupportAgentOutlined />,
    expanded: false,
    dataQA: 'AdminNavASCSupport',
    isHidden: true,
  },
  {
    category: 'Staff',
    linkText: 'Service Orders',
    path: '',
    linkClassName,
    icon: <ReceiptOutlined htmlColor="#FFFFFF" fontSize="large" />,
    expanded: false,
    dataQA: 'AdminNavServiceOrders',
    isHidden: true,
  },
  {
    category: 'Staff',
    linkText: 'Vendor Mappings',
    path: '',
    linkClassName,
    icon: <StorefrontOutlined htmlColor="#FFFFFF" fontSize="large" />,
    expanded: false,
    dataQA: 'AdminNavVendorMappings',
    isHidden: true,
  },
];
