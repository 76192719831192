import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ofacPdf from '../../../static/NA_Standard_OFAC_Underwriting_Communication_Agents.pdf';
import PdfIcon from 'ui-kit/icons/PDF';
import './SdnContent.scss';
import { makeStyles } from '@material-ui/core/styles';
import { AgentNetTextInput } from 'ui-kit/inputs';
import { DividerType } from 'ui-kit/components/dividers';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import { Notification } from 'ui-kit/components/notification/Notification';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { useAuth } from '@agentnet/auth';
import { searchSdnName } from '../../../api/sdn/sdn-api';
import { openDocument } from '../../../utilities/utilities';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import SdnResultComponent from './SdnResultComponent';
import {
  Notice,
  OFACPdfWindowName,
  SdnPdfWindowName,
  WindowFeatures,
  Regex,
  SdnErrorAlert,
  SdnWarningAlert,
  SdnAlertDuration,
  DocumentType,
  addRowsMaxCount,
} from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: `6.4rem`,
  },
  ofac: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      marginLeft: theme.spacing(1),
      fontSize: '20px',
    },
  },
}));

const SdnContent = (): JSX.Element => {
  const [isSearching, setSearchFlag] = useState(false);
  const [addedRowsCount, addRowCount] = useState(0);
  const [isAddSearchDisabled, setIsAddSearchDisabled] = useState(false);
  const { getAccessToken } = useAuth();
  const classes = useStyles();
  const notice = Notice;
  const qaAttrPrefix = 'SDNSearchName';
  const openOfacPDF = () => {
    window.open(ofacPdf, OFACPdfWindowName, WindowFeatures);
  };
  const openSdnPDF = (pdf: string) => {
    openDocument(pdf, DocumentType, SdnPdfWindowName, WindowFeatures);
  };
  const [showBottomAppBar, setShowBottomAppBar] = useState(false);
  const defaultList = ['', '', '', '', '', '', '', ''];
  const newSearchFields = ['', '', '', ''];
  const [searchNameList, setSearchNameList] = useState(defaultList);
  const previousSearchFields = React.useRef(searchNameList).current;
  const addSearchFields = () => {
    if (addedRowsCount < addRowsMaxCount) {
      const updatedList = [...searchNameList].concat(newSearchFields);
      setSearchNameList(updatedList);
      addRowCount(addedRowsCount + 1);
      setIsAddSearchDisabled(false);
      if (addedRowsCount == addRowsMaxCount - 1) {
        setIsAddSearchDisabled(true);
      }
    } else {
      setIsAddSearchDisabled(true);
    }
  };
  const resetInputField = () => {
    setSdnSearch(false);
    let resetFields = [...defaultList];
    for (let i = 0; i < addedRowsCount; i++) {
      resetFields = resetFields.concat(newSearchFields);
    }
    setSearchNameList(resetFields);
  };
  React.useEffect(() => {
    if (JSON.stringify(previousSearchFields) !== JSON.stringify(searchNameList)) {
      setShowBottomAppBar(true);
    }
  }, [searchNameList]);
  const keyPress = (e: React.KeyboardEvent) => {
    const regex = new RegExp(Regex);
    if (regex.test(e.key)) return true;
    e.preventDefault();
    return false;
  };
  const setSearchName = (name: string, index: number) => {
    const tempSearchNameList = [...searchNameList];
    tempSearchNameList[index] = name;
    setSearchNameList(tempSearchNameList);
  };
  const [sdnResult, setSdnResult] = useState({
    id: 0,
    names: '',
    result: '',
    searchDate: '',
    document: '',
    isMatched: false,
    partiesResult: [{ partyName: '', isMatched: false }],
  });
  const showErrorAlert = () => {
    setErrorNotification(true);
    setTimeout(function () {
      setErrorNotification(false);
    }, SdnAlertDuration);
  };
  const showWarningAlert = () => {
    setWarningNotification(true);
    setTimeout(function () {
      setWarningNotification(false);
    }, SdnAlertDuration);
  };
  const searchName = async () => {
    setSearchFlag(true);
    const token = await getAccessToken();
    const names = searchNameList.filter((element) => {
      return element !== '';
    });

    if (names.length <= 0) {
      showWarningAlert();
      setSdnSearch(false);
      setSearchFlag(false);
      return;
    }

    const res = await searchSdnName(names, token).catch((error) => {
      console.error('Error in search names:', error);
      showErrorAlert();
    });

    if (res != null) {
      setSdnResult({
        id: res.id,
        document: res.document,
        isMatched: res.isMatched,
        names: res.names,
        result: res.result,
        searchDate: res.searchDate,
        partiesResult: res.partiesResult,
      });
      setSdnSearch(true);
    } else {
      setSdnSearch(false);
    }
    setSearchFlag(false);
  };
  const resetForm = () => {
    addRowCount(0);
    setSearchNameList(defaultList);
    setSearchFlag(false);
    setSdnSearch(false);
    setIsAddSearchDisabled(false);
  };

  const backToSearch = () => {
    setSearchFlag(false);
    setSdnSearch(false);
  };

  const [sdnSearch, setSdnSearch] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [warningNotification, setWarningNotification] = useState(false);

  return (
    <>
      <div className={classes.root}>
        <Grid container justifyContent={'center'}>
          {errorNotification ? (
            <Grid item xs={12} sm={12}>
              <Notification
                msg={SdnErrorAlert}
                severity="error"
                alertClass="sdn-search-alert"
                handleClose={() => setErrorNotification(false)}
              />
            </Grid>
          ) : null}
          {warningNotification ? (
            <Grid item xs={12} sm={12}>
              <Notification
                msg={SdnWarningAlert}
                severity="warning"
                alertClass="sdn-search-alert"
                handleClose={() => setWarningNotification(false)}
              />
            </Grid>
          ) : null}
        </Grid>
        {isSearching ? <LoadingSpinner status={'pending'} variant="linear" /> : null}
        {sdnSearch ? (
          <>
            <SdnResultComponent sdnResult={sdnResult} />
            <BottomAppBar
              primaryActionLabel="Start New Search"
              primaryActionDataQa="SDNStartNewSearch"
              onPrimaryClick={resetForm}
              secondaryActionLabel="View PDF"
              secondaryActionDataQa="SDNViewPdf"
              secondaryFilled
              onSecondaryClick={() => openSdnPDF(sdnResult.document)}
              cancelLabel="Modify Search"
              cancelDataQa="SDNModifySearch"
              onCancel={backToSearch}
            />
          </>
        ) : (
          <>
            {' '}
            <div className=" sdn-search-container">
              <AgentNetDivider variant={DividerType.header} title="SDN Search" message="*required" />
              <div className="sdn-search-content">
                <Typography
                  variant="h6"
                  dangerouslySetInnerHTML={{
                    __html: notice,
                  }}
                />
                <Typography className={classes.ofac} variant="h6" data-qa="SDNOFACComplianceMandatesPDF">
                  <span>OFAC Compliance Mandates</span>
                  <PdfIcon onClick={openOfacPDF} />
                </Typography>
                <Grid container spacing={3} className="search-fields">
                  {searchNameList.map((value, index) => {
                    return (
                      <Grid item xs={12} sm={6} md={3} key={index} className="fields">
                        <AgentNetTextInput
                          margin={'normal'}
                          variant="outlined"
                          label="Search Name"
                          inputProps={{ maxLength: 300 }}
                          onKeyPress={keyPress}
                          Data-QA={qaAttrPrefix + [index + 1]}
                          value={searchNameList[index]}
                          onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                              setSearchName(e.target.value, index);
                              searchName();
                            }
                          }}
                          onChange={(e) => {
                            setSearchName(e.target.value, index);
                          }}
                          fullWidth
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
            {showBottomAppBar && (
              <BottomAppBar
                primaryActionLabel="Search"
                primaryActionDataQa="SDNSearch"
                secondaryActionLabel="Clear All"
                secondaryActionDataQa="SDNClearAll"
                cancelLabel="Add Search Fields"
                cancelDataQa="SDNAddSearchFields"
                onCancel={addSearchFields}
                onPrimaryClick={searchName}
                onSecondaryClick={resetInputField}
                disableCancel={isAddSearchDisabled}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SdnContent;
