// import { Color } from '@material-ui/core';
import { SimplePaletteColor } from '@material-ui/core/styles/createPalette';
import { common, grey } from '@material-ui/core/colors';
import createPalette from '@material-ui/core/styles/createPalette';

export const primary: SimplePaletteColor = {
  light: '#4F9BE3',
  main: '#0074CA',
  dark: '#265E92',
  contrastText: '#FFFFFF',
};

export const secondary: SimplePaletteColor = {
  light: '#FFCC33', // brand fa/yellow
  main: '#013A6F', // brand/fa/blue
  dark: '#E62E1F', // brand/fa/red
  contrastText: '#FFFFFF',
};

export const outlined: SimplePaletteColor = {
  light: '#EBF2F8', //'#4F9BE3',
  main: '#217DD4',
  dark: '#4F9BE3', //'#265E92',
  contrastText: '#FFFFFF',
};

const palette = createPalette({
  primary,
  secondary,
  outlined,
  error: {
    light: '#F88078',
    main: '#B3150A',
    dark: '#B3150A',
  },
  success: {
    light: '#7BC67E',
    main: '#4CAF50',
    dark: '#3B873E',
  },
  background: {
    paper: common.white,
    default: '#F6F9FF',
  },
  action: {
    active: grey[600],
    hover: grey[100],
    hoverOpacity: 0.12,
    selected: '#EBEBEB',
    disabled: '#989898',
    disabledBackground: grey[100],
    focus: grey[300],
  },
  text: {
    primary: grey[900], //'rgba(0,0,0,.87)',
    secondary: '#666666', //'rgba(0,0,0,.6)',
    disabled: grey[500], //'rgba(0,0,0,.38)',
  },
  divider: grey[300],
});

export default palette;
