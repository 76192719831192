import * as yup from 'yup';
import { isValid, isAfter, isBefore } from 'date-fns';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'Required';
export const INVALID_FIELD_MESSAGE_TEXT = 'Invalid';
export const requiredFieldMessage = (fieldName: any): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const invalidFieldMessage = (fieldName: any): string => `${fieldName} ${INVALID_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (fieldName?: string, maxLength?: number): string =>
  `${fieldName} Max length is ${maxLength}`;

declare module 'yup' {
  interface StringSchema {
    validatePolicyField(): StringSchema;
    validateCalcCriteria(path?: string, parentLabel?: boolean, customErrFunc?: any): StringSchema;
  }
}

yup.addMethod<yup.StringSchema>(yup.string, 'validatePolicyField', function () {
  return this.test({
    test(value) {
      const { parent, createError } = this;

      const isCurrencyValueZeroOrLess = parent.dataType === 'currency' && parseFloat(value ?? '') <= 0;

      if (parent.isRequired && (!value || isCurrencyValueZeroOrLess)) {
        return createError({ path: parent.name, message: requiredFieldMessage(parent.label) });
      }

      const isDateValueInvalid = parent.dataType === 'date' && value && !isValidDate(new Date(value ?? ''));

      if (isDateValueInvalid) {
        return createError({ path: parent.name, message: invalidFieldMessage(parent.label) });
      }

      return true;
    },
  });
});

const isFutureDate = (date: Date) => {
  const today = new Date();
  return +date > +today;
};

yup.addMethod<yup.StringSchema>(
  yup.string,
  'validateCalcCriteria',
  function (path, parentLabel = false, customErrFunc = requiredFieldMessage) {
    return this.test({
      test(value) {
        const { parent, createError } = this;
        const currentPath = path === 'parentName' ? parent.name : `${path}${parent.randomId}`;
        const isDateValueInvalid = parent.dataType === 'date' && value && !isValidDate(new Date(value ?? ''));
        const isEndorsement = path === 'endorsementName';
        const inValidSelect = parent.dataType === 'select' && value === '----Select----';

        if ((parent.isRequired || isEndorsement) && (!value || inValidSelect)) {
          const currentErrorMessage = isEndorsement ? requiredFieldMessage('Endorsement Name') : ' ';
          const message = parentLabel ? customErrFunc(parent.label) : currentErrorMessage;
          return createError({ path: currentPath, message: message });
        }

        if (parent.dataType === 'select' && !parent?.options?.some((option: any) => option.value === value)) {
          return createError({ path: currentPath, message: parentLabel ? customErrFunc(parent.label) : ' ' });
        }

        if (isDateValueInvalid) {
          const isQuestion = path === 'question';
          const message = isQuestion ? 'Invalid Date' : invalidFieldMessage(parent.label);
          return createError({ path: currentPath, message: message });
        }

        if (parent.name === 'PolicyComplianceDate' && isFutureDate(new Date(value ?? ''))) {
          return createError({ path: currentPath, message: 'Can not be future date' });
        }

        return true;
      },
    });
  },
);

export const JacketSchema = yup.object().shape({
  policyType: yup.string().required(requiredFieldMessage('PolicyType')),
  formNumber: yup.string().required(requiredFieldMessage('FormNumber')),
  policyStatus: yup.string(),
  policyFields: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        value: yup.string().nullable().validatePolicyField(),
      }),
    )
    .optional(),
  jacketEndorsements: yup
    .array()
    .of(
      yup.object({
        endorsementName: yup.string().test('Endorsement Name Validation', '', function (value) {
          if (!value) {
            return this.createError({
              message: requiredFieldMessage('Endorsement Name'),
              path: 'endorsementName' + this.parent.randomId, // Fieldname
            });
          } else return true;
        }),
      }),
    )
    .optional(),
});
export const StandaloneSchema = yup.array().of(
  yup.object({
    endorsementName: yup.string().test('Endorsement Name Validation', '', function (value) {
      if (!value) {
        return this.createError({
          message: requiredFieldMessage('Endorsement Name'),
          path: 'endorsementName' + this.parent.randomId, // Fieldname
        });
      } else return true;
    }),
  }),
);

export const CalcCriteriaStandaloneSchema = yup.object().shape({
  endorsements: yup
    .array()
    .of(
      yup.object({
        endorsementName: yup.string().nullable().validateCalcCriteria('endorsementName'),
      }),
    )
    .optional(),
  questions: yup
    .array()
    .of(
      yup.object({
        value: yup.string().nullable().validateCalcCriteria('question'),
      }),
    )
    .optional(),
});

export const CalcCriteriaPolicySchema = yup.object().shape({
  policyType: yup.string().required(requiredFieldMessage('PolicyType')),
  policyStatus: yup.string(),
  fields: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        value: yup.string().nullable().validateCalcCriteria('parentName', true),
      }),
    )
    .optional(),
  endorsements: yup
    .array()
    .of(
      yup.object({
        endorsementName: yup.string().nullable().validateCalcCriteria('endorsementName'),
      }),
    )
    .optional(),
  questions: yup
    .array()
    .of(
      yup.object({
        value: yup.string().nullable().validateCalcCriteria('question'),
      }),
    )
    .optional(),
});

export const CPLPolicySchema = yup.object().shape({
  fields: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().validateCalcCriteria('parentName'),
      }),
    )
    .optional(),
  questions: yup
    .array()
    .of(
      yup.object({
        value: yup.string().nullable().validateCalcCriteria('question'),
      }),
    )
    .optional(),
});

export const FileLevelSettings = yup.object().shape({
  BusinessSegment: yup.object().shape({
    value: yup.string().validateCalcCriteria('parentName', true),
  }),
  PolicyComplianceDate: yup.object().shape({
    value: yup.string().nullable().validateCalcCriteria('parentName', true),
  }),
  RateEffectiveDate: yup.object().shape({
    value: yup
      .string()
      .nullable()
      .validateCalcCriteria('parentName', true, () => 'Effective Date Required'),
  }),
});

export const isValidDate = (date: Date | null): boolean | null => {
  return date && isValid(date) && isAfter(date, new Date('01/01/1753')) && isBefore(date, new Date('12/31/2999'));
};
