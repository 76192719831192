import { HELP_ROUTE } from 'features/help/constants';
import { ContentfulErrorContext } from 'features/help/hooks/ContentfulErrorContext';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const NoContent = (): JSX.Element => {
  const history = useHistory();

  const { setContentfulErrorType } = React.useContext(ContentfulErrorContext);

  const handleClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      const path = `${HELP_ROUTE}`;
      history.push(path);
      setContentfulErrorType(null);
      e.preventDefault();
    },
    [history],
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px 0',
      }}
    >
      <h1 style={{ fontWeight: 500, marginBottom: '50px' }}>No Content Found</h1>
      <svg width="396" height="214" viewBox="0 0 396 214" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M101.2 164.3C143.726 164.3 178.2 129.826 178.2 87.2998C178.2 44.7739 143.726 10.2998 101.2 10.2998C58.674 10.2998 24.2 44.7739 24.2 87.2998C24.2 129.826 58.674 164.3 101.2 164.3Z"
          fill="#F1F3F9"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M382.6 171.8H17.1001L74.0001 95.4L98.4001 63L119.7 86.1L123.8 90.5L169.6 27.1L189.2 0L204.5 22.3L256.6 98.2L277.6 74.2L288.3 61.9L297.4 72.8L382.6 171.8Z"
          fill="#F1F3F9"
        />
        <path d="M98.4001 63L97.4001 171.8H17.1001L74.0001 95.4L98.4001 63Z" fill="#E0E4ED" />
        <path d="M189.2 0L185.9 160.4L122.933 91.9396L189.2 0Z" fill="#E0E4ED" />
        <path d="M290.2 156.1L255.571 99.7771L288.3 61.9004L290.2 156.1Z" fill="#E0E4ED" />
        <path
          d="M1.69995 172.8H97.3999H382.6H394.3"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M208.1 187H233.5"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M278.1 187H303.5"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M262.6 187H268"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.8999 187H184.9"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.5 187H67"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M347.8 187H379.8"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 187H38.1"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.7998 199.5H132.3"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M191.7 199.5H262.6"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M299.1 199.5H370"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M162.1 199.5H177.6"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M240.7 211.9H326.5"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M151.5 211.9H208.1"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M115.5 211.9H132.3"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.7998 211.9H89.8998"
          stroke="#D6DCE8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M135.2 146.9C135.2 153.2 130.1 156 123.9 156C117.7 156 112.6 153.2 112.6 146.9C112.6 140.6 123.9 101.7 123.9 101.7C123.9 101.7 135.2 140.7 135.2 146.9Z"
          fill="white"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M123.8 135.9V171.6"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M123.8 142.1L127.9 138.4"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M123.8 150.601L117.9 143.601"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M236.8 145.1C236.8 152.1 231.1 155.2 224.2 155.2C217.2 155.2 211.6 152.1 211.6 145.1C211.6 138.1 224.2 94.7998 224.2 94.7998C224.2 94.7998 236.8 138.2 236.8 145.1Z"
          fill="white"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M224.2 132.8V172.6"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M224.2 139.8L228.7 135.6"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M224.2 149.299L217.6 141.399"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M264.8 155.8C264.8 160.1 261.3 162 257.1 162C252.8 162 249.4 160.1 249.4 155.8C249.4 151.5 257.1 125 257.1 125C257.1 125 264.8 151.5 264.8 155.8Z"
          fill="white"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M257 148.3V172.6"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M257 152.5L259.8 150"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M257 158.3L253 153.5"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M319.1 151C319.1 156.5 314.7 158.9 309.2 158.9C303.7 158.9 299.3 156.5 299.3 151C299.3 145.5 309.2 111.4 309.2 111.4C309.2 111.4 319.1 145.5 319.1 151Z"
          fill="white"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M309.2 141.301V172.601"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M309.2 146.7L312.7 143.5"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M309.2 154.2L304 148"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.6"
          d="M124.7 91.1L107.5 92.6L99.5 100.8L89.9 99.1L74 109L69 102.5L98.7 63L124.7 91.1Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M212.3 33.6L204.2 31.8L195.9 35.7L189.2 34L182.8 45.4L172.8 40.7L161.6 45.4L159.7 41L189.2 0L212.3 33.6Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M302.7 78.6004L294.3 85.2004L286.5 80.9004L275.5 87.3004L272.4 80.6004L288.7 61.9004L302.7 78.6004Z"
          fill="white"
        />
        <path
          d="M90.7999 33.4001C92.3999 33.0001 93.8999 32.9001 95.4999 32.9001C96.9999 32.9001 98.5999 33.0001 100.1 33.2001C101.6 33.4001 103.2 33.8001 104.7 34.5001C106.2 35.2001 107.7 36.2001 108.6 37.9001L106.9 37.5001L108.7 36.1001C109.4 35.7001 110 35.2001 110.6 34.8001C111.9 34.0001 113.3 33.3001 114.8 32.8001C116.3 32.3001 117.8 31.9001 119.3 31.7001C120.9 31.6001 122.4 31.6001 124 32.0001C124.3 32.1001 124.4 32.4001 124.3 32.6001C124.2 32.9001 124 33.0001 123.7 32.9001H123.6C122.3 32.6001 120.8 32.7001 119.4 32.9001C118 33.1001 116.6 33.6001 115.3 34.2001C114 34.8001 112.8 35.5001 111.6 36.3001C111 36.7001 110.4 37.2001 109.9 37.6001L108.3 39.0001C107.8 39.4001 107.2 39.3001 106.8 38.9001L106.7 39.0001L106.6 38.8001C106.2 37.7001 105.1 36.8001 103.9 36.2001C102.7 35.6001 101.3 35.1001 99.7999 34.8001C98.3999 34.5001 96.8999 34.3001 95.3999 34.2001C93.8999 34.1001 92.3999 34.1001 91.0999 34.4001H90.9999C90.6999 34.5001 90.4999 34.3001 90.3999 34.0001C90.3999 33.7001 90.4999 33.4001 90.7999 33.4001Z"
          fill="#AAB2C5"
        />
        <path
          d="M46.4 60.8005C48 60.4005 49.5 60.3005 51.1 60.3005C52.6 60.3005 54.2 60.4005 55.7 60.6005C57.2 60.8005 58.8 61.2005 60.3 61.9005C61.8 62.6005 63.3 63.6005 64.2 65.3005L62.5 64.9005L64.3 63.5005C65 63.1005 65.6 62.6005 66.2 62.2005C67.5 61.4005 68.9 60.7005 70.4 60.2005C71.9 59.7005 73.4 59.3005 74.9 59.1005C76.5 59.0005 78 59.0005 79.6 59.4005C79.9 59.5005 80 59.8005 79.9 60.0005C79.8 60.3005 79.6 60.4005 79.3 60.3005H79.2C77.9 60.0005 76.4 60.1005 75 60.3005C73.6 60.5005 72.2 61.0005 70.9 61.6005C69.6 62.2005 68.4 62.9005 67.2 63.7005C66.6 64.1005 66 64.6005 65.5 65.0005L63.9 66.4005C63.4 66.8005 62.8 66.7005 62.4 66.3005L62.3 66.2005L62.2 66.0005C61.8 64.9005 60.7 64.0005 59.5 63.4005C58.3 62.8005 56.9 62.3005 55.4 62.0005C54 61.7005 52.5 61.5005 51 61.4005C49.5 61.3005 48 61.3005 46.7 61.6005H46.6C46.3 61.7005 46.1 61.5005 46 61.2005C46 61.1005 46.2 60.8005 46.4 60.8005Z"
          fill="#AAB2C5"
        />
        <path
          d="M101.6 56.9001C103.2 56.5001 104.7 56.4001 106.3 56.4001C107.8 56.4001 109.4 56.5001 110.9 56.7001C112.4 56.9001 114 57.3001 115.5 58.0001C117 58.7001 118.5 59.7001 119.4 61.4001L117.7 61.0001L119.5 59.6001C120.2 59.2001 120.8 58.7001 121.4 58.3001C122.7 57.5001 124.1 56.8001 125.6 56.3001C127.1 55.8001 128.6 55.4001 130.1 55.2001C131.7 55.1001 133.2 55.1001 134.8 55.5001C135.1 55.6001 135.2 55.9001 135.1 56.1001C135 56.4001 134.8 56.5001 134.5 56.4001H134.4C133.1 56.1001 131.6 56.2001 130.2 56.4001C128.8 56.6001 127.4 57.1001 126.1 57.7001C124.8 58.3001 123.6 59.0001 122.4 59.8001C121.8 60.2001 121.2 60.7001 120.7 61.1001L119.1 62.5001C118.6 62.9001 118 62.8001 117.6 62.4001L117.5 62.3001L117.4 62.1001C117 61.0001 115.9 60.1001 114.7 59.5001C113.5 58.9001 112.1 58.4001 110.6 58.1001C109.2 57.8001 107.7 57.6001 106.2 57.5001C104.7 57.4001 103.2 57.4001 101.9 57.7001H101.8C101.5 57.8001 101.3 57.6001 101.2 57.3001C101.2 57.2001 101.3 56.9001 101.6 56.9001Z"
          fill="#AAB2C5"
        />
        <path
          d="M382.738 172.887H17.2378L74.1378 96.4869L98.5378 64.0869L119.838 87.1869L121.888 89.3869L123.938 91.5869L169.738 28.1869L189.338 1.08691L204.638 23.3869L256.738 99.2869L277.738 75.2869L288.438 62.9869L297.538 73.8869L382.738 172.887Z"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M171.543 76.3046C171.543 80.6773 168.007 84.2135 163.596 84.2135C163.52 84.2135 162.874 84.2135 158.121 84.2135C154.813 84.2135 149.528 84.2135 141.086 84.2135H137.056C131.77 84.3276 127.55 80.107 127.55 75.0118C127.55 69.8786 131.808 65.6199 137.17 65.8861C141.771 51.5131 162.836 53.5284 164.623 68.3957C168.577 68.89 171.543 72.2361 171.543 76.3046Z"
          fill="white"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M164.509 68.4335C164.205 68.3955 163.9 68.3955 163.596 68.3955C162.037 68.3955 160.554 68.8518 159.3 69.6503"
          fill="white"
        />
        <path
          d="M164.509 68.4335C164.205 68.3955 163.9 68.3955 163.596 68.3955C162.037 68.3955 160.554 68.8518 159.3 69.6503"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M137.17 65.8857C136.713 67.2546 136.485 68.6995 136.485 70.2204C136.485 70.8288 136.523 71.3992 136.599 71.9695"
          fill="white"
        />
        <path
          d="M137.17 65.8857C136.713 67.2546 136.485 68.6995 136.485 70.2204C136.485 70.8288 136.523 71.3992 136.599 71.9695"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M262.6 57.7701C262.6 63.48 257.982 68.0976 252.223 68.0976C252.124 68.0976 251.28 68.0976 245.073 68.0976C240.753 68.0976 233.852 68.0976 222.829 68.0976H217.566C210.665 68.2465 205.153 62.7352 205.153 56.0819C205.153 49.379 210.714 43.818 217.715 44.1656C223.723 25.3973 251.23 28.0289 253.564 47.4426C258.727 48.088 262.6 52.4574 262.6 57.7701Z"
          fill="white"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M253.415 47.492C253.017 47.4424 252.62 47.4424 252.223 47.4424C250.187 47.4424 248.251 48.0382 246.612 49.0809"
          fill="white"
        />
        <path
          d="M253.415 47.492C253.017 47.4424 252.62 47.4424 252.223 47.4424C250.187 47.4424 248.251 48.0382 246.612 49.0809"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M217.715 44.166C217.119 45.9535 216.821 47.8402 216.821 49.8263C216.821 50.6207 216.871 51.3655 216.97 52.1103"
          fill="white"
        />
        <path
          d="M217.715 44.166C217.119 45.9535 216.821 47.8402 216.821 49.8263C216.821 50.6207 216.871 51.3655 216.97 52.1103"
          stroke="#D6DCE8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <p style={{ fontSize: '14px', marginBottom: 0, marginTop: '50px' }}>
        We dont have any related content to display at this time.
      </p>
      <p style={{ fontSize: '14px', marginTop: '10px' }}>
        Go back, or head over to{' '}
        <a className="underline" style={{ color: '#0074CA', textDecoration: 'underline' }} onClick={handleClick}>
          the home page
        </a>{' '}
        to choose a new direction.
      </p>
    </div>
  );
};
