import React, { ReactNode, useEffect, useState } from 'react';
import { Container, createStyles, makeStyles, Theme } from '@agentnet/components';
import { LeftHandNav } from '../LeftHandNav';
import { TopicPageHeader } from '../Header/TopicPageHeader';
import { useTopicsWithArticlesQuery } from '../../../queries/useTopicsWithArticlesQuery';
import { TopicCollection } from 'features/help/types';
import { HelpHeader } from '../Header/HelpHeader';
import { useLocation } from 'react-router-dom';
import { HelpFooter } from '../Footer/HelpFooter';
import { RenderStyles } from '../RenderStyles';
import { ContentfulErrorContext, ContentfulErrorTypes } from 'features/help/hooks/ContentfulErrorContext';
import { Error } from '../Error/Error';
import { NoContent } from '../Error/NoContent';
import { HELP_ROUTE } from 'features/help/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      minHeight: '100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
    },
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    pageContentArea: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    navAndContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
    },
    leftHandNavContainer: {
      display: 'flex',
      minWidth: theme.spacing(35),
      flexDirection: 'column',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
      borderRight: '1px solid var(--primary-border, #D9E0E5)',
      paddingTop: theme.spacing(4),
      marginRight: theme.spacing(4),
      paddingRight: theme.spacing(4),
      height: 'calc(100vh - 50px)',
      maxWidth: theme.spacing(35),
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
      },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
      },
    },
    bodyContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(4),
    },
  }),
);

export default function HelpLayout({ children }: { children: ReactNode }) {
  const classes = useStyles();
  const [topics, setTopics] = useState<TopicCollection | null>();

  const location = useLocation();
  const [path, setPath] = useState<string>(location.pathname.replaceAll('/', ''));

  const { contentfulErrorType, setContentfulErrorType } = React.useContext(ContentfulErrorContext);

  const helpHomePage = `${HELP_ROUTE.replaceAll('/', '')}`;

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await useTopicsWithArticlesQuery();

      setTopics(data);

      if (error) {
        setContentfulErrorType(ContentfulErrorTypes.Error);
      }

      if (!data) {
        setContentfulErrorType(ContentfulErrorTypes.NoContent);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setPath(location.pathname.replaceAll('/', ''));
  }, [location]);

  return (
    <>
      <RenderStyles />
      <div className={classes.page}>
        {path === helpHomePage ? <HelpHeader /> : <TopicPageHeader />}
        <div className={classes.pageContentArea}>
          {contentfulErrorType ? (
            <>
              {contentfulErrorType === ContentfulErrorTypes.Error && <Error />}
              {contentfulErrorType === ContentfulErrorTypes.NoContent && <NoContent />}
            </>
          ) : (
            <>
              {path === helpHomePage ? (
                children
              ) : (
                <Container className={classes.container} maxWidth={'lg'}>
                  <>
                    <div className={classes.navAndContent}>
                      {topics && (
                        <div className={classes.leftHandNavContainer}>
                          <LeftHandNav topics={topics} />
                        </div>
                      )}
                      <div className={classes.bodyContent}>{children}</div>
                    </div>
                  </>
                </Container>
              )}
            </>
          )}
        </div>
        <HelpFooter />
      </div>
    </>
  );
}
