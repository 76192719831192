import { NavLinkType, links as navLinks, adminLinks as adminNavLinks } from 'core/navbar/NavLinks';
import React, { createContext, useContext, useState, ReactNode } from 'react';
interface ViewStateContextValue {
  viewState: string;
  setViewState: (viewState: string) => void;
  menuCollapsed: boolean;
  setMenuCollapse: (menuCollapsed: boolean) => void;
  navState: NavLinkType[];
  setNavState: (navlist: NavLinkType[]) => void;
  adminNavState: NavLinkType[];
  setAdminNavState: (navlist: NavLinkType[]) => void;
}

const defaultSetViewState: ViewStateContextValue['setViewState'] = () => {
  //this will be replaced by the provider's setState function
};

const defaultMenuCollapse: ViewStateContextValue['setMenuCollapse'] = () => {
  //this will be replaced by the provider's setState function
};

const defaultSetNavState: ViewStateContextValue['setNavState'] = () => {
  //this will be replaced by the provider's setState function
};

const defaultSetAdminNavState: ViewStateContextValue['setAdminNavState'] = () => {
  //this will be replaced by the provider's setState function
};

const ViewStateContext = createContext<ViewStateContextValue>({
  viewState: 'portal',
  setViewState: defaultSetViewState,
  menuCollapsed: true,
  setMenuCollapse: defaultMenuCollapse,
  navState: navLinks,
  setNavState: defaultSetNavState,
  adminNavState: adminNavLinks,
  setAdminNavState: defaultSetAdminNavState,
});

export const useViewState = () => useContext(ViewStateContext);

interface ViewStateProviderProps {
  children: ReactNode;
}

export const ViewStateProvider: React.FC<ViewStateProviderProps> = ({ children }) => {
  const isWindowMd = window.innerWidth < 1280;
  const [viewState, setViewState] = useState<string>('portal');
  const [menuCollapsed, setMenuCollapse] = useState<boolean>(isWindowMd);
  const [navState, setNavState] = useState(navLinks);
  const [adminNavState, setAdminNavState] = useState(adminNavLinks);

  return (
    <ViewStateContext.Provider
      value={{
        viewState,
        setViewState,
        menuCollapsed,
        setMenuCollapse,
        navState,
        setNavState,
        adminNavState,
        setAdminNavState,
      }}
    >
      {children}
    </ViewStateContext.Provider>
  );
};
