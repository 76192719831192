import React from 'react';

interface CalculatorPricingProps {
  isValid: boolean;
}

function CalculatorPricing({ isValid }: CalculatorPricingProps) {
  return (
    <>
      {!isValid ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="51" fill="none">
          <path
            fill="#265E92"
            d="M27.071 15.5H12.93c-1.03 0-1.93.875-1.93 1.875v16.25c0 1 .9 1.875 1.929 1.875H27.07c1.03 0 1.93-.875 1.93-1.875v-16.25c0-1-.9-1.875-1.929-1.875zm-10.928 17c0 .25-.257.5-.514.5h-1.543c-.257 0-.515-.25-.515-.5V31c0-.25.258-.5.515-.5h1.543c.257 0 .514.25.514.5v1.5zm0-5c0 .25-.257.5-.514.5h-1.543c-.257 0-.515-.25-.515-.5V26c0-.25.258-.5.515-.5h1.543c.257 0 .514.25.514.5v1.5zm5.143 5c0 .25-.257.5-.515.5H19.23c-.258 0-.515-.25-.515-.5V31c0-.25.257-.5.515-.5h1.542c.258 0 .515.25.515.5v1.5zm0-5c0 .25-.257.5-.515.5H19.23c-.258 0-.515-.25-.515-.5V26c0-.25.257-.5.515-.5h1.542c.258 0 .515.25.515.5v1.5zm5.143 5c0 .25-.258.5-.515.5h-1.543c-.257 0-.514-.25-.514-.5V26c0-.25.257-.5.514-.5h1.543c.257 0 .515.25.515.5v6.5zm0-10c0 .25-.258.5-.515.5H14.086c-.257 0-.515-.25-.515-.5v-4c0-.25.258-.5.515-.5h11.828c.257 0 .515.25.515.5v4z"
          ></path>
          <path
            fill="#FF9800"
            d="M39.333 23.5h1.334v1.333h-1.334V23.5zm0-5.334h1.334v4h-1.334v-4zm.66-3.333a6.663 6.663 0 00-6.66 6.667c0 3.68 2.98 6.666 6.66 6.666a6.67 6.67 0 006.674-6.666 6.67 6.67 0 00-6.674-6.667zm.007 12a5.332 5.332 0 01-5.333-5.333A5.332 5.332 0 0140 16.166a5.332 5.332 0 015.333 5.334A5.332 5.332 0 0140 26.833z"
          ></path>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="51" fill="none">
          <path
            fill="#265E92"
            d="M27.071 15.5H12.93c-1.03 0-1.93.875-1.93 1.875v16.25c0 1 .9 1.875 1.929 1.875H27.07c1.03 0 1.93-.875 1.93-1.875v-16.25c0-1-.9-1.875-1.929-1.875zm-10.928 17c0 .25-.257.5-.514.5h-1.543c-.257 0-.515-.25-.515-.5V31c0-.25.258-.5.515-.5h1.543c.257 0 .514.25.514.5v1.5zm0-5c0 .25-.257.5-.514.5h-1.543c-.257 0-.515-.25-.515-.5V26c0-.25.258-.5.515-.5h1.543c.257 0 .514.25.514.5v1.5zm5.143 5c0 .25-.257.5-.515.5H19.23c-.258 0-.515-.25-.515-.5V31c0-.25.257-.5.515-.5h1.542c.258 0 .515.25.515.5v1.5zm0-5c0 .25-.257.5-.515.5H19.23c-.258 0-.515-.25-.515-.5V26c0-.25.257-.5.515-.5h1.542c.258 0 .515.25.515.5v1.5zm5.143 5c0 .25-.258.5-.515.5h-1.543c-.257 0-.514-.25-.514-.5V26c0-.25.257-.5.514-.5h1.543c.257 0 .515.25.515.5v6.5zm0-10c0 .25-.258.5-.515.5H14.086c-.257 0-.515-.25-.515-.5v-4c0-.25.258-.5.515-.5h11.828c.257 0 .515.25.515.5v4z"
          ></path>
          <path
            fill="#3B873E"
            d="M43.06 18.553l-4.393 4.393-2.394-2.386-.94.94 3.334 3.333L44 19.5l-.94-.947zM40 14.833a6.67 6.67 0 00-6.666 6.667A6.67 6.67 0 0040 28.166a6.669 6.669 0 006.667-6.666A6.67 6.67 0 0040 14.833zm0 12a5.332 5.332 0 01-5.333-5.333A5.332 5.332 0 0140 16.166a5.332 5.332 0 015.334 5.334A5.332 5.332 0 0140 26.833z"
          ></path>
        </svg>
      )}
    </>
  );
}

export default CalculatorPricing;
