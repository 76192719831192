import React, { useState, useEffect, useContext, useCallback, useRef, useMemo } from 'react';
import useAsync from '../../../hooks/useAsync';
import { deletePropertyFromList } from '../../../api/file/file-property-api';
import { DividerType } from '../../../ui-kit/components/dividers';
import { AgentNetDivider as NewAgentNetDivider } from '../../../ui-kit/components/dividers/AgentNetDivider2';
import { Parties } from '../parties/parties';
import { fileInfoType, propertyType } from '../../../api/file/interfaces/get-file';
import { AgentNetConfirmationDialog } from '../../../ui-kit/components/modal/ConfirmationDialog';
import { createStyles } from '@material-ui/core/styles';
import { FileDataContext, FileDataContextInterface } from '../../../hooks/FileDataContext';
import { Grid } from '@material-ui/core';
import { useAuth } from '@agentnet/auth';
import { fileInfoEditFormSchema } from 'utilities/validation/schemas';
import PropertyGroupWithAgentNetBase from 'features/files/file-property/PropertyGroupWithBaseFormGroup';
import { useHistory, useParams } from 'react-router-dom';
import { ICreateFileResponse } from 'api/file/interfaces/create-file';
import { saveFile } from 'api/file/file-api';
import './FileInformation.scss';
import FileInfoEditFormWithBaseFormGroup from './form/FileInfoEditFormWithBaseFormGroup';
import * as _ from 'lodash';
import { getFileInfoProps } from 'api/file/file-api';
import {
  SdnPdfWindowName,
  WindowFeatures,
  DocumentType,
  DateFormat,
  OFACPdfWindowName,
  newWindow,
} from 'features/constants';
import moment from 'moment';
import ofacPdf from '../../../static/NA_Standard_OFAC_Underwriting_Communication_Agents.pdf';
import { removeEmpty, isObjectEmpty, openDocument } from 'utilities/utilities';
import { makeStyles, Container } from '@material-ui/core';
import { getFileDocument } from 'api/sdn/sdn-api';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { ILenderFirm } from '../parties/interfaces/LenderModels';
import { getLenderListFromFirmID } from 'api/file/file-party-api';
import { getStateOptions, sortLenders } from 'api/profile/profile-utilities';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import { IGetBuyerSellerResponse } from '../parties/interfaces/CreateBuyerSellerRequest';
import { IGetLenderResponse } from '../parties/interfaces/CreateLenderRequest';
import sdnPolling from 'hooks/sdnPolling';
import { MAX_BUYERS, MAX_LENDERS, MAX_SELLERS } from 'api/file/constants';
import { PrismGenerateReport } from 'api/prismPropertyProfile/types';
import { generateReport } from 'api/prismPropertyProfile/prismProperty-profile-api';
import { isPropertyReportExceptionState } from '../file-utilities';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formContainer: {
      [theme.breakpoints.up('sm')]: {
        minWidth: 200,
      },
    },
    addPropertyBtn: {
      display: 'flex',
      margin: '2.4rem 0',
    },
    sdnResultsLoadingContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    sdnResults: {
      border: 'none',
      background: 'transparent',
      fontFamily: 'Inter',
      padding: '0',
      fontSize: '1.4rem',
      lineHeight: '2rem',
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
    pdfIcon: {
      padding: 0,
      marginLeft: theme.spacing(1),
    },
  }),
);
type FileInformationProps = {
  isPropertyAdded?: any;
  isAdmin?: boolean;
  getFileApiStatus?: 'idle' | 'pending' | 'success' | 'error';
  getFileError: string[];
};

const FileInformation = ({
  isPropertyAdded,
  isAdmin,
  getFileApiStatus,
  getFileError,
}: FileInformationProps): JSX.Element => {
  const { fileId: currentFileId } = useParams<getFileInfoProps>();
  const openSdnPDF = async (fileId: string, accountNumber: number | undefined) => {
    setSearchDocument(true);
    const token = await getAccessToken();
    const pdf = await getFileDocument(fileId as string, accountNumber as number, token);
    openDocument(pdf, DocumentType, SdnPdfWindowName, WindowFeatures);
    setSearchDocument(false);
  };
  const [isGetDocument, setSearchDocument] = useState(false);

  const [isPending, setPendingFlag] = useState(false);
  const [showGenerateReportNotification, setShowGenerateReportNotification] = useState<boolean>(false);
  const [generateReportError, setGenerateReportError] = useState<string[]>([]);
  const [showGenerateReportFailed, setShowGenerateReportFailed] = useState<boolean>(false);

  const formatDate = (date: string) => {
    return moment(date).format(DateFormat);
  };
  const openOfacPDF = () => {
    window.open(ofacPdf, OFACPdfWindowName, WindowFeatures);
  };

  const { getAccessToken, signOut } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();
  const { addSnackbarMessage } = useSnackBars();

  const isDisabledPdfIcon = () => {
    return !fileData?.sdnSearchDate;
  };

  const timer = useRef<number | null>(null);
  const classes = useStyles();
  const [currentPropertyId, setCurrentPropertyId] = useState<string>('');
  const [currentPropertyIndex, setCurrentPropertyIndex] = useState<number>(-1);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [showKYWarning, setShowKYWarning] = useState(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [isRootFormValid, setIsRootFormValid] = useState<boolean>(true);
  const [subformGroupValidityMap, setSubformGroupValidityMap] = useState<Record<string, boolean>>({});
  const [isFileUpdated, setFileUpdated] = useState<boolean>(false);
  const [isPartiesUpdated, setPartiesUpdated] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [showDeletePropertyNotification, setShowDeletePropertyNotification] = useState<boolean>(false);
  const [fileChanged, setFileChanged] = useState<boolean>(false);

  const [userHasInteracted, setUserHasInteracted] = useState<boolean>(false);
  const [nextLocation, setNextLocation] = useState<string>();
  const [submitAttempted, setSubmitAttempted] = useState<boolean>();
  const [showSaveFailedNotification, setShowSaveFailedNotification] = useState<boolean>();
  const [showSaveSuccessNotification, setShowSaveSuccessNotification] = useState<boolean>();
  const [saveFileLoader, setSaveFileLoader] = useState<boolean>(false);
  const [saveConfirmFileLoader, setSaveConfirmFileLoader] = useState<boolean>(false);
  // TODO: We shouldn't have a separate state for this. Consider refactoring.
  const [currentFile, setCurrentFile] = useState<fileInfoType>({} as fileInfoType);
  const [pollingToken, setPollingToken] = useState('');
  const [parties, setParties] = useState<any>({});

  const [propertiesUpdated, setPropertiesUpdated] = useState<boolean>(false);
  const [cancelModel, setCancelModel] = useState<boolean>(false);
  const [isPartyValid, setIsPartyValid] = useState<Record<string, boolean>>({});
  const [isFileReady, setIsFileReady] = useState<boolean>(false);
  const [fileInfoError, setFileInfoError] = useState<string[]>([]);
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const fileSection = useRef(null);
  const propertiesRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);

  const partiesRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);
  const maxPartyErrorRef = useRef(null);

  const {
    fileData,
    setFileData,
    openSaveModal,
    setOpenSaveModal,
    isFormDirty,
    setIsFormDirty,
    logoutAttempted,
    setLogoutAttempted,
    setOpenCreateDrawer,
    isCreateDrawerClicked,
    setIsCreateDrawerClicked,
    setTabValue,
    setResetFileNoDisable,
    setOfficeDisabled,
    setUnderwriterDisabled,
  } = fileDataCtx;
  const history = useHistory();
  const [properties, setProperties] = useState(fileData?.properties ?? []);
  const [openPartiesAccordian, setPartiesAccordian] = useState([]);
  const [savingFailedError, setSavingFailedError] = useState<string[]>([]);
  const disableInputs = () => {
    setResetFileNoDisable && setResetFileNoDisable(true);
    setOfficeDisabled && setOfficeDisabled(true);
    setUnderwriterDisabled && setUnderwriterDisabled(true);
  };
  const fileId = fileData?.fileId ?? '';
  const DEFAULT_TOASTER_TIMEOUT = 6000;

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile, prismUserProfile } = profileCtx;

  const getLenderFirmList = async (): Promise<ILenderFirm[]> => {
    const token = await getAccessToken();
    const firmId = userFirm?.firmId ?? '';
    return await getLenderListFromFirmID(firmId as string, token);
  };
  const [lenderfirms, setLenderFirms] = useState<
    {
      name: string;
      value: string;
      createdDate: number;
    }[]
  >([]);

  const {
    execute: executeGetLenderFirms,
    value: lenderFirmList,
    status: getLenderStatus,
  } = useAsync<ILenderFirm[]>(getLenderFirmList, false);

  useEffect(() => {
    executeGetLenderFirms();
  }, [userFirm?.firmId]);

  const sortedLenderFirms = React.useMemo(() => {
    if (lenderFirmList) {
      return sortLenders([...lenderFirmList]);
    }
    return [];
  }, [lenderFirmList]);

  useEffect(() => {
    setLenderFirms(sortedLenderFirms);
  }, [sortedLenderFirms]);

  const { setStartPolling, setDelay, pollingResult, hasPollingResult } = sdnPolling(pollingToken, fileData, null);
  useEffect(() => {
    if (hasPollingResult) {
      setFileData &&
        setFileData({
          ...fileData,
          buyers: pollingResult?.buyers,
          sellers: pollingResult?.sellers,
          lenders: pollingResult?.lenders,
          sdnSearchDate: pollingResult?.searchDate,
        });
      setFileUpdated(true);
    }
  }, [hasPollingResult]);

  useEffect(() => {
    if (fileData?.properties) {
      const tempPropertyList =
        fileData?.properties.map((property: any) => {
          property.expand = property.expand ?? false;
          return property;
        }) ?? [];
      setProperties(tempPropertyList);
    }
  }, [fileData?.properties]);

  const deleteProperty = async (): Promise<propertyType> => {
    const token = await getAccessToken();
    return await deletePropertyFromList(fileId ?? '', currentPropertyId ?? '', token);
  };

  const evaluateSubformValidity = () =>
    isObjectEmpty(subformGroupValidityMap as Record<string, unknown>) ||
    Object.keys(subformGroupValidityMap).every((subform) => subformGroupValidityMap[subform]);

  const evaluatePartyValidity = () =>
    isObjectEmpty(isPartyValid) || Object.keys(isPartyValid).every((subform: any) => isPartyValid[subform]);

  const isFormValid = evaluateSubformValidity() && isRootFormValid && evaluatePartyValidity();

  const resetValidation = () => {
    setIsPartyValid({});
    setSubformGroupValidityMap({});
    setIsRootFormValid(true);
  };
  const handleOpenAccordian = useCallback((value) => {
    setPartiesAccordian(value);
  }, []);

  const handleScroll = (currentElement: any) => {
    if (currentElement) {
      const headerHeight = 70;
      const elementRect = currentElement.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollDistance = elementRect.top + scrollTop - headerHeight;
      const rootElement = document.getElementById('root');
      if (rootElement) rootElement.scrollBy({ top: scrollDistance, behavior: 'smooth' });
    }
  };

  const handleScrollToNew = (currentElement: any) => {
    if (currentElement) {
      const elementRect = currentElement.getBoundingClientRect();
      const scrollTop = document.documentElement.scrollTop;
      const scrollDistance = window.innerHeight - elementRect.top;
      console.log(scrollDistance, 'scrollDistance');
      const rootElement = document.getElementById('root');
      if (rootElement) rootElement.scrollBy({ top: scrollDistance, behavior: 'smooth' });
    }
  };

  const openErrorAccordians = (partyLimitReached: boolean) => {
    setProperties((prevProperties: propertyType[]) => {
      const tempProperties = prevProperties.map((item: propertyType) => {
        if (subformGroupValidityMap[item.propertyCustomId ?? item.propertyId] === false) {
          return { ...item, expand: true };
        }
        return item;
      });
      return tempProperties;
    });

    const partyValidaty = !evaluatePartyValidity();
    const propertyValidaity = !evaluateSubformValidity();
    if (partyValidaty) {
      handleOpenAccordian(Object.keys(isPartyValid));
    }

    if (!isRootFormValid) {
      // autoscroll fileinfo
      handleScroll(fileSection?.current);
    } else if (propertyValidaity) {
      // autoscroll property
      const getFirstProperty = Number(Object.keys(subformGroupValidityMap)[0]);
      handleScroll(propertiesRefs?.current[getFirstProperty]);
    } else if (!partyLimitReached) {
      // autoscroll party max error
      handleScroll(maxPartyErrorRef.current);
    } else if (partyValidaty) {
      // autoscroll party
      const getFirstParty = Number(Object.keys(isPartyValid)[0]);
      handleScroll(partiesRefs?.current[getFirstParty]);
    }

    const msg: string[] = [];

    if (!isRootFormValid) {
      msg.push('File Info details error');
    }
    if (propertyValidaity) {
      msg.push('Property details error');
    }
    if (partyValidaty || !partyLimitReached) {
      // autoscroll party
      msg.push('Party details error');
    }
    setSavingFailedError([...msg]);
  };

  const [showMaxPartyError, setMaxPartyError] = useState({
    buyers: false,
    sellers: false,
    lenders: false,
  });

  const isMaxParty = () => {
    const isMaxBuyers = parties?.buyers?.length > MAX_BUYERS;
    const isMaxSellers = parties?.sellers?.length > MAX_SELLERS;
    const isMaxLenders = parties?.lenders?.length > MAX_LENDERS;

    setMaxPartyError((prevFileData: any) => ({
      ...prevFileData,
      buyers: isMaxBuyers,
      sellers: isMaxSellers,
      lenders: isMaxLenders,
    }));

    return !(isMaxBuyers || isMaxSellers || isMaxLenders);
  };

  const handleSaveFile = () => {
    if (!submitAttempted) {
      setSubmitAttempted(true);
    }
    const isPartyLimitReached = isMaxParty();
    if (isPartyLimitReached && isFormValid) {
      setSaveFileLoader(true);
      executeSaveFile();
    } else {
      openErrorAccordians(isPartyLimitReached);
      setSaveFileLoader(false);
      showToaster(setShowSaveFailedNotification);
    }
  };

  const updateSdnStatus = async () => {
    const token = await getAccessToken();
    setPollingToken(token);
    setStartPolling(true);
    setDelay(7000);
  };

  const saveFileData = async (): Promise<ICreateFileResponse> => {
    const token = await getAccessToken();
    setPollingToken(token);
    setStartPolling(true);
    setDelay(7000);
    return await saveFile({ ...fileData, ...parties }, fileId as string, token);
  };

  /* Handle PROPERTY API REQUESTS and its values */

  const {
    execute: executeDeleteProperty,
    value: deletedProperty,
    status: deletedPropertyStatus,
    errors: deletedPropertyError,
  } = useAsync<propertyType>(deleteProperty, false);

  /* Handle UPDATE FILE REQUESTS and its values  */
  const {
    execute: executeSaveFile,
    value: editFile,
    status: saveFileStatus,
    errors: saveFileErrors,
  } = useAsync<ICreateFileResponse>(saveFileData, false);
  const showToaster = useCallback(
    (notificationHook: (show: boolean) => void, duration: number = DEFAULT_TOASTER_TIMEOUT) => {
      notificationHook(true);
      changeValueWithDelay(notificationHook, duration, false);
    },
    [],
  );
  const fileDataDefaultValue = useRef<ICreateFileResponse | null>(null);

  const changeValueWithDelay = useCallback(
    (notificationHook: (show: boolean) => void, duration: number = DEFAULT_TOASTER_TIMEOUT, value = false) => {
      setTimeout(() => {
        notificationHook(value);
      }, duration);
    },
    [],
  );

  useEffect(() => {
    if (deletedPropertyStatus === 'error') {
      handleCloseDialog();
    }
  }, [deletedPropertyStatus]);

  const handleFailureSavePrompt = (showSavingFailed: boolean) => {
    setTabValue && setTabValue('0');
    setNextLocation('');
    setSaveConfirmFileLoader(false);
    if (showSavingFailed) showToaster(setShowSaveFailedNotification);
    setLogoutAttempted && setLogoutAttempted(false);
    setIsCreateDrawerClicked && setIsCreateDrawerClicked(false);
    setOpenSaveModal && setOpenSaveModal(false);
  };
  /* Error Handling for file info */

  const showMessage = (errorMessage: string[]) => {
    errorMessage?.map((err) => {
      addGlobalMsg({
        message: err,
        type: 'error',
      });
    });
    handleFailureSavePrompt(false);
    setFileInfoError([]);
    const isDirty =
      getFileApiStatus !== 'pending' && userHasInteracted && (fileChanged || propertiesUpdated || isPartiesUpdated);
    setIsFormDirty && setIsFormDirty(isDirty);
  };

  useEffect(() => {
    if (Array.isArray(saveFileErrors) && saveFileErrors.length > 0 && saveFileStatus === 'error') {
      setFileInfoError([...saveFileErrors]);
    }
  }, [saveFileErrors, saveFileStatus]);

  useEffect(() => {
    if (Array.isArray(getFileError) && getFileError.length > 0 && getFileApiStatus === 'error') {
      setFileInfoError([...getFileError]);
    }
  }, [getFileError, getFileApiStatus]);

  useEffect(() => {
    if (Array.isArray(deletedPropertyError) && deletedPropertyError.length > 0 && deletedPropertyStatus === 'error') {
      setFileInfoError([...deletedPropertyError]);
    }
  }, [deletedPropertyError, deletedPropertyStatus]);

  useEffect(() => {
    if (fileInfoError.length > 0) showMessage(fileInfoError);
  }, [fileInfoError]);

  /*
   Handle save confirmation model by watching for changes in history.
  */
  useEffect(() => {
    if (isFormDirty) {
      history.block((prompt) => {
        if (!prompt.pathname.includes('/files/file-info')) {
          setNextLocation(prompt.pathname);
          if (timer.current === null) {
            setOpenSaveModal && setOpenSaveModal(true);
          } else {
            navigate();
          }
        }
        return 'true';
      });
    } else {
      history.block(() => undefined);
    }

    return () => {
      history.block(() => undefined);
    };
  }, [history, isFormDirty]);

  const checkPropertyUpdated = (propertyData: any, tempProperty: any) => {
    let isPropertySame = true;
    if (propertyData) {
      const defaultProperty = _.cloneDeep(propertyData);
      defaultProperty.forEach((property: any) => {
        delete property.expand;
      });
      const tempPropertyList = _.cloneDeep(tempProperty);

      tempPropertyList.forEach((property: any) => {
        delete property.expand;
      });
      isPropertySame = _.isMatch(defaultProperty, tempPropertyList);
    }
    setPropertiesUpdated(!isPropertySame);
  };

  const handlePropertiesValidity = (valid: any, key: any) => {
    setSubformGroupValidityMap((prev: any) => {
      const tempForm: any = { ...prev, [key]: valid as boolean };
      return Object.fromEntries(Object.entries(tempForm).filter(([k, v]) => !v)) as any;
    });
  };

  useEffect(() => {
    if (deletedProperty) {
      const tempPropertyList = properties.filter((property) => property.propertyId != currentPropertyId);
      const updatedFileInfo: fileInfoType = {
        ...fileData,
        properties: tempPropertyList ?? ([] as propertyType[]),
      } as fileInfoType;
      if (fileDataDefaultValue.current?.properties) {
        fileDataDefaultValue.current.properties = fileDataDefaultValue.current?.properties.filter(
          (property) => property.propertyId != currentPropertyId,
        );
      }
      window.sessionStorage.setItem('properties', JSON.stringify(updatedFileInfo.properties));
      handlePropertiesValidity(true, currentPropertyId);
      checkPropertyUpdated(fileDataDefaultValue.current?.properties, tempPropertyList);
      setFileData && setFileData(updatedFileInfo);
      handleCloseDialog();
      showToaster(setShowDeletePropertyNotification);
    } else if (deletedProperty !== null) {
      setIsSubmitted(false);
    }
  }, [deletedProperty]);

  const isKyTaxCodeNotFound = (currentProperties: any) => {
    let found = false;
    if (currentProperties) {
      currentProperties.forEach((property: any) => {
        if (property?.taxCode === '000' || property?.kyTaxCodeResult?.taxCode === '000') {
          found = true;
        }
      });
    }

    if (found) {
      showToaster(setShowKYWarning);
    }
  };

  useEffect(() => {
    if (isFileReady) {
      isKyTaxCodeNotFound(fileData?.properties);
    }
  }, [isFileReady]);

  useEffect(() => {
    if (fileData && !isObjectEmpty(fileData as Record<string, unknown>)) {
      if (currentFile.fileId !== fileData.fileId) {
        sessionStorage.removeItem('fileId');
        sessionStorage.removeItem('accountNumber');
        setIsFormDirty && setIsFormDirty(false);
        setCurrentFile(_.cloneDeep(fileData));
        setIsFileReady(false);
        return;
      } else if (
        currentFile.fileId &&
        currentFile.fileId === fileData.fileId &&
        !fileDataDefaultValue.current &&
        getFileApiStatus !== 'pending'
      ) {
        fileDataDefaultValue.current = _.cloneDeep(fileData);
        sessionStorage.setItem('fileId', fileData?.fileId ?? '');
        sessionStorage.setItem('accountNumber', (fileData?.accountId ?? 0).toString());
        setIsFileReady(true);
      } else if (
        getFileApiStatus !== 'pending' &&
        fileDataDefaultValue.current?.fileId &&
        fileDataDefaultValue.current?.fileId !== fileData.fileId
      ) {
        fileDataDefaultValue.current = _.cloneDeep(fileData);
        sessionStorage.setItem('fileId', fileData?.fileId ?? '');
        sessionStorage.setItem('accountNumber', (fileData?.accountId ?? 0).toString());
        setIsFileReady(true);
      }
      const isDirty =
        getFileApiStatus !== 'pending' && userHasInteracted && (fileChanged || propertiesUpdated || isPartiesUpdated);
      setIsFormDirty && setIsFormDirty(isDirty);

      if (
        ((fileData?.buyers !== undefined && fileData.buyers.length > 0) ||
          (fileData?.sellers !== undefined && fileData.sellers.length > 0) ||
          (fileData?.lenders !== undefined && fileData.lenders.length > 0)) &&
        (fileData?.sdnSearchDate === undefined || fileData?.sdnSearchDate === null || fileData?.sdnSearchDate === '')
      ) {
        updateSdnStatus();
      }
      return;
    } else {
      setIsFormDirty && setIsFormDirty(false);
      return;
    }
  }, [fileData, currentFile, userHasInteracted, fileChanged, propertiesUpdated, getFileApiStatus, isPartiesUpdated]);

  const checkSSNorTaxId = (item: any, currentParty: any) => {
    const patterns = ['__-_______', '___-__-____'];
    if (item?.entity && Object.keys(item.entity).length > 0) {
      if (patterns.includes(item.entity?.taxId as string) && !currentParty.entity?.taxId) {
        delete item.entity.taxId;
      }
      currentParty;
      if (!currentParty.entity?.entityType && !item.entity?.entityType) {
        delete item.entity.entityType;
      }
      if (!currentParty.entity?.incorporatedState && !item.entity?.incorporatedState) {
        delete item.entity.incorporatedState;
      }
    }

    if (patterns.includes(item.trustEstate?.taxId as string) && !currentParty.trustEstate?.taxId) {
      delete item.trustEstate.taxId;
    }
    if (patterns.includes(item.individual?.ssn as string) && !currentParty.individual?.ssn) {
      delete item.individual.ssn;
    }
    if (item.couple) {
      if (patterns.includes(item.couple?.spouse1?.ssn as string) && !currentParty.couple?.spouse1?.ssn)
        delete item.couple.spouse1.ssn;
      if (patterns.includes(item.couple?.spouse2?.ssn as string) && !currentParty.couple?.spouse1?.ssn)
        delete item.couple.spouse2.ssn;
    }
  };

  const checkPartiesUpdated = React.useCallback((buyer: any, seller: any, lender: any, tempParties: any) => {
    let isPartiesSame = true;

    buyer.forEach((item: IGetBuyerSellerResponse) => {
      item.partyMethod = 'buyer';
      delete item.expand;
    });
    seller.forEach((item: IGetBuyerSellerResponse) => {
      item.partyMethod = 'seller';
      delete item.expand;
    });
    lender.forEach((item: IGetLenderResponse) => {
      item.partyMethod = 'lender';
      delete item.expand;
    });
    const partiesData = [...buyer, ...seller, ...lender];
    if (partiesData) {
      const defaultParty = _.cloneDeep(partiesData);
      const tempPartiesList = _.cloneDeep(tempParties);

      tempPartiesList.forEach((party: any) => {
        // need to check correct party type for all of them
        delete party.expand;
        if (party.partyMethod === 'lender') {
          delete party.partyType;
        } else {
          const currentParty: any[] = defaultParty.filter((item) => item.partyId === party.partyId);
          if (currentParty.length > 0) checkSSNorTaxId(party, currentParty[0]);
          if (party.address) {
            if (
              party.address.isForeignAddress === false &&
              typeof currentParty[0]?.address?.isForeignAddress === 'undefined'
            ) {
              delete party.address.isForeignAddress;
            }
            if (Object.keys(party.address).length === 0) {
              delete party.address;
            }
          }
        }
      });

      const cleanDefaultParty = removeEmpty(defaultParty);
      const cleanTempPartiesList = removeEmpty(tempPartiesList);
      ((cleanTempPartiesList || []) as any[])?.forEach((party: any) => {
        if (party.address && Object.keys(party.address).length === 0) {
          delete party.address;
        }
      });
      isPartiesSame = _.isEqual(cleanDefaultParty, cleanTempPartiesList);
    }
    setPartiesUpdated(!isPartiesSame);
  }, []);

  useEffect(() => {
    if (editFile) {
      fileDataDefaultValue.current = _.cloneDeep(editFile);
      setCurrentFile(_.cloneDeep(editFile) as fileInfoType);
      window.sessionStorage.setItem('properties', JSON.stringify(editFile?.properties ?? []));
      setFileData && setFileData(_.cloneDeep(editFile) as fileInfoType);
      setIsPartyValid({});
      isKyTaxCodeNotFound(editFile.properties);
      showToaster(setShowSaveSuccessNotification);
      setPropertiesUpdated(false);
      setPartiesUpdated(false);
      setFileChanged(false);
      setIsFormDirty && setIsFormDirty(false);
      setSubmitAttempted(false);
      setOpenSaveModal && setOpenSaveModal(false);
      setFileUpdated(true);
      executeGetLenderFirms();
      if (isCreateDrawerClicked) {
        setIsCreateDrawerClicked && setIsCreateDrawerClicked(false);
        setOpenCreateDrawer && setOpenCreateDrawer(true);
      } else if (logoutAttempted) {
        setLogoutAttempted && setLogoutAttempted(false);
        signOut().then();
        sessionStorage.clear();
        localStorage.clear();
      } else if (nextLocation) {
        navigate();
      }
      setFileInfoError([]);
      disableInputs();

      changeValueWithDelay(setSaveFileLoader, 1000, false);
      changeValueWithDelay(setSaveConfirmFileLoader, 1000, false);
    } else if (editFile !== null) {
      setOpenSaveModal && setOpenSaveModal(false);
      setIsFormDirty && setIsFormDirty(true);
      showToaster(setShowSaveFailedNotification);
      disableInputs();
      changeValueWithDelay(setSaveFileLoader, 1000, false);
      changeValueWithDelay(setSaveConfirmFileLoader, 1000, false);
    }
  }, [editFile]);

  useEffect(() => {
    if (saveFileStatus === 'error') {
      disableInputs();
      setSaveFileLoader(false);
      setSaveConfirmFileLoader(false);
      setOpenSaveModal && setOpenSaveModal(false);
    }
  }, [saveFileStatus]);

  const handleSaveConfirm = useCallback(async () => {
    if (!submitAttempted) {
      setSubmitAttempted(true);
    }
    const isPartyLimitReached = isMaxParty();

    if (isPartyLimitReached && isFormValid) {
      setSaveConfirmFileLoader(true);
      setIsFormDirty && setIsFormDirty(false);
      executeSaveFile();
      if (!logoutAttempted && !isCreateDrawerClicked && nextLocation) {
        history.push(nextLocation);
      }
    } else {
      openErrorAccordians(isPartyLimitReached);
      handleFailureSavePrompt(true);
    }
  }, [
    history,
    parties,
    isFormValid,
    isPartyValid,
    isRootFormValid,
    subformGroupValidityMap,
    nextLocation,
    logoutAttempted,
    isCreateDrawerClicked,
    fileData,
  ]);

  const navigate = useCallback(() => {
    setIsFormDirty && setIsFormDirty(false);
    setOpenSaveModal && setOpenSaveModal(false);

    /* Don't need to navigate to the file-info page, we're already there. Otherwise, go. */
    if (!nextLocation?.includes('/files/file-info')) {
      setLogoutAttempted && setLogoutAttempted(false);
      setIsCreateDrawerClicked && setIsCreateDrawerClicked(false);
      /*
       * setTimeout is used here to fix a hard-to-diagnose problem that blocked the navigation from happening.
       * Without it, it wouldn't go, maybe because other stuff on the page was still executing?
       * */
      timer.current = window.setTimeout(() => {
        nextLocation && history.push(nextLocation);
        timer.current = null;
      }, 0);
    }
  }, [history, nextLocation, openSaveModal, isFormDirty]);

  /* Handle Dialog Modal */
  const handleCloseDialog = () => {
    setIsSubmitted(false);
    setOpenDeleteModal(false);
    setCurrentPropertyId('');
    setCurrentPropertyIndex(-1);
  };

  const handleAddNewProperty = () => {
    if (properties.length < 5) {
      const defaultAddress = {
        address1: '',
        city: '',
        county: '',
        postalCode: '',
        propertyType: properties[0]?.propertyType ?? 'Residential (1-4 family)',
        state: fileData?.propertyState,
        expand: true,
        propertyCustomId: Date.now(),
      };
      setPropertiesUpdated(true);
      setSubmitAttempted(false);
      setUserHasInteracted(true);
      setFileData && setFileData({ ...fileData, properties: [...properties, defaultAddress] });
      setProperties([...properties, defaultAddress]);
      handleScrollToNew(fileSection?.current);
    }
  };

  const handleExpand = (isExpand: boolean) => {
    const tempPropertList = properties.map((property: any) => {
      property.expand = isExpand;
      return property;
    });
    setProperties([...tempPropertList]);
  };

  const handlePropertyDelete = (index: number, propertyId: string) => {
    if (propertyId) {
      setCurrentPropertyId(propertyId);
    } else {
      setCurrentPropertyIndex(index);
    }
    setOpenDeleteModal(true);
  };

  const handleGenerateReport = async (fullAddress: string) => {
    if (fullAddress && !isPending) {
      const stateOptions = userFirm?.offices ? getStateOptions(userFirm) : [];
      const names = stateOptions.map((option) => option.name)?.join(',');
      const officeAddress = userFirm?.offices?.find(
        (office) => office.isDefault && office.status === 'Active',
      )?.address;

      if (!prismUserProfile || names?.length == 0) {
        return;
      }

      prismUserProfile.availableLocations = names;
      prismUserProfile.firmName = userFirm?.name ?? '';
      prismUserProfile.officeAddress1 = officeAddress?.address1 ?? '';
      prismUserProfile.officeAddress2 = officeAddress?.address2 ?? '';
      prismUserProfile.officeCity = officeAddress?.city ?? '';
      prismUserProfile.officeState = officeAddress?.state ?? '';
      prismUserProfile.officeZipCode = officeAddress?.postalCode ?? '';

      const generateReportPayload: PrismGenerateReport = {
        propertyReportUser: prismUserProfile,
        fullAddress: fullAddress,
        state: fileData?.propertyState || '',
      };

      setPendingFlag(true);
      const token = await getAccessToken();
      const response = await generateReport(generateReportPayload, token);

      if (response?.error) {
        setGenerateReportError([response.error]);
      }

      setPendingFlag(false);
      if (response?.reportPdfBytes) {
        showToaster(setShowGenerateReportNotification);
        openDocument(response?.reportPdfBytes, DocumentType, newWindow, WindowFeatures);
      }
    }
  };

  useEffect(() => {
    showSaveSuccessNotification &&
      addSnackbarMessage({
        message: `Saving Successful`,
        type: 'success',
      });
  }, [showSaveSuccessNotification]);

  useEffect(() => {
    showKYWarning &&
      addSnackbarMessage({
        message: `Unable to determine match for the Kentucky Premium Tax Code. Please manually update.`,
        type: 'warning',
      });
  }, [showKYWarning]);

  useEffect(() => {
    let list = '';
    savingFailedError.forEach((msg: string) => {
      list += `<li>${msg}</li>`;
    });
    showSaveFailedNotification &&
      addSnackbarMessage({
        html: true,
        message: `<b class="savingFailedErrorHeading">Saving Failed</b>
            ${
              list
                ? `<ul class="savingFailedErrorList">
              ${list}
            </ul>`
                : ''
            }`,
        type: 'error',
        onClose: () => {
          setShowSaveFailedNotification(false);
        },
      });
  }, [showSaveFailedNotification]);

  useEffect(() => {
    showDeletePropertyNotification &&
      addSnackbarMessage({
        message: `Property Deleted Successfully`,
        type: 'success',
        onClose: () => {
          setShowDeletePropertyNotification(false);
        },
      });
  }, [showDeletePropertyNotification]);

  useEffect(() => {
    showGenerateReportNotification &&
      addSnackbarMessage({
        title: `Property Report Generated Successfully`,
        message: `Report available for the next 24 hours`,
        type: 'success',
        onClose: () => {
          setShowGenerateReportNotification(false);
        },
      });
  }, [showGenerateReportNotification]);

  useEffect(() => {
    const errorMessages = generateReportError.join(', ');
    const isGlobalError = errorMessages.toLowerCase().includes('please call');
    if (isGlobalError) {
      showGenerateReportFailed &&
        addGlobalMsg({
          message: `${errorMessages}`,
          type: 'error',
        });
    } else {
      showGenerateReportFailed &&
        addSnackbarMessage({
          title: 'Property Report Not Generated',
          message: `<div class="f-14">${errorMessages}</div>`,
          type: 'error',
          html: true,
          onClose: () => {
            setShowGenerateReportFailed(false);
          },
        });
    }
  }, [showGenerateReportFailed]);

  useEffect(() => {
    if (generateReportError.length > 0) showToaster(setShowGenerateReportFailed);
  }, [generateReportError]);

  const toggleCancelModel = useCallback(() => {
    setCancelModel((prevData) => !prevData);
  }, []);

  const isFileLoaded = useMemo(
    () => !(isFileReady && fileDataDefaultValue?.current?.fileId === currentFileId),
    [isFileReady, fileDataDefaultValue, currentFileId],
  );

  const handlePartyUpdate = useCallback((value) => {
    setPartiesUpdated(value);
  }, []);

  const handleFileUpdate = useCallback((value) => {
    setFileUpdated(value);
  }, []);

  const handleFileInfoError = useCallback((value) => {
    setFileInfoError(value);
  }, []);

  const handlePartyValid = useCallback((value) => {
    setIsPartyValid(value);
  }, []);

  const handleMaxPartyLimit = useCallback((value) => {
    setMaxPartyError(value);
  }, []);
  const handleSubmitted = useCallback((value) => {
    setSubmitAttempted(value);
  }, []);

  const handleParties = useCallback((newValueOrFunction) => {
    setParties((prevValue: any) => {
      if (typeof newValueOrFunction === 'function') {
        return newValueOrFunction(prevValue);
      } else {
        return newValueOrFunction;
      }
    });
  }, []);
  const BottomFooter = React.useMemo(() => {
    return (
      isFormDirty && (
        <BottomAppBar
          primaryActionLabel="Save Changes"
          primaryActionDataQa="FileInfoSave"
          cancelDataQa="FileInfoCancel"
          onPrimaryClick={handleSaveFile}
          disablePrimary={saveFileLoader}
          onCancel={toggleCancelModel}
          primaryButtonLoader={saveFileLoader}
        />
      )
    );
  }, [
    parties,
    isFormDirty,
    isPartyValid,
    saveFileLoader,
    fileData,
    isFormValid,
    isRootFormValid,
    subformGroupValidityMap,
    submitAttempted,
  ]);

  const sdnResultsMessage = (
    <>
      <LoadingSpinner status={isGetDocument ? 'pending' : 'success'} variant="linear" />
      Up to 8 Buyer/Borrowers, 8 Seller/Owners, and 4 Lenders permitted.
    </>
  );

  const PartiesSection = React.useMemo(() => {
    return (
      isFileReady && (
        <Parties
          isFileUpdated={isFileUpdated}
          isAddPartyDisabled={isFileLoaded}
          handleSubmitted={handleSubmitted}
          setIsFormValid={handlePartyValid}
          lenderList={lenderfirms}
          getLenderStatus={getLenderStatus}
          defaultValue={fileDataDefaultValue.current}
          lenderFirmList={lenderFirmList ?? ({} as ILenderFirm[])}
          showAllValidation={submitAttempted}
          setPartiesUpdated={handlePartyUpdate}
          setFileUpdated={handleFileUpdate}
          setFileInfoError={handleFileInfoError}
          checkPartiesUpdated={checkPartiesUpdated}
          handleParties={handleParties}
          showMaxPartyError={showMaxPartyError}
          handleMaxPartyLimit={handleMaxPartyLimit}
          showToaster={showToaster}
          openPartiesAccordian={openPartiesAccordian}
          handleOpenAccordian={handleOpenAccordian}
          maxPartyErrorRef={maxPartyErrorRef}
          partiesRefs={partiesRefs}
          sdnResultsMessage={sdnResultsMessage}
          openSdnPDF={() => openSdnPDF(fileId, fileData?.accountId)}
        />
      )
    );
  }, [
    currentFileId,
    isFileReady,
    isFileUpdated,
    submitAttempted,
    lenderFirmList,
    getLenderStatus,
    fileDataDefaultValue?.current,
    lenderfirms,
    isFileLoaded,
    showMaxPartyError,
    openPartiesAccordian,
  ]);

  const resetFileInfo = useCallback(
    _.debounce(() => {
      resetValidation();
      setPropertiesUpdated(false);
      setPartiesUpdated(false);
      setFileChanged(false);
      setSubmitAttempted(false);
      setFileUpdated(true);
    }, 1000),
    [],
  );

  return (
    <div ref={fileSection} onClick={() => setUserHasInteracted(true)} onKeyUp={() => setUserHasInteracted(true)}>
      <LoadingSpinner status={isPending ? 'pending' : 'success'} variant="linear" />
      <NewAgentNetDivider
        variant={DividerType.header}
        title="File Info"
        typoVariant="h1"
        id={`FileInfo${fileData?.propertyState}`}
      />

      <Container className="file-information-container">
        <FileInfoEditFormWithBaseFormGroup
          showAllValidation={submitAttempted}
          value={fileData ?? ({} as fileInfoType)}
          onChange={(newFileData: fileInfoType) => {
            if (fileDataDefaultValue.current && isFileReady && fileDataDefaultValue.current.fileId === currentFileId) {
              // Remove Unwanted Data
              const tempFileData = _.cloneDeep(newFileData);
              delete tempFileData.properties;
              delete tempFileData.sdnSearchDate;
              delete tempFileData.buyers;
              delete tempFileData.sellers;
              delete tempFileData.lenders;
              delete tempFileData.remainingParties;
              if (!tempFileData.closingDate && !fileDataDefaultValue.current.closingDate) {
                delete tempFileData.closingDate;
              }
              if (!tempFileData.loanAmount && !fileDataDefaultValue.current.loanAmount) {
                delete tempFileData.loanAmount;
              }
              if (!tempFileData.salesPrice && !fileDataDefaultValue.current.salesPrice) {
                delete tempFileData.salesPrice;
              }
              const isFileSame = _.isMatch(fileDataDefaultValue.current, tempFileData);
              setFileChanged(!isFileSame);
              if (newFileData.lenders && newFileData.lenders[0]) {
                newFileData.lenders[0].loanAmount = newFileData?.loanAmount ?? 0;
              }
            } else {
              setFileChanged(false);
            }
            newFileData && setFileData && setFileData({ ...newFileData });
          }}
          schema={fileInfoEditFormSchema}
          setIsFormValid={setIsRootFormValid}
        />
        <NewAgentNetDivider
          variant={DividerType.header}
          title="Properties"
          buttonName="Close All"
          buttonNameQaAttr="FileInfoPropertyCloseAll"
          onClick={() => {
            handleExpand(false);
          }}
          buttonName2="Expand All"
          buttonName2QaAttr="FileInfoPropertyExpandAll"
          onClick2={() => {
            handleExpand(true);
          }}
          primaryButtonName="Add New Property"
          onClickPrimary={handleAddNewProperty}
          primaryButtonQaAttr="FileInfoAddPropertyTop"
          primaryButtonDisable={isFileLoaded || properties.length === 5 || fileData?.fileStatus !== 'Open'}
          disablePaddingX
        />
        {isFileReady &&
          properties.map((property: propertyType, index: number) => {
            return (
              <Grid key={index}>
                <PropertyGroupWithAgentNetBase
                  isFileInformation={true}
                  propertyRefs={propertiesRefs}
                  key={index}
                  properties={properties}
                  defaultProperty={fileDataDefaultValue.current?.properties}
                  handleGenerateReport={
                    isPropertyReportExceptionState(fileData?.propertyState || '') ? undefined : handleGenerateReport
                  }
                  handlePropertyDelete={handlePropertyDelete}
                  setProperties={setProperties}
                  currentIndex={index}
                  value={property}
                  isFileReady={isFileReady}
                  disableState={true}
                  onChange={(newPropertyData: propertyType) => {
                    const tempPropertyList = properties;
                    if (
                      currentFile.fileId === fileData?.fileId &&
                      isFileReady &&
                      fileDataDefaultValue.current?.fileId === currentFileId
                    ) {
                      if (fileDataDefaultValue.current?.properties?.[index]) {
                        if (!fileDataDefaultValue.current?.properties?.[index].apn && !newPropertyData?.apn) {
                          delete newPropertyData.apn;
                        }
                        if (!fileDataDefaultValue.current?.properties?.[index].address2 && !newPropertyData?.address2) {
                          delete newPropertyData.address2;
                        }
                        if (!newPropertyData?.county && !fileDataDefaultValue.current?.properties?.[index].county) {
                          delete newPropertyData.county;
                        }

                        // Remove Unwanted Data
                        const exactTempPropertyList = _.cloneDeep(tempPropertyList[index]);
                        const exactDefaultPropertyList = _.cloneDeep(fileDataDefaultValue.current?.properties?.[index]);
                        const exactNewPropertyList = _.cloneDeep(newPropertyData);
                        delete exactTempPropertyList.expand;
                        delete exactDefaultPropertyList.expand;
                        delete exactNewPropertyList.expand;

                        delete exactTempPropertyList.propertyCustomId;
                        delete exactDefaultPropertyList.propertyCustomId;
                        delete exactNewPropertyList.propertyCustomId;

                        if (exactNewPropertyList?.county && exactDefaultPropertyList.county) {
                          exactNewPropertyList.county = exactNewPropertyList.county.toUpperCase();
                          exactDefaultPropertyList.county = exactDefaultPropertyList.county.toUpperCase();
                        }
                        if (exactTempPropertyList?.county && exactNewPropertyList.county) {
                          exactNewPropertyList.county = exactNewPropertyList.county.toUpperCase();
                          exactTempPropertyList.county = exactTempPropertyList.county.toUpperCase();
                        }

                        const isRerendered = _.isEqual(exactTempPropertyList, exactNewPropertyList);
                        const isPropertySame = _.isMatch(exactDefaultPropertyList, exactNewPropertyList);
                        if (!isPropertySame) {
                          setPropertiesUpdated(getFileApiStatus !== 'pending');
                          setUserHasInteracted(true);
                        } else if (!isRerendered) {
                          setPropertiesUpdated(false);
                          setUserHasInteracted(false);
                        }
                      } else {
                        setPropertiesUpdated(getFileApiStatus !== 'pending');
                        setUserHasInteracted(true);
                      }
                    }
                    tempPropertyList[index] = newPropertyData;
                    fileData && setFileData && setFileData({ ...fileData, properties: tempPropertyList });
                  }}
                  setIsFormValid={(valid: any) => {
                    handlePropertiesValidity(valid, property.propertyId ?? property.propertyCustomId);
                  }}
                  listIndex={index}
                  firstLoad={firstLoad}
                  isCreateProperty={false}
                  showAllValidation={submitAttempted}
                  isFirstPropertyStateOnly={true}
                />
              </Grid>
            );
          })}
        {properties.length > 1 && (
          <AgentNetButton
            color="primary"
            size="medium"
            variant="outlined"
            onClick={handleAddNewProperty}
            data-qa="FileInfoAddPropertyBottom"
            disabled={
              !(isFileReady && fileDataDefaultValue?.current?.fileId === currentFileId) ||
              properties.length === 5 ||
              fileData?.fileStatus !== 'Open'
            }
            className={classes.addPropertyBtn}
          >
            Add New Property
          </AgentNetButton>
        )}

        {PartiesSection}
      </Container>
      {/* Wil need to make this either a ternary or dynamic based on the property delete confirmation or file  save confirmation */}
      <AgentNetConfirmationDialog
        onConfirm={() => {
          if (currentPropertyId) {
            executeDeleteProperty().then(() => setOpenDeleteModal(false));
            setIsSubmitted(true);
          } else {
            handlePropertiesValidity(
              true,
              properties?.filter((propertyValue, propertyIndex) => propertyIndex === currentPropertyIndex)?.[0]
                ?.propertyCustomId,
            );

            const tempProperty =
              properties?.filter((propertyValue, propertyIndex) => propertyIndex !== currentPropertyIndex) ?? [];
            setProperties([...tempProperty]);
            checkPropertyUpdated(fileDataDefaultValue.current?.properties, tempProperty);
            setFileData && setFileData({ ...fileData, properties: [...tempProperty] });
            setOpenDeleteModal(false);
            setCurrentPropertyId('');
            showToaster(setShowDeletePropertyNotification);
          }
          setCurrentPropertyIndex(-1);
        }}
        disabled={isSubmitted}
        open={openDeleteModal}
        disableCrossIconClick={isSubmitted}
        onDismissAction={handleCloseDialog}
        dialogTitle="Delete Property"
        dialogBtnContent="Delete Property"
        qaAttrPrefix="ConfirmationProperty"
        dialogText="Are you sure you want to delete this property? You will not be able to recover it."
      />
      <AgentNetConfirmationDialog
        onConfirm={() => {
          handleSaveConfirm().then();
        }}
        open={openSaveModal || false}
        disabled={saveConfirmFileLoader}
        disableCrossIconClick={saveConfirmFileLoader}
        onDismissAction={() => {
          /* Handle Don't Save */
          setOpenSaveModal && setOpenSaveModal(false);
          if (setFileData && fileDataDefaultValue?.current) {
            // Reset the data to clean
            setFileData(_.cloneDeep(fileDataDefaultValue.current) as fileInfoType);
            // TODO: We shouldn't have a separate state for this. Consider refactoring.
            setCurrentFile(_.cloneDeep(fileDataDefaultValue.current) as fileInfoType);
          }
          setFileInfoError([]);
          if (isCreateDrawerClicked) {
            setOpenCreateDrawer && setOpenCreateDrawer(true);
            setIsFormDirty && setIsFormDirty(false);
            setIsCreateDrawerClicked && setIsCreateDrawerClicked(false);
            resetFileInfo();
            if (fileDataDefaultValue.current && setFileData)
              setFileData(_.cloneDeep(fileDataDefaultValue.current) as fileInfoType);
          } else if (logoutAttempted) {
            setIsFormDirty && setIsFormDirty(false);
            setLogoutAttempted && setLogoutAttempted(false);
            signOut().then();
            sessionStorage.clear();
            localStorage.clear();
          } else {
            navigate();
          }
        }}
        onExitButtonAction={() => {
          setOpenSaveModal && setOpenSaveModal(false);
          setIsFormDirty && setIsFormDirty(true);
          setLogoutAttempted && setLogoutAttempted(false);
          setIsCreateDrawerClicked && setIsCreateDrawerClicked(false);
          setTabValue && setTabValue('0');
        }}
        dialogTitle="Save Confirmation"
        dialogBtnContent="Save"
        secondaryActionBtnContent="Don't Save"
        dialogText="You have made changes to information on this page. Do you want to save the changes before you proceed?"
        disableBackDropClick
        qaAttrPrefix="ConfirmationSave"
      />

      <AgentNetConfirmationDialog
        onConfirm={() => {
          if (setFileData && fileDataDefaultValue?.current) {
            resetValidation();
            // Reset the data to clean
            setFileData(_.cloneDeep(fileDataDefaultValue.current) as fileInfoType);
            setCurrentFile(_.cloneDeep(fileDataDefaultValue.current) as fileInfoType);
            setPropertiesUpdated(false);
            setPartiesUpdated(false);
            setFileChanged(false);
            setFileUpdated(true);
            setSubmitAttempted(false);
            disableInputs();
          }
          toggleCancelModel();
        }}
        open={cancelModel || false}
        onDismissAction={toggleCancelModel}
        onExitButtonAction={toggleCancelModel}
        dialogTitle="Warning!"
        dialogBtnContent="Continue"
        secondaryActionBtnContent="Cancel"
        dialogText="Continue will revert any unsaved changes"
        disableBackDropClick
        qaAttrPrefix="Confirmation"
      />
      <div className={classes.sdnResultsLoadingContainer}>
        <>
          <LoadingSpinner status={isGetDocument ? 'pending' : 'success'} variant="linear" />
        </>
      </div>
      {BottomFooter}
    </div>
  );
};

export default FileInformation;
