import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone, FileWithPath, FileRejection, ErrorCode } from 'react-dropzone';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, CircularProgress, Grid, IconButton, Typography, Tooltip } from '@material-ui/core';
import { CheckCircle, Close, Error, CloudUploadOutlined } from '@material-ui/icons';

interface UploaderProps {
  listTitle?: string;
  onDeleteItem?: (file?: any) => void;
  files?: (FileWithPath | FileRejection)[];
  handleFiles?: (files: FileWithPath[]) => void;
  hideInfo?: boolean;
  acceptTypes?: Record<string, string[]> | undefined;
  disabled?: boolean;
  drawerMode?: boolean;
  /**
   * @property {number} maxSize in MegaBytes.
   * Defaults to 70 MB
   */
  maxSize?: number;
  clearAll?: boolean;
  successUploads?: string[];
  errorUploads?: string[];
  isServerUpload?: boolean;
  allFiles?: any[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      background: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
    },
    fileContainer: {},
    mr2: {
      marginRight: theme.spacing(2),
    },
    file: {
      border: `1px solid #EEE`,
      borderRadius: '6px',
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    fileError: {
      borderColor: theme.palette.error.main,
    },
    fileInfo: {
      marginLeft: theme.spacing(2),
      maxWidth: '77%',
    },
    fileDelete: {
      marginLeft: 'auto',
      padding: '5px',
    },
    fileName: {
      marginBottom: '3px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
    toolTip: {
      '&:hover': {
        cursor: 'default',
      },
    },
    dropzone: {
      border: '1px dashed #c4c4c4',
      borderRadius: '4px',
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      transition: '.3s all',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
    disabledDropzone: {
      border: '1px dashed #c4c4c4',
      borderRadius: '4px',
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '.3s all',
    },
    dragMessage: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    mb0: {
      marginBottom: 0,
    },
    blueLink: {
      color: theme.palette.primary.main,
      padding: '0 4px',
    },
    blackLink: {
      color: theme.palette.common.black,
      padding: '0 4px',
    },
  }),
);

const Uploader = ({
  listTitle,
  onDeleteItem,
  files,
  handleFiles,
  hideInfo,
  acceptTypes = {},
  disabled,
  drawerMode,
  /**
   * @property {number} maxSize in MegaBytes
   */
  maxSize,
  clearAll,
  successUploads,
  errorUploads,
  isServerUpload,
  allFiles,
}: UploaderProps): JSX.Element => {
  const classes = useStyles();

  const [myFiles, setMyFiles] = useState<(FileWithPath | FileRejection)[]>(files && files.length ? files : []);

  const uploadedFiles = myFiles.flatMap((file) => {
    if ('file' in file) {
      if (allFiles && !allFiles.some((existingFile) => existingFile.name === file.file.name)) {
        allFiles.push(file.file);
      }
      return [file.file];
    }
    if (allFiles && !allFiles.some((existingFile) => existingFile.name === file.name)) {
      allFiles.push(file);
    }
    return [file];
  });

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      setMyFiles([...myFiles, ...acceptedFiles, ...fileRejections]);
      handleFiles && handleFiles(acceptedFiles);
    },
    [myFiles],
  );
  const acceptedTypes = acceptTypes
    ? acceptTypes
    : {
        'application/pdf': ['.pdf'],
        'image/tiff': ['.tif', '.tiff'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/rtf': ['.rtf'],
      };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedTypes,
    disabled: disabled,
    maxSize: (maxSize ?? 70) * 1024 * 1024,
  });

  const removeFile = (file: any) => () => {
    onDeleteItem && onDeleteItem(file);
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAllFiles = () => {
    setMyFiles([]);
  };

  useEffect(() => {
    console.log(files, 'files');
    uploadedFiles;
  }, [files]);

  useEffect(() => {
    if (clearAll == true) {
      removeAllFiles();
    }
  }, [clearAll]);

  const QueuedFiles = myFiles.map((file: any, i) => {
    const hasError = file.file ? true : false;
    const isLoading = isServerUpload;
    const allFiles = successUploads?.flat();
    const isUploaded = allFiles?.some((e) => e == file.name);
    const errorUpload = errorUploads
      ?.flat()
      .flatMap((e) => e)
      .some((e) => e == file.name);
    return (
      <Grid item key={i} sm={drawerMode ? 6 : 4}>
        <div className={clsx(classes.file, { [classes.fileError]: hasError })}>
          {!isUploaded && isLoading && !(errorUpload || hasError) ? (
            <CircularProgress size={20} className="MuiAgentNetButton-Spinner" />
          ) : hasError || errorUpload ? (
            <Error color="error" fontSize="large" />
          ) : (
            <CheckCircle color="secondary" fontSize="large" />
          )}
          <div className={classes.fileInfo}>
            <Tooltip title={hasError ? file.file.name : file.path} className={clsx(classes.toolTip)}>
              <Typography variant="body1" className={classes.fileName}>
                {hasError ? file.file.name : file.path}
              </Typography>
            </Tooltip>
            <div>
              {hasError ? (
                file.errors.map((e: any, i: any) => (
                  <Typography variant="caption" key={i} color={'error'}>
                    {e.code === ErrorCode.FileInvalidType
                      ? 'Invalid File Type'
                      : e.code === ErrorCode.FileTooLarge
                      ? 'File exceeds size limit'
                      : 'Error'}
                  </Typography>
                ))
              ) : (
                <Typography variant="caption" color={'textSecondary'}>
                  {(file.size * 0.000001).toFixed(2)} MB
                </Typography>
              )}
            </div>
            <Typography variant="caption" color={hasError ? 'error' : 'textSecondary'}></Typography>
          </div>
          <IconButton
            className={classes.fileDelete}
            disableFocusRipple
            disableRipple
            focusRipple={false}
            disableTouchRipple
            disabled={isUploaded == undefined ? false : !isUploaded && !(errorUpload || hasError) ? !isUploaded : false}
            onClick={removeFile(file)}
          >
            <Close fontSize="large" />
          </IconButton>
        </div>
      </Grid>
    );
  });

  return (
    <Grid className={classes.root} container>
      <div {...getRootProps({ className: !disabled ? classes.dropzone : classes.disabledDropzone })}>
        <input {...getInputProps()} />
        <Typography
          variant="body2"
          color="textPrimary"
          className={clsx(classes.dragMessage, { [classes.mb0]: hideInfo })}
        >
          <CloudUploadOutlined fontSize="large" htmlColor="#666666" className={classes.mr2} />
          Drag &amp; Drop or <span className={!disabled ? classes.blueLink : classes.blackLink}>Click to Upload</span> a
          File
        </Typography>
        {!hideInfo && (
          <>
            <Typography variant="caption" color="textSecondary">
              Supported Doc Types: {Object.values(acceptTypes).flat().join(', ').toLocaleLowerCase()}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Maximum upload file size: {maxSize} MB
            </Typography>
          </>
        )}
      </div>
      {QueuedFiles.length > 0 && (
        <>
          <Box my={3}>
            <Typography variant="h3">{listTitle ? listTitle : 'Files'}</Typography>
          </Box>
          <Grid container className={classes.fileContainer} spacing={2}>
            {QueuedFiles}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Uploader;
