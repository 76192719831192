import React, { useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { ChevronRightOutlined } from '@material-ui/icons';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { useViewState } from 'hooks/ViewStateContext';

interface BreadCrumbProps {
  children?: React.ReactNode;
  crumbs?: string[];
  categoryName?: string;
  buttonName?: string;
  serviceQAAttribute?: string;
  disableCrumbs?: boolean;
}

const BreadCrumbComponent = ({
  children,
  crumbs,
  categoryName,
  buttonName,
  serviceQAAttribute,
  disableCrumbs,
}: BreadCrumbProps): JSX.Element => {
  const { menuCollapsed } = useViewState();
  const [drawerWidth, setDrawerWidth] = React.useState(menuCollapsed ? 72 : 252);
  React.useEffect(() => {
    setDrawerWidth(menuCollapsed ? 72 : 252);
  }, [menuCollapsed]);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      breadCrumbWrap: {
        backgroundColor: theme.palette.background.default,
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #C4C4C4',
        position: 'fixed',
        width: `calc(100% - ${drawerWidth}px)`,
        zIndex: 6,
      },
      breadCrumb: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
          transform: 'scale(0.85)',
        },
      },
      breadCrumbBtn: {
        padding: `0px 8px`,
        margin: 0,
        justifyContent: 'flex-start',
        fontWeight: 400,
        textDecoration: 'underline',
        minWidth: 'auto',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      breadCrumbActive: {
        color: theme.palette.text.secondary,
        textDecoration: 'none',
        fontSize: '1.4rem',
        fontWeight: 400,
        padding: '0px 8px',
      },
      actionBar: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        justifyContent: 'space-between',
        width: '100%',
        '& div > .MuiButton-root': {
          padding: '0px 8px',
          '&.MuiButton-outlined': {
            padding: '3px 12px 3px 16px',
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiSvgIcon-root': {
            marginLeft: theme.spacing(1),
            fontSize: '2rem',
          },
        },
      },
    }),
  );
  const location = useLocation();
  const history = useHistory();
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData } = fileDataCtx;
  const classes = useStyles();
  const isFiles = location.pathname.includes('/files');
  const exactFiles = location.pathname === '/files';
  const isServices = location.pathname.includes('/services');
  const showFileNumber = location.pathname.endsWith('/files') || location.pathname.endsWith('/files/');

  return (
    <Grid container className={classes.breadCrumbWrap}>
      {!disableCrumbs && (
        <Grid item className={classes.breadCrumb}>
          {isFiles &&
            (exactFiles ? (
              <span className={classes.breadCrumbActive}>Files</span>
            ) : (
              <>
                <Button
                  disableRipple
                  disableTouchRipple
                  href="#"
                  color="primary"
                  className={classes.breadCrumbBtn}
                  onClick={() => history.push(`/files`)}
                  Data-QA="FileSummaryFileList"
                >
                  Files
                </Button>
                <ChevronRightOutlined fontSize="small" htmlColor="#666" />
                <span className={classes.breadCrumbActive}>File {showFileNumber ? '' : fileData?.fileNumber}</span>
              </>
            ))}
          {isServices && (
            <>
              <Button
                disableRipple
                disableTouchRipple
                href="#"
                color="primary"
                className={classes.breadCrumbBtn}
                onClick={() => history.push(`/`)}
                Data-QA={serviceQAAttribute}
              >
                Home
              </Button>
              <ChevronRightOutlined fontSize="small" htmlColor="#666" />
              <span className={classes.breadCrumbActive}>
                {location.pathname.includes('/services/back-title-search') && 'Back Title Search'}
                {location.pathname.includes('/services/sdn-search') && 'SDN Search'}
                {location.pathname.includes('/services/policy-upload') && 'Policy Upload'}
              </span>
            </>
          )}
          {buttonName && (
            <>
              <Button
                disableRipple
                disableTouchRipple
                href="#"
                color="primary"
                className={classes.breadCrumbBtn}
                onClick={() => history.push(`/`)}
              >
                {buttonName}
              </Button>
              <ChevronRightOutlined fontSize="small" htmlColor="#666" />
              <span className={classes.breadCrumbActive}>{categoryName}</span>
            </>
          )}
        </Grid>
      )}
      {children && (
        <Grid item className={classes.actionBar}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default BreadCrumbComponent;
