import React from 'react';
import { LeftNav } from './ServiceLeftNav';
import NestedRouteLayout from '../NestedRouteLayout';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const ServiceContent = ({ routes }: never): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
    }),
  );
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NestedRouteLayout {...{ leftNav: <LeftNav />, routes }} />
    </div>
  );
};

export default ServiceContent;
