export enum UserRoles {
  Agent = 1,
  User = 2,
  SystemAdministrator = 3,
  OnBehalfOf = 5,
  TitleEscrowUser = 7,
  TitleUser = 10,
  EscrowUser = 11,
  CPLOnlyUser = 12,
  StaffAdministrator = 13,
}

export enum UserRoleTypes {
  Agent = 100,
  Staff = 101,
}

export const signatureFilter = [
  { name: 'Select All', value: 'Select All' },
  { name: 'Signature Available', value: 'Signature Available' },
  { name: 'Signature Not Available', value: 'Signature Not Available' },
];

export const addSignatureMsg = 'Click and drag your mouse to create your signature in the area below';
export const agreementMsg = ' I accept the terms and conditions for the AgentNet eSignature Agreement';
export const cautionText =
  'The affixing of an electronic signature by a title agent upon a policy form is done by direction' +
  ' of the agent and is the responsibility of the agent. No liability is assumed by First American or' +
  ' its affiliates regarding regulatory compliance.';
