import React from 'react';
function NoProducts() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="105" viewBox="0 0 108 105" fill="none">
      <path
        d="M51.8001 102.6C79.8001 102.6 102.5 79.9 102.5 51.8C102.5 23.7 79.7001 1 51.8001 1C23.8001 1 1.1001 23.7 1.1001 51.8C1.1001 79.9 23.8001 102.6 51.8001 102.6Z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M66.5001 24.7998L81.8001 79.7998C82.3001 81.6998 81.2001 83.5998 79.3001 84.0998L25.4001 98.3998C23.5001 98.8998 21.6001 97.7998 21.1001 95.8998L2.30012 24.8998C1.80012 22.9998 2.90012 21.0998 4.80012 20.5998L43.8001 10.2998L66.5001 24.7998Z"
        fill="white"
        stroke="#D6DCE8"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M65.7004 59.6002L36.6004 67.3002L33.4004 68.1002L23.7004 70.7002C23.3004 70.8002 23.0004 71.3002 23.2004 71.8002C23.3004 72.3002 23.8004 72.6002 24.2004 72.5002L33.9004 69.9002L37.1004 69.1002L66.2004 61.4002C66.6004 61.3002 66.9004 60.8002 66.7004 60.3002C66.6004 59.8002 66.1004 59.5002 65.7004 59.6002Z"
        fill="#D6DCE8"
      />
      <path
        d="M63.8002 52.7997L53.0002 55.5997L49.3002 56.5997L22.0002 63.8997C21.6002 63.9997 21.3002 64.4997 21.5002 64.9997C21.6002 65.4997 22.1002 65.7997 22.5002 65.6997L49.9002 58.3997L53.6002 57.3997L64.4002 54.5997C64.8002 54.4997 65.1002 53.9997 64.9002 53.4997C64.7002 53.0997 64.2002 52.6997 63.8002 52.7997Z"
        fill="#D6DCE8"
      />
      <path
        d="M62.2003 45.9002L59.4003 46.6002L56.6003 47.3002L20.1003 57.0002C19.7003 57.1002 19.4003 57.6002 19.6003 58.1002C19.7003 58.6002 20.2003 58.9002 20.6003 58.8002L57.1003 49.1002L60.2003 48.3002L62.7003 47.6002C63.1003 47.5002 63.4003 47.0002 63.2003 46.5002C63.1003 46.1002 62.7003 45.8002 62.2003 45.9002Z"
        fill="#D6DCE8"
      />
      <path
        d="M45.2999 81.2996L43.2999 81.7996C42.8999 81.8996 42.3999 81.5996 42.2999 81.0996C42.1999 80.5996 42.3999 80.0996 42.7999 79.9996L44.7999 79.4996C45.1999 79.3996 45.6999 79.6996 45.7999 80.1996C45.8999 80.7996 45.6999 81.1996 45.2999 81.2996Z"
        fill="#D6DCE8"
      />
      <path
        d="M39.2003 82.9002L27.7003 85.9002C27.3003 86.0002 26.8003 85.7002 26.7003 85.2002C26.6003 84.7002 26.8003 84.2002 27.2003 84.1002L38.7003 81.1002C39.1003 81.0002 39.6003 81.3002 39.7003 81.8002C39.9003 82.3002 39.7003 82.8002 39.2003 82.9002Z"
        fill="#D6DCE8"
      />
      <path
        d="M60.8001 38.9999L51.7001 41.3999L49.5001 41.9999L18.4001 50.1999C18.0001 50.2999 17.7001 50.7999 17.9001 51.2999C18.0001 51.7999 18.5001 52.0999 18.9001 51.9999L49.9001 43.8999L52.1001 43.2999L61.1001 40.8999C61.6001 40.7999 61.8001 40.2999 61.7001 39.6999C61.7001 39.2999 61.2001 38.8999 60.8001 38.9999Z"
        fill="#D6DCE8"
      />
      <path
        d="M39.2999 23.8995L13.4999 30.6995C12.8999 30.8995 12.2999 30.4995 12.0999 29.8995C11.8999 29.2995 12.2999 28.6995 12.8999 28.4995L38.6999 21.6995C39.2999 21.4995 39.8999 21.8995 40.0999 22.4995C40.2999 23.0995 39.8999 23.6995 39.2999 23.8995Z"
        fill="#D6DCE8"
      />
      <path
        d="M25.1001 34.2999L15.2001 36.8999C14.6001 37.0999 14.0001 36.6999 13.8001 36.0999C13.6001 35.4999 14.0001 34.8999 14.6001 34.6999L24.4001 32.0999C25.0001 31.8999 25.6001 32.2999 25.8001 32.8999C26.0001 33.4999 25.6001 34.0999 25.1001 34.2999Z"
        fill="#D6DCE8"
      />
      <path
        d="M43.8003 10.2998L47.8003 25.4998C48.4003 27.5998 50.7003 28.8998 52.8003 28.2998L66.5003 24.7998"
        fill="#D6DCE8"
      />
      <path
        d="M106.9 40.3004L97.7 96.7004C97.4 98.6004 95.5 99.9004 93.6 99.5004L38.8 89.9004C36.9 89.6004 35.6 87.7004 35.9 85.8004L48.6 13.5004C48.9 11.6004 50.8 10.3004 52.7 10.7004L92.5 17.7004L106.9 40.3004Z"
        fill="white"
        stroke="#D6DCE8"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M91.5998 71.6004L61.9999 66.4004L58.7999 65.8004L48.8999 64.1004C48.4999 64.0004 47.9999 64.4004 47.9999 64.9004C47.8999 65.4004 48.1998 65.9004 48.5998 66.0004L58.4999 67.7004L61.6998 68.3004L91.2999 73.5004C91.6999 73.6004 92.1998 73.2004 92.1998 72.7004C92.2998 72.2004 92.0998 71.7004 91.5998 71.6004Z"
        fill="#D6DCE8"
      />
      <path
        d="M92.8001 64.7L81.8001 62.8L78.1001 62.1L50.2001 57.2C49.8001 57.1 49.3001 57.5 49.3001 58C49.2001 58.5 49.5001 59 49.9001 59.1L77.8001 64L81.5001 64.7L92.5001 66.6C92.9001 66.7 93.4001 66.3 93.4001 65.8C93.5001 65.3 93.2001 64.8 92.8001 64.7Z"
        fill="#D6DCE8"
      />
      <path
        d="M94.2001 57.7L91.3001 57.2L88.4001 56.7L51.2001 50.2C50.8001 50.1 50.3001 50.5 50.3001 51C50.2001 51.5 50.5001 52 50.9001 52.1L88.1001 58.6L91.3001 59.2L93.8001 59.6C94.2001 59.7 94.7001 59.3 94.7001 58.8C94.9001 58.3 94.7001 57.8 94.2001 57.7Z"
        fill="#D6DCE8"
      />
      <path
        d="M64.0002 82.8004L62.0002 82.5004C61.6002 82.4004 61.3002 81.9004 61.4002 81.4004C61.5002 80.9004 61.9002 80.5004 62.3002 80.6004L64.3002 80.9004C64.7002 81.0004 65.0002 81.5004 64.9002 82.0004C64.8002 82.6004 64.4002 82.8004 64.0002 82.8004Z"
        fill="#D6DCE8"
      />
      <path
        d="M57.9 81.7006L46.1999 79.7006C45.7999 79.6006 45.4999 79.1006 45.5999 78.6006C45.6999 78.1006 46.0999 77.7006 46.4999 77.8006L58.1999 79.8006C58.5999 79.9006 58.9 80.4006 58.8 80.9006C58.7 81.5006 58.3 81.8006 57.9 81.7006Z"
        fill="#D6DCE8"
      />
      <path
        d="M95.8 50.9006L86.5 49.3006L84.1999 48.9006L52.4999 43.3006C52.0999 43.2006 51.5999 43.6006 51.5999 44.1006C51.4999 44.6006 51.7999 45.1006 52.1999 45.2006L83.9 50.8006L86.1999 51.2006L95.4 52.8006C95.9 52.9006 96.3 52.5006 96.4 52.0006C96.5 51.5006 96.2 51.0006 95.8 50.9006Z"
        fill="#D6DCE8"
      />
      <path
        d="M82.7002 28.2001L56.4002 23.6001C55.8002 23.5001 55.4002 22.9001 55.5002 22.3001C55.6002 21.7001 56.2002 21.3001 56.8002 21.4001L83.1002 26.0001C83.7002 26.1001 84.1002 26.7001 84.0002 27.3001C83.9002 27.8001 83.3002 28.3001 82.7002 28.2001Z"
        fill="#D6DCE8"
      />
      <path
        d="M65.4001 31.6003L55.3001 29.8003C54.7001 29.7003 54.3001 29.1003 54.4001 28.5003C54.5001 27.9003 55.1001 27.5003 55.7001 27.6003L65.7001 29.4003C66.3001 29.5003 66.7001 30.1003 66.6001 30.7003C66.5001 31.3003 65.9001 31.7003 65.4001 31.6003Z"
        fill="#D6DCE8"
      />
      <path
        d="M92.4002 17.7002L89.7002 33.2002C89.3002 35.4002 90.9002 37.5002 93.1002 37.9002L106.9 40.3002"
        fill="#D6DCE8"
      />
      <path
        d="M69.2004 91.3998C81.5163 91.3998 91.5004 81.4157 91.5004 69.0998C91.5004 56.7838 81.5163 46.7998 69.2004 46.7998C56.8844 46.7998 46.9004 56.7838 46.9004 69.0998C46.9004 81.4157 56.8844 91.3998 69.2004 91.3998Z"
        fill="white"
      />
      <path
        d="M101.541 103.54C100.676 103.54 99.8099 103.194 99.1175 102.415L84.6621 87.9593L84.1428 88.3055C79.4686 91.7679 74.0153 93.5856 68.4756 93.5856C61.8105 93.5856 55.1455 90.9023 50.2116 86.2281C45.0181 81.2943 42.1616 74.6292 42.1616 67.3582C42.1616 52.9029 53.9337 41.1309 68.389 41.1309C78.2567 41.1309 86.9126 46.3244 91.5868 55.1534C96.1745 63.8959 95.5685 74.1099 89.9422 82.333L89.596 82.8523L104.138 97.3942C105.609 98.8657 105.263 100.337 105.003 101.116C104.311 102.501 102.926 103.54 101.541 103.54ZM68.389 48.3153C57.8288 48.3153 49.346 56.8846 49.346 67.3582C49.346 79.3034 59.1272 86.4878 68.6487 86.4878C74.4481 86.4878 79.7282 83.891 83.4503 79.2168C88.0379 73.5039 88.8169 65.8002 85.6143 59.1351C82.325 52.4701 75.7465 48.3153 68.389 48.3153Z"
        fill="#D6DCE8"
        stroke="#AAB2C5"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M67.1004 72.7004C67.0004 72.3004 67.0004 71.9004 67.0004 71.4004C67.0004 69.7004 67.7004 68.2004 69.5004 66.9004L71.0004 65.8004C71.9004 65.1004 72.3004 64.3004 72.3004 63.3004C72.3004 61.9004 71.3004 60.5004 69.2004 60.5004C67.0004 60.5004 66.0004 62.3004 66.0004 64.0004C66.0004 64.4004 66.0004 64.7004 66.1004 65.0004L62.1004 64.9004C62.0004 64.5004 61.9004 64.0004 61.9004 63.5004C61.9004 60.1004 64.4004 56.9004 69.1004 56.9004C73.9004 56.9004 76.5004 60.0004 76.5004 63.1004C76.5004 65.6004 75.2004 67.3004 73.4004 68.6004L72.1004 69.5004C71.0004 70.3004 70.4004 71.3004 70.4004 72.5004V72.7004H67.1004ZM68.8004 74.7004C70.2004 74.7004 71.3004 75.8004 71.3004 77.2004C71.3004 78.6004 70.2004 79.7004 68.8004 79.7004C67.4004 79.7004 66.3004 78.6004 66.3004 77.2004C66.4004 75.9004 67.4004 74.7004 68.8004 74.7004Z"
        fill="#B2C0D1"
      />
    </svg>
  );
}
export default NoProducts;
