import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import { createFile } from 'api/file/file-api';
import { FileCreateContext, FileCreateContextInterface } from 'hooks/FileCreateContext';
import useAsync from 'hooks/useAsync';
import { useAuth } from '@agentnet/auth';
import './FileCreateForm.scss';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import FileCreateFormWithBaseFormGroup from './FileCreateFormWithBaseFormGroup';
import { useHistory } from 'react-router-dom';
import { DefaultCreateFile } from 'models/create-file-model';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { uniqueArray } from 'utilities/utilities';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
interface FileCreateContainerProps {
  openDrawer: boolean;
  setOpenDrawer: (isOpen: boolean) => void;
}

interface Sections {
  fileSection: React.MutableRefObject<null>;
  propertySection: React.MutableRefObject<null>;
  sellerSection: React.MutableRefObject<null>;
  buyerSection: React.MutableRefObject<null>;
  lenderSection: React.MutableRefObject<null>;
  secondBuyerSection: React.MutableRefObject<null>;
  secondSellerSection: React.MutableRefObject<null>;
}

const FileCreateContainer = ({ openDrawer, setOpenDrawer }: FileCreateContainerProps): JSX.Element => {
  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();
  const fileCreateCtx: FileCreateContextInterface = useContext(FileCreateContext) ?? {};
  const { fileCreateData, setFileCreateData } = fileCreateCtx;
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { setTabValue } = fileDataCtx;
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const { addSnackbarMessage } = useSnackBars();
  const [showSaveFailedNotification, setShowSaveFailedNotification] = useState<boolean>();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const history = useHistory();
  const [fileCreateError, setFileCreateError] = useState<string[]>([]);
  const [savingFailedError, setSavingFailedError] = useState<{ msg: string[]; type: string }>({ msg: [], type: '' });
  const fileSection = useRef(null);
  const propertySection = useRef(null);
  const sellerSection = useRef(null);
  const buyerSection = useRef(null);
  const secondBuyerSection = useRef(null);
  const secondSellerSection = useRef(null);
  const lenderSection = useRef(null);
  const DEFAULT_TOASTER_TIMEOUT = 6000;

  const showToaster = useCallback(
    (notificationHook: (show: boolean) => void, duration: number = DEFAULT_TOASTER_TIMEOUT) => {
      notificationHook(true);
      changeValueWithDelay(notificationHook, duration, false);
    },
    [],
  );
  const changeValueWithDelay = useCallback(
    (notificationHook: (show: boolean) => void, duration: number = DEFAULT_TOASTER_TIMEOUT, value = false) => {
      setTimeout(() => {
        notificationHook(value);
      }, duration);
    },
    [],
  );
  const refsArray: Sections = {
    fileSection,
    propertySection,
    sellerSection,
    buyerSection,
    secondBuyerSection,
    secondSellerSection,
    lenderSection,
  };
  useEffect(() => {
    setSubmitAttempted(false);
    setFileCreateError([]);
  }, [openDrawer]);

  /** Submit data to create file */
  const submit = async (): Promise<string> => {
    /* remove the fileId field, so the backend will create a new one. */
    setSubmitAttempted(true);
    let fileId = '';
    if (isFormValid) {
      const token = await getAccessToken();
      const { lenders, buyers, sellers } = fileCreateData || {};
      lenders?.forEach((element) => {
        element.emailAddress = element.emailAddress?.trim() ?? '';
      });
      const cleanedFileInfo = {
        ...fileCreateData,
        propertyState: fileCreateData?.stateCode,
        firmId: +(userFirm?.firmId ?? 0),
        underwriterCode: `${fileCreateData?.underwriterCode ?? 0}`,
        buyers: buyers ? buyers.filter((buyer) => !!buyer.partyType) : [],
        sellers: sellers ? sellers.filter((sellers) => !!sellers.partyType) : [],
        lenders: lenders ? lenders.filter((lender) => !!lender.name) : [],
      };

      fileId = await createFile(cleanedFileInfo, token);
    }
    return fileId;
  };

  const handleCancelCreate = () => {
    setOpenDrawer(false);
    // Resetting all the data
    setFileCreateData && setFileCreateData({ ...DefaultCreateFile, stateCode: '' });
    setSubmitAttempted(false);
    setFileCreateError([]);
  };
  const {
    execute: handleCreateFile,
    value: createFileResults,
    status: createFileStatus,
    errors: createFileError,
  } = useAsync<string>(submit, false);

  useEffect(() => {
    if (createFileStatus === 'success') {
      /* It worked. Clear the data so next click get the defaults */
      setFileCreateData && setFileCreateData(DefaultCreateFile);
    } else if (createFileStatus == 'error') {
      setIsSubmitted(false);
      if (Array.isArray(createFileError) && createFileError.length > 0)
        setFileCreateError(uniqueArray([...createFileError]));
    }
  }, [createFileStatus, createFileError]);

  useEffect(() => {
    if (createFileResults) {
      setOpenDrawer(false);
      setSubmitAttempted(false);
      setFileCreateError([]);
      setIsSubmitted(false);
      /* Navigate to the file info page */
      history.push(`/files/file-info/${createFileResults}`);
    }
  }, [createFileResults]);

  useEffect(() => {
    fileCreateError?.map((err) => {
      addGlobalMsg({
        message: err,
        type: 'error',
      });
    });
  }, [fileCreateError]);

  const handleScroll = (currentElement: any) => {
    if (currentElement) {
      currentElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    let list = '';
    savingFailedError.msg.forEach((msg: string) => {
      if (msg) list += `<li>${msg}</li>`;
    });
    showSaveFailedNotification &&
      addSnackbarMessage({
        html: true,
        message: `<b class="savingFailedErrorHeading">Saving Failed</b>
            ${
              list
                ? `<ul class="savingFailedErrorList">
              ${list}
            </ul>`
                : ''
            }`,
        type: 'error',
        onClose: () => {
          setShowSaveFailedNotification(false);
        },
      });
  }, [showSaveFailedNotification]);

  return (
    <FormDrawerComponent
      title={'Create a File'}
      open={openDrawer}
      primaryActionProps={{
        loading: isSubmitted,
        disabled: isSubmitted,
        'data-qa': 'CreateFileCreate',
        size: 'small',
      }}
      dismissActionProps={{
        disabled: isSubmitted,
        'data-qa': 'CreateFileCancel',
        size: 'small',
      }}
      crossQAPrefix="CreateFile"
      primaryActionLabel="Create & View File"
      onPrimaryAction={() => {
        if (isFormValid) {
          setTabValue && setTabValue('0');
          setIsSubmitted(true);
          handleCreateFile().then();
        } else {
          if (savingFailedError.type) handleScroll(refsArray[savingFailedError.type as keyof Sections]?.current);
          showToaster(setShowSaveFailedNotification);
        }
        setSubmitAttempted(true);
      }}
      onDismissAction={handleCancelCreate}
      testId="scrollable-form-drawer"
      width={960}
    >
      {fileCreateData && setFileCreateData && (
        <>
          <FileCreateFormWithBaseFormGroup
            isFormValid={isFormValid}
            setIsFormValid={setIsFormValid}
            showAllValidation={submitAttempted}
            value={fileCreateData}
            onChange={setFileCreateData}
            setSavingFailedError={setSavingFailedError}
            refsArray={refsArray}
          />
        </>
      )}
    </FormDrawerComponent>
  );
};

export default FileCreateContainer;
