import React from 'react';
import { Box, Typography, CircularProgress, LinearProgress, CircularProgressProps } from '@material-ui/core';
import './LoadingSpinner.scss';

export interface LoadingSpinnerProps {
  size?: number;
  status?: string;
  className?: string;
  label?: string;
  variant?: string;
  progress?: number;
  message?: string;
  showLabel?: boolean;
}

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="progress-percent"
      >
        <Typography variant="caption" color="primary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

/*
Displays the spinner while status is 'pending'
*/
const LoadingSpinner = ({
  size = 20,
  status,
  className = 'spinner-root',
  label = 'Loading',
  variant = 'default',
  progress = undefined,
  message,
  showLabel = true,
}: LoadingSpinnerProps): JSX.Element => {
  return (
    <>
      {status === 'pending' && (
        <div className={`spinner-root ${variant} ${className} ${status} ${progress && 'progress'}`}>
          <div className="spinner-wrapper">
            {variant !== 'linear' ? (
              <>
                {progress === undefined ? (
                  <>
                    <CircularProgress size={size} className="spinner" color={'primary'} />
                    {showLabel ? <span className="spinner-text">{label}</span> : null}
                    {message && (
                      <Box position="relative" display="block" left={0} top={8}>
                        <Typography variant="caption" color="secondary">
                          {message}
                        </Typography>
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    <CircularProgressWithLabel size={65} className="spinner" value={progress} />
                    {message && (
                      <Box position="relative" display="block" left={0} top={8}>
                        <Typography variant="caption" color="secondary">
                          {message}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <LinearProgress />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingSpinner;
