import React, { useContext, useEffect, useState } from 'react';
import { StateSelectField, StateSelectFieldProps } from 'ui-kit/inputs/StateSelectField';
import { AgentNetTextInput } from 'ui-kit/inputs/TextField';
import {
  AddressFields,
  DomesticAddressFieldGroupProps,
  IDomesticAddressFields,
} from 'ui-kit/form/interfaces/AddressModal';
import { Grid, TextFieldProps, GridProps, GridSize } from '@material-ui/core';
import AgentNetDropdownSelector, { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import { fileInfoType } from 'api/file/interfaces/get-file';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { domesticAddressSchema } from 'utilities/validation/schemas';
import USPostalCodeField from 'ui-kit/inputs/USPostalCodeField/USPostalCodeField';
import { getCountiesPropertyFromList } from 'api/file/file-property-api';
import { ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { useAuth } from '@agentnet/auth';
import { FileCreateContext, FileCreateContextInterface } from 'hooks/FileCreateContext';
import './DomesticAddress.scss';
import GoogleAutocompleteSearch from 'ui-kit/inputs/GoogleAutocompleteSearch/GoogleAutocompleteSearch';
import * as _ from 'lodash';

export type searchType = {
  id?: number | string | undefined | null;
  value: any;
  name: any;
  type?: ('primary' | 'secondary' | 'none') | string | undefined | null;
};
export interface AddressComponents {
  long_name: string;
  short_name: string;
  types: string[];
}
export interface IPlaceDetails {
  address_components: AddressComponents[];
  name: string;
  formatted_address: string;
  place_id: string;
}
export interface PlaceAddress {
  address?: string;
  state?: string;
  city?: string;
  zipcode?: string;
  county?: string;
}

export type IDomesticAddressProps = DomesticAddressFieldGroupProps & {
  displayRequiredAddressFields?: boolean;
  isOutsideJurisdiction?: boolean;
  isFileInformation?: boolean;
  ContainerComponentProps?: GridProps;
  Address1Props?: TextFieldProps;
  Address2Props?: TextFieldProps;
  CityProps?: TextFieldProps;
  County?: TextFieldProps;
  PostalCodeProps?: TextFieldProps;
  StateProps?: StateSelectFieldProps;
  error?: string | undefined;
  value?: AddressFields;
  onChange?: (value: AddressFields) => void;
  onBlur?: (value: AddressFields) => void;
  fileInfo?: fileInfoType | null;
  isCreateFile?: boolean;
  postalCodeRequired?: boolean;
  showCounty?: boolean;
  disabled?: boolean;
  disableState?: boolean;
  showAllValidation?: boolean;
  schema?: ObjectSchema<ObjectShape>;
  addPropertyClicked?: boolean;
  setIsValid?: (isValid: boolean) => void;
  isCreateFileForm?: boolean;
  stateOrProvinceField?: boolean;
  stateOptions?: { name: string; abbreviation: string }[] | false;
  useGoogleAutocomplete?: boolean;
  qaAttributePrefix?: string;
  parentValidation?: boolean;
  onCountyUpdate?: any;
  getAllState?: boolean;
};

export const DomesticAddressField: React.FC<IDomesticAddressProps> = ({
  onChange,
  ContainerComponentProps,
  value,
  isOutsideJurisdiction,
  StateProps,
  displayRequiredAddressFields,
  error,
  disabled,
  disableState,
  showCounty = false,
  showAllValidation,
  schema,
  addPropertyClicked,
  isCreateFileForm,
  useGoogleAutocomplete,
  stateOrProvinceField = true,
  setIsValid,
  stateOptions,
  qaAttributePrefix = '',
  parentValidation,
  isFileInformation = false,
  onCountyUpdate,
  getAllState,
}: IDomesticAddressProps): JSX.Element => {
  const [currentValue, setCurrentValue] = useState<AddressFields>(value ?? ({} as AddressFields));
  const { getAccessToken } = useAuth();
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const fileCreateCtx: FileCreateContextInterface = useContext(FileCreateContext) ?? {};
  const { fileCreateData } = fileCreateCtx;
  const validateErrors = async (addressFields: IDomesticAddressFields, e: any) => {
    const errs = (await doValidate(addressFields, schema || domesticAddressSchema)) || [];
    setValidationErrors(errs);
    setIsValid && setIsValid(errs.length === 0);
  };
  /* Check Domestic State or Foreign State */
  const isStateOrProvinceField = stateOrProvinceField ? 'stateOrProvince' : 'state';

  const onBlurValidation = async (addressFields: IDomesticAddressFields, e: any = null) => {
    validateErrors(addressFields, e);
  };

  /* County List will be pulled From API */
  let countyList: Array<SelectOption> = [];
  const isRequired = displayRequiredAddressFields;
  const [counties, setCounties] = useState(countyList);
  const getState = () => {
    const currentState: any = value?.stateOrProvince ? value?.stateOrProvince : value?.state ?? '';
    return typeof currentState !== 'string' ? currentState?.target?.value ?? '' : currentState ?? '';
  };
  /* Retrieve CountyList from API */
  useEffect(() => {
    if (showCounty) {
      const state = getState() || '';
      if (state != '') {
        getAccessToken().then((token) => {
          getCountiesPropertyFromList(state, token).then((data) => {
            countyList = [];
            data?.counties?.forEach((c) => {
              const option = {
                name: c,
                value: c,
              };
              countyList.push(option);
            });
            setCounties(countyList);
          });
        });
      }
    }
    return () => {
      setCounties([]);
    };
  }, [value?.state, showCounty]);

  useEffect(() => {
    if (currentValue?.county && counties.length && counties.length > 0) {
      const tempCounty = currentValue?.county.replace(' County', '').replace(' Parish', '');
      const formattedCounty = counties.find(
        (countyOption) => countyOption.value?.toLowerCase() === tempCounty?.toLowerCase(),
      )?.value;
      if (onCountyUpdate && isFileInformation) {
        onCountyUpdate && onCountyUpdate({ ...currentValue, county: formattedCounty });
      } else {
        onChange && onChange({ ...currentValue, county: formattedCounty });
      }
    } else if (counties.length === 1 && !isFileInformation) {
      onChange && onChange({ ...currentValue, county: counties[0]?.value });
    }
  }, [counties, currentValue?.address1, currentValue?.county]);

  useEffect(() => {
    const validateErrorscFunc = async () => {
      if (value && !_.isEqual(value, currentValue)) {
        setCurrentValue(value);
        validateErrors(value, null);
      }
    };
    validateErrorscFunc();
  }, [value, showAllValidation]);

  useEffect(() => {
    if (value) {
      validateErrors(value, null);
    }
  }, [parentValidation]);

  /* Check  validations - but render google api prior before validations happen */
  useEffect(() => {
    validationErrors && setIsValid && setIsValid(validationErrors.length === 0);
  }, [validationErrors]);

  useEffect(() => {
    if (firstLoad) {
      currentValue && validateErrors(currentValue, null);
      setFirstLoad(false);
    }
  }, []);

  /* Configure Domestic Address Layout  */
  const configureDomesticLayout = (arr: GridSize[]) => {
    return (
      <>
        <Grid item xs={arr[0]}>
          <AgentNetTextInput
            variant="outlined"
            required={isRequired}
            fullWidth
            label="City"
            id="city"
            name="city"
            Data-QA={qaAttributePrefix + 'City'}
            firstLoad={firstLoad}
            errs={validationErrors}
            value={currentValue?.city ?? ''}
            showValidation={showAllValidation}
            addPropertyClicked={addPropertyClicked}
            onChange={(e) => {
              onChange && onChange({ ...currentValue, city: e.target.value } as IDomesticAddressFields);
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              onBlurValidation && onBlurValidation({ ...currentValue, city: e.target.value } as IDomesticAddressFields);
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={arr[1]}>
          <StateSelectField
            label="State"
            required={isRequired}
            Data-QA={qaAttributePrefix + 'State'}
            helperText={error}
            name={isStateOrProvinceField}
            id={isStateOrProvinceField}
            disabled={disableState || disabled}
            shouldDisableClearable={!isOutsideJurisdiction ? true : false}
            showValidation={showAllValidation}
            errs={validationErrors}
            stateOptions={stateOptions}
            value={getState() ?? ''}
            {...StateProps}
            onChange={(e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              onChange &&
                onChange({
                  ...currentValue,
                  [isStateOrProvinceField]: typeof e === 'string' ? e : e.target.value,
                } as IDomesticAddressFields);
            }}
            onBlur={(e) => {
              onBlurValidation &&
                onBlurValidation({ ...currentValue } as IDomesticAddressFields, isStateOrProvinceField);
            }}
          />
        </Grid>
        <Grid item xs={arr[2]}>
          <USPostalCodeField
            variant="outlined"
            fullWidth
            name="postalCode"
            required={isRequired ? true : false}
            InputLabelProps={{ shrink: true }}
            label={'Zip'}
            Data-QA={qaAttributePrefix + 'Zip'}
            id="postalCode"
            disabled={disabled}
            errs={validationErrors}
            showValidation={showAllValidation}
            onChange={(e) => {
              onChange && onChange({ ...currentValue, postalCode: e.target.value } as IDomesticAddressFields);
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              onBlurValidation &&
                onBlurValidation(
                  { ...currentValue, postalCode: e.target.value } as IDomesticAddressFields,
                  'postalCode',
                );
            }}
            value={value?.postalCode ?? ''}
          />
        </Grid>
      </>
    );
  };

  /* Takes the current value for state and iterates through the list of google predictions and separating predictions that match the current state
  into one array and all else into another - returns concationation of the array of the values corresponding to state and others  */

  const prioritizeStateOptions = (states: Array<{ name: string; value: string }>) => {
    const currentState = getState();

    let tempState = currentState?.toLowerCase();
    if (tempState === 'vi') {
      tempState = 'usvi';
    } else if (tempState === 'pr') {
      tempState = 'puerto rico';
    }
    const currentStateList: {
      name: string;
      value: string;
    }[] = [];

    const restStateList: {
      name: string;
      value: string;
    }[] = [];

    for (const state of states) {
      if (
        currentState &&
        state?.name?.split(',').some((addressItem) => addressItem.toLowerCase().trim() === tempState)
      ) {
        currentStateList.push(state);
      } else {
        restStateList.push(state);
      }
    }

    if (getAllState) {
      return [...currentStateList, ...restStateList];
    }
    return [...currentStateList];
  };
  const renderAutoCompleteAddressFields = () => {
    if (useGoogleAutocomplete) {
      return (
        <GoogleAutocompleteSearch
          variant="outlined"
          fullWidth
          required={isRequired}
          label="Address 1"
          id="address1"
          name="address1"
          Data-QA={qaAttributePrefix + 'Address1'}
          firstLoad={firstLoad}
          errs={validationErrors}
          orderOptions={prioritizeStateOptions}
          onChange={(e) => {
            if (e?.target?.value || e?.target?.value === '') {
              onChange && onChange({ ...currentValue, address1: e.target.value } as IDomesticAddressFields);
            }
          }}
          onPlaceSelect={(place: any) => {
            const currentState = getState();
            if (currentState?.toLowerCase() === place?.state?.toLowerCase() || getAllState) {
              onChange &&
                onChange({
                  ...currentValue,
                  address1: place?.address1 ?? '',
                  address2: place?.address2 ?? value?.address2 ?? '',
                  postalCode: place?.zipcode ?? '',
                  [isStateOrProvinceField]: place?.state ?? '',
                  city: place?.city ?? '',
                  county: place?.county ?? '',
                });
            } else {
              onChange &&
                onChange({
                  ...currentValue,
                  address1: '',
                  postalCode: '',

                  city: '',
                  county: '',
                });
            }
          }}
          value={value?.address1 ?? ''}
          showValidation={showAllValidation}
          addPropertyClicked={addPropertyClicked}
          stateConstraint={getState()}
          disabled={disabled}
        />
      );
    } else {
      return (
        <AgentNetTextInput
          variant="outlined"
          fullWidth
          required={isRequired}
          label="Address 1"
          id="address1"
          name="address1"
          Data-QA={qaAttributePrefix + 'Address1'}
          firstLoad={firstLoad}
          errs={validationErrors}
          onChange={(e) => {
            onChange && onChange({ ...currentValue, address1: e.target.value } as IDomesticAddressFields);
          }}
          value={value?.address1 ?? ''}
          showValidation={showAllValidation}
          addPropertyClicked={addPropertyClicked}
          disabled={disabled}
        />
      );
    }
  };
  return (
    <Grid container spacing={3} {...ContainerComponentProps}>
      <Grid item xs={6}>
        {renderAutoCompleteAddressFields()}
      </Grid>

      <Grid item xs={6}>
        <AgentNetTextInput
          variant="outlined"
          name="address2"
          fullWidth
          label="Address 2"
          id="address2"
          Data-QA={qaAttributePrefix + 'Address2'}
          errs={validationErrors}
          firstLoad={firstLoad}
          onChange={(e) => {
            onChange && onChange({ ...currentValue, address2: e.target.value } as IDomesticAddressFields);
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            onBlurValidation &&
              onBlurValidation({ ...currentValue, address2: e.target.value } as IDomesticAddressFields);
          }}
          value={value?.address2 ?? ''}
          showValidation={showAllValidation}
          addPropertyClicked={addPropertyClicked}
          disabled={disabled}
        />
      </Grid>
      {configureDomesticLayout([3, 3, 3])}

      <Grid item xs={3}>
        {showCounty && (
          <AgentNetDropdownSelector
            name="county"
            label="County"
            required={isRequired}
            errs={validationErrors}
            options={counties}
            value={value?.county ?? ''}
            id="county"
            showValidation={showAllValidation}
            menuOption={(county: string) => onChange && onChange({ ...value, county: county })}
            dropdowntype="outlined"
            disabled={disabled}
            Data-QA={qaAttributePrefix + 'County'}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DomesticAddressField;
