import { UnderwritingRequest } from './types';
import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import { DividerType } from 'ui-kit/components/dividers';
import { UnderwritingRisk } from './types';
import AgentNetDropdownSelector, { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import { AgentNetTextInput } from 'ui-kit/inputs';
import AccordionContent from 'ui-kit/components/accordion/AccordionContent';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { Notification } from 'ui-kit/components/notification/Notification';

import { FieldValidationError, doValidate } from 'utilities/validation/validation';
import { RiskSchema } from 'utilities/validation/schemas/underwriting-schema';

interface UnderwritingProps {
  uwr?: UnderwritingRequest;
  updateUnderwriting: any;
  isUwrDisabled?: boolean;
  isDisabled?: boolean;
  updateUwrErrorStatus: any;
  submitAttempted: boolean;
  setSubmitAttempted: React.Dispatch<React.SetStateAction<boolean>>;
  isDuplicateRisk?: any;
}
const UnderwritingRisks: React.FC<UnderwritingProps> = ({
  uwr,
  updateUnderwriting,
  isUwrDisabled,
  isDisabled,
  updateUwrErrorStatus,
  submitAttempted,
  setSubmitAttempted,
  isDuplicateRisk,
}: UnderwritingProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
      UnderwritingWrap: {
        padding: `0 2.4rem 8.8rem`,
      },
      mb2: {
        marginBottom: theme.spacing(2),
      },
      riskDisclaimer: {
        paddingTop: `1.2rem`,
        //marginTop: `0 rem`
      },
      warningMsg: { marginTop: theme.spacing(3) },
    }),
  );

  const randomId = () => (Math.random() + 1).toString(36).substring(7);

  const [riskOptions, setRiskOptions] = useState<SelectOption[]>([]);

  const [isdeleteRisk, setIsDeleteRisk] = useState(false);
  const [deleteRiskIndex, setDeleteRiskIndex] = useState<any>('');
  const [deleteRiskName, setDeleteRiskName] = useState('');

  const [duplicateRiskName, setDuplicateRiskName] = useState('');
  const [duplicateRiskId, setDuplicateRiskId] = useState<any>('');

  // Form Validation State Vars
  const [showMandatoryField, setShowMandatoryField] = useState(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[][]>([]);

  useEffect(() => {
    const riskOptionsMap =
      uwr?.TemplateMetaData.Risks.map((x, i) => {
        return { name: x.Name, value: x.Name };
      }) ?? [];

    setRiskOptions(riskOptionsMap);

    if (!uwr?.Risks?.length && uwr?.RequestInformation && uwr?.RequestInformation?.RequestId == 0) {
      handleNewRisk(true);
    } else if (
      !uwr?.Risks?.length &&
      uwr?.RequestInformation?.RequestId &&
      uwr?.RequestInformation?.RequestId > 0 &&
      uwr?.AdditionalRisks?.length > 0
    ) {
      handleNewRisk(false);
    } else if (
      !uwr?.Risks?.length &&
      uwr?.RequestInformation?.RequestId &&
      uwr?.RequestInformation?.RequestId > 0 &&
      uwr?.AdditionalRisks?.length == 0
    ) {
      handleNewRisk(false);
    }
  }, [uwr?.TemplateMetaData]);

  const classes = useStyles();

  const validateErrors = async () => {
    const riskErrs: FieldValidationError[][] = await Promise.all(
      (uwr?.Risks ?? []).map(async (risk) => {
        const perRiskErr = (await doValidate(risk, RiskSchema)) || [];
        return perRiskErr;
      }),
    );

    // Deleting 1st index from error array for bypass the validation by assuming
    // no new risk would be added unless the exiting Risk is filled

    let errs = [];
    if (uwr?.AdditionalRisks && uwr?.AdditionalRisks?.length > 0 && uwr?.Risks?.length == 1) {
      errs = riskErrs.splice(1);
    } else {
      errs = riskErrs;
    }

    updateUwrErrorStatus('Risks', noErrorsObj(errs ?? []));
    setValidationErrors(errs);
  };

  const noErrorsObj = (obj: any) => {
    return Object.values(obj).every((val) => Array.isArray(val) && val.length === 0);
  };

  // Risks Validation on Save/ Submit
  useEffect(() => {
    if (submitAttempted) {
      validateErrors();
      setShowMandatoryField(true);
      setSubmitAttempted(false);
    }
  }, [submitAttempted]);

  const handleNewRisk = (isHili: boolean) => {
    setShowMandatoryField(false);
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);

    risks.push({
      Name: isHili ? uwr?.TemplateMetaData.Risks[0].Name : '',
      Summary: '',
      IsExpanded: true,
      Id: randomId(),
    } as UnderwritingRisk);

    updateUnderwriting('Risks', risks);
    validateErrors();
  };

  const updateRiskValue = (index: number, field: string, value: any) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);

    risks[index] = { ...risks[index], [field]: value };

    if (field === 'Name') {
      const riskMetadata = uwr?.TemplateMetaData.Risks.filter((risk) => {
        return risk.Name === value;
      })[0] ?? { Definition: '' };
      risks[index].Definition = riskMetadata.Definition;
      risks[index].Id = randomId();
      risks[index].Summary = '';
    }

    updateUnderwriting('Risks', risks);
    validateErrors();
  };

  const deleteRisk = () => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);
    const deleteRiskName = risks[deleteRiskIndex].Name;
    risks.splice(deleteRiskIndex, 1);
    updateUnderwriting('Risks', risks);
    setIsDeleteRisk(false);
    const duplicateRisks = risks.filter((x) => x.Name == deleteRiskName);
    const duplicates = duplicateElements(risks);

    if (duplicateRisks.length > 1) {
      setDuplicateRiskName(duplicateRisks[1]?.Name);
      setDuplicateRiskId(duplicateRisks[0].Id);
      updateUwrErrorStatus('Risks', false);
      isDuplicateRisk(true);
    } else if (duplicates?.length > 0 ? true : false) {
      setDuplicateRiskName(duplicates[1]?.Name);
      setDuplicateRiskId(duplicates[0].Id);
      updateUwrErrorStatus('Risks', false);
      isDuplicateRisk(true);
    } else {
      setDuplicateRiskName('');
      setDuplicateRiskId('');
      updateUwrErrorStatus('Risks', true);
      isDuplicateRisk(false);
    }
    validateErrors();
    // if (deleteRiskName === duplicateRiskName) {
    //   setDuplicateRiskName('');
    // }
  };

  const cancelDeleteRisk = () => {
    setIsDeleteRisk(false);
    validateErrors();
  };

  const handleRiskExpandIndividual = (isExpand: boolean, id: string | number) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);

    const updatedRisks = risks.map((risk) => {
      if (risk.Name === id) {
        return { ...risk, IsExpanded: isExpand };
      }
      return risk;
    });
    updateUnderwriting('Risks', updatedRisks);
  };

  const handleRiskExpand = (isExpand: boolean) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);

    const updatedRisks = risks.map((risk) => {
      risk.IsExpanded = isExpand;
      return risk;
    });
    updateUnderwriting('Risks', updatedRisks);
    validateErrors();
  };

  function duplicateElements(array: UnderwritingRisk[]) {
    const map = new Map();
    array.forEach((a) => map.set(a.Name, (map.get(a.Name) || 0) + 1));
    return array.filter((a) => map.get(a.Name) > 1);
  }
  const renderRisks = () => {
    return uwr?.Risks?.map((risk, i) => (
      <Grid container spacing={3} key={'UnderwritngRiskContriner' + [i + 1]}>
        <Grid item sm={12}>
          <AccordionContent
            accordianQAAttribute={'UnderwritingRiskAccordionContent' + [i + 1]}
            icon={'list'}
            type={'underwriting'}
            title={(risk?.Name ?? '') === '' ? 'Select a Risk' : risk.Name}
            hideSubtitle={true}
            key={`underwritingRisk_${risk.Id}`}
            id={risk.Name}
            //closeAll={riskActionsState.closeAll}
            expanded={risk.IsExpanded}
            onExpandChange={handleRiskExpandIndividual}
            status={''}
            className={classes.mb2}
            hideStatus={true}
            onDelete={
              i > 0
                ? () => {
                    setIsDeleteRisk(true);
                    setDeleteRiskIndex(i);
                    setDeleteRiskName(risk.Name);
                    updateUwrErrorStatus('Risks', true);
                  }
                : undefined
            }
            isDeleteDisabled={isUwrDisabled || isDisabled}
            removeAsterisk
          >
            <Grid container spacing={0}>
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <AgentNetDropdownSelector
                    label={'Risk'}
                    name={'Name'}
                    qaAttribute={'UnderwritingRisk'}
                    options={riskOptions}
                    value={risk.Name}
                    menuOption={(val: string, name: any) => {
                      updateRiskValue(i, 'Name', val);
                      const sameRisks = uwr.Risks.filter((e) => e.Name === val);
                      const duplicates = duplicateElements(uwr.Risks);
                      if (sameRisks.length > 1) {
                        setDuplicateRiskName(sameRisks[1]?.Name);
                        setDuplicateRiskId(sameRisks[0].Id);
                        updateUwrErrorStatus('Risks', false);
                        isDuplicateRisk(true);
                      } else if (duplicates?.length > 0 ? true : false) {
                        setDuplicateRiskName(duplicates[1]?.Name);
                        setDuplicateRiskId(duplicates[0].Id);
                        updateUwrErrorStatus('Risks', false);
                        isDuplicateRisk(true);
                      } else {
                        setDuplicateRiskName('');
                        setDuplicateRiskId('');
                        updateUwrErrorStatus('Risks', true);
                        isDuplicateRisk(false);
                      }
                    }}
                    disabled={isUwrDisabled || isDisabled}
                    required
                    dropdowntype="outlined"
                    showValidation={showMandatoryField}
                    errs={validationErrors[i]}
                  />
                </Grid>
              </Grid>
              <Grid>
                {duplicateRiskName && risk.Id !== duplicateRiskId && risk.Name === duplicateRiskName && (
                  <>
                    <Notification inline severity="warning" className={classes.warningMsg}>
                      {duplicateRiskName} is already selected for the Underwriting Request. Please select a different
                      Risk.
                    </Notification>
                  </>
                )}
              </Grid>
              {risk?.Name && (
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <Typography variant="h6" className={classes.riskDisclaimer}>
                      {' '}
                      {risk.Definition}
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <AgentNetTextInput
                      multiline
                      fullWidth
                      variant="outlined"
                      label="Summary of Risk"
                      name="Description"
                      Data-QA={'UnderwritingDescription'}
                      value={risk.Summary ?? ''}
                      disabled={isUwrDisabled || isDisabled}
                      onChange={(event: any) => {
                        updateRiskValue(i, 'Summary', event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </AccordionContent>
        </Grid>
      </Grid>
    ));
  };

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <AgentNetDivider
              variant={DividerType.header}
              title={'Risks'}
              typoVariant="h2"
              primaryButtonName="Add New Risk"
              primaryButtonDisable={isUwrDisabled || (uwr?.Risks?.length ?? 0) >= 4 || isDisabled}
              onClickPrimary={() => handleNewRisk(false)}
              buttonName={'Close All'}
              buttonName2={'Expand All'}
              onClick={() => {
                handleRiskExpand(false);
              }}
              onClick2={() => {
                handleRiskExpand(true);
              }}
              primaryButtonQaAttr="AddNewRiskTop"
              buttonNameQaAttr="RisksCloseAll"
              buttonName2QaAttr="RisksExpandAll"
              disablePaddingX
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          {renderRisks()}
        </Grid>
        <AgentNetButton
          variant="outlined"
          size="small"
          onClick={() => handleNewRisk(false)}
          disabled={isUwrDisabled || (uwr?.Risks?.length ?? 0) >= 4 || isDisabled}
          data-qa="AddNewRiskBottom"
        >
          Add New Risk
        </AgentNetButton>
      </Container>
      <>
        <AgentNetConfirmationDialog
          qaAttrPrefix="ConfirmationRiskDelete"
          onConfirm={deleteRisk}
          open={isdeleteRisk}
          onDismissAction={cancelDeleteRisk}
          dialogTitle="Delete Risk"
          dialogBtnContent="Yes, Delete"
          dialogText="You are about to delete this risk from your underwriting request. Are you sure you want to proceed?"
        />
      </>
    </>
  );
};

export default UnderwritingRisks;
