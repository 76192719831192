import React, { useContext, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, ListItemIcon, ListItemText, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import RadioGroup from 'ui-kit/components/radios/RadioGroup';
import { AgentNetCheckBox, AgentNetDropdownSelector } from 'ui-kit/inputs';
import DateField from 'ui-kit/inputs/DateField/DateFieldString';
import MyReportsList from 'core/layout-core/core-content/Reports/MyReportsList';
import {
  CPLActivityLogReport,
  InCompleteFiles,
  MyReportsRequest,
  PolicyLogReport,
  ServiceOrderLogReport,
} from './types';
import { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { FieldValidationError, doValidate } from 'utilities/validation/validation';
import { MyReportSchema } from 'utilities/validation/schemas/report-schema';
import useAsync from 'hooks/useAsync';
import { useAuth } from '@agentnet/auth';
import { incompleteFiles, serviceOrderLog, CplActivityLog, PolicyLog } from 'api/myReports/report-api';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import { DividerType } from 'ui-kit/components/dividers';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import ErrorSection from 'features/files/file-create/ErrorSection';
import { HelpOutline } from '@material-ui/icons';
import { getHistory } from 'api/myReports/report-api';
import { Firm, FirmAccount } from 'api/profile/types';

const MyReportsContent = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: '6.4rem',
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      },
      reportSearch: {
        padding: '24px',
      },
      fieldPadding: {
        padding: '0 24px 0 24px',
      },
      fromToDate: {
        font: 'Inter',
        fontWeight: 500,
        size: '20px',
        LineHeight: '24.2px',
        padding: '12px 0 0 0',
      },
      createButton: {
        padding: '24px 0 0 24px',
      },
      radioButton: {
        padding: '24px 24px 0 24px',
      },
      radioButton1: {
        height: '25px',
      },
      radioName: {
        font: 'Inter',
        weight: '500',
        size: '13px',
        LineHeight: '15.73px',
        marginBottom: '2px',
      },
    }),
  );

  const classes = useStyles();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile } = profileCtx;
  const { getAccessToken } = useAuth();
  const { addSnackbarMessage } = useSnackBars();
  const { addGlobalMsg } = useGlobalMessages();

  const defaultValues: MyReportsRequest = {
    firmId: userFirm?.firmId ?? '',
    officeId: 'All',
    accountId: 'All',
    stateCode: 'All',
    reportName: '',
    reportFormat: 'PDF',
    letterStatus: ['Open'],
    serviceOrderLogStatus: ['Pending'],
    policyLogStatus: 'All',
    dateType: 'Jacket Creation Date',
    startDate: '',
    endDate: '',
  };

  const [myReport, setMyReport] = useState<MyReportsRequest>(defaultValues);
  const [reportType, setReportType] = useState<any>('CPL Activity Log');
  const [firmOptions, setFirmOptions] = useState<SelectOption[]>([]);
  const [officeOptions, setOfficeOptions] = useState<SelectOption[]>([]);
  const [accountOptions, setAccountOptions] = useState<SelectOption[]>([]);
  const [stateOptions, setStateOptions] = useState<SelectOption[]>([]);
  const [officeValue, setOfficeValue] = useState<any>('');
  const [firmValue, setFirmValue] = useState<any>('');
  const [status, setStatus] = useState<string[]>(['Pending']);
  const [inActiveOffice, setInActiveOffice] = useState<boolean>(false);
  const [showFields, setShowFields] = useState<boolean>(true);

  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [shouldShowValidation, setShouldShowValidation] = useState(false);
  const [isSearching, setSearchFlag] = useState(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const [reportSearchType, setReportSearchType] = useState<string[]>(['Open']);
  const [isComplianceDate, SetComplianceDate] = useState<boolean>(false);
  let cplReportArray: Array<string>;

  let incompleteFilesPayload: InCompleteFiles;
  let serviceOrderLogPayload: ServiceOrderLogReport;
  let cplActivityLogPayload: CPLActivityLogReport;
  let policyLogPayload: PolicyLogReport;

  const iconStyles = { marginLeft: '1.2rem', marginTop: '6px' };
  const statusToolTipMessage = (
    <>
      <div>
        • When Pending is selected, the report will include submitted service orders that have not been completed.
      </div>
      <div>
        • When Delivered is selected, the report will include service orders where the documents have been delivered.
      </div>
    </>
  );

  const getHistoryData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    setSearchFlag(true);
    const token = await getAccessToken();
    return await getHistory(token);
  };

  const {
    execute: executeHistoryList,
    status: getHistoryStatus,
    value: getHistoryResults,
    errors: getHistoryErrors,
  } = useAsync<any>(getHistoryData, false);

  useEffect(() => {
    if (getHistoryResults) {
      setSearchFlag(false);
    }
  }, [getHistoryResults]);

  useEffect(() => {
    executeHistoryList().then();
  }, []);

  useEffect(() => {
    //on change of report type, reset the values of Office, Account, State
    myReport.officeId = 'All';
    myReport.accountId = 'All';
    myReport.stateCode = 'All';

    setOfficeValue('All');
    if (profile?.isHqUser === true) {
      setFirmValue(userFirm?.firmId ?? '');
    }

    //reset the validation errors
    setValidationErrors([]);
    setShouldShowValidation(false);
    setInActiveOffice(false);
  }, [reportType]);

  useEffect(() => {
    if (profile?.isHqUser === true) {
      profile.firms?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      profile.firms?.forEach((firm) => {
        if (firm.status === 'Inactive' || firm.status === 'inactive') {
          if (!firm.name.includes('(Inactive)')) {
            firm.name = firm.name + ' (Inactive)';
          }
        }
      });
      const firmOptions: SelectOption[] =
        profile?.firms?.map((firm) => {
          return { name: firm.name, value: firm.firmId };
        }) ?? [];

      if (firmOptions.length === 1) {
        handleUpdateMyReportsRequest('firmId', firmOptions[0].value);
        setFirmValue(firmOptions[0].value);
        myReport.firmId = firmOptions[0].value;
      } else {
        const allFirmOption: SelectOption = { name: 'All', value: 'All' };
        firmOptions.unshift(allFirmOption);
      }
      setFirmOptions(firmOptions);
    } else if (profile?.isHqUser === false) {
      if (userFirm) {
        const firmOptions: SelectOption[] = [{ name: userFirm.name, value: userFirm.firmId }];
        setFirmOptions(firmOptions);
      }
    }
    if (userFirm) {
      //Assigning office values
      userFirm.offices = userFirm.offices?.filter((office) => office.status === 'Active' || office.status === 'active');
      userFirm.offices?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      const officeOptions: SelectOption[] =
        userFirm.offices?.map((office) => {
          return { name: office.name, value: office.officeId };
        }) ?? [];

      if (officeOptions.length === 1) {
        handleUpdateMyReportsRequest('officeId', officeOptions[0].value);
        setOfficeValue(officeOptions[0].value);
        myReport.officeId = officeOptions[0].value;
      } else {
        const allOfficeOption: SelectOption = { name: 'All', value: 'All' };
        officeOptions.unshift(allOfficeOption);
        setOfficeValue('All');
        myReport.officeId = 'All';
      }
      setOfficeOptions(officeOptions);

      //Assigning account values
      const accountOptions: SelectOption[] = [];
      const allAccountOption: SelectOption = { name: 'All', value: 'All' };
      accountOptions.unshift(allAccountOption);
      setAccountOptions(accountOptions);
    }
  }, [userFirm, profile]);

  useEffect(() => {
    let firm: Firm | undefined | null;
    if (profile?.isHqUser === true && firmValue != '') {
      firm = profile?.firms?.find((firm) => firm.firmId === firmValue);
    } else {
      firm = userFirm;
    }
    if (officeValue === 'All') {
      //Assigning office options and default office when there is a HQ user
      if (profile?.isHqUser === true) {
        if (firm) {
          firm.offices = firm.offices?.filter((office) => office.status === 'Active' || office.status === 'active');
          firm.offices?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

          const officeOptions: SelectOption[] =
            firm.offices?.map((office) => {
              return { name: office.name, value: office.officeId };
            }) ?? [];

          if (officeOptions.length === 1) {
            handleUpdateMyReportsRequest('officeId', officeOptions[0].value);
            setOfficeValue(officeOptions[0].value);
            myReport.officeId = officeOptions[0].value;
          } else {
            const allOfficeOption: SelectOption = { name: 'All', value: 'All' };
            officeOptions.unshift(allOfficeOption);
            setOfficeValue('All');
            myReport.officeId = 'All';
          }
          setOfficeOptions(officeOptions);
        }
      }

      setInActiveOffice(false);
      //Assigning account value when office is All
      myReport.accountId = 'All';
      const accountOptions: SelectOption[] = [];
      const allAccountOption: SelectOption = { name: 'All', value: 'All' };
      accountOptions.unshift(allAccountOption);
      setAccountOptions(accountOptions);
      handleUpdateMyReportsRequest('accountId', 'All');

      //Assigning state value when office is All
      const stateOptions: SelectOption[] = [];
      const allStateOption: SelectOption = { name: 'All', value: 'All' };
      stateOptions.unshift(allStateOption);
      setStateOptions(stateOptions);
      handleUpdateMyReportsRequest('stateCode', 'All');
      SetComplianceDate(stateOptions.some((x) => x.value == 'CO' || x.value == 'MO') ? true : false);
    } else {
      //Assigning account values when office is selected
      myReport.accountId = 'All';
      const office = firm?.offices?.find((office) => office.officeId === officeValue);
      const accountOptions: SelectOption[] = [];
      let modifiedAccountOptions: FirmAccount[] | undefined;
      if (office?.accounts) {
        modifiedAccountOptions = office?.accounts?.filter(
          (account) => account.status === 'Active' || account.status === 'active',
        );
        modifiedAccountOptions?.sort((a, b) => a.accountId - b.accountId);
      }
      modifiedAccountOptions?.forEach((account) => {
        const name: string = account.accountId + '-' + account.stateCode + '-' + account.underwriterCode;
        accountOptions.push({ name: name, value: account.accountId });
      });
      if (accountOptions.length === 1) {
        handleUpdateMyReportsRequest('accountId', accountOptions[0].value);
        myReport.accountId = accountOptions[0].value;
      } else {
        const allAccountOption: SelectOption = { name: 'All', value: 'All' };
        accountOptions.unshift(allAccountOption);
        handleUpdateMyReportsRequest('accountId', 'All');
      }
      setAccountOptions(accountOptions);
      handleUpdateMyReportsRequest('accountId', accountOptions[0].value);

      //Assigning state values when office is selected
      const stateOptions: SelectOption[] = [];
      office?.accounts?.forEach((account) => {
        const stateCode = account.stateCode;
        if (stateOptions.findIndex((x) => x.value === stateCode) === -1) {
          stateOptions.push({ name: stateCode, value: stateCode });
        }
      });
      stateOptions.sort((a, b) => (a?.name ?? '').toLowerCase().localeCompare((b?.name ?? '').toLowerCase()));
      if (stateOptions.length === 1) {
        handleUpdateMyReportsRequest('stateCode', stateOptions[0].value);
      } else {
        const allStateOption: SelectOption = { name: 'All', value: 'All' };
        stateOptions.unshift(allStateOption);
        handleUpdateMyReportsRequest('stateCode', 'All');
      }
      setStateOptions(stateOptions);
      SetComplianceDate(stateOptions.some((x) => x.value == 'CO' || x.value == 'MO') ? true : false);
      const inActiveOffice = firm?.offices?.find(
        (office) => office.officeId === officeValue && office.status !== 'Active' && office.status !== 'active',
      );
      if (inActiveOffice) {
        setInActiveOffice(true);
      } else {
        setInActiveOffice(false);
      }
    }
  }, [officeValue]);

  useEffect(() => {
    if (firmValue == 'All') {
      setOfficeValue('All');
      myReport.officeId = 'All';
      const officeOptions: SelectOption[] = [];
      const allOfficeOption: SelectOption = { name: 'All', value: 'All' };
      officeOptions.unshift(allOfficeOption);
      setOfficeOptions(officeOptions);
      handleUpdateMyReportsRequest('officeId', 'All');
    } else {
      if (profile) {
        const firm = profile.firms?.find((firm) => firm.firmId === firmValue);
        if (firm) {
          firm.offices = firm.offices?.filter((office) => office.status === 'Active' || office.status === 'active');
          firm.offices?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          const officeOptions: SelectOption[] =
            firm.offices?.map((office) => {
              return { name: office.name, value: office.officeId };
            }) ?? [];

          if (officeOptions.length === 1) {
            handleUpdateMyReportsRequest('officeId', officeOptions[0].value);
            setOfficeValue(officeOptions[0].value);
            myReport.officeId = officeOptions[0].value;
          } else {
            const allOfficeOption: SelectOption = { name: 'All', value: 'All' };
            officeOptions.unshift(allOfficeOption);
            setOfficeValue('All');
            myReport.officeId = 'All';
          }

          setOfficeOptions(officeOptions);
        }
      }
    }
  }, [firmValue]);

  const reportTypeOptions = [
    { name: 'CPL Activity Log', value: 'CPL Activity Log' },
    { name: 'Incomplete Files', value: 'Incomplete Files' },
    { name: 'Policy Log', value: 'Policy Log' },
    { name: 'Service Order Activity Log', value: 'Service Order Activity Log' },
  ];

  const statusOption = [
    { name: 'Pending', value: 'Pending' },
    { name: 'Delivered', value: 'Delivered' },
    { name: 'Unsubmitted', value: 'Unsubmitted' },
    { name: 'Opened In Error', value: 'Opened In Error' },
    { name: 'Cancelled', value: 'Cancelled' },
  ];

  const statusPolicyLogOptions = [
    { name: 'All', value: 'All' },
    { name: 'Open', value: 'Open' },
    { name: 'Pending', value: 'Pending' },
    { name: 'Pending Void', value: 'Pending Void' },
    { name: 'Remitted', value: 'Remitted' },
    { name: 'Void', value: 'Void' },
  ];

  const dateTypeOptions = [
    { name: 'Jacket Creation Date', value: 'Jacket Creation Date' },
    { name: 'Date of Policy', value: 'Date of Policy' },
  ];

  const dateTypeOptionswithComplianceDate = [
    { name: 'Jacket Creation Date', value: 'Jacket Creation Date' },
    { name: 'Date of Policy', value: 'Date of Policy' },
    { name: 'Policy Compliance Date', value: 'Policy Compliance Date' },
  ];

  const radioOption = [
    { name: 'PDF', value: 'PDF', dataQA: 'MyReportPdf' },
    { name: 'XLS', value: 'EXCEL', dataQA: 'MyReportXls' },
  ];

  const letterOptions = ['Open', 'Pending', 'Pending Void', 'Remitted', 'Void', 'Closed'];

  const radioOptions = radioOption.map((x) => {
    return { label: x.name, value: x.value, dataQA: x.dataQA };
  });

  const selectedRadioOption = radioOptions?.find((option) => option.value === myReport.reportFormat);

  console.log('userFirm', userFirm);
  console.log('myReport', myReport);

  //Incomplete  Promise
  const incompleteFilesReport = async (): Promise<any> => {
    setSearchFlag(true);
    const token = await getAccessToken();

    return incompleteFiles(incompleteFilesPayload, token);
  };

  const {
    execute: executeIncompleteFilesReport,
    value: incompleteFilesResponseValue,
    status: incompleteFilesResponseStatus,
    errors: incompleteFilesResponseError,
  } = useAsync<any>(incompleteFilesReport, false);

  //service order log Promise
  const serviceOrderLogReport = async (): Promise<any> => {
    setSearchFlag(true);
    const token = await getAccessToken();

    return serviceOrderLog(serviceOrderLogPayload, token);
  };

  const {
    execute: executeServiceOrderLogReport,
    value: ServiceOrderLogResponseValue,
    status: ServiceOrderLogResponseStatus,
    errors: ServiceOrderLogResponseError,
  } = useAsync<any>(serviceOrderLogReport, false);

  //CPL Activity Log Promise
  const CplActivityLogReport = async (): Promise<any> => {
    setSearchFlag(true);
    const token = await getAccessToken();

    return CplActivityLog(cplActivityLogPayload, token);
  };

  const {
    execute: executeCplActivityLogReport,
    value: CPLActivityLogResponseValue,
    status: CPLActivityLogResponseStatus,
    errors: CPLActivityLogResponseError,
  } = useAsync<any>(CplActivityLogReport, false);

  //Policy Log Promise
  const PolicyLogReport = async (): Promise<any> => {
    setSearchFlag(true);
    const token = await getAccessToken();

    return PolicyLog(policyLogPayload, token);
  };

  const {
    execute: executePolicyLogReport,
    value: PolicyResponseValue,
    status: PolicyResponseStatus,
    errors: PolicyResponseError,
  } = useAsync<any>(PolicyLogReport, false);

  useEffect(() => {
    if (
      incompleteFilesResponseValue ||
      ServiceOrderLogResponseValue ||
      CPLActivityLogResponseValue ||
      PolicyResponseValue
    ) {
      addSnackbarMessage({
        message: 'Report Requested Successfully',
        type: 'success',
      });
      setSearchFlag(false);
    }
  }, [incompleteFilesResponseValue, ServiceOrderLogResponseValue, CPLActivityLogResponseValue, PolicyResponseValue]);

  useEffect(() => {
    setDisplayError(
      incompleteFilesResponseStatus === 'error' ||
        ServiceOrderLogResponseStatus === 'error' ||
        CPLActivityLogResponseStatus === 'error' ||
        PolicyResponseValue === 'error' ||
        getHistoryStatus === 'error',
    );
  }, [
    incompleteFilesResponseStatus,
    ServiceOrderLogResponseStatus,
    CPLActivityLogResponseStatus,
    PolicyResponseStatus,
    getHistoryStatus,
  ]);

  useEffect(() => {
    const errorMessages = incompleteFilesResponseError?.length
      ? incompleteFilesResponseError
      : ServiceOrderLogResponseError?.length
      ? ServiceOrderLogResponseError
      : CPLActivityLogResponseError?.length
      ? CPLActivityLogResponseError
      : PolicyResponseError?.length
      ? PolicyResponseError
      : getHistoryErrors?.length
      ? getHistoryErrors
      : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
      setSearchFlag(false);
    }
  }, [displayError, errMsgs]);

  const handleUpdateMyReportsRequest = (propertyName: string, value: any) => {
    const updatedValue: any = { ...myReport, [propertyName]: value };
    setMyReport(updatedValue);
  };

  const validateMyReportErrors = async () => {
    const errs: FieldValidationError[] = (await doValidate(myReport, MyReportSchema)) || [];

    if (inActiveOffice) {
      errs.push({
        field: 'officeId',
        message: 'Your report criterion does not contain an active office or account. A report cannot be generated.',
      });
    } else {
      errs.filter((x) => x.field !== 'officeId');
    }

    setValidationErrors(errs);
  };
  console.log('validationErrors', validationErrors);
  console.log('status', status);

  useEffect(() => {
    validateMyReportErrors();
  }, [myReport]);

  const CreateReport = () => {
    console.log('My Report', myReport);
    setShouldShowValidation(true);
    if (validationErrors.some((x) => x.field === 'officeId')) {
      addGlobalMsg({
        message: validationErrors.find((x) => x.field === 'officeId')?.message,
        type: 'error',
      });
      setSearchFlag(false);
    }
    if (validationErrors.length == 0) {
      if (reportType == 'Incomplete Files') {
        const reportPayload: InCompleteFiles = {
          firmId: myReport.firmId == 'All' ? '0' : myReport.firmId,
          officeId: myReport.officeId == 'All' ? '0' : myReport.officeId,
          accountId: myReport.accountId == 'All' ? '0' : myReport.accountId,
          reportName: 'IncompleteFiles',
          reportFormat: myReport.reportFormat,
          startDate: myReport.startDate,
          endDate: myReport.endDate,
        };
        incompleteFilesPayload = reportPayload;
        //Call API
        executeIncompleteFilesReport().then();
      } else if (reportType == 'Service Order Activity Log') {
        const reportPayload: ServiceOrderLogReport = {
          firmId: myReport.firmId == 'All' ? '0' : myReport.firmId,
          officeId: myReport.officeId == 'All' ? '0' : myReport.officeId,
          stateCode: myReport.stateCode,
          reportName: 'ServiceOrderActivityLog',
          reportFormat: myReport.reportFormat,
          status: status.map(String).join(','), // status.join(','),
          startDate: myReport.startDate,
          endDate: myReport.endDate,
        };
        serviceOrderLogPayload = reportPayload;
        //Call API
        executeServiceOrderLogReport().then();
      } else if (reportType == 'CPL Activity Log') {
        const reportPayload: CPLActivityLogReport = {
          officeId: myReport.officeId == 'All' ? '0' : myReport.officeId,
          firmId: myReport.firmId == 'All' ? '0' : myReport.firmId,
          reportName: 'CPLActivityLog',
          reportFormat: myReport.reportFormat,
          letterStatus: reportSearchType.map(String).join(','),
          stateCode: myReport.stateCode,
          startDate: myReport.startDate,
          endDate: myReport.endDate,
        };
        cplActivityLogPayload = reportPayload;
        //Call API
        executeCplActivityLogReport().then();
      } else if (reportType == 'Policy Log') {
        const reportPayload: PolicyLogReport = {
          officeId: myReport.officeId == 'All' ? '0' : myReport.officeId,
          accountID: myReport.accountId == 'All' ? '0' : myReport.accountId,
          firmId: myReport.firmId == 'All' ? '0' : myReport.firmId,
          reportName: 'PolicyLog',
          reportFormat: myReport.reportFormat,
          startDate: myReport.startDate,
          endDate: myReport.endDate,
          status: myReport.policyLogStatus,
          dateType: myReport.dateType,
        };
        policyLogPayload = reportPayload;
        //Call API
        executePolicyLogReport().then();
      }
    } else {
      addSnackbarMessage({
        message: 'Create Report Failed',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (
      incompleteFilesResponseStatus === 'success' ||
      ServiceOrderLogResponseStatus === 'success' ||
      CPLActivityLogResponseStatus === 'success' ||
      PolicyResponseStatus === 'success'
    ) {
      executeHistoryList().then();
    }
  }, [
    incompleteFilesResponseStatus,
    ServiceOrderLogResponseStatus,
    CPLActivityLogResponseStatus,
    PolicyResponseStatus,
  ]);

  return (
    <>
      <div className={classes.root}>
        <div className="paper-layout contained">
          <LoadingSpinner status={isSearching ? 'pending' : 'success'} variant="linear" />
        </div>
        <div className={classes.contentWrap}>
          <AgentNetDivider
            variant={DividerType.header}
            title="Reports Search"
            typoVariant="h1"
            buttonName={showFields ? 'Collapse Search -' : 'Expand Search +'}
            onClick={() => {
              setShowFields(!showFields);
            }}
            buttonNameQaAttr={showFields ? 'CollapseSearch' : 'ExpandSearch'}
          />
          {showFields && (
            <div>
              <Grid container spacing={2} className={classes.fieldPadding}>
                <Grid item sm={3}>
                  <AgentNetDropdownSelector
                    label={'Report Type'}
                    name={'MyReportType'}
                    qaAttribute={'MyReportType'}
                    required
                    value={reportType}
                    options={reportTypeOptions}
                    menuOption={(value: any) => {
                      setReportType(value);
                      setStateOptions([{ name: 'All', value: 'All' }]);
                      setMyReport(defaultValues);
                    }}
                    showValidation={shouldShowValidation}
                    errs={validationErrors}
                  />
                </Grid>
                {reportType == 'CPL Activity Log' || reportType == 'Service Order Activity Log' ? (
                  <Grid item sm={3}>
                    <AgentNetDropdownSelector
                      label={'Firm'}
                      name={'Firm'}
                      qaAttribute={'MyReportFirm'}
                      required
                      value={myReport?.firmId ?? ''}
                      options={firmOptions}
                      menuOption={(value: any) => {
                        setFirmValue(value);
                        handleUpdateMyReportsRequest('firmId', value);
                      }}
                      showValidation={shouldShowValidation}
                      errs={validationErrors}
                    />
                  </Grid>
                ) : (
                  <>
                    {reportType == 'Policy Log' ? (
                      <Grid item sm={3}>
                        <AgentNetDropdownSelector
                          label={'Date Type'}
                          name={'DateType'}
                          qaAttribute={'MyReportPolicyLogDateType'}
                          required
                          value={myReport?.dateType ?? ''}
                          options={isComplianceDate ? dateTypeOptionswithComplianceDate : dateTypeOptions}
                          menuOption={(value: any) => {
                            handleUpdateMyReportsRequest('dateType', value);
                          }}
                          showValidation={shouldShowValidation}
                          errs={validationErrors}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                  </>
                )}
                <Grid item sm={2}>
                  <DateField
                    fullWidth
                    variant="outlined"
                    label="From"
                    name="startDate"
                    qaAttribute={'MyReportFromDate'}
                    required
                    value={myReport?.startDate ?? ''}
                    onChange={(e: any) => {
                      if (e == null) {
                        handleUpdateMyReportsRequest('startDate', '');
                      } else {
                        handleUpdateMyReportsRequest('startDate', e);
                      }
                    }}
                    disableFuture
                    showValidation={shouldShowValidation}
                    errs={validationErrors}
                  />
                </Grid>
                <Grid>
                  <Typography variant="h1" className={classes.fromToDate}>
                    -
                  </Typography>
                </Grid>
                <Grid item sm={2}>
                  <DateField
                    fullWidth
                    variant="outlined"
                    label="To"
                    name="endDate"
                    qaAttribute={'MyReportToDate'}
                    required
                    minDate={myReport?.startDate ?? ''}
                    disableFuture
                    value={myReport?.endDate ?? ''}
                    onChange={(e: any) => {
                      if (e == null) {
                        handleUpdateMyReportsRequest('endDate', '');
                      } else {
                        handleUpdateMyReportsRequest('endDate', e);
                      }
                    }}
                    showValidation={shouldShowValidation}
                    errs={validationErrors}
                  />
                </Grid>
              </Grid>

              <Grid className={classes.radioButton}>
                <RadioGroup
                  options={radioOptions}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleUpdateMyReportsRequest('reportFormat', e.target.value);
                  }}
                  selected={selectedRadioOption}
                  label="File Type"
                  Data-QA="MyReportFormat"
                />
              </Grid>

              <Typography variant="h1" className={classes.reportSearch}>
                Filter Results
              </Typography>

              <Grid container spacing={2} className={classes.fieldPadding}>
                {reportType == 'Service Order Activity Log' ? (
                  <Grid item sm={3}>
                    <Grid style={{ display: 'flex' }}>
                      <TextField
                        select
                        variant="outlined"
                        label="Status"
                        name="serviceOrderLogStatus"
                        data-qa="MyReportServiceOrderActivityLogStatus"
                        required
                        SelectProps={{
                          SelectDisplayProps: {
                            title: status.join(', ').toString(),
                          },
                          displayEmpty: true,
                          multiple: true,
                          MenuProps: {
                            variant: 'menu',
                            PaperProps: {
                              style: { maxHeight: 200 },
                            },
                          },
                          value: Array.isArray(status) ? status : [],
                          onChange: (e: any) => {
                            const statusArray = e.target.value as string[];
                            setStatus(statusArray);
                            handleUpdateMyReportsRequest('serviceOrderLogStatus', statusArray);
                          },
                          renderValue: (selected) => (selected as string[]).join(', '),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={
                          validationErrors.some((x) => x.field === 'serviceOrderLogStatus') && shouldShowValidation
                            ? true
                            : false
                        }
                        fullWidth
                      >
                        {statusOption.map((option: any) => (
                          <MenuItem key={option.name} value={option.value}>
                            <ListItemIcon>
                              <AgentNetCheckBox
                                size="medium"
                                checkHandler={() => console.log('clicked')}
                                value={status ? status.includes(option.value) : false}
                              />
                            </ListItemIcon>
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </TextField>
                      <Tooltip data-qa={'maxLiabililityToolTip'} title={statusToolTipMessage} style={iconStyles}>
                        <HelpOutline fontSize="large" color="primary" />
                      </Tooltip>
                    </Grid>
                    {validationErrors && shouldShowValidation && (
                      <ErrorSection errs={validationErrors} field={'serviceOrderLogStatus'} />
                    )}
                  </Grid>
                ) : (
                  <>
                    {reportType == 'CPL Activity Log' ? (
                      <Grid item sm={3}>
                        <TextField
                          select
                          variant="outlined"
                          label={'Letter Status'}
                          name="letterStatus"
                          data-qa="MyReportCplLetterStatus"
                          required
                          SelectProps={{
                            SelectDisplayProps: {
                              title: reportSearchType.join(', ').toString(),
                            },
                            multiple: true,
                            displayEmpty: true,
                            MenuProps: {
                              variant: 'menu',
                              PaperProps: {
                                style: { maxHeight: 200 },
                              },
                            },
                            required: true,
                            value: Array.isArray(reportSearchType) ? reportSearchType : [],
                            onChange: (event) => {
                              cplReportArray = event.target.value as string[];
                              setReportSearchType(cplReportArray);
                              handleUpdateMyReportsRequest('letterStatus', cplReportArray);
                            },
                            renderValue: (selected) => (selected as string[]).join(','),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            validationErrors.some((x) => x.field === 'letterStatus') && shouldShowValidation
                              ? true
                              : false
                          }
                          fullWidth={true}
                        >
                          {letterOptions.map((opt: any) => (
                            <MenuItem key={opt} value={opt}>
                              <AgentNetCheckBox
                                value={
                                  reportSearchType ? reportSearchType && reportSearchType.indexOf(opt) > -1 : false
                                }
                                size="medium"
                                checkHandler={() => console.log('clicked')}
                              />
                              <ListItemText primary={opt} />
                            </MenuItem>
                          ))}
                        </TextField>
                        {validationErrors && shouldShowValidation && (
                          <ErrorSection errs={validationErrors} field={'letterStatus'} />
                        )}
                      </Grid>
                    ) : (
                      <>
                        {reportType == 'Policy Log' ? (
                          <Grid item sm={3}>
                            <AgentNetDropdownSelector
                              label={'Status'}
                              name={'policyLogStatus'}
                              qaAttribute={'MyReportPolicyLogStatus'}
                              required
                              value={myReport?.policyLogStatus ?? ''}
                              options={statusPolicyLogOptions}
                              menuOption={(value: any) => {
                                handleUpdateMyReportsRequest('policyLogStatus', value);
                              }}
                              showValidation={shouldShowValidation}
                              errs={validationErrors}
                            />
                          </Grid>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </>
                )}

                <Grid item sm={3}>
                  <AgentNetDropdownSelector
                    label={'Office'}
                    name={'Office'}
                    qaAttribute={'MyReportOffice'}
                    value={myReport?.officeId ?? ''}
                    options={officeOptions}
                    menuOption={(value: any) => {
                      setOfficeValue(value);
                      handleUpdateMyReportsRequest('officeId', value);
                    }}
                    showValidation={shouldShowValidation}
                    errs={validationErrors}
                  />
                </Grid>
                {reportType == 'Policy Log' || reportType == 'Incomplete Files' ? (
                  <Grid item sm={3}>
                    <AgentNetDropdownSelector
                      label={'Account'}
                      name={'Account'}
                      qaAttribute={'MyReportAccount'}
                      required
                      value={myReport?.accountId ?? ''}
                      options={accountOptions}
                      menuOption={(value: any) => {
                        handleUpdateMyReportsRequest('accountId', value);
                      }}
                      showValidation={shouldShowValidation}
                      errs={validationErrors}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item sm={3}>
                      <AgentNetDropdownSelector
                        label={'State'}
                        name={'State'}
                        qaAttribute={'MyReportState'}
                        value={myReport?.stateCode ?? ''}
                        options={stateOptions}
                        menuOption={(value: any) => {
                          handleUpdateMyReportsRequest('stateCode', value);
                        }}
                        showValidation={shouldShowValidation}
                        errs={validationErrors}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <div className={classes.createButton}>
                <AgentNetButton color="primary" variant="contained" onClick={CreateReport} data-qa="CreateReport">
                  Create Report
                </AgentNetButton>
              </div>
            </div>
          )}
          {getHistoryStatus === 'success' && (
            <>
              <div>
                <MyReportsList getHistoryResults={getHistoryResults} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MyReportsContent;
