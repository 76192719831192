import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ANFooterLogo } from 'ui-kit/icons/Logo';

const useStyles = makeStyles((theme) => ({
  footer: {
    margin: '6.4rem 0 0 0',
    display: 'flex',
    width: 'calc(100% - 64px)',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10.5px 32px',
    borderTop: '1px solid #C4C4C4',
  },
  footerText: {
    textAlign: 'right',
  },
  footerTos: {
    marginLeft: theme.spacing(2),
    display: 'inline-block',
    '& span': {
      padding: '0 10px',
      color: theme.palette.text.secondary,
      transform: `translateY(1px)`,
      display: 'inline-block',
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      '&:hover, &:visited': {
        color: theme.palette.primary.dark,
      },
    },
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <a href="https://www.firstam.com/" target="_blank" rel="noreferrer">
        <ANFooterLogo />
      </a>
      <div className={classes.footerText}>
        <Typography variant="h6" color="textSecondary" className={classes.mb1}>
          AgentNet V4.0.0.0
        </Typography>
        <Typography variant="h6" color="textSecondary">
          © 2023 First American Financial Corporation and/or its affiliates. All rights reserved.
          <div className={classes.footerTos}>
            <a href="https://www.firstam.com/terms-of-use/" target="_blank" rel="noreferrer">
              Terms of Use
            </a>
            <span>|</span>
            <a href="https://www.firstam.com/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </div>
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
