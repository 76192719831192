import { IProfileResponse, IUserFirm, IUserFirmAccounts, IUserFirmAddress, IUserFirmOffice } from './interfaces';
import { Firm, FirmAccount, FirmAddress, Office, Profile } from './types';
import { SelectOption } from '../../ui-kit/inputs/AgentNetDropdownSelector';
import DeepSet from '../../utilities/DeepSet';
import { ILenderFirm } from 'features/files/parties/interfaces/LenderModels';

export const ADMIN_ROLE_ID = 3;

export function parseAddress(addressApiData: IUserFirmAddress): FirmAddress {
  const {
    Address1: address1,
    Address2: address2,
    City: city,
    PostalCode: postalCode,
    State: state,
    County: county,
  } = addressApiData || {};
  return {
    address1,
    address2,
    city,
    postalCode,
    state,
    county,
  };
}

export function parseAccount(accountData: IUserFirmAccounts): FirmAccount {
  const {
    AccountId: accountId,
    AccountNumber: accountNumber,
    Name: name,
    StateCode: stateCode,
    UnderwriterCode: underwriterCode,
    UnderwriterName: underwriterName,
    AccountStatus: accountStatus,
    Status: status,
    ClientType: clientType,
    ClientTypeId: clientTypeId,
  } = accountData;
  return {
    accountId,
    accountNumber,
    name,
    stateCode,
    underwriterCode,
    underwriterName,
    accountStatus,
    status,
    clientType,
    clientTypeId,
  };
}

export function parseOffice(officeData: IUserFirmOffice): Office {
  const { OfficeId, Name, Address, Accounts, OfficeStatus, IsDefault, Status } = officeData;
  return {
    officeId: OfficeId,
    name: Name,
    address: parseAddress(Address),
    accounts: Accounts.map((accountData: IUserFirmAccounts) => parseAccount(accountData)),
    officeStatus: OfficeStatus,
    isDefault: IsDefault,
    status: Status,
  };
}

export function parseFirm(firmApiData: IUserFirm): Firm {
  const { FirmId, Name, Offices, FirmStatus, IsDefault, PolicySoftware, Status } = firmApiData;
  return {
    firmId: FirmId ? FirmId.toString() : '',
    name: Name ?? '',
    offices: Offices ? Offices.map((officeData: IUserFirmOffice) => parseOffice(officeData)) : [],
    firmStatus: FirmStatus,
    isDefault: IsDefault,
    policySoftware: PolicySoftware,
    status: Status,
  };
}

export function parseProfile(profileApiData: IProfileResponse): Profile {
  const {
    UserId: userId,
    Login: login,
    AzureObjectId: azureObjectId,
    EmailAddress: emailAddress,
    FirstName: firstName,
    MiddleName: middleName,
    LastName: lastName,
    PhoneNumber: phoneNumber,
    RoleId: roleId,
    RoleTypeId: roleTypeId,
    IsHqUser: isHqUser,
    HqId: hqId,
    IsActive: isActive,
    IsESignatureEnable: isESignatureEnable,
    IsMultiFirmAccess: isMultiFirmAccess,
    FirmAccess: firmAccess,
    ActivityRights: activityRights,
  } = profileApiData || {};
  return {
    userId,
    login,
    azureObjectId,
    emailAddress,
    firstName,
    middleName,
    lastName,
    phoneNumber,
    roleId,
    roleTypeId,
    isHqUser,
    hqId,
    isActive,
    isESignatureEnable,
    isMultiFirmAccess,
    firms: firmAccess ? firmAccess.map((firmData: IUserFirm) => parseFirm(firmData)) : [],
    activityRights,
  };
}

/** Returns unique state select-options for given firm */
export function getStateOptions(firmData: Firm): Array<SelectOption> {
  const stateOptions: SelectOption[] = [];
  if (!firmData) return [];
  if (firmData.offices) {
    for (const office of firmData.offices) {
      for (const account of office.accounts) {
        stateOptions.push({ name: account.stateCode, value: account.stateCode });
      }
    }
  }
  return Array.from(new DeepSet(stateOptions)).sort((a, b) => (a.name < b.name ? -1 : 0));
}

/** Returns select options for offices that contain an account with given stateCode */
export function getOfficeOptionsByState(
  firmData: Firm | null | undefined,
  state: string | null | undefined,
): Array<SelectOption> {
  const offices = [];
  if (!firmData || !state) return [];

  if (firmData.offices) {
    for (const office of firmData.offices) {
      for (const account of office.accounts) {
        if (account.stateCode === state) offices.push({ name: office.name, value: office.officeId.toString() });
      }
    }
  }
  return Array.from(new DeepSet(offices)).sort((a, b) => (a.name < b.name ? -1 : 0));
}

/** Returns unique underwriter select-options for given firm by officeId + State */
export function getUnderwriterOptionsByOfficeAndState(
  firmData: Firm | null | undefined,
  officeId: number | null | undefined,
  state: string | null | undefined,
): Array<SelectOption> {
  if (!firmData || !officeId) return [];
  const underwriters = [];
  if (firmData.offices) {
    for (const office of firmData.offices) {
      for (const account of office.accounts) {
        if (office.officeId === officeId && account.stateCode === state)
          underwriters.push({ name: account.underwriterName, value: account.underwriterCode.toString() });
      }
    }
  }
  return Array.from(new DeepSet(underwriters)).sort((a, b) => (a.name < b.name ? -1 : 0));
}

/** Returns account id for given options */
export function getAccountIdByOfficeAndUnderwriterAndState(
  firmData: Firm | null | undefined,
  officeId: number | null | undefined,
  stateCode: string | null | undefined,
  underwriterCode: string | null | undefined,
): number | undefined {
  if (!firmData || !underwriterCode || !officeId || !stateCode) return undefined;
  let foundActiveAccId = false;
  let finalAccountId;
  let foundInactiveAccId = false;
  if (firmData.offices) {
    for (const office of firmData.offices) {
      if (office.officeId === officeId) {
        for (const account of office.accounts) {
          if (account.underwriterCode === underwriterCode && account.stateCode === stateCode && !foundActiveAccId) {
            if (account.status === 'Active') {
              foundActiveAccId = true;
              finalAccountId = account.accountId;
            } else if (!foundInactiveAccId) {
              foundInactiveAccId = true;
              finalAccountId = account.accountId;
            }
          }
        }
        return finalAccountId;
      }
    }
  }
}

export function getDefaultFirm(firms: Firm[] | undefined): Firm | undefined {
  if (!firms) return undefined;
  return firms.find((firm: Firm) => firm.isDefault);
}

export function sortLenders(lender: ILenderFirm[]) {
  return lender
    ?.map((lender) => {
      const { Address1, Address2, City, State, PostalCode } = lender.Address;
      const option = `${lender.Name}, ${Address1}${Address2 && Address2.trim() ? `, ${Address2}` : ''}, ${City}${
        State ? `, ${State}` : ''
      } ${PostalCode}`;
      return {
        name: option.trim(),
        value: lender.AddressBookEntryId,
        createdDate: new Date(lender.CreatedDate).getTime(),
      };
    })
    .sort((firstLender, secondLender) => {
      if (firstLender.name.toLowerCase() === secondLender.name.toLowerCase()) {
        return secondLender.createdDate - firstLender.createdDate;
      } else if (firstLender.name.toLowerCase() > secondLender.name.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    });
}
