import { fileInfoType } from './interfaces/get-file';
import { getApiHost } from '../host-by-env';
import { searchOptionsType, FileResults } from 'features/files/files-summary/types';
import { ICreateFileResponse } from './interfaces/create-file';
import { handleError } from 'utilities/utilities';

/* Use Async to Handle Property Details */

export type getFileInfoProps = {
  fileId?: string;
};

/* Use Async GET Request */
export async function getFileInfo(fileId: string, token: string): Promise<fileInfoType> {
  const url = `${getApiHost()}file/${fileId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

/* Use Async POST Request */
export async function createFile(payload: ICreateFileResponse, token: string): Promise<string> {
  const url = `${getApiHost()}file/`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });

  const json = await response.json();
  const { result, messages, errorCode } = json;
  handleError(json);
  return result?.fileId;
}

/* Use Async PUT Request */
export const saveFile = async (
  payload: ICreateFileResponse | undefined | null,
  fileId: string,
  token: string,
): Promise<ICreateFileResponse> => {
  const url = `${getApiHost()}file/${fileId}`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};

export async function searchFiles(
  payload: searchOptionsType | string,
  token: string,
): Promise<{ result: FileResults; errorCode?: string | number }> {
  const url = ` ${getApiHost()}search/search-files`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return await response.json();
}

export const saveFileStatus = async (payload: any, token: string): Promise<any> => {
  const url = `${getApiHost()}file/status`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
};

export async function getFileDetail(fileId: string | undefined, token: string): Promise<unknown> {
  const url = `${getApiHost()}file/summary/${fileId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export const saveFileReopen = async (payload: any, token: string): Promise<any> => {
  const url = `${getApiHost()}remittance/file/reopen`;
  let fileReopenResponse: any = {};
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });

  const json = await response.json();
  if (json.messages && json.messages.length > 0) {
    fileReopenResponse = { messages: json.messages, result: false };
  } else {
    fileReopenResponse = { result: json.result };
  }
  return fileReopenResponse;
};
