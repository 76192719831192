import {
  Endorsement,
  Jacket,
  JacketsPageData,
  JacketPdf,
  PolicyFieldsModel,
  PolicyType,
  StandaloneEndorsement,
  StandaloneEndorsementFormDataType,
  VoidJacket,
  RateTypes,
} from 'features/jackets/types';
import { getApiHost } from './host-by-env';
import { handleError } from 'utilities/utilities';

export async function getJacketTypes(stateCode: string, accountNumber: number, token: string): Promise<PolicyType[]> {
  const url = `${getApiHost()}jacket/jacket-types/${accountNumber}/${stateCode}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getJackets(fileId: string, token: string): Promise<Jacket[]> {
  const url = `${getApiHost()}jacket/jackets/${fileId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getJacketPdf(policyNumber: string, token: string): Promise<JacketPdf> {
  const url = `${getApiHost()}jacket/policy-document/${policyNumber}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getEndorsements(
  stateCode: string,
  underwriterCode: string,
  token: string,
): Promise<Endorsement[]> {
  const url = `${getApiHost()}jacket/endorsement-types/${stateCode}/${underwriterCode}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getStandaloneEndorsements(fileId: string, token: string): Promise<StandaloneEndorsement[]> {
  const url = `${getApiHost()}jacket/standalone-endorsement/${fileId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function postStandaloneEndorsements(
  standaloneEndorsementData: StandaloneEndorsementFormDataType,
  token: string,
): Promise<StandaloneEndorsement[]> {
  const url = `${getApiHost()}jacket/standalone-endorsement`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(standaloneEndorsementData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function postJackets(jacketsPageData: JacketsPageData, token: string): Promise<Jacket[]> {
  const url = `${getApiHost()}jacket/jackets`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(jacketsPageData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function putJackets(jacketsPageData: JacketsPageData, token: string): Promise<Jacket[]> {
  const url = `${getApiHost()}jacket/jackets`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(jacketsPageData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function deleteStandaloneEndorsements(fileId: string, token: string): Promise<boolean> {
  const url = `${getApiHost()}jacket/standalone-endorsement/${fileId}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function voidJacket(policyNumber: string, token: string): Promise<VoidJacket> {
  const url = `${getApiHost()}jacket/void`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ policyNumber }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getJacketFields(
  formNumber?: string,
  accountNumber?: number,
  stateCode?: string,
  county?: string,
  token?: string,
): Promise<PolicyFieldsModel> {
  const url = `${getApiHost()}jacket/policy-fields/${formNumber}/${accountNumber}/${stateCode}/${county}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getRateTypes(
  formNumber?: string,
  stateCode?: string,
  policyEffectiveDate?: string,
  isExtendedCoverage?: boolean,
  countyName?: string,
  accountNumber?: number,
  token?: string,
): Promise<RateTypes[]> {
  if (typeof isExtendedCoverage == 'undefined') {
    isExtendedCoverage = false;
  }
  const url = `${getApiHost()}jacket/rate-types/${formNumber}/${stateCode}/${policyEffectiveDate}/${isExtendedCoverage}/${countyName}/${accountNumber}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}
