import { getApiHost, getAgencyApiHost } from './host-by-env';
import { handleError } from 'utilities/utilities';

export async function getAutoReporting(token: string, firmId: string): Promise<any> {
  const url = `${getApiHost()}firms/autoReporting/${firmId}`;
  const response = await fetch(url, {
    method: 'Get',
    headers: { Authorization: `Bearer ${token}` },
  });

  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function saveAutoReporting(token: string, firmId: string, autoReportingData: any): Promise<any> {
  const url = `${getAgencyApiHost()}firm-service/v1/firms/admin/${firmId}/auto-Reporting`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(autoReportingData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}

export async function postAutoReporting(token: string, firmId: string, autoReportingData: any): Promise<any> {
  const url = `${getApiHost()}firms/autoReporting/${firmId}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(autoReportingData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  // const status = response['status'];
  return json.result;
}

export async function updateAutoReporting(token: string, firmId: string, autoReportingData: any): Promise<any> {
  const url = `${getApiHost()}firms/autoReporting/${firmId}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(autoReportingData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}
