import { makeStyles } from '@material-ui/core/styles';
import './usersList.scss';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#fff',
    color: ' #423d3d',
    fontSize: '13px',
    padding: '5px',
    whiteSpace: 'nowrap',
    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif;',
    border: '1px solid lightgrey',
  },
});

type LoginUserNameRendererProps = {
  loginUserId?: string;
};

const LoginUserNameRenderer = ({ loginUserId }: LoginUserNameRendererProps) => {
  const classes = useStyles(); // Declare the 'classes' variable
  return (
    <Tooltip title={loginUserId ?? ''} classes={{ tooltip: classes.tooltip }}>
      <div data-qa={loginUserId} className="loginUserId">
        {loginUserId}
      </div>
    </Tooltip>
  );
};

export default LoginUserNameRenderer;
