import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import useAsync from 'hooks/useAsync';
import { deleteEsignatureApi, getSignatureList } from 'api/admin/signature-api';
import { useAuth } from '@agentnet/auth';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { SignatureGridModel, GetSignature } from './types';
import NoResults from 'ui-kit/components/noResults/NoResults';
import clsx from 'clsx';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { signatureFilter } from '../constants';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { getStateOptions } from 'api/profile/profile-utilities';
import AddSignatureSliderFC from './AddSignatureSlider';
import { eSignatureContext } from '../types';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { DeleteEsignature } from 'features/constants';

const Signature = (): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: '6.4rem',
      },
      completeGrid: {
        width: '100%',
        height: '100%',
        padding: '0 24px 24px 24px',
      },
      tableDefault: {
        height: `calc(100vh - 160px) !important`,
        //fontFamily: theme.typography.fontFamily,
      },
      paddingX: {
        padding: '0 24px 24px 24px',
        //width: 'auto',
      },
      contentClass: {
        margin: 'auto',
      },
    }),
  );

  const [signatureData, setSignatureData] = useState<any>([]);
  const [searchFlag, setSearchFlag] = useState<boolean>(false);
  const { getAccessToken } = useAuth();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile } = profileCtx;
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const { addSnackbarMessage } = useSnackBars();
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

  let stateOptions = userFirm?.offices ? getStateOptions(userFirm) : [];
  stateOptions = stateOptions.filter((s) => s.value == 'TX');

  const [openDrawer, setOpenDrawer] = useState(false);
  const [signatureContext, setSignatureContext] = useState<eSignatureContext>();
  const [isDeleteESignature, setisDeleteESignature] = useState<boolean>(false);
  const [signaturedata, setsignaturedata] = useState<eSignatureContext>();

  const addEditButtonDisabled = () => {
    if (profile) {
      if (profile.roleTypeId != 100 && stateOptions.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getSignatureData = async (): Promise<GetSignature[]> => {
    setSearchFlag(true);
    const token = await getAccessToken();
    return await getSignatureList(userFirm?.firmId, token);
  };

  const {
    execute: executeSignatureList,
    value: getSignatureResults,
    status: getSignatureStatus,
    errors: getSignatureErrors,
  } = useAsync<any>(getSignatureData, false);

  const deleteSignatureData = async (): Promise<eSignatureContext> => {
    const token = await getAccessToken();
    setSearchFlag(true);
    return await deleteEsignatureApi(signaturedata, token);
  };

  const {
    execute: deleteESignature,
    value: deleteSignatureResults,
    status: deleteSignatureStatus,
    errors: deleteSignatureErrors,
  } = useAsync<any>(deleteSignatureData, false);

  useEffect(() => {
    setDisplayError(getSignatureStatus === 'error');
  }, [getSignatureStatus]);

  useEffect(() => {
    const errorMessages = getSignatureErrors?.length ? getSignatureErrors : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addSnackbarMessage({
          message: err,
          type: 'error',
        });
      });
      setSearchFlag(false);
    }
  }, [displayError, errMsgs]);

  console.log('getSignatureResults', getSignatureResults);

  useEffect(() => {
    if (deleteSignatureResults) {
      addSnackbarMessage({
        message: 'eSignature Deleted Successfully',
        type: 'success',
      });
      setSearchFlag(false);
      setSaveSuccess(true);
    }
  }, [deleteSignatureResults]);

  useEffect(() => {
    const errorMessages = deleteSignatureErrors?.length ? deleteSignatureErrors : [];
    setErrMsgs(errorMessages);
    setSearchFlag(false);
  }, [displayError]);

  useEffect(() => {
    setDisplayError(deleteSignatureStatus === 'error');
    setSearchFlag(false);
  }, [deleteSignatureStatus]);

  useEffect(() => {
    if (getSignatureResults) {
      const signatureData: SignatureGridModel[] = getSignatureResults.map((signature: any) => {
        return {
          signatoryName: signature.FullName,
          approvedStates: signature.ApprovedStates,
          eSignature: signature.ESignature,
          firmId: signature.FirmId,
          signatoryId: signature.SignatoryId,
          fullName: signature.FullName,
          isESignatureEnable: signature.IsESignatureEnable,
          firmESignatureId: signature.FirmESignatureId,
        };
      });
      setSignatureData(signatureData);
      setSearchFlag(false);
      // setShowGrid(true);
    }
  }, [getSignatureResults]);

  useEffect(() => {
    // fetch signature data
    executeSignatureList().then();
  }, []);

  const handeleDeleteESignature = (data: any) => {
    setsignaturedata(data);
    setisDeleteESignature(true);
  };

  const cancelDeleteESignature = () => {
    setisDeleteESignature(false);
  };

  const confirmDeleteESignature = () => {
    setisDeleteESignature(false);
    deleteESignature().then();
  };
  const signatureCellRenderer = (params: any) => {
    // Assuming params.value contains the binary data for the signature
    if (!params.value) return ''; // Return empty if no data

    // Convert binary data to base64 for image display (if applicable)
    const signatureImageSrc = `data:image/jpeg;base64,${params.value}`;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '100%',
          padding: '8px 0 8px 0',
          //   //height: 'auto',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            border: '1px solid #E0E0E0',
            //boxSizing: 'border-box',
            // maxWidth: '100%',
            // maxHeight: '10vh',
            width: '308px',
            height: '75px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            borderRadius: '10px',
          }}
        >
          <img
            src={signatureImageSrc}
            style={{
              // maxWidth: '100%',
              // maxHeight: '60vh',
              width: '100%',
              height: '75px',
              borderRadius: '10px',
              //objectFit: 'contain',
            }}
          />
        </div>
      </div>
    );
  };

  const eSignatureColumnsConfig: any = [
    {
      headerName: 'Signatory Name',
      field: 'signatoryName',
      tooltipField: 'signatoryName',
      filter: 'agTextColumnFilter',
      sortable: 'true',
      //width: 200,
      // height: 400,
    },
    {
      headerName: 'Approved States',
      field: 'approvedStates',
      //tooltipField: 'approvedStates',
      filter: 'agTextColumnFilter',
      // sortable: 'true',
      //width: 270,
      // height: 400,
      autoHeight: true,
      cellStyle: { wordWrap: 'break-word' },
    },
    {
      headerName: 'eSignature',
      field: 'eSignature',
      //tooltipField: 'eSignature',
      filter: 'agTextColumnFilter',
      // sortable: 'true',
      //width: 270,
      //height: 400,
      //flex: 1,
      autoHeight: true,
      cellStyle: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        // alignItems: 'center',
      },
      cellRenderer: signatureCellRenderer,
      filterParams: {
        filterOptions: signatureFilter.slice(0).map((signature) => ({
          key: signature.value,
          displayKey: signature.name,
          displayName: signature.name,
          test: (filterValue: any, cellValue: any) => {
            if (signature.value.toLowerCase() == 'select all') return true;
            else if (signature.value.toLowerCase() == 'signature available') {
              return cellValue ? true : false;
            } else if (signature.value.toLowerCase() == 'signature not available') {
              return cellValue ? false : true;
            }
          },
          hideFilterInput: true,
        })),
        //disabled: true,
      },
    },
    {
      //width: 165,
      // height: 400,
      filter: 'false',
      floatingFilter: false,
      cellRenderer: (params: any) => {
        {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
              {' '}
              {params.data.eSignature ? (
                <div>
                  <AgentNetButton
                    onClick={() => handleAddSignature(params?.data)}
                    style={{ margin: '0', padding: '4px 8px' }}
                    variant="outlined"
                    data-qa="EditSignature"
                    disabled={addEditButtonDisabled()}
                  >
                    Edit
                  </AgentNetButton>
                  <AgentNetButton
                    onClick={() => handeleDeleteESignature(params?.data)}
                    variant="text"
                    danger
                    data-qa="DeleteSignature"
                  >
                    Delete
                  </AgentNetButton>
                </div>
              ) : (
                <div>
                  <AgentNetButton
                    color="primary"
                    size="small"
                    onClick={() => handleAddSignature(params?.data)}
                    variant="contained"
                    data-qa="AddSignature"
                    disabled={addEditButtonDisabled()}
                  >
                    Add
                  </AgentNetButton>
                </div>
              )}
            </div>
          );
        }
      },
      //field: 'Buttons',
      //filter: 'false',
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      cellClass: 'left-align-column',
      headerClass: 'left-align-header',
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: false,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
      cellStyle: { whiteSpace: 'normal' },
    };
  }, []);

  const CustomOverlayNoRows = () => {
    if (getSignatureStatus == 'pending' && (!signatureData || signatureData.length === 0)) {
      return (
        <div className={classes.contentClass}>
          <NoResults
            showButton={false}
            type="results"
            titleText="No Results Found"
            pText="Try adjusting your filters to find what you’re looking for."
          />
        </div>
      );
    }
  };

  const classes = useStyles();

  // Handle Add Signature Slider
  const handleAddSignature = (sigContext: any) => {
    // console.log('SigContext===>', sigContext);
    setOpenDrawer(true);
    setSignatureContext(sigContext);
  };

  useEffect(() => {
    if (saveSuccess) {
      executeSignatureList().then();
      setSaveSuccess(false);
    }
  }, [saveSuccess]);

  return (
    <>
      {showGrid ? (
        <div className={classes.root}>
          <div className="paper-layout contained">
            <LoadingSpinner status={searchFlag ? 'pending' : 'success'} variant="linear" />
          </div>
          <div>
            <AgentNetDivider typoVariant="h1" title="eSignatures" />
          </div>
          {getSignatureStatus == 'success' && (
            <>
              <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
                <AgGridReact
                  rowData={signatureData}
                  columnDefs={eSignatureColumnsConfig}
                  defaultColDef={defaultColDef}
                  //rowSelection={'multiple'}
                  // suppressRowClickSelection={true}
                  //domLayout="autoHeight"
                  //rowHeight={91}
                  components={{
                    customOverlayNoRows: CustomOverlayNoRows,
                  }}
                  noRowsOverlayComponent={'customOverlayNoRows'}
                ></AgGridReact>
              </div>
              <div>
                <AddSignatureSliderFC
                  isDrawerOpen={openDrawer}
                  closeDrawer={() => setOpenDrawer(false)}
                  sliderSignatureCtx={signatureContext}
                  saveSuccess={(saveSuccess: boolean) => setSaveSuccess(saveSuccess)}
                />
              </div>
              <div>
                <AgentNetConfirmationDialog
                  qaAttrPrefix="ConfirmationEndorsementDelete"
                  onConfirm={confirmDeleteESignature}
                  open={isDeleteESignature}
                  onDismissAction={cancelDeleteESignature}
                  dialogTitle="Delete eSignature"
                  dialogBtnContent="Yes, Delete"
                  dialogText={DeleteEsignature.DELETE_ESIG_CONFIRM_MSG}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Signature;
