import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
interface InfoWithTooltipProps {
  title?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'error' | 'disabled';
  size?: 'small' | 'default' | 'large';
  style?: React.CSSProperties;
  placement?: any;
  arrow?: boolean;
}

const InfoWithTooltip: React.FC<InfoWithTooltipProps> = ({
  title = 'More Info',
  color = 'primary',
  size = 'small',
  style = { cursor: 'pointer' },
  arrow = false,
  placement,
}) => {
  return (
    <Tooltip title={title} placement={placement} arrow={arrow}>
      <HelpOutlineIcon color={color} style={style} fontSize={size} />
    </Tooltip>
  );
};

export default InfoWithTooltip;
