import React, { useContext, useEffect } from 'react';
import { useAuth } from '@agentnet/auth';
import { getFileInfo, getFileInfoProps } from 'api/file/file-api';
import { fileInfoType, propertyType } from 'api/file/interfaces/get-file';
import { FileDataContextInterface, FileDataContext } from 'hooks/FileDataContext';
import useAsync from 'hooks/useAsync';

import { useParams } from 'react-router-dom';
import { Map } from './Map';
import './FileSummaryMap.scss';

export const FileSummaryMap = () => {
  const { fileId } = useParams<getFileInfoProps>();
  const { getAccessToken } = useAuth();
  const formatAddress = (property: propertyType): string =>
    `${property?.address1 ?? ''} ${property?.city ?? ''}, ${property?.state ?? ''} ${property.postalCode ?? ''}`;
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};

  const { fileData, setFileData } = fileDataCtx;

  /* Handle GET File DATA and its values  */
  const getFile = async (): Promise<fileInfoType> => {
    const token = await getAccessToken();
    return getFileInfo(fileId ?? '', token);
  };

  const { execute, status, value: fileApiData } = useAsync<fileInfoType>(getFile, false);

  useEffect(() => {
    execute().then();
  }, [fileId]);

  /* Fetch the data for current file */
  useEffect(() => {
    execute().then();
  }, []);

  /* Updating Context update File */
  useEffect(() => {
    if (fileDataCtx) {
      setFileData && setFileData(fileApiData);
    }
  }, [fileApiData]);

  return (
    <div className="file-summary-map-container">
      {status !== 'pending' && (
        <div>
          <Map
            zoomControl
            zoomLevel={7}
            mapTypeControl={false}
            addresses={fileData?.properties?.map((property) => formatAddress(property)) ?? []}
          />
        </div>
      )}
    </div>
  );
};
