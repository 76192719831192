import React, { useContext } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { fileInfoType } from 'api/file/interfaces/get-file';
import { FileDataContext, FileDataContextInterface } from '../../hooks/FileDataContext';
import { format as formatDate } from 'date-fns';
import { PartyTypeValue } from '../../api/file/constants';
import { getMapAddressList, isValidDate } from '../../utilities/utilities';
import { Map } from './files-summary/file-summary-google-map/Map';

export function getBuyerName(fileInfo: fileInfoType): string {
  let buyerName = '';
  let firstBuyer;
  if (!fileInfo.buyers || fileInfo.buyers.length == 0) {
    buyerName = '';
    firstBuyer = null;
  } else {
    firstBuyer = fileInfo.buyers[0];
  }

  if (firstBuyer && firstBuyer.partyType === PartyTypeValue.married) {
    const spouse1 = firstBuyer.couple?.spouse1;
    const spouse2 = firstBuyer.couple?.spouse2;
    if (spouse1?.lastName === spouse2?.lastName) {
      buyerName = `${spouse1?.firstName} and ${spouse2?.firstName} ${spouse2?.lastName}`;
    } else {
      buyerName = `${spouse1?.firstName} ${spouse1?.lastName} and ${spouse2?.firstName} ${spouse2?.lastName}`;
    }
  } else if (firstBuyer && firstBuyer.partyType === PartyTypeValue.individual) {
    buyerName = `${firstBuyer?.individual?.firstName} ${firstBuyer?.individual?.lastName}`;
  } else if (firstBuyer && firstBuyer.partyType === PartyTypeValue.entity) {
    buyerName = `${firstBuyer?.entity?.name}`;
  } else if (firstBuyer && firstBuyer.partyType === PartyTypeValue.trust) {
    buyerName = `${firstBuyer?.trustEstate?.name}`;
  }

  return buyerName;
}

export function getSellerName(fileInfo: fileInfoType): string {
  let sellerName = '';
  let firstSeller;
  if (!fileInfo.sellers || fileInfo.sellers.length == 0) {
    sellerName = '';
    firstSeller = null;
  } else {
    firstSeller = fileInfo.sellers[0];
  }

  if (firstSeller && firstSeller.partyType === PartyTypeValue.married) {
    const spouse1 = firstSeller.couple?.spouse1;
    const spouse2 = firstSeller.couple?.spouse2;
    if (spouse1?.lastName === spouse2?.lastName) {
      sellerName = `${spouse1?.firstName} and ${spouse2?.firstName} ${spouse2?.lastName}`;
    } else {
      sellerName = `${spouse1?.firstName} ${spouse1?.lastName} and ${spouse2?.firstName} ${spouse2?.lastName}`;
    }
  } else if (firstSeller && firstSeller.partyType === PartyTypeValue.individual) {
    sellerName = `${firstSeller?.individual?.firstName} ${firstSeller?.individual?.lastName}`;
  } else if (firstSeller && firstSeller.partyType === PartyTypeValue.entity) {
    sellerName = `${firstSeller?.entity?.name}`;
  } else if (firstSeller && firstSeller.partyType === PartyTypeValue.trust) {
    sellerName = `${firstSeller?.trustEstate?.name}`;
  }
  return sellerName;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileDetailsWrap: {
      backgroundColor: theme.palette.background.default,
      marginTop: '9.8rem',
    },
    fileDetailsContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(3),
      maxWidth: '1200px',
    },
    fileMap: {
      '& .map-container': {
        height: '100%',
        '& .map-container__map': {
          borderRadius: '4px',
          border: '2px solid white',
          height: '100%',
          minWidth: '235px',
          minHeight: '160px',
        },
      },
    },
    fileDetails: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    subtitle: {},
    info: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(1),
    },
    detail: {
      paddingLeft: '1.6rem',
    },
  }),
);

const FileSnapshot = (): JSX.Element => {
  const classes = useStyles();

  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData } = fileDataCtx;
  const { lenders, fileNumber } = fileData || {};

  const buyerName = fileData ? getBuyerName(fileData) : '';
  const sellerName = fileData ? getSellerName(fileData) : '';
  const propertyData = fileData?.properties ? fileData?.properties[0] : null;
  const property = propertyData ? (
    <span>
      {propertyData.address1}
      {propertyData.address2 && <>,{propertyData.address2}, </>} <br /> {propertyData.city}, {propertyData.state}{' '}
      {propertyData.postalCode}{' '}
    </span>
  ) : (
    <span></span>
  );
  const displayOfficeDetails = () => {
    return (
      <>
        {fileData?.office?.name && <span>{fileData?.office?.name},</span>}
        <span>
          {fileData?.office?.address1}, {fileData?.office?.city}, {fileData?.office?.state} {fileData?.office?.zip}
        </span>
      </>
    );
  };

  const displayLenderDetails = () => {
    return (
      <>
        {lenders && lenders[0]
          ? lenders[0].address
            ? `${lenders[0].name}, ${lenders[0].address.address1}, ${lenders[0].address.city}, ${lenders[0].address.state} ${lenders[0].address.postalCode}`
            : `${lenders[0].name}`
          : noInfo}
      </>
    );
  };

  const mapAddressList: string[] = fileData?.properties ? getMapAddressList(fileData?.properties) : [];

  const noInfo = 'N/A';

  return (
    <div className={classes.fileDetailsWrap}>
      <Grid container className={classes.fileDetailsContainer}>
        <Grid item sm={3} className={classes.fileMap} data-qa="FileSummaryMap">
          {mapAddressList.length > 0 && (
            <Map
              // Set a changing key to ensure the map refreshes as needed
              key={`${fileData?.fileId}_mapKey`}
              mapTypeControl={false}
              addresses={mapAddressList}
              zoomLevel={16}
            />
          )}
        </Grid>
        <Grid item sm={9} className={classes.fileDetails}>
          <Grid container sm={12}>
            <Grid sm={4}>
              <div className={classes.detail}>
                <Typography variant="overline" className={classes.subtitle}>
                  Property Address
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  {property}
                </Typography>
              </div>
            </Grid>
            <Grid sm={4}>
              <div className={classes.detail}>
                <Typography variant="overline" className={classes.subtitle}>
                  Buyer/Borrower
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  {buyerName ? buyerName : noInfo}
                </Typography>
              </div>
            </Grid>
            <Grid sm={4}>
              <div className={classes.detail}>
                <Typography variant="overline" className={classes.subtitle}>
                  Seller/Owner
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  {sellerName ? sellerName : noInfo}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Grid container sm={12}>
            <Grid sm={4}>
              <div className={classes.detail}>
                <Typography variant="overline" className={classes.subtitle}>
                  Lender
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  {displayLenderDetails()}
                </Typography>
              </div>
            </Grid>
            <Grid sm={4}>
              <div className={classes.detail}>
                <Typography variant="overline" className={classes.subtitle}>
                  Office
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  {displayOfficeDetails()}
                </Typography>
              </div>
            </Grid>
            <Grid sm={4}>
              <div className={classes.detail}>
                <Typography variant="overline" className={classes.subtitle}>
                  Closing Date
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  {fileData?.closingDate && isValidDate(new Date(fileData?.closingDate))
                    ? formatDate(new Date(fileData?.closingDate as unknown as number | Date), 'MM/dd/yyyy')
                    : noInfo}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FileSnapshot;
