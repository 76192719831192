import { Overrides } from '@material-ui/core/styles/overrides';
import palette from '../palette/palette';
import spacing from '../spacing';
import typography from '../typography';
import breakpoints from '../breakpoints';
import Inter from '../../../common-assets/fonts/inter/Inter-Regular.ttf';
import InterMedium from '../../../common-assets/fonts/inter/Inter-Medium.ttf';

const inter = {
  fontFamily: 'Inter',
  src: `local('Inter'), url(${Inter}) format("woff2")`,
  fontStyle: 'normal',
  fontWeight: '400',
};

const inter500 = {
  fontFamily: 'Inter',
  src: `local('Inter Medium'), url(${InterMedium}) format("woff2")`,
  fontStyle: 'normal',
  fontWeight: '400',
};

const overrides: Overrides = {
  //BUTTONS
  MuiButton: {
    root: {
      textTransform: 'none',
      fontFamily: 'Inter',
      fontSize: '1.4rem',
      color: palette.primary.main,
      borderRadius: '4px',
      lineHeight: `1.7rem`,
      transition: '.3s all cubic-bezier(0.420, 0.000, 0.580, 1.000)',
    },

    contained: {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      boxShadow: 'none',
      '&:hover': {
        borderColor: palette.outlined.dark,
        backgroundColor: palette.outlined.dark,
        boxShadow: 'none',
      },
      '&$disabled': {
        color: palette.action.disabled,
        backgroundColor: palette.action.disabledBackground,
      },
    },

    containedPrimary: {
      backgroundColor: palette.primary.main,
    },

    containedSizeLarge: {
      fontSize: '1.6rem',
    },

    containedSizeSmall: {
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
      padding: '6px 16px',
    },

    outlined: {
      color: palette.primary.main,
      borderColor: palette.outlined.main,
      backgroundColor: 'transparent',
      borderRadius: '4px',
      '&:hover': {
        borderColor: palette.outlined.dark,
        backgroundColor: palette.outlined.light,
        boxShadow: 'none',
      },
    },

    outlinedSizeLarge: {
      fontSize: '1.6rem',
    },

    outlinedSizeSmall: {
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
      padding: '6px 16px',
    },

    text: {
      '&:hover': {
        backgroundColor: 'transparent',
        color: palette.primary.dark,
      },
    },

    textSizeLarge: {
      fontSize: '1.6rem',
    },
  },

  //AVATAR
  MuiAvatar: {
    circle: {
      backgroundColor: palette.action.disabledBackground,
      margin: 'auto',
    },
    colorDefault: {
      backgroundColor: palette.action.disabled,
    },
  },

  //SVG ICON
  MuiSvgIcon: {
    fontSizeSmall: {
      fontSize: '2.2rem',
    },
  },

  //DIVIDER
  MuiDivider: {
    root: {
      height: '1px',
      margin: 0,
      backgroundColor: '#c4c4c4',
    },
  },

  //FORM INPUTS
  MuiSelect: {
    select: {
      textAlign: 'left',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    selectMenu: {
      height: '0', //to adhere to 14px font size
    },
    icon: {
      position: 'relative',
      right: '1rem',
      bottom: '1rem',
      transform: 'scale(1.5)',
    },
    iconOpen: {
      transform: 'rotate(180deg) scale(1.5)',
    },
  },
  MuiInputBase: {
    root: {
      '&$disabled': {
        background: palette.action.disabledBackground,
        color: palette.action.disabled,
      },
    },
    input: {
      ...typography.body2,
      color: palette.text.primary,
      fontFamily: 'Inter',
      fontSize: '1.4rem',
      fontWeight: 400,
      position: 'relative',
      padding: '8px 0 10px',
      '&$disabled': {
        background: palette.action.disabledBackground,
        color: '#666',
        borderStyle: 'dotted',
        '&:hover': {
          background: palette.action.hover,
          color: '#666',
        },
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      overflow: 'hidden',
      '&$disabled': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderStyle: 'dotted',
        },
      },
      '&$focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: palette.primary.main,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        transition: '.3s all cubic-bezier(0.420, 0.000, 0.580, 1.000)',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          transition: '.2s all cubic-bezier(0.420, 0.000, 0.580, 1.000)',
          borderColor: palette.primary.dark,
        },
      },
      '& legend': {
        maxWidth: '1000px',
      },
      '&$error $notchedOutline': {
        borderColor: `${palette.error.main} !important`,
      },
      '&.MuiOutlinedInput-adornedEnd': {
        paddingRight: '8px',
      },
    },
    input: {
      padding: '10px 12px',
      color: palette.text.primary,
      fontFamily: 'Inter',
      fontWeight: 400,
      borderColor: '#c4c4c4',
    },
    inputMarginDense: {
      paddingTop: '1.5rem',
      paddingBottom: '1.5rem',
    },
  },
  MuiInputLabel: {
    formControl: {
      fontSize: '1.3rem',
    },
    filled: {
      transform: ' translate(12px, 8px) scale(1)',
      fontSize: '1.3rem',
    },
    outlined: {
      fontSize: '1.6rem',
      fontFamily: 'Inter',
      fontWeight: 500,
      letterSpacing: '0.15px',
      color: palette.text.secondary,
      '&.MuiInputLabel-shrink': {
        fontSize: '1.3rem',
        transform: `translate(14px, -6px)`,
        backgroundColor: 'white',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: '1.2rem',
      letterSpacing: '0.4px',
      color: palette.text.secondary,
      '&$focused': {
        color: palette.primary.main,
      },
    },
  },
  MuiFilledInput: {
    input: {
      padding: ' 2.7rem 1.2rem 1rem 1rem',
    },
    inputMultiline: {
      padding: '3rem 0',
    },
  },
  MuiFormLabel: {
    asterisk: {
      color: palette.secondary.dark,
      fontSize: '1.4rem',
      fontWeight: 600,
      '&$error': {
        color: palette.secondary.dark,
      },
    },
  },
  MuiInputAdornment: {
    positionEnd: {
      marginLeft: 'auto',
    },
  },

  //SWITCH
  MuiSwitch: {
    root: {
      '&$checked': {
        color: palette.primary.main,
      },
    },
    colorSecondary: {
      '&$checked': {
        color: palette.primary.main,
      },
    },
    track: {
      opacity: 0.38,
      backgroundColor: '#000',
      '$checked$checked + &': {
        opacity: 0.7,
        backgroundColor: palette.primary.main,
      },
    },
  },

  //CHECKBOX
  MuiCheckbox: {
    root: {
      padding: `2px 9px`,
    },
  },
  //MENU
  MuiMenu: {
    paper: {},
  },
  //MENU ITEM
  MuiMenuItem: {
    root: {
      fontSize: '1.6rem',
      color: palette.text.primary,
      fontFamily: 'Inter',
    },
  },
  MuiListItem: {
    button: {
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
    },
  },
  MuiPopover: {
    paper: {
      top: '155px',
    },
  },

  //TEXT INPUT
  MuiInput: {
    formControl: {
      'label + &': {
        marginTop: 19,
      },
    },
    underline: {
      '&.MuiInput-formControl:before': {
        borderBottomStyle: 'none',
      },
      '& .MuiInputAdornment-root': {
        marginBottom: spacing(0.375),
        '& .MuiIconButton-root': {
          padding: 3,
          fontSize: spacing(2.25),
          marginBottom: spacing(0.5),
        },
      },
      '&:hover:not($disabled):before': {
        borderBottomColor: 'none',
      },
    },
  },

  //DROPDOWN
  MuiFormControl: {
    root: {
      backgroundColor: palette.background.paper,
      '& .MuiSelect-icon': {
        position: 'absolute',
        top: 'calc(50% - 8px)',
      },
    },
  },

  //FORMCONTROL FOR SWITCHES
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
    },
  },

  //TYPOGRAPHY
  MuiTypography: {
    root: {
      padding: 0,
    },
    h5: {
      padding: 0,
    },
  },

  //AUTOCOMPLETE
  MuiAutocomplete: {
    root: {
      '&.MuiAutocomplete-hasClearIcon,.MuiAutocomplete-hasPopupIcon': {
        height: '97.5%',
      },
    },
    popupIndicator: {
      '& div': {
        padding: 0,
        marginRight: 0,
      },
    },
    inputRoot: {
      padding: '0px',
      '&[class*="MuiOutlinedInput-root"]': {
        padding: '0px',
        '& .MuiAutocomplete-input': {
          padding: '10px 12px',
        },
        '& .MuiAutocomplete-input:first-child': {
          paddingLeft: '12px',
        },
      },
    },
    noOptions: {
      padding: `16px 24px`,
      fontSize: `1.4rem`,
    },
  },

  //CUSTOM FONTS
  MuiCssBaseline: {
    '@global': {
      '@font-face': [inter, inter500],
    },
  },

  // CONTAINERS
  MuiContainer: {
    maxWidthLg: {
      // Custom content width utilized for entirety of application.
      // All page content should be wrapped in a container with maxWidthLg as well as contextual areas of L1 / L2 Nav.
      [breakpoints.up('lg')]: {
        maxWidth: 1440,
      },
    },
  },

  // CHIPS
  MuiChip: {
    root: {
      fontSize: '1.3rem',
      fontFamily: 'Inter',
      color: palette.text.primary,
      fontWeight: 400,
    },
  },

  // TOOLTIP
  MuiTooltip: {
    tooltip: {
      fontSize: '1.3rem',
      fontFamily: 'Inter',
      lineHeight: '1.6rem',
      padding: '8px',
    },
  },

  //MODAL
  MuiDialogContent: {
    root: {
      padding: `2.4rem`,
    },
  },
  MuiDialog: {
    paperWidthSm: {
      minWidth: 400,
    },
    paperWidthMd: {
      minWidth: 600,
    },
  },

  //TABS
  MuiTab: {
    root: {
      [breakpoints.up('sm')]: {
        minWidth: 'auto',
      },
      padding: spacing(0, 1),
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
      fontWeight: 500,
      textTransform: 'none',
      minHeight: '52px',
      margin: spacing(0, 1),
    },
    textColorPrimary: {
      color: palette.primary.dark,
    },
  },

  MuiTabs: {
    indicator: {
      backgroundColor: palette.secondary.light,
      height: '3px',
    },
  },
};

export default overrides;
