/* eslint-disable react/prop-types */
import React from 'react';
import InvoiceSummaryTable from 'ui-kit/components/payment/InvoiceSummaryTable';

const InvoiceSummaryList: React.FC<{ orders: any; underwriter: any }> = React.memo(({ orders, underwriter }) => {
  return orders
    ? orders?.map((order: any) => (
        <InvoiceSummaryTable
          key={order.orderNumber}
          orderDate={order.orderDate}
          orderNumber={order.orderNumber}
          invoices={order.invoices}
          underwriter={underwriter}
          showInvoiceNo
          isClosedStatus={orders?.payment?.paymentStatus === 'Closed'}
        />
      ))
    : null;
});
InvoiceSummaryList.displayName = 'InvoiceSummaryList';
export default InvoiceSummaryList;
