import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { AgentNetTextInput } from 'ui-kit/inputs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Popper, Grid, IconButton, Typography, Paper, Card, Checkbox, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
  DeleteUserAccessKey,
  getUserAcessKeys,
  revokeUserAccessKey,
  UnmaskUserAccessKey,
} from 'api/admin/user-profile-api';
import { useAuth } from '@agentnet/auth';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import moment from 'moment';
import useAsync from 'hooks/useAsync';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpwardOutlined';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import CloseIcon from 'ui-kit/icons/Close';

export interface AccessKeyOptionType {
  ThirdPartyVendorId?: string | number;
  vendorName: string;
  Status?: string | undefined;
  accessKey?: string | undefined;
  visible?: boolean | undefined;
  lastUpdated?: string | undefined;
}

interface DialogInterface {
  show: boolean;
  id: any;
  action: string | undefined;
}

export interface UserAccessKeyType {
  ThirdPartyVendor: any;
  UserAccessKeyId?: string;
  UserAccessKey: string;
  ThirdPartyVendorId: string;
  bmaskFlag?: boolean;
  Status?: number;
}

interface UserAccessKeyComponentProps {
  accessKeyOptions: AccessKeyOptionType[];
  onChange: (value: any) => void;
  onDelete?: (index: any) => void;
  onRevoke?: (value: any) => void;
  value?: AccessKeyOptionType[];
}

const popperWidth = 400;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '99%',
      padding: '0 0 24px',
      overflow: 'auto',
    },
    tableHeader: {
      backgroundColor: '#F2F5F6',
      borderBottom: '1px solid #D9E0E5',
      fontWeight: 600,
      fontSize: '1.4rem',
    },
    tableHeaderCell: {
      padding: '1.2rem',
      display: 'flex',
    },
    tableCellBorderLeft: {
      borderLeft: `1px solid #E0E0E0`,
      maxWidth: 90,
      margin: '0 0 0 auto',
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
    },
    tableList: {
      // fontSize: '1.1rem',
      // fontWeight: 500,
    },
    tableRow: {
      borderBottom: `1px solid #E0E0E0`,
    },
    Icon: {
      '&:hover': {
        cursor: 'pointer',
      },
      padding: '0.2rem 0 0.2rem 0.3rem',
    },
    toolTip: {
      '&:hover': {
        cursor: 'default',
      },
    },
    tableCell: {
      padding: `1.2rem`,
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    tableCellHidden: {
      overflow: 'hidden',
    },
    deleteRow: {
      padding: 0,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    my1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    checkbox: {
      borderRadius: '4px',
      width: '10px',
      marginRight: theme.spacing(1),
      transition: '.2s all',
    },
    deleteCell: {
      justifyContent: 'center',
    },
    ctaBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    validationMsg: {
      width: '100%',
      fontSize: '1.4rem',
    },
    popper: {
      backgroundColor: '#FFF',
      border: '1px solid #c4c4c4',
      borderRadius: '6px',
      zIndex: 2000,
    },
    popperWrap: {
      width: popperWidth,
      backgroundColor: '#fff',
    },
    textInputWrap: {
      borderBottom: '1px solid #c4c4c4',
      padding: theme.spacing(2),
    },
    textInput: {
      '&>div': {
        paddingRight: '8px !important',
      },
    },
    autocompletePopper: {
      height: 0,
    },
    autocompleteOptionsPaper: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      width: popperWidth,
    },

    popperContent: {
      width: 400,
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    },
    popperHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #c4c4c4',
      padding: '0 8px 0 24px',
    },
    popperClose: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    popperOption: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: '8px 16px',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
      width: '100% !important',
    },
    popperPaper: {
      margin: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  }),
);

const CustomPopper = (props: any) => {
  const classes = useStyles();
  //force the list to only open downwards
  return (
    <Popper
      className={classes.autocompletePopper}
      {...props}
      modifiers={{
        filp: { enabled: false },
        preventOverflow: {
          enabled: 'auto',
          boundariesElement: 'scrollParent',
        },
      }}
      popperOptions={{ placement: 'bottom-start' }}
      placement="bottom-start"
      disablePortal
    />
  );
};
const CustomPaper = ({ children }: any) => {
  const classes = useStyles();
  return <Card className={classes.autocompleteOptionsPaper}>{children}</Card>;
};
const UserAccessKeyComponent = ({
  accessKeyOptions,
  onChange,
  onDelete,
  onRevoke,
  value,
}: UserAccessKeyComponentProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selectedKeys, setSelectedKeys] = useState<AccessKeyOptionType[]>([]);
  const [userAccessKeyData, setuserAccessKeyData] = useState<UserAccessKeyType[]>();
  const [dialog, setDialog] = React.useState<DialogInterface>({ show: false, id: undefined, action: undefined });
  const modalConfirmation = (e: AccessKeyOptionType[], action: string) => {
    setDialog({ show: true, id: e, action: action });
  };
  const [visibility, setVisibility] = useState(Array(userAccessKeyData?.length).fill(false));
  const [isSorted, setSorting] = useState(true);
  const [UnmaskedUAK, setUnmaskedUAK] = useState(null);
  const { getAccessToken, account, signOut } = useAuth();
  const { addSnackbarMessage } = useSnackBars();
  const userAccessKeysData = async () => {
    const token = await getAccessToken();
    const userAccessresponse = await getUserAcessKeys(token);
    setuserAccessKeyData(userAccessresponse);
  };

  const { execute: executeUserAccessKeysData } = useAsync(userAccessKeysData, false);

  useEffect(() => {
    executeUserAccessKeysData().then();
  }, []);

  const togglePopper = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('event.currentTarget', event.currentTarget);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'autocomplete-checklist-popper' : undefined;

  const handleDelete = async (e: any) => {
    await modalConfirmation(e, 'delete');
  };

  const handleRevoke = async (e: any) => {
    await modalConfirmation(e, 'revoke');
  };

  const handleChange = async (val: AccessKeyOptionType[]) => {
    console.log(val, 'handleChange');
    onChange && (await onChange(val));
    await setSelectedKeys(val);
    setVisibility(Array(userAccessKeyData?.length).fill(false));
    await executeUserAccessKeysData().then();
  };

  useEffect(() => {
    const selectedUserAccessKeyData = userAccessKeyData?.filter((x) => x.Status != 2);
    const selectedUserAccessKey: any = selectedUserAccessKeyData?.map((x, i) => {
      return {
        vendorName: x.ThirdPartyVendor.VendorName,
        ThirdPartyVendorId: x.ThirdPartyVendor.ThirdPartyVendorId,
      };
    });
    setSelectedKeys(selectedUserAccessKey || []);
  }, [userAccessKeyData]);

  const [menuOpen, setMenuOpen] = useState(true);

  const copyUserAccessKey = async (useraccesskeyId: any) => {
    const token = await getAccessToken();
    const finalResponse = await UnmaskUserAccessKey(token, useraccesskeyId);
    navigator.clipboard
      .writeText(finalResponse.UserAccessKey)
      .then(() => {
        addSnackbarMessage({
          message: 'Text Copied',
          type: 'success',
        });
      })
      .catch((err) => {
        addSnackbarMessage({
          message: 'Text Could Not Be Copied',
          type: 'failure',
        });
      });
  };

  const unmaskKeyData = async (e: any) => {
    const token = await getAccessToken();
    console.log('eee', e);
    const unmaskKeyresponse = await UnmaskUserAccessKey(token, e);
    console.log('seetttt', unmaskKeyresponse);
    // await setuserAccessKeyData((prevState: any) =>
    //   prevState.map((item: any) =>
    //     item.UserAccessKeyId == e ? { ...item, UserAccessKey: unmaskKeyresponse.UserAccessKey } : item,
    //   ),
    // );
    setUnmaskedUAK(unmaskKeyresponse.UserAccessKey);
    console.log('userAccessKeyData', userAccessKeyData);
    return unmaskKeyresponse;
  };
  console.log('final', userAccessKeyData);
  // const { execute: executeUnmaskKeyData } = useAsync(unmaskKeyData(), false);

  // const unmaskKey = async (e: any, i: any) => {
  //   // setMaskFlag(false);
  //   const updatedVisibility = [...visibility];
  //   updatedVisibility[i] = !updatedVisibility[i];
  //   setVisibility(updatedVisibility);
  //   if (updatedVisibility[i] == true) {
  //     const res = await unmaskKeyData(e);
  //     console.log('ress', res);
  //   } else {
  //     const token = await getAccessToken();
  //     const userAccessresponse = await getUserAcessKeys(token);
  //     setuserAccessKeyData(userAccessresponse);
  //   }
  //   console.log('updatedVisibility', updatedVisibility);
  //   console.log('updatedVisibility[i]', updatedVisibility[i]);
  // };

  const handleAccessKeyDelete = async (key: any, action: any) => {
    const token = await getAccessToken();
    if (action == 'delete') {
      const deleteUserAccessresponse = await DeleteUserAccessKey(token, key.UserAccessKeyId);
      await setSelectedKeys((prevState: any) =>
        prevState.filter((item: any) => item.ThirdPartyVendorId !== key.ThirdPartyVendor.ThirdPartyVendorId),
      );
      await setuserAccessKeyData((prevState: any) =>
        prevState.filter((item: any) => item.UserAccessKeyId !== key.UserAccessKeyId),
      );
      // addSnackbarMessage({
      //   message: 'Deleted User Access Key Successfully',
      //   type: 'success',
      // });
    } else {
      const reqBody = {
        userAccessKeyId: key.UserAccessKeyId,
      };
      await revokeUserAccessKey(token, reqBody);
      await setuserAccessKeyData((prevState: any) =>
        prevState.map((item: any) =>
          item.UserAccessKeyId == key.UserAccessKeyId ? { ...item, Status: 2 } : { ...item },
        ),
      );
      await setSelectedKeys((prevState: any) =>
        prevState.filter((item: any) => item.ThirdPartyVendorId !== key.ThirdPartyVendor.ThirdPartyVendorId),
      );
      // addSnackbarMessage({
      //   message: 'Revoked User Access Key Successfully',
      //   type: 'success',
      // });
    }
    setDialog({ show: false, id: undefined, action: undefined });
  };
  console.log('final', userAccessKeyData);
  // const { execute: executeUnmaskKeyData } = useAsync(unmaskKeyData(), false);

  const unmaskKey = async (e: any, i: any) => {
    // setMaskFlag(false);
    const updatedVisibility = [...visibility];
    updatedVisibility[i] = !updatedVisibility[i];
    setVisibility(updatedVisibility);
    if (updatedVisibility[i] == true) {
      const res = await unmaskKeyData(e);
    } else {
      const token = await getAccessToken();
      const userAccessresponse = await getUserAcessKeys(token);
      const uakData = userAccessresponse.find((ele: any) => ele.UserAccessKeyId == e);
      await setuserAccessKeyData((prevState: any) =>
        prevState.map((item: any) =>
          item.UserAccessKeyId == e ? { ...item, UserAccessKey: uakData.UserAccessKey } : item,
        ),
      );
    }
  };

  const handlesort = async (e: any, sortStatus: any) => {
    if (userAccessKeyData) {
      if (sortStatus == true) {
        const ascSortedData = [...userAccessKeyData].sort((a: any, b: any) => (a[e] < b[e] ? 1 : -1));
        setuserAccessKeyData(ascSortedData);
        setSorting(false);
      } else {
        const descSortedData = [...userAccessKeyData].sort((a: any, b: any) => (a[e] > b[e] ? 1 : -1));
        setuserAccessKeyData(descSortedData);
        setSorting(true);
      }
    }
  };

  return (
    <>
      {dialog.id && (
        <AgentNetConfirmationDialog
          onConfirm={() => handleAccessKeyDelete(dialog.id, dialog.action)}
          onDismissAction={() => setDialog({ show: false, id: undefined, action: undefined })}
          open={dialog.show}
          dialogTitle={dialog.action === 'delete' ? 'Delete User Access Key' : 'Revoke User Access Key'}
          dialogBtnContent="Yes, Continue"
          dialogText={`You are about to permanently ${dialog.action} this User Access Key. Are you sure you want to proceed?`}
          qaAttrPrefix="ConfirmationUAK"
        />
      )}
      <Grid item sm={8}>
        <Typography variant="body2">
          {!userAccessKeyData?.length ? (
            <div className={classes.validationMsg}>There are no user access keys.</div>
          ) : null}
        </Typography>
      </Grid>
      <Grid item sm={4}>
        <Typography>
          <div className={classes.ctaBtn}>
            <AgentNetButton
              variant="contained"
              data-qa="AddUserAccessKeys"
              size="small"
              onClick={togglePopper}
              aria-describedby={id}
            >
              Add Access Key
            </AgentNetButton>
          </div>
        </Typography>
      </Grid>
      <div>
        <Popper id={id} open={open} anchorEl={anchorEl} className={classes.popper}>
          <Paper elevation={8} className={classes.popperContent}>
            <div className={classes.popperHeader}>
              <Typography variant="h2" color="secondary">
                Access Keys
              </Typography>
              <IconButton
                className={classes.popperClose}
                aria-label="Close User Access Keys Popper"
                onClick={() => setAnchorEl(null)}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </div>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={accessKeyOptions}
              disableCloseOnSelect
              classes={{
                option: classes.popperOption,
                popperDisablePortal: classes.popperDisablePortal,
                paper: classes.popperPaper,
              }}
              disableClearable
              disablePortal
              getOptionDisabled={(option: AccessKeyOptionType) =>
                selectedKeys.some((selectedOption) => selectedOption.vendorName === option.vendorName)
              }
              getOptionLabel={(option: AccessKeyOptionType) => option.vendorName}
              PaperComponent={CustomPaper}
              PopperComponent={CustomPopper}
              onChange={(event, newValue: AccessKeyOptionType[]) => {
                //selectedKeys;
                //setSelectedKeys(newValue);
                // onChange(newValue);
                handleChange(newValue);
              }}
              renderOption={(option, state) => {
                //console.log(selectedKeys.some((selected) => selected.vendorName === option.vendorName),option.id,);
                //console.log(selectedKeys.filter((o) => o.vendorName === option.vendorName).length, state, option.vendorName);
                return (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selectedKeys.filter((o) => o.vendorName === option.vendorName).length ? true : false}
                      className={classes.checkbox}
                      color="primary"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      data-qa="AddUakCheckbox"
                    />
                    <Typography variant="body1">{option.vendorName}</Typography>
                  </>
                );
              }}
              renderInput={(params) => (
                <div className={classes.textInputWrap}>
                  <AgentNetTextInput
                    {...params}
                    label={'Search Access Keys'}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textInput}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: null,
                      endAdornment: (
                        <IconButton
                          className={classes.deleteRow}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          onClick={() => setMenuOpen(!menuOpen)}
                          data-qa="AddUakDropDown"
                        >
                          {menuOpen ? (
                            <ArrowDropUp fontSize="large" color="disabled" />
                          ) : (
                            <ArrowDropDown fontSize="large" color="disabled" />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              )}
              open={menuOpen}
              value={selectedKeys}
            />
          </Paper>
        </Popper>
      </div>
      {userAccessKeyData && userAccessKeyData.length > 0 ? (
        <div className={classes.table}>
          <Grid container className={classes.tableHeader}>
            <Grid item sm={3} className={classes.tableHeaderCell}>
              Vendor Name
            </Grid>
            <Grid item sm={3} className={classes.tableHeaderCell}>
              Access Key
            </Grid>
            <Grid item sm={2} className={classes.tableHeaderCell}>
              Status
            </Grid>
            <Grid item sm={2} className={classes.tableHeaderCell}>
              Last Updated
              {isSorted == true ? (
                <ArrowDownwardIcon onClick={() => handlesort('LastUpdatedDate', true)} />
              ) : (
                <ArrowUpwardIcon onClick={() => handlesort('LastUpdatedDate', false)} />
              )}
            </Grid>
            <Grid
              item
              sm={2}
              className={clsx(classes.tableHeaderCell, classes.deleteCell, classes.tableCellBorderLeft)}
            >
              Actions
            </Grid>
          </Grid>
          <Grid container className={classes.tableList}>
            {userAccessKeyData?.map((e: any, i) => (
              <Grid container key={i} className={clsx(classes.tableRow)}>
                <Grid item sm={3} className={clsx(classes.tableCell)}>
                  <Tooltip title={e.ThirdPartyVendor.VendorName} className={clsx(classes.toolTip)}>
                    <Typography className={clsx(classes.tableList)} variant="body2">
                      {e.ThirdPartyVendor.VendorName}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item sm={2} className={clsx(classes.tableCell, classes.tableCellHidden)}>
                  <Tooltip
                    title={UnmaskedUAK ? UnmaskedUAK : 'Loading User Access Key'}
                    className={clsx(classes.toolTip)}
                  >
                    <Typography
                      className={clsx(classes.tableList)}
                      variant="body2"
                      onMouseOver={() => unmaskKeyData(e.UserAccessKeyId)}
                      onMouseOut={() => setUnmaskedUAK(null)}
                    >
                      {e.UserAccessKey.includes('*') ? e.UserAccessKey.slice(15) : e.UserAccessKey}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item sm={1} className={clsx(classes.tableCell)}>
                  <CopyIcon
                    className={classes.Icon}
                    color="disabled"
                    fontSize="large"
                    data-qa={'UakCopy' + i}
                    onClick={() => copyUserAccessKey(e.UserAccessKeyId)}
                  />
                </Grid>
                <Grid item sm={2} className={clsx(classes.tableCell)}>
                  <Typography className={clsx(classes.tableList)} variant="body2">
                    {e.Status == 0 ? 'Created' : e.Status == 1 ? 'Activated' : 'Revoked'}
                  </Typography>
                </Grid>
                <Grid item sm={2} className={clsx(classes.tableCell)}>
                  <Typography className={clsx(classes.tableList)} variant="body2">
                    {moment(e.LastUpdatedDate).format('MM/DD/YYYY')}
                  </Typography>
                </Grid>
                <Grid item sm={2} className={clsx(classes.tableCell, classes.deleteCell, classes.tableCellBorderLeft)}>
                  {e.Status != 2 ? (
                    <AgentNetButton
                      variant="text"
                      danger
                      className={classes.deleteRow}
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      data-qa={e.Status == 0 ? 'DeleteUak' + i : 'RevokeUak' + i}
                      aria-label="delete document"
                      onClick={() => (e.Status == 0 ? handleDelete(e) : handleRevoke(e))}
                    >
                      {e.Status == 0 ? 'Delete' : 'Revoke'}
                    </AgentNetButton>
                  ) : null}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : null}
    </>
  );
};

export default UserAccessKeyComponent;
