import React from 'react';

export function AgentAdvantage() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#F5B335" rx="8"></rect>
      <path
        fill="#fff"
        d="M23.52 15.208v6.625a1.6 1.6 0 01-.489 1.177c-.326.327-.718.49-1.177.49H10.188c-.459 0-.851-.163-1.178-.49a1.605 1.605 0 01-.49-1.177v-6.625a2.867 2.867 0 01-.739-1.125c-.173-.458-.177-.958-.01-1.5l.875-2.833a1.84 1.84 0 01.594-.896c.284-.236.614-.354.99-.354h11.583c.375 0 .7.115.979.344.277.229.479.531.604.906l.875 2.833c.167.542.163 1.035-.01 1.48-.174.444-.42.826-.74 1.145zm-5.666-.875c.375 0 .66-.128.854-.385.195-.257.271-.545.23-.865l-.459-2.916h-1.625v3.083c0 .292.097.545.292.76a.92.92 0 00.708.323zm-3.75 0c.32 0 .58-.107.782-.323.2-.215.302-.468.302-.76v-3.083h-1.625l-.459 2.916c-.055.334.018.625.219.875s.462.375.781.375zm-3.708 0c.25 0 .469-.09.656-.27.188-.181.302-.41.344-.688l.458-3.208H10.23l-.833 2.791c-.083.278-.038.577.135.896.174.32.462.48.865.48zm11.25 0c.403 0 .694-.16.875-.479.18-.32.222-.618.125-.896l-.875-2.791h-1.583l.458 3.208c.042.278.156.507.344.688.187.18.406.27.656.27z"
      ></path>
    </svg>
  );
}

export function AgentPrintPro() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#742F75" rx="8"></rect>
      <path
        fill="#fff"
        d="M17.875 24.333c-.32.32-.715.48-1.188.48a1.61 1.61 0 01-1.187-.48L8.167 17a1.73 1.73 0 01-.365-.542 1.659 1.659 0 01-.135-.666V9.833c0-.458.163-.85.49-1.177.326-.326.718-.49 1.176-.49h5.959a1.73 1.73 0 011.208.5l7.333 7.355c.32.32.48.712.48 1.177a1.6 1.6 0 01-.48 1.177l-5.958 5.958zm-6.458-11.166c.347 0 .642-.122.885-.365s.365-.538.365-.885c0-.348-.122-.643-.365-.886a1.206 1.206 0 00-.885-.364c-.348 0-.643.121-.886.364a1.206 1.206 0 00-.364.886c0 .347.121.642.364.885s.538.365.886.365z"
      ></path>
    </svg>
  );
}

export function Discownts() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#9E3B2D" rx="8"></rect>
      <path
        fill="#fff"
        d="M12.25 9.333a2.92 2.92 0 00-2.917 2.917 2.92 2.92 0 002.917 2.917 2.92 2.92 0 002.917-2.917 2.92 2.92 0 00-2.917-2.917zm0 4.167c-.692 0-1.25-.558-1.25-1.25S11.558 11 12.25 11s1.25.558 1.25 1.25-.558 1.25-1.25 1.25zm7.5 3.333a2.92 2.92 0 00-2.917 2.917 2.92 2.92 0 002.917 2.917 2.92 2.92 0 002.917-2.917 2.92 2.92 0 00-2.917-2.917zm0 4.167c-.692 0-1.25-.558-1.25-1.25s.558-1.25 1.25-1.25 1.25.558 1.25 1.25S20.442 21 19.75 21zm-9.242 1.667l-1.175-1.175L21.492 9.333l1.175 1.175-12.159 12.159z"
      ></path>
    </svg>
  );
}

export function EagleAcademy() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#71767B" rx="8"></rect>
      <path
        fill="#fff"
        d="M10.167 16.983v3.334L16 23.5l5.833-3.183v-3.334L16 20.167l-5.833-3.184zM16 8.5l-9.167 5 9.167 5 7.5-4.092v5.759h1.667V13.5L16 8.5z"
      ></path>
    </svg>
  );
}

export function ComprehensiveCalc() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#013A6F" rx="8"></rect>
      <path
        fill="#fff"
        d="M21.893 7.667H10.107c-.857 0-1.607.729-1.607 1.562v13.542c0 .833.75 1.562 1.607 1.562h11.786c.857 0 1.607-.729 1.607-1.562V9.229c0-.833-.75-1.562-1.607-1.562zm-9.107 14.166c0 .209-.215.417-.429.417h-1.286c-.214 0-.428-.208-.428-.417v-1.25c0-.208.214-.416.428-.416h1.286c.214 0 .429.208.429.416v1.25zm0-4.166c0 .208-.215.416-.429.416h-1.286c-.214 0-.428-.208-.428-.416v-1.25c0-.209.214-.417.428-.417h1.286c.214 0 .429.208.429.417v1.25zm4.285 4.166c0 .209-.214.417-.428.417h-1.286c-.214 0-.428-.208-.428-.417v-1.25c0-.208.214-.416.428-.416h1.286c.214 0 .428.208.428.416v1.25zm0-4.166c0 .208-.214.416-.428.416h-1.286c-.214 0-.428-.208-.428-.416v-1.25c0-.209.214-.417.428-.417h1.286c.214 0 .428.208.428.417v1.25zm4.286 4.166c0 .209-.214.417-.428.417h-1.286c-.214 0-.429-.208-.429-.417v-5.416c0-.209.215-.417.429-.417h1.286c.214 0 .428.208.428.417v5.416zm0-8.333c0 .208-.214.417-.428.417H11.07c-.214 0-.428-.209-.428-.417v-3.333c0-.209.214-.417.428-.417h9.858c.214 0 .428.208.428.417V13.5z"
      ></path>
    </svg>
  );
}

export function RecordsCenter() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#013A6F" rx="8"></rect>
      <path
        fill="#fff"
        d="M22.667 7.667H9.333c-.833 0-1.666.75-1.666 1.666v2.509c0 .6.358 1.116.833 1.408v9.417c0 .916.917 1.666 1.667 1.666h11.666c.75 0 1.667-.75 1.667-1.666V13.25c.475-.292.833-.808.833-1.408V9.333c0-.916-.833-1.666-1.666-1.666zm-4.167 10h-5V16h5v1.667zm4.167-5.834H9.333v-2.5l13.334-.016v2.516z"
      ></path>
    </svg>
  );
}

export function SoftwareIntegrationHub() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#013A6F" rx="8"></rect>
      <path
        fill="#fff"
        d="M21.833 8.5H18.35A2.509 2.509 0 0016 6.833c-1.083 0-2 .7-2.35 1.667h-3.483a1.46 1.46 0 00-.334.033 1.673 1.673 0 00-1.2.992 1.605 1.605 0 00-.133.642v11.666c0 .225.05.45.133.65a1.673 1.673 0 001.2.992c.109.017.217.025.334.025h11.666c.917 0 1.667-.75 1.667-1.667V10.167c0-.917-.75-1.667-1.667-1.667zm-6.666 9.308l-1.175 1.184L11 16l2.992-2.992 1.175 1.184L13.358 16l1.809 1.808zM16 9.542a.63.63 0 01-.625-.625.63.63 0 01.625-.625.63.63 0 01.625.625.63.63 0 01-.625.625zm2.008 9.45l-1.175-1.184L18.642 16l-1.809-1.808 1.175-1.184L21 16l-2.992 2.992z"
      ></path>
      <path fill="#fff" d="M16 9.542a.625.625 0 100-1.25.625.625 0 000 1.25z"></path>
      <path fill="#fff" d="M16 9.542a.625.625 0 100-1.25.625.625 0 000 1.25z"></path>
      <path fill="#fff" d="M16 9.542a.625.625 0 100-1.25.625.625 0 000 1.25z"></path>
    </svg>
  );
}

export function AgentNetResourceCenter() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#F5B335" rx="8"></rect>
      <path
        fill="#fff"
        d="M9.333 11H7.667v11.667c0 .916.75 1.666 1.666 1.666H21v-1.666H9.333V11zm13.334-3.333h-10c-.917 0-1.667.75-1.667 1.666v10c0 .917.75 1.667 1.667 1.667h10c.916 0 1.666-.75 1.666-1.667v-10c0-.916-.75-1.666-1.666-1.666zm-.834 7.5H13.5V13.5h8.333v1.667zM18.5 18.5h-5v-1.667h5V18.5zm3.333-6.667H13.5v-1.666h8.333v1.666z"
      ></path>
    </svg>
  );
}

export function AgentNetTitleEscrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#013A6F" rx="8"></rect>
      <path
        fill="#fff"
        d="M22.667 11h-3.334V9.333a1.66 1.66 0 00-1.666-1.666h-3.334a1.66 1.66 0 00-1.666 1.666V11H9.333c-.925 0-1.658.742-1.658 1.667l-.008 9.166A1.66 1.66 0 009.333 23.5h13.334a1.66 1.66 0 001.666-1.667v-9.166A1.66 1.66 0 0022.667 11zm-5 0h-3.334V9.333h3.334V11z"
      ></path>
    </svg>
  );
}
