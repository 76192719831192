import React, { useEffect } from 'react';
import { Grid, ListItemText, MenuItem, TextField, Typography } from '@material-ui/core';
import RadioGroup from 'ui-kit/components/radios/RadioGroup';
import { AgentNetCheckBox, AgentNetDropdownSelector, AgentNetTextInput } from 'ui-kit/inputs';
import { FieldValidationError, doValidate } from 'utilities/validation/validation';
import DateField from 'ui-kit/inputs/DateField/DateFieldString';
import { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import { DisclaimerInfos, Field, Order, OrderDocument, ProductResponse } from './type';
import { useState } from 'react';
import Uploader from 'ui-kit/components/uploader/Uploader';
import { content } from './constants';
import './ServiceOrdersContainer.scss';
import { Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { FileWithPath } from 'react-dropzone';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import ServiceOrderDocumentsTable from 'ui-kit/components/tables/ServiceOrderTable';
import { ExpandMoreOutlined, ExpandLessOutlined } from '@material-ui/icons';
import { ServiceOrderSchema } from 'utilities/validation/schemas/serviceOrder-schema';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import clsx from 'clsx';

interface ServiceOrderFormProps {
  index?: number;
  order: Order;
  productList?: ProductResponse[];
  contentDisabled: boolean;
  updateOrder: (
    id: any,
    value: any,
    field: string,
    isFieldValue: boolean,
    isDocumentDelete?: boolean,
    selectedProduct?: ProductResponse,
  ) => void;
  isFormDirty?: boolean;
  setIsFormDirty?: (val: boolean) => void;
  addOrderFields: (randomId: string, fields: Field[]) => void;
  submitAttempted: boolean;
  setSubmitAttempted: React.Dispatch<React.SetStateAction<boolean>>;
  setTriggerSaveCall?: React.Dispatch<React.SetStateAction<boolean>>;
  updateAttempted: boolean;
  setUpdateAttempted: React.Dispatch<React.SetStateAction<boolean>>;
  setOnViewDocument?: any;
  setOnDownloadPdfZip?: any;
}

const ServiceOrderForm = ({
  index,
  order,
  productList,
  contentDisabled,
  updateOrder,
  addOrderFields,
  submitAttempted,
  setSubmitAttempted,
  setTriggerSaveCall,
  updateAttempted,
  setUpdateAttempted,
  setOnViewDocument,
  setOnDownloadPdfZip,
}: ServiceOrderFormProps) => {
  const { addSnackbarMessage } = useSnackBars();
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [shouldShowValidation, setShouldShowValidation] = useState(false);
  const [orderInfoFields, setOrderInfoFields] = useState<Field[]>([]);
  const [orderDetails, setOrderDetails] = useState(order);
  const [productOptions, setProductOptions] = useState<SelectOption[]>([]);
  const [showOrderInfo, setOrderInfo] = useState(false);
  const { addGlobalMsg } = useGlobalMessages();

  const DisclaimerInfo: DisclaimerInfos = {
    text: '',
    isDisclaimerAcceptanceRequired: false,
  };
  const productField: ProductResponse = {
    productName: '',
    fields: [],
    disclaimerInfo: DisclaimerInfo,
    notificationEmailAddress: '',
  };
  const [selectedProduct, setSelectedProduct] = useState(productField);
  let showOtherContent = false;
  const [munSearchType, setMunSearchType] = useState<string[]>([]);
  const [showFields, setShowFields] = useState(false);
  const [copyTypeTooltipMsg, setToolTip] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [documents, setDocuments] = useState<OrderDocument[]>([]);

  const getOrderInfoFields = () => {
    const fieldsList: React.SetStateAction<Field[]> = [];
    selectedProduct.fields?.forEach((element) => {
      if (
        element.label === 'Municipal Search Type' ||
        element.label === 'Copy Type' ||
        element.label === 'Search Type'
      ) {
        fieldsList.push(element);
        if (element.label === 'Copy Type') {
          if (element.options != null) {
            let msg = '';
            element.options?.forEach((option) => {
              msg += option.helpText + '\n';
            });
            setToolTip(msg);
          }
        }
      }
    });
    setOrderInfoFields(fieldsList);
  };

  useEffect(() => {
    if (orderInfoFields?.length > 0) {
      setOrderInfo(true);
    }
  }, [orderInfoFields]);

  useEffect(() => {
    if (productList?.length === 1) {
      if (orderDetails.productName === '') {
        const productField = productList?.find((x) => x.productName == productList[0]?.productName);
        productField ? setSelectedProduct(productField) : '';
        updateOrder(orderDetails.randomId, productList[0]?.productName, 'productName', false, false, productField);
        setShowFields(true);
      }
    } else {
      const productListOptions = productList?.map((x, i) => {
        if (
          orderDetails?.isCascaded === true &&
          x.productName == orderDetails.productName.replace(',AgentNet Search Product', '')
        ) {
          return { name: orderDetails.productName, value: orderDetails.productName };
        }
        return { name: x.productName, value: x.productName };
      });

      productListOptions != null ? setProductOptions(productListOptions) : '';
    }
  }, [productList]);

  useEffect(() => {
    addOrderFields(orderDetails.randomId, selectedProduct.fields);
    getOrderInfoFields();
  }, [selectedProduct]);

  const validateErrors = async (order: Order) => {
    const errs: FieldValidationError[] = (await doValidate(order, ServiceOrderSchema)) || [];
    setValidationErrors(errs);
  };

  useEffect(() => {
    if (order) {
      if (order.productName) {
        validateErrors(order);
      }
      setOrderDetails(order);
    }
  }, [order]);

  useEffect(() => {
    if (contentDisabled) {
      setShowFields(true);
      const DisclaimerInfo: DisclaimerInfos = {
        text: orderDetails.disclaimerInformation,
        isDisclaimerAcceptanceRequired:
          orderDetails.productField?.find((x) => x.name === 'DisclaimerAccepted')?.value == 'true' ? true : false,
      };
      const product = {
        ...selectedProduct,
        productName: orderDetails.productName,
        fields: orderDetails.productField,
        disclaimerInfo: DisclaimerInfo,
        notificationEmailAddress: orderDetails.orderNotificationEmail,
      };
      setSelectedProduct(product);
    }
  }, [contentDisabled]);

  useEffect(() => {
    if (order.productName && submitAttempted) {
      setShouldShowValidation(true);
      if (validationErrors?.length > 0) {
        const validationMessage = validationErrors.filter((e) => e.field == 'CustomError');
        if (validationMessage && validationMessage.length > 0) {
          validationMessage?.map((err) => {
            addGlobalMsg({
              message: err.message,
              type: 'error',
            });
          });
        } else {
          addSnackbarMessage({
            message: `Place Service Order Failed`,
            type: 'error',
          });
        }
        setTriggerSaveCall && setTriggerSaveCall(false);
      } else {
        setTriggerSaveCall && setTriggerSaveCall(true);
      }
      setSubmitAttempted(false);
    }
  }, [submitAttempted]);

  useEffect(() => {
    if (documents.length > 0) {
      updateOrder(orderDetails.randomId, documents, 'userUploadedDocuments', false);
    }
  }, [documents]);

  async function getAsByteArray(file: Blob) {
    const val: ArrayBuffer = (await readFile(file)) as ArrayBuffer;
    const base64 = btoa(new Uint8Array(val).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return base64;
  }

  function readFile(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => resolve(e?.target?.result));
      reader.addEventListener('error', reject);
      reader.readAsArrayBuffer(file);
    });
  }

  const handleDocuments: any = async (uploads: FileWithPath[]) => {
    const documentList: React.SetStateAction<OrderDocument[]> = [];
    const filePathsPromises: any[] = [];
    let i = 0;
    uploads.forEach((file) => {
      const document = {
        name: '',
        description: 'Agent Doc',
        mimeType: '',
        content: '',
        path: file.path,
      };
      document.name = file.name.split('.').slice(0, -1).join('.');
      document.mimeType = file.name.split('.').pop() ?? '';
      documentList.push(document);
      filePathsPromises.push(getAsByteArray(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    filePaths.forEach((e) => {
      documentList[i].content = e;
      ++i;
    });
    setDocuments(documentList);
  };

  const deleteDocument: any = async (file?: any) => {
    const documents = orderDetails.userUploadedDocuments.filter((e) => e.path !== file.path);
    updateOrder(orderDetails.randomId, documents, 'userUploadedDocuments', false, true);
  };

  const renderFieldComponent = (field: Field) => {
    showOtherContent = true;
    const optionlist: { name: string; value: string }[] = [];
    if (field.options && field.options != null) {
      field.options?.forEach((c: any) => {
        const option = {
          name: c.text,
          value: c.text,
        };
        optionlist.push(option);
      });
    } else if (field.value && field.value?.trim().length > 0) {
      const option = {
        name: field.value,
        value: field.value,
      };
      optionlist.push(option);
    }
    switch (field.type) {
      case 'dropdown':
        if (!field.value && optionlist && optionlist.length === 1) {
          field.value = optionlist[0].value;
          if (field.name === 'ProductTransactionType') {
            updateOrder(orderDetails.randomId, optionlist[0].value, field.name, true);
          } else {
            updateOrder(orderDetails.randomId, optionlist[0].value, field.name, true);
          }
        }
        return (
          <Grid item xs={12} sm={6} md={3}>
            <AgentNetDropdownSelector
              label={field.label}
              name={field.name}
              qaAttribute={
                field.name === 'ProductTransactionType'
                  ? 'ServiceOrder' + 'TransactionType' + [index]
                  : 'ServiceOrder' + 'BusinessSegment' + [index]
              }
              options={optionlist}
              menuOption={(e) => {
                field.value = e;
                updateOrder(orderDetails.randomId, e, field.name, true);
              }}
              value={field.value ?? ''}
              errs={validationErrors}
              showValidation={shouldShowValidation}
              disabled={contentDisabled}
              required={field.isRequired}
              error={
                validationErrors &&
                shouldShowValidation &&
                validationErrors.length > 0 &&
                validationErrors.filter((x) => x.field == field.name).length > 0
                  ? true
                  : false
              }
            />
          </Grid>
        );
      case 'date':
        return (
          <Grid item xs={9} sm={3} md={2}>
            <DateField
              label={field.label}
              name={field.name}
              value={field.value}
              onChange={(date) => {
                field.value = date?.toString() ?? '';
                updateOrder(orderDetails.randomId, date ?? '', field.name, true);
              }}
              qaAttribute={'ServiceOrder' + 'DueDate' + [index]}
              disabled={contentDisabled}
              required={field.isRequired}
              errs={validationErrors}
              showValidation={shouldShowValidation}
              disablePast
            />
          </Grid>
        );
      default:
        return '';
    }
  };
  const renderOrderInfoComponent = (field: Field) => {
    const optionlist: { name: string; value: string }[] = [];
    let municipleArray: Array<string>;
    if (field.options != null && field.type === 'dropdown') {
      field.options?.forEach((c: any) => {
        const option = { name: c.text, value: c.text };
        optionlist.push(option);
      });
    } else {
      field.options?.forEach((c: any) => {
        const option = c.text;
        optionlist.push(option);
      });
    }
    switch (field.type) {
      case 'dropdown':
        return (
          <Grid item xs={12} sm={6} md={3}>
            <AgentNetDropdownSelector
              label={field.label}
              name={field.name}
              qaAttribute={'ServiceOrder' + 'SearchType' + [index]}
              options={optionlist}
              menuOption={(e) => {
                field.value = e;
                updateOrder(orderDetails.randomId, e, field.name, true);
              }}
              value={field.value ?? ''}
              errs={validationErrors}
              showValidation={shouldShowValidation}
              disabled={contentDisabled}
              required={field.isRequired}
              error={
                validationErrors &&
                shouldShowValidation &&
                validationErrors.length > 0 &&
                validationErrors.filter((x) => x.field == field.name).length > 0
                  ? true
                  : false
              }
            />
          </Grid>
        );
      case 'radio': {
        const options = field?.options?.map((x) => {
          return { label: x.text, value: x.text, dataQA: 'ServiceOrder' + x.text };
        });

        if (!field.value && options.length > 0) {
          field.value = options[0].value;
        }
        return (
          <Grid item sm={6} alignItems="center" style={{ display: 'flex' }}>
            <RadioGroup
              label={field.label}
              options={options}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateOrder(orderDetails.randomId, e.target.value, field.name, true)
              }
              required={field.isRequired}
              selected={options.find((f) => f.value === field.value)}
              disabled={contentDisabled}
              compact
              removeMarginTop
              Data-QA={'ServiceOrder' + 'CopyType' + [index]}
              // errs={validationErrors}
              // showValidation={shouldShowValidation}
            />
            {field.name === 'CopyType' ? (
              <Tooltip
                className="iconStyles"
                data-qa={'copyTypeToolTip'}
                title={copyTypeTooltipMsg.split('\n').map((e) => (
                  <p style={{ margin: 0 }} key={'copyType'}>
                    {e}
                  </p>
                ))}
              >
                <HelpOutline fontSize="large" color="primary" />
              </Tooltip>
            ) : (
              ''
            )}
          </Grid>
        );
      }
      case 'multiselect':
        if (field.value?.length > 0 && !(munSearchType?.length > 0)) {
          setMunSearchType(field.value?.split(';'));
        } else if (!(munSearchType?.length > 0)) {
          setMunSearchType(['None']);
          field.value = field.options[0].text;
        }

        return (
          <>
            <Grid item sm={6}>
              <TextField
                select
                variant="outlined"
                label={field.label}
                SelectProps={{
                  SelectDisplayProps: {
                    title: munSearchType.join('; ').toString(),
                  },
                  multiple: true,
                  displayEmpty: true,
                  MenuProps: {
                    variant: 'menu',
                    PaperProps: {
                      style: { maxHeight: 200 },
                    },
                  },
                  value: Array.isArray(munSearchType) ? munSearchType : [],
                  onChange: (event) => {
                    municipleArray = event.target.value as string[];
                    setMunSearchType(municipleArray);
                    if ((event.target.value as []).length > 1) {
                      if (municipleArray[municipleArray.length - 1] === 'None') {
                        setMunSearchType(['None']);
                        municipleArray = ['None'];
                      } else {
                        setMunSearchType(municipleArray.filter((x) => x !== 'None'));
                        municipleArray = municipleArray.filter((x) => x !== 'None');
                      }
                    }
                    updateOrder &&
                      updateOrder(orderDetails.randomId, municipleArray?.join(';').toString(), field.name, true);
                  },
                  renderValue: (selected) => (selected as string[]).join('; '),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                disabled={contentDisabled}
                data-qa={'ServiceOrder' + 'MunicipalSearchType' + [index]}
              >
                {/* <div className="scroll"> */}
                {optionlist.map((opt: any) => (
                  <MenuItem key={opt} value={opt}>
                    <AgentNetCheckBox
                      value={munSearchType ? munSearchType && munSearchType.indexOf(opt) > -1 : false}
                      size="medium"
                      checkHandler={() => console.log('clicked')}
                      Data-QA={'ServiceOrder' + 'MunicipalSearchType' + [index]}
                    />
                    <ListItemText primary={opt} />
                  </MenuItem>
                ))}
                {/* </div> */}
              </TextField>
            </Grid>
          </>
        );
      default:
        return '';
    }
  };

  return (
    <Grid container spacing={3}>
      <>
        <Grid item sm={12}>
          <Typography variant="h3">Order Details</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AgentNetDropdownSelector
            name="product"
            required
            label="Product Name"
            value={productList?.length === 1 ? productList[0].productName : orderDetails?.productName}
            options={
              productList?.length === 1
                ? [{ name: productList[0].productName, value: productList[0].productName }]
                : productOptions
            }
            id="product"
            qaAttribute={'ServiceOrder' + 'Product' + [index]}
            dropdowntype="outlined"
            menuOption={(val: string) => {
              const productField = productList?.find((x) => x.productName == val);
              productField ? setSelectedProduct(productField) : '';
              //getOrderInfoFields();
              updateOrder(orderDetails.randomId, val, 'productName', false, false, productField);
              setShowFields(true);
            }}
            disabled={contentDisabled}
          />
        </Grid>
        {showFields ? (
          <>
            {selectedProduct.fields?.map((field, index) =>
              field.name !== 'SearchType' ? renderFieldComponent(field) : '',
            )}
          </>
        ) : (
          ''
        )}
        {showOtherContent ? (
          <>
            <Grid item sm={6}>
              <AgentNetTextInput
                variant="outlined"
                fullWidth
                required
                label="Email Notification"
                id="orderNotificationEmail"
                name="orderNotificationEmail"
                value={orderDetails.orderNotificationEmail}
                Data-QA={'ServiceOrder' + 'EmailNotification' + [index]}
                onChange={(e) => updateOrder(orderDetails.randomId, e.target.value, 'orderNotificationEmail', false)}
                disabled={contentDisabled}
                errs={validationErrors}
                showValidation={shouldShowValidation}
              />
            </Grid>
            {showOrderInfo ? (
              <>
                <div className="otherContent">
                  <Typography variant="h3">Order Info</Typography>
                </div>
              </>
            ) : (
              ''
            )}
            {orderInfoFields?.map((field, index) => renderOrderInfoComponent(field))}
            <div className="otherContent">
              <Typography variant="h3">Documents</Typography>
            </div>
            {orderDetails.orderStatus != '' ? (
              <Grid item sm={12}>
                <ServiceOrderDocumentsTable
                  documents={orderDetails.documents}
                  onRowCheck={(el: any, e: any) => console.log(el, e)}
                  onDownloadPdf={(e: string[]) => setOnDownloadPdfZip(e, true)}
                  onDownloadZip={(e: string[]) => setOnDownloadPdfZip(e, false)}
                  onViewDocument={setOnViewDocument}
                  disabled={false}
                />
              </Grid>
            ) : (
              <Grid item sm={12}>
                <Uploader
                  handleFiles={handleDocuments}
                  onDeleteItem={deleteDocument}
                  maxSize={30}
                  listTitle="Uploaded Document(s)"
                  acceptTypes={{
                    'application/pdf': ['.pdf'],
                    'image/tif': ['.tif'],
                    'image/tiff': ['.tiff'],
                    'image/jpg': ['.JPG'],
                    'image/bmp': ['.bmp'],
                    'application/msword': ['.doc'],
                    'application/x-excel': ['.xls'],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                    'application/rtf': ['.rtf'],
                    'application/txt': ['.txt'],
                    'application/gif': ['.GIF'],
                    'application/msg': ['.msg'],
                    'application/htm': ['.htm'],
                    'application/png': ['.png'],
                    'application/xml': ['.xml'],
                  }}
                  Data-QA={'ServiceOrder' + 'DocumentUpload' + [index]}
                />
              </Grid>
            )}
            {orderDetails.serviceOrderContact?.contactName?.length > 0 && (
              <>
                <Grid item sm={12}>
                  <Typography variant="h3">Service Order Contact</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Typography className="orderContact">
                    <span>Contact:</span> {orderDetails.serviceOrderContact.contactName}
                  </Typography>
                  <Typography variant="body1" className="orderContact">
                    <span>Email:</span> {orderDetails.serviceOrderContact.emailAddress}
                  </Typography>
                  <Typography variant="body1" className="orderContact">
                    <span>Office Phone:</span> {orderDetails.serviceOrderContact.phoneNumber}
                  </Typography>
                  <Typography variant="body1" className="orderContact">
                    <span>Office Fax:</span> {orderDetails.serviceOrderContact.faxNumber}
                  </Typography>
                  <Typography variant="body1" className="orderContact">
                    <span>Office Address:</span> {orderDetails.serviceOrderContact.streetAddress}
                  </Typography>
                </Grid>
              </>
            )}
            <div className="otherContent">
              <Typography variant="h3">Notes</Typography>
            </div>
            <Grid item sm={12}>
              <AgentNetTextInput
                variant="outlined"
                label="Notes"
                name="notes"
                value={
                  orderDetails?.fileNotes != null && orderDetails.fileNotes.length > 0
                    ? orderDetails.fileNotes
                    : orderDetails.note
                }
                multiline
                fullWidth
                className="inputField"
                required={false}
                Data-QA={'ServiceOrder' + 'Notes' + [index]}
                onChange={(e) => updateOrder(orderDetails.randomId, e.target.value, 'note', false)}
                disabled={contentDisabled}
                errs={validationErrors}
                showValidation={shouldShowValidation}
              />
            </Grid>
            <Grid item sm={12}>
              {selectedProduct.disclaimerInfo != null &&
              selectedProduct.disclaimerInfo.isDisclaimerAcceptanceRequired ? (
                <div className="">
                  <br />
                  {shouldShowValidation &&
                  orderDetails.productField?.find((x) => x.name === 'DisclaimerAccepted')?.value != 'true' ? (
                    <div className={clsx('errorMessage')}>Disclaimer selection required</div>
                  ) : (
                    <></>
                  )}
                  <AgentNetCheckBox
                    label={' I accept the terms and conditions for the ' + selectedProduct.productName}
                    name={'disclaimer'}
                    value={
                      orderDetails.productField?.find((x) => x.name === 'DisclaimerAccepted')?.value == 'true'
                        ? true
                        : false
                    }
                    checkHandler={(val: any) => {
                      updateOrder(orderDetails.randomId, val.toString(), 'DisclaimerAccepted', true);
                    }}
                    disabled={contentDisabled}
                    required={selectedProduct.disclaimerInfo.isDisclaimerAcceptanceRequired}
                    Data-QA={'ServiceOrder' + 'Disclaimer' + [index]}
                  />
                  <AgentNetButton
                    color="primary"
                    size="medium"
                    variant="text"
                    //className={'button'}
                    onClick={() => setShowMore(!showMore)}
                    endIcon={showMore ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                  >
                    {showMore ? 'Show less' : 'Show more'}
                  </AgentNetButton>
                  <br />
                  {showMore ? (
                    <Typography variant="caption">
                      <br />
                      <strong>{content}</strong>
                      <br />
                      {selectedProduct.disclaimerInfo.text.replace('IMPORTANT - READ CAREFULLY: ', '')}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <div className="otherFieldsWrap">
                  <AgentNetButton
                    color="primary"
                    size="medium"
                    variant="text"
                    //className={'button'}
                    onClick={() => setShowMore(!showMore)}
                    endIcon={showMore ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                  >
                    Disclaimer
                  </AgentNetButton>
                  <br />
                  {showMore ? (
                    <Typography variant="caption">
                      <br />
                      <strong>{content}</strong>
                      <br />
                      {selectedProduct.disclaimerInfo.text.replace(
                        'COMMERCIAL ORDER NOTICE',
                        '\nCOMMERCIAL ORDER NOTICE',
                      )}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </Grid>
          </>
        ) : (
          ''
        )}
      </>
    </Grid>
  );
};
export default ServiceOrderForm;
