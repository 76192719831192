import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import { DividerType } from './enums/DividerType';
import './AgentNetDivider.scss';
import AgentNetButton from '../button/AgentNetButton';
export type CustomDividerProps = {
  variant: DividerType;
  title?: string;
  message?: string;
  buttonName?: string;
  customClass?: string;
  onClick?: any;
  disableButton?: boolean;
  loader?: boolean;
  status?: 'open' | 'error' | 'cancelled' | 'closed' | 'pending' | 'void' | 'reported';
};

type MUIClasses = {
  root: string;
};

type BaseDividerType = {
  dividerVariant?: 'fullWidth' | 'inset' | 'middle';
  classes: MUIClasses;
  typography?: MUIClasses;
  containerTypeClass?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 100,
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.primary.contrastText,
      display: 'block',
    },
    dashed: {
      borderBottom: `2px dashed ${theme.palette.divider} `,
      backgroundColor: 'transparent',
      margin: `10px 0 0 ${theme.spacing(2)}px`,
    },
    spacing: {
      display: 'none',
    },
    dividerInset: {
      color: theme.palette.action.disabledBackground,
    },
    headerTypography: {
      ...theme.typography.h3,
      position: 'relative',
      display: 'flex',
      color: theme.palette.secondary.main,
      top: '.5rem',
      margin: '0 0 1.5rem 0',
    },
    subheaderTypography: {
      ...theme.typography.h2,
      display: 'flex',
      color: theme.palette.text.primary,
      padding: '1.5rem',
    },
    button: {
      margin: '0 2.4rem 0 0',
    },
    buttonNoMargin: {
      margin: '0 0 0 2.4rem',
    },
    innerWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    openPendingCountContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.contrastText,
      zIndex: 3,
      '& > div': {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        fontSize: '1.4rem',
        letterSpacing: '0.009rem',
        lineHeight: '1.25rem',
        marginTop: '.375rem',
        paddingRight: '2.4rem',
      },
      '& button.disabled': {
        cursor: 'pointer',
        color: theme.palette.action.disabled,
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
    disabled: {
      cursor: 'pointer',
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
    required: {
      color: theme.palette.error.main,
    },
  }),
);

export const AgentNetDivider: React.FC<CustomDividerProps> = ({
  title,
  variant,
  buttonName,
  customClass,
  onClick,
  message,
  disableButton = false,
  status,
  loader = false,
}: CustomDividerProps) => {
  const className = useStyles();

  const TYPOGRAPHY_VARIANT = 'h1';

  const dividerConfig: Record<DividerType, BaseDividerType> = {
    [DividerType.inset]: {
      dividerVariant: 'inset',
      classes: { root: className.dividerInset },
      typography: { root: className.headerTypography },
      containerTypeClass: 'inset',
    },

    [DividerType.subheader]: {
      classes: { root: className.dividerInset },
      typography: { root: className.subheaderTypography },
      containerTypeClass: 'subheader',
    },
    [DividerType.header]: {
      classes: { root: className.dividerInset },
      typography: { root: className.subheaderTypography },
      containerTypeClass: 'header',
    },
    [DividerType.dashed]: {
      classes: { root: className.dashed },
    },
    [DividerType.spacing]: {
      classes: { root: className.spacing },
    },
  };

  const dividerIntegrater = (dividerType: DividerType) => {
    return baseDivider(dividerConfig[dividerType], title, buttonName, onClick, message, loader);
  };

  const baseDivider = (
    baseDividerType: BaseDividerType,
    title?: string,
    buttonName?: string,
    onClick?: () => void,
    message?: string,
    loader?: boolean,
  ) => {
    return (
      <div className={`divider-container ${baseDividerType.containerTypeClass ?? ''} ${customClass ?? ''}`}>
        <div className={className.innerWrap}>
          {title && (
            <Typography classes={baseDividerType.typography} variant={TYPOGRAPHY_VARIANT}>
              {title}
              {status && (
                <span className={`status status-${status}`}>
                  {status[0].toUpperCase()}
                  {status.slice(1)}
                </span>
              )}
            </Typography>
          )}

          <div className={className.openPendingCountContainer}>
            {message && (
              <div>
                {message === '*required' ? (
                  <>
                    <span className={className.required}>*</span> required
                  </>
                ) : (
                  message
                )}
              </div>
            )}
            {buttonName && (
              <AgentNetButton
                color="primary"
                size="medium"
                variant={baseDividerType.containerTypeClass === 'inset' ? 'text' : 'contained'}
                className={baseDividerType.containerTypeClass === 'inset' ? className.buttonNoMargin : className.button}
                onClick={onClick}
                disabled={disableButton}
                loading={loader}
              >
                {buttonName}
              </AgentNetButton>
            )}
          </div>
        </div>

        <Divider classes={baseDividerType.classes} variant={baseDividerType.dividerVariant} />
      </div>
    );
  };
  return <>{dividerIntegrater(variant)}</>;
};
