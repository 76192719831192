import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import './InvoiceContent.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabWrap: {
      padding: `0 ${theme.spacing(2)}px`,
      borderBottom: `1px solid #e5e5e5`,
      position: 'fixed',
      zIndex: 5,
      width: '100%',
      '& .MuiTab-root': {
        margin: '0 8px',
      },
    },
  }),
);

const InvoiceNav = ({ handleTabChange, tabSelection, payRights }: any): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper square elevation={0} className={classes.tabWrap}>
      <Tabs
        value={tabSelection}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, v) => {
          handleTabChange && handleTabChange(v);
        }}
      >
        {/* Needs to put tab seperataly else they are not working  */}
        {payRights && <Tab label="Pay" value={0} Data-QA="SOPayTab" />}
        {<Tab label="View Invoices" value={1} Data-QA="SOViewTab" />}
      </Tabs>
    </Paper>
  );
};

export default InvoiceNav;
