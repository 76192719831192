import React, { useContext, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  Typography,
  Tooltip,
  Popover,
  IconButton,
} from '@material-ui/core';
import {
  ExpandMore,
  ChevronRightOutlined,
  AddCircleOutlineOutlined,
  SettingsOutlined,
  ExitToAppOutlined,
} from '@material-ui/icons';
import FileCreateContainer from '../../features/files/file-create/FileCreateContainer';
import { FileCreateProvider } from '../../hooks/FileCreateContext';
import AgentNetButton from '../../ui-kit/components/button/AgentNetButton';
import './NavBar.scss';
import { FileDataContext, FileDataContextInterface } from '../../hooks/FileDataContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { CollapseArrow, ExpandArrow } from 'ui-kit/icons/AgentNetIcon';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { useViewState } from 'hooks/ViewStateContext';
import AppSwitcherLinks from './AppSwitcherLinks';
import { createPrismUser } from 'api/prismPropertyProfile/prismProperty-profile-api';
import { useAuth } from '@agentnet/auth';
import { getPrismUrl } from 'api/host-by-env';
import { getStateOptions } from 'api/profile/profile-utilities';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';

interface Props {
  localWindow?: () => Window;
  appSwitcher?: boolean;
  currentView?: string;
  onViewSwitch?: () => void;
  onPortalSwitch?: () => void;
}

const roles = new Map();
roles.set(1, 100);
roles.set(3, 101);
roles.set(5, 101);
roles.set(7, 100);
roles.set(10, 100);
roles.set(11, 100);
roles.set(12, 100);

const NavBar = (props: Props): JSX.Element => {
  const theme = useTheme();
  const { addGlobalMsg } = useGlobalMessages();
  const showGlobalMsg = (errorMessage: string[], fn: (arg: string[]) => void): void => {
    errorMessage?.map((err: string) => {
      addGlobalMsg({
        message: err,
        type: 'error',
      });
    });

    fn([]);
  };
  const { addSnackbarMessage } = useSnackBars();
  const history = useHistory();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { getAccessToken } = useAuth();
  const {
    setOpenSaveModal,
    isFormDirty,
    openCreateDrawer,
    setOpenCreateDrawer,
    setTabValue,
    setIsCreateDrawerClicked,
    setFirmAnchorEl,
  } = fileDataCtx;
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile, prismUserProfile } = profileCtx;

  const hideReportsAndPayNav = useMemo(() => {
    const payRights = profile?.activityRights?.find((rights) => rights.ActivityRightId === 61);
    const remitRights = profile?.activityRights?.find((rights) => rights.ActivityRightId === 33);
    return !payRights && !remitRights;
  }, [profile?.activityRights]);

  const hasNoPayRights = useMemo(() => {
    //payRights
    const payRights =
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 61)) ||
      //view pay rights
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 84)) ||
      //pay manual rights
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 83));
    return !payRights;
  }, [profile?.activityRights]);

  useEffect(() => {
    if (openCreateDrawer === true) {
      setOpenCreateDrawer && setOpenCreateDrawer(false);
      setOpenDrawer(true);
    }
  }, [openCreateDrawer]);

  const { menuCollapsed, setMenuCollapse, viewState, navState, setNavState, adminNavState, setAdminNavState } =
    useViewState();
  const [drawerWidth, setDrawerWidth] = useState(menuCollapsed ? 72 : 252);
  const [cMargin, setCMargin] = useState(viewState === 'portal' ? 6.7 : 5);

  const handleMenuCollapse = () => {
    const newObj = navState.map((obj) => {
      return { ...obj, expanded: false };
    });
    setNavState(newObj);
    setMenuCollapse(!menuCollapsed);
  };

  //viewState variables
  useEffect(() => {
    setDrawerWidth(menuCollapsed ? 72 : 252);
  }, [menuCollapsed]);
  useEffect(() => {
    setCMargin(viewState === 'portal' ? 6.5 : 5);
  }, [viewState]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      drawer: {
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      drawerPaper: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: `64px`,
        width: drawerWidth,
      },
      // necessary for content to be below app bar
      toolbar: {
        ...theme.mixins.toolbar,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '0 !important',
      },
      toolbarNotCollapsed: {
        width: `calc(${drawerWidth}px - 32px)`,
        position: 'fixed',
        backgroundColor: theme.palette.secondary.main,
        zIndex: 1,
      },
      toolbarCollapsed: {
        padding: 0,
        '& button': {
          borderRadius: 0,
          paddingTop: '1.0rem',
          paddingBottom: '1.0rem',
        },
        '& svg': {
          width: '24px',
          height: '24px',
        },
      },
      content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
      },
      list: {
        padding: 0,
        color: theme.palette.primary.contrastText,
        marginTop: viewState === 'portal' ? (menuCollapsed ? theme.spacing(3) : theme.spacing(7)) : 0,
      },
      listNotCollapsed: {
        marginBottom: '12.6rem',
      },
      listCollapsed: {
        '& .MuiListItem-button': {
          paddingRight: 0,
          justifyContent: 'center',
          paddingLeft: theme.spacing(2),
        },
      },
      linkItem: {
        color: theme.palette.primary.contrastText,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '& .MuiListItemText-primary': {
          color: theme.palette.primary.contrastText,
          fontWeight: 500,
          fontSize: '1.6rem',
          lineHeight: '1.9rem',
        },
      },
      linkItemCollapsed: {
        paddingRight: 0,
        alignItems: 'center',
        justifyContent: 'center',
      },
      linkItemText: {},
      subLinkItem: {
        padding: `12px 24px`,
        fontSize: '1.6rem',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      subLinkItemActive: {
        '&>div': {
          backgroundImage: `url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAwaDJhMyAzIDAgMCAxIDMgM3YxMWEzIDMgMCAwIDEtMyAzSDBWMFoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center left',
          '&>div>span': {
            fontWeight: `700 !important`,
          },
        },
      },
      subLinkItemText: {
        margin: 0,
        '& .MuiListItemText-primary': {
          color: theme.palette.primary.contrastText,
          fontSize: '1.4rem',
          lineHeight: '1.7rem',
          fontWeight: 500,
        },
      },
      subLinkHeader: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1.5),
      },
      subLinkItemPopover: {},
      subLinkItemTextPopover: {
        letterSpacing: 0,
      },
      popoverPaper: {
        minWidth: 250,
        overflow: 'visible',
      },
      popoverContent: {
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 'calc(10px)',
          left: '-15px',
          borderWidth: '10px',
          borderStyle: 'solid',
          borderColor: 'transparent white transparent transparent',
        },
      },
      listItemIcon: {
        minWidth: '38px',
      },
      p0: {
        padding: 0,
      },
      navBottomWrap: {
        margin: `auto`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderTop: '1px solid',
        borderColor: theme.palette.primary.dark,
        height: '6.6rem',
        backgroundColor: theme.palette.secondary.main,
      },
      navBottomWrapNotCollapsed: {
        position: 'fixed',
        zIndex: 1,
        width: drawerWidth,
        bottom: 0,
      },
      navBottomWrapAdminCollapsed: {
        margin: `auto 0 ${cMargin + 2}rem`,
        border: 'none',
      },
      navBottomWrapPortalCollapsed: {
        margin: `auto 0 ${cMargin + 3}rem`,
      },
      adminCategoryWrap: {
        marginTop: theme.spacing(2),
        borderTop: '1px solid #34618C',
      },
      adminCategory: {
        color: '#809DB7',
        textTransform: 'uppercase',
        width: '100%',
        display: 'block',
        padding: '8px 16px',
        fontSize: '1rem',
        fontWeight: 500,
        letterSpacing: 1.5,
      },
      adminCategoryCollapsed: {
        padding: '8px 0',
        textAlign: 'center',
      },
      adminCategoryFirst: {
        marginTop: 0,
        border: 'none',
      },
      collapseBtn: {
        color: theme.palette.primary.contrastText,
        fontSize: '1.6rem',
        paddingLeft: theme.spacing(3),
        '& .collapseText': {
          marginLeft: theme.spacing(1),
        },
      },
      collapseBtnText: {
        '& .collapseText': {
          display: 'none',
        },
        '& .MuiButton-startIcon': {
          margin: 0,
        },
      },
      collapseBtnAdminText: {
        padding: theme.spacing(2),
        '& .collapseText': {
          display: 'none',
          marginLeft: theme.spacing(1),
        },
        '& .MuiButton-startIcon': {
          margin: 0,
        },
      },
      collapseBtnCollapsed: {
        paddingLeft: `${theme.spacing(2)}px !important`,
        '& .collapseText': {
          display: 'none',
        },
        '& .MuiButton-startIcon': {
          margin: 0,
        },
      },
      createNewFileBtn: {
        lineHeight: 'normal',
        transition: 'none',
      },
      adminToggleWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      },
      adminToggleWrapCollapsed: {
        flexDirection: 'column-reverse',
      },
      collapseToggleIconBtn: {
        marginRight: '0.2rem',
        '&:hover': {
          background: 'transparent',
        },
      },
      collapseToggleIconBtnCl: {
        width: '100%',
        borderBottom: '1px solid #34618C',
        borderRadius: 0,
        marginRight: 0,
      },
      iconBtn: {
        '&:hover': {
          background: 'transparent',
        },
      },
    }),
  );

  const classes = useStyles();

  const { localWindow, appSwitcher, currentView, onPortalSwitch, onViewSwitch } = props;
  const container = localWindow !== undefined ? () => localWindow().document.body : undefined;

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [isPending, setPendingFlag] = useState(false);
  const noBehalfOfUser = profile?.roleId === 2 || profile?.roleId === 13;
  const behalfOfUser = profile?.roleId === 3 || profile?.roleId === 5;

  useEffect(() => {
    if (displayError) {
      addSnackbarMessage({
        message: 'Firm must be selected before taking any action on files',
        type: 'error',
        onClose: () => setDisplayError(false),
      });
    }
  }, [displayError]);

  const showMessage = (errorMessage: string[]) => {
    errorMessage?.map((err) => {
      addGlobalMsg({
        message: err,
        type: 'error',
      });
    });
  };

  const shownCategories: { [key: string]: boolean } = {};

  const [popperEl, setPopperEl] = useState<any>(null);
  const [openedPopperId, setOpenedPopperId] = useState<any>(null);

  const handlePopperClose = (event?: any) => {
    setPopperEl(null);
    setOpenedPopperId(null);
  };

  const handleParentClick = (event: any, link: any, idx: any) => {
    if (
      behalfOfUser &&
      !userFirm?.firmId &&
      ['Files', 'Accounting', 'AgentNet Title & Escrow', 'Reports'].includes(link.linkText)
    ) {
      setDisplayError(true);
      return;
    }
    sessionStorage.setItem('fileId', '');
    if (link.sublinks.length === 0) setTabValue && setTabValue('0');
    if (!menuCollapsed) {
      const newObj = navState.map((obj) => {
        if (obj.linkText === link.linkText) {
          return { ...obj, expanded: !obj.expanded };
        }
        return obj;
      });
      setNavState(newObj);
    } else {
      setPopperEl(event.currentTarget);
      setOpenedPopperId(idx);
    }
  };

  const handleSublinkClick = async (link: any, event: any) => {
    if (link.isDisabled === true) {
      event.preventDefault();
      return;
    }
    if (link.linkText === 'Property Reports' && !isPending) {
      await handlePropertyProfileClick();
    }
    if (behalfOfUser && !userFirm?.firmId && ['Policy Upload'].includes(link.linkText)) {
      setDisplayError(true);
      event.preventDefault();
      return;
    }
    sessionStorage.setItem('fileId', '');
  };

  const handlePropertyProfileClick = async () => {
    const stateOptions = userFirm?.offices ? getStateOptions(userFirm) : [];
    const names = stateOptions.map((option) => option.name)?.join(',');
    const officeAddress = userFirm?.offices?.find((office) => office.isDefault && office.status === 'Active')?.address;

    if (!prismUserProfile || names?.length == 0) {
      return;
    }

    prismUserProfile.availableLocations = names;
    prismUserProfile.firmName = userFirm?.name ?? '';
    prismUserProfile.officeAddress1 = officeAddress?.address1 ?? '';
    prismUserProfile.officeAddress2 = officeAddress?.address2 ?? '';
    prismUserProfile.officeCity = officeAddress?.city ?? '';
    prismUserProfile.officeState = officeAddress?.state ?? '';
    prismUserProfile.officeZipCode = officeAddress?.postalCode ?? '';

    setPendingFlag(true);
    const token = await getAccessToken();
    const response = await createPrismUser(prismUserProfile, token);

    if (response?.error) {
      showMessage([response.error]);
    }

    setPendingFlag(false);

    if (response?.userId > 0) {
      const url = getPrismUrl();
      window.open(url, '_blank');
    }
  };

  const handleAdminParentClick = (event: any, link: any, idx: any) => {
    if (link.sublinks.length === 0) setTabValue && setTabValue('0');
    if (!menuCollapsed) {
      const newObj = adminNavState.map((obj) => {
        if (obj.linkText === link.linkText) {
          return { ...obj, expanded: !obj.expanded };
        }
        return obj;
      });
      setAdminNavState(newObj);
    } else {
      setPopperEl(event.currentTarget);
      setOpenedPopperId(idx);
    }
  };

  const [showAppSwitcher, setShowAppSwitcher] = useState(false);

  useEffect(() => {
    if (appSwitcher) {
      setShowAppSwitcher(true);
    } else {
      const timer = setTimeout(() => setShowAppSwitcher(false), 300);
      return () => clearTimeout(timer);
    }
  }, [appSwitcher]);

  const handleCloseAppSwitcher = () => {
    setShowAppSwitcher(false);
  };

  return (
    <>
      <LoadingSpinner status={isPending ? 'pending' : 'success'} variant="linear" />
      <nav className={classes.drawer}>
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
          container={container}
        >
          {noBehalfOfUser || currentView === 'admin' ? null : (
            <div
              className={clsx(classes.toolbar, {
                [classes.toolbarCollapsed]: menuCollapsed,
                [classes.toolbarNotCollapsed]: !menuCollapsed,
              })}
            >
              <AgentNetButton
                className={classes.createNewFileBtn}
                color="primary"
                size="large"
                variant="contained"
                data-qa="GlobalNavCreateNewFile"
                disabled={false}
                onClick={() => {
                  if (behalfOfUser && !userFirm?.firmId) {
                    setDisplayError(true);
                    return;
                  }
                  // If the Edit File form has unsaved changes, open the modal
                  if (isFormDirty) {
                    setOpenSaveModal && setOpenSaveModal(true);
                    setIsCreateDrawerClicked && setIsCreateDrawerClicked(true);
                  }
                  // otherwise, just open the drawer
                  else {
                    setOpenDrawer(true);
                    setFirmAnchorEl && setFirmAnchorEl(null);
                  }
                }}
                fullWidth
              >
                {menuCollapsed ? (
                  <Tooltip title={'Create New File'}>
                    <AddCircleOutlineOutlined htmlColor="#FFF" fontSize="large" />
                  </Tooltip>
                ) : (
                  'Create New File'
                )}
              </AgentNetButton>
            </div>
          )}
          <List
            className={clsx(classes.list, {
              [classes.listCollapsed]: menuCollapsed,
              [classes.listNotCollapsed]: !menuCollapsed,
            })}
          >
            {currentView === 'admin' &&
              adminNavState.map((link, idx) => {
                if (link.isHidden || (link.path === '/admin/billing' && hasNoPayRights)) return null;

                const renderThis = link.sublinks ? (
                  <>
                    <ListItem
                      className={classes.linkItem}
                      button
                      key={idx}
                      onClick={(e) => handleAdminParentClick(e, link, idx)}
                    >
                      {!menuCollapsed ? (
                        <>
                          <ListItemIcon className={classes.listItemIcon}>{link.icon}</ListItemIcon>
                          <ListItemText primary={link.linkText} Data-QA={link.dataQA && link.dataQA} />
                          {link.expanded ? <ExpandMore fontSize="large" /> : <ChevronRightOutlined fontSize="large" />}
                        </>
                      ) : (
                        <Tooltip title={link.linkText}>
                          <ListItemIcon className={classes.listItemIcon} onClick={(e) => setPopperEl(e.currentTarget)}>
                            {link.icon}
                          </ListItemIcon>
                        </Tooltip>
                      )}
                    </ListItem>
                    {!menuCollapsed ? (
                      <Collapse key={'SubListOf_' + link.linkText} in={link.expanded}>
                        <List disablePadding>
                          {link.sublinks.length > 0 &&
                            link.sublinks.map((sublink) =>
                              sublink.isHidden ||
                              (sublink.path === '/reports' && hideReportsAndPayNav) ||
                              (sublink.path === '/statements' && hasNoPayRights) ? null : (
                                <Link
                                  to={sublink.path}
                                  className={`${sublink.linkClassName} ${classes.p0}`}
                                  key={sublink.linkText}
                                  Data-QA={sublink.dataQA && sublink.dataQA}
                                >
                                  <ListItem className={classes.subLinkItem} button>
                                    <ListItemText className={classes.subLinkItemText} primary={sublink.linkText}>
                                      {sublink.linkText}
                                    </ListItemText>
                                  </ListItem>
                                </Link>
                              ),
                            )}
                        </List>
                      </Collapse>
                    ) : (
                      <Popover
                        open={openedPopperId === idx}
                        id={'popover-' + idx}
                        anchorEl={popperEl}
                        onClose={handlePopperClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        PaperProps={{
                          square: true,
                          className: classes.popoverPaper,
                        }}
                      >
                        <List disablePadding className={classes.popoverContent}>
                          <Typography variant="h2" className={classes.subLinkHeader}>
                            {link.linkText}
                          </Typography>
                          {link.sublinks.length > 0 &&
                            link.sublinks.map((sublink, index) =>
                              (sublink.path === '/reports' && hideReportsAndPayNav) ||
                              (sublink.path === '/statements' && hasNoPayRights) ||
                              (sublink.linkText === 'Policy Upload' && noBehalfOfUser) ? null : (
                                <Link
                                  to={sublink.path}
                                  className={`${sublink.linkClassName} ${classes.p0}`}
                                  key={index}
                                  Data-QA={sublink.dataQA && sublink.dataQA}
                                  onClick={(event) => handleSublinkClick(sublink, event)}
                                >
                                  <ListItem className={classes.subLinkItemPopover} button>
                                    <ListItemText className={classes.subLinkItemTextPopover} primary={sublink.linkText}>
                                      {sublink.linkText}
                                    </ListItemText>
                                  </ListItem>
                                </Link>
                              ),
                            )}
                        </List>
                      </Popover>
                    )}
                  </>
                ) : (
                  <>
                    <Link
                      onClick={() => {
                        setTabValue && setTabValue('0');
                        if (link.linkText !== 'Apps') {
                          history.push(link.path);
                        }
                      }}
                      to={link.path}
                      className={`${link.linkClassName} ${classes.p0}`}
                      // eslint-disable-next-line react/no-unknown-property
                      Data-QA={link.dataQA && link.dataQA}
                    >
                      <ListItem className={classes.linkItem} button key={link.linkText}>
                        <ListItemIcon className={classes.listItemIcon}>{link.icon}</ListItemIcon>
                        {!menuCollapsed && (
                          <ListItemText className={classes.linkItemText} primary={link.linkText}>
                            {link.linkText}
                          </ListItemText>
                        )}
                      </ListItem>
                    </Link>
                  </>
                );

                if (link.category && !shownCategories[link.category]) {
                  shownCategories[link.category] = true;
                  return (
                    <div
                      key={idx}
                      className={clsx(classes.adminCategoryWrap, {
                        [classes.adminCategoryFirst]: link.category === 'User',
                      })}
                    >
                      <Typography
                        variant="overline"
                        className={clsx(classes.adminCategory, { [classes.adminCategoryCollapsed]: menuCollapsed })}
                      >
                        {link.category}
                      </Typography>
                      {renderThis}
                    </div>
                  );
                }

                return renderThis;
              })}

            {currentView === 'portal' &&
              navState.map((link, idx) => {
                if (link.isHidden) return null;

                if (
                  noBehalfOfUser &&
                  ['Files', 'Accounting', 'AgentNet Title & Escrow', 'Reports'].includes(link.linkText)
                )
                  return null;

                const renderThis = link.sublinks ? (
                  <>
                    <ListItem
                      className={classes.linkItem}
                      button
                      key={idx}
                      onClick={(e) => handleParentClick(e, link, idx)}
                    >
                      {!menuCollapsed ? (
                        <>
                          <ListItemIcon className={classes.listItemIcon}>{link.icon}</ListItemIcon>
                          <ListItemText primary={link.linkText} Data-QA={link.dataQA && link.dataQA} />
                          {link.expanded ? <ExpandMore fontSize="large" /> : <ChevronRightOutlined fontSize="large" />}
                        </>
                      ) : (
                        <Tooltip title={link.linkText}>
                          <ListItemIcon className={classes.listItemIcon} onClick={(e) => setPopperEl(e.currentTarget)}>
                            {link.icon}
                          </ListItemIcon>
                        </Tooltip>
                      )}
                    </ListItem>

                    {!menuCollapsed ? (
                      <Collapse key={'SubListOf_' + link.linkText} in={link.expanded}>
                        <List disablePadding>
                          {link.sublinks.length > 0 &&
                            link.sublinks.map((sublink) =>
                              sublink.isHidden ||
                              (sublink.path === '/reports' && hideReportsAndPayNav) ||
                              (sublink.path === '/statements' && hasNoPayRights) ||
                              (sublink.linkText === 'Policy Upload' && noBehalfOfUser) ? null : (
                                <Link
                                  to={sublink.path}
                                  className={clsx(`${sublink.linkClassName} ${classes.p0}`, {
                                    [classes.subLinkItemActive]: location.pathname.includes(sublink.path),
                                  })}
                                  key={sublink.linkText}
                                  Data-QA={sublink.dataQA && sublink.dataQA}
                                  onClick={(event) => handleSublinkClick(sublink, event)}
                                >
                                  <ListItem className={clsx(classes.subLinkItem)} button>
                                    <ListItemText className={classes.subLinkItemText} primary={sublink.linkText}>
                                      {sublink.linkText}
                                    </ListItemText>
                                  </ListItem>
                                </Link>
                              ),
                            )}
                        </List>
                      </Collapse>
                    ) : (
                      <Popover
                        open={openedPopperId === idx}
                        id={'popover-' + idx}
                        anchorEl={popperEl}
                        onClose={handlePopperClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        PaperProps={{
                          square: true,
                          className: classes.popoverPaper,
                        }}
                      >
                        <List disablePadding className={classes.popoverContent}>
                          <Typography variant="h2" className={classes.subLinkHeader}>
                            {link.linkText}
                          </Typography>
                          {link.sublinks.length > 0 &&
                            link.sublinks.map((sublink, index) =>
                              (sublink.path === '/reports' && hideReportsAndPayNav) ||
                              (sublink.path === '/statements' && hasNoPayRights) ||
                              (sublink.linkText === 'Policy Upload' && noBehalfOfUser) ? null : (
                                <Link
                                  to={sublink.path}
                                  className={clsx(`${sublink.linkClassName} ${classes.p0}`, {
                                    [classes.subLinkItemActive]: location.pathname.includes(sublink.path),
                                  })}
                                  key={index}
                                  Data-QA={sublink.dataQA && sublink.dataQA}
                                  onClick={(event) => handleSublinkClick(sublink, event)}
                                >
                                  <ListItem className={classes.subLinkItemPopover} button>
                                    <ListItemText className={classes.subLinkItemTextPopover} primary={sublink.linkText}>
                                      {sublink.linkText}
                                    </ListItemText>
                                  </ListItem>
                                </Link>
                              ),
                            )}
                        </List>
                      </Popover>
                    )}
                  </>
                ) : (
                  <Link
                    onClick={() => {
                      if (
                        behalfOfUser &&
                        !userFirm?.firmId &&
                        ['Files', 'Accounting', 'AgentNet Title & Escrow', 'Reports'].includes(link.linkText)
                      ) {
                        setDisplayError(true);
                        return;
                      } else {
                        if (link.linkText === 'Apps') {
                          setShowAppSwitcher(!showAppSwitcher);
                        } else {
                          history.push(link.path);
                        }
                        setTabValue && setTabValue('0');
                      }
                    }}
                    to={link.linkText === 'Apps' ? location.pathname : link.path}
                    className={`${link.linkClassName} ${classes.p0}`}
                    // eslint-disable-next-line react/no-unknown-property
                    Data-QA={link.dataQA && link.dataQA}
                  >
                    <ListItem className={classes.linkItem} button key={link.linkText}>
                      {!menuCollapsed ? (
                        <>
                          <ListItemIcon className={classes.listItemIcon}>{link.icon}</ListItemIcon>
                          <ListItemText className={classes.linkItemText} primary={link.linkText}>
                            {link.linkText}
                          </ListItemText>
                        </>
                      ) : (
                        <Tooltip title={link.linkText}>
                          <ListItemIcon className={classes.listItemIcon}>{link.icon}</ListItemIcon>
                        </Tooltip>
                      )}
                    </ListItem>
                  </Link>
                );

                return renderThis;
              })}
          </List>

          <div
            className={clsx(classes.navBottomWrap, {
              [classes.navBottomWrapNotCollapsed]: !menuCollapsed,
              [classes.navBottomWrapAdminCollapsed]: menuCollapsed,
              [classes.navBottomWrapPortalCollapsed]: currentView !== 'portal' && menuCollapsed,
            })}
          >
            <div
              className={clsx(classes.adminToggleWrap, {
                [classes.adminToggleWrapCollapsed]: menuCollapsed,
              })}
            >
              {menuCollapsed ? (
                <Tooltip title={currentView !== 'portal' ? 'Back to Files' : 'View Admin'}>
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    onClick={currentView !== 'portal' ? onPortalSwitch : onViewSwitch}
                    className={clsx(classes.collapseBtn, classes.iconBtn, {
                      [classes.collapseBtnAdminText]: menuCollapsed,
                    })}
                  >
                    {currentView !== 'portal' ? (
                      <ExitToAppOutlined fontSize="large" />
                    ) : (
                      <SettingsOutlined fontSize="large" />
                    )}
                  </IconButton>
                </Tooltip>
              ) : (
                <AgentNetButton
                  onClick={currentView !== 'portal' ? onPortalSwitch : onViewSwitch}
                  variant="text"
                  startIcon={currentView !== 'portal' ? <ExitToAppOutlined /> : <SettingsOutlined />}
                  className={clsx(classes.collapseBtn, {
                    [classes.collapseBtnAdminText]: menuCollapsed,
                  })}
                >
                  <span className="collapseText"> {currentView !== 'portal' ? 'Back to Files' : 'View Admin'}</span>
                </AgentNetButton>
              )}
              <IconButton
                aria-label="Collapse Menu Toggle"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={handleMenuCollapse}
                className={clsx(classes.collapseToggleIconBtn, { [classes.collapseToggleIconBtnCl]: menuCollapsed })}
              >
                {menuCollapsed ? <ExpandArrow /> : <CollapseArrow />}
              </IconButton>
            </div>
          </div>
        </Drawer>
      </nav>

      <AppSwitcherLinks
        visible={showAppSwitcher}
        drawerWidth={drawerWidth}
        isHqUser={profile?.isHqUser ?? false}
        onClose={handleCloseAppSwitcher}
      />

      <FileCreateProvider>
        <FileCreateContainer {...{ openDrawer, setOpenDrawer }} />
      </FileCreateProvider>
    </>
  );
};

export default NavBar;
