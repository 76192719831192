import React from 'react';
import { Article } from '../../types';
import { createStyles, makeStyles, Theme, Grid, Typography } from '@agentnet/components';
import { ArticleTip } from './ArticleTip';
import { RenderContent } from '../RenderContent/RenderContent';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

type ArticleBodyProps = {
  article: Article | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    articleBody: {
      marginTop: theme.spacing(1),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
  }),
);
function ArticleBody({ article }: ArticleBodyProps) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container>
      {article && (
        <>
          <AgentNetButton
            className={classes.mb2}
            variant="text"
            size="medium"
            startIcon={<ArrowBack />}
            onClick={() => history.goBack()}
          >
            Back
          </AgentNetButton>
          <Grid item sm={12}>
            <Typography variant="h1">{article?.articleTitle}</Typography>
          </Grid>
          <Grid item sm={12}>
            <div className={classes.articleBody}>
              <RenderContent document={article?.articleBody} />
            </div>
          </Grid>
          <ArticleTip articleTip={article?.articleTip} />
        </>
      )}
    </Grid>
  );
}

export { ArticleBody };
